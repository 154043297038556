import React, { useEffect, useState } from 'react';
import profile from './Images/profilepic.svg'
import icon from "./Images/icon.svg"
import home from "./Images/homee.svg"
import axios from 'axios';
import { Link } from 'react-router-dom';

export default function LeadsHistoryMob() {
    const authTok = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "";
    const [lead, setLead] = useState([]);
    async function TotalLead() {
        await axios
            .get("https://pro-api.idesign.market/user/leads?pageNo=0", {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: authTok,
                },
            })
            .then((res) => {
                console.log(res?.data?.data?.data[0])
                setLead(res?.data?.data);
            })
            .catch((err) => {
                console.log(err);
            });

    }

    useEffect(() => {
        TotalLead();

    }, []);

    return (
        <div style={{ overflow:'scroll', height: "70vh" }}>
            <div className='lead_main' style={{ marginLeft: '5px' }}>
                {lead?.data && lead?.data.map((curElm) => {
                    return (
                        <div className=' d-flex main_content'>
                            <div style={{
                                backgroundColor: "rgba(59, 89, 152, 0.1)",
                                margin: "6px",
                                padding: '12px',
                                height: '55px', borderRadius: '4px'
                                // borderBottom: '1px #DFDFDF'
                            }}>
                                <img src={profile} style={{ width: '40px', height: '32px' }} />
                            </div>
                            <div className='d-flex w-100' style={{ marginLeft: '5px', marginTop: '8px' }}>
                                <div style={{ width: '100%' }}>
                                    <div className="d-flex w-100">
                                        <div className='d-flex flex-column w-100'>
                                            <div className='name' style={{ fontSize: '14px' }}>
                                                {curElm.name}
                                            </div>
                                            <div className='time' style={{ fontSize: '12px' }}>
                                                {curElm.createdAt?.slice(0, 10)}
                                            </div>

                                        </div>
                                        <div className='paisa' style={{ fontSize: '14px' }}>₹{curElm.leadPrice}</div>
                                    </div>
                                    <div className='d-flex'>
                                        <div className='d-flex' style={{ marginTop: '10px' }}>
                                            <img src={icon} style={{ width: "15px", height: "20px" }} />
                                            <div className='pricee' style={{ marginLeft: '5px' }}>{curElm.budget === '₹ 1,00,000 - ₹ 3,00,000' ? "1-3 Lacs" : curElm.budget === '₹ 3,00,000 - ₹ 5,00,000' ? "3-5 Lacs" : curElm.budget === 'Over ₹ 7,00,000' ? "over 7 Lacs" : curElm.budget === '₹ 3,00,000 - ₹ 7,00,000' ? '3-7 Lacs' : ''}</div>
                                        </div>
                                        <div className='d-flex' style={{ marginTop: '10px', marginLeft: '10px' }}>
                                            <img src={home} style={{ width: "15px", height: "20px" }} />
                                            <div className='roomss' style={{ marginLeft: '5px', fontSize: '12px' }}>{curElm.config}</div>
                                        </div>
                                        <Link className='vieww' to="/lead" style={{ marginLeft: 'auto', fontSize: '12px', marginTop: '10px' }}>View Details</Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                    )
                })}

            </div>
        </div>
    )
}