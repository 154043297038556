import React from "react";
import styles from "./ProductsCss/productSec9.module.css";
import icon1 from "./ProductsImg/chats/communication.svg";
import icon2 from "./ProductsImg/chats/heap_snapshot_thumbnail.svg";
import icon3 from "./ProductsImg/chats/screen_share.svg";
// import img9 from "./ProductsImg/chats/chats.svg";

const ProductSection9 = () => {
  return (
    <>
      <div className={styles.container} id="chat">
        <div className={styles.files_contain}>
          <div className={styles.img_div}>
            <img
              src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group%202610956.svg"
              alt=""
              className={styles.mainImg}
            />
          </div>
          <div className={styles.file_content_div}>
            <div className={styles.file_head}>CHAT</div>
            <div className={styles.file_para}>
              Enable quick and efficient communication with access to all
              project documentation. Get trackable approvals and sign offs in
              chat.
            </div>

            <div className={styles.iconDiv}>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon3} alt="" className={styles.icon} />
                </div>
                <div className={styles.icon_para}>
                  Share Files to team <br /> & client
                </div>
              </div>

              <div className={styles.eachBox}>
                <div>
                  <img src={icon2} alt="" className={styles.icon} />
                </div>
                <div className={styles.icon_para}>
                Create Multi-channels
                </div>
              </div>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon1} alt="" className={styles.icon} />
                </div>
                <div className={styles.icon_para}>
                  Real-time <br />
                  communication
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSection9;
