import React from 'react'
import CompanyDetails from '../onBoarding/CompanyDetails';
import CompanyMob2 from '../onboardingMob/CompanyMob2';
import { useState,useEffect } from 'react';
import HeaderMob from "../onboardingMob/HeaderMob";


const CompanyCombined = () => {
    const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
    const updateMedia = () => {
      setIsDesktop(window.screen.width > 767);
    };
  
    useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });
  return (
   <>
     {/* {isDesktop?<CompanyDetails/>:
     <>
     <HeaderMob/>
     <CompanyMob2/>
     </>
   
     } */}
     <CompanyDetails/>
   </>
  )
}

export default CompanyCombined;