import React from "react";
import styles from "./productMobCss/ProductSec1.module.css";
import file from "./productMobImg/fileImg/file.png";
import icon1 from "./productMobImg/fileImg/Group 2610881.png";
import icon2 from "./productMobImg/fileImg/Group 2610883.png";
import icon3 from "./productMobImg/fileImg/snake.png";

const ProductSec1 = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.heading}>
            Tools for every project & team
          </div>
          <div className={styles.para}>
            Our platform offers a range of powerful <br /> tools that will help
            streamline your operations, increase efficiency, and drive
            profitability.
          </div>

        </div>
       
      </div>
    </>
  );
};

export default ProductSec1;
