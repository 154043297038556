import React from "react";
import logo from "../../../Images/webViewLogo.svg";
import styles from "./headerNav.module.css";
import notification from "../../../Images/notificationWebIcon.svg";
import cart from "../../../Images/cartIcon.svg";
import cartImageSelected from "../../../Images/SelectedVector3.svg";
import bucket from "./headerImg/Vector (5).svg";
import notify from "./headerImg/Vector (4).svg";
import defaultProfile from "../../../Images/defaultProfileIcon.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { AiOutlineInfoCircle, AiOutlineQuestionCircle } from "react-icons/ai";
import { HiOutlineClipboardList } from "react-icons/hi";
import { BsChevronDown, BsPersonCircle } from "react-icons/bs";
import { MdOutlineReviews, MdLogout } from "react-icons/md";
import buyleadsimg from './headerImages/buyleadsimg.svg'
import manageleadsimg from './headerImages/manageleadsimg.svg'
import communityimg from './headerImages/communityimg.svg'
import threedimg from './headerImages/threedimg.svg'
import buyleadsActive from './headerImages/buyleadsActive.svg'
import manageleadsActive from './headerImages/manageleadsActive.svg'
import communityActive from './headerImages/communityActive.svg'
import threedActive from './headerImages/threedActive.svg'
import { useEffect } from "react";
import axios from "axios";
import { commgetReq, getReq } from "../../../Components/Services/api";
import { useState } from "react";

const HeaderNav = () => {
  const leadsInCart = useSelector((state) => state.addToCartReducer.leadsInCart);
  const profileData = useSelector((state) => state.addToCartReducer.profileData);
  const [newCount, setNewCount] = useState(0)
  const path = useLocation();
  const navigateTo = useNavigate();
  const goToCart = () => {
    navigateTo("/mycart");
  };
  const goToProfile = () => {
    navigateTo("/myprofile");
  };

  const goToNav = (location) => {
    navigateTo(`/${location}`);
  };

  const goLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    window.location.reload();
  };

  useEffect(async()=> {
    const res = await commgetReq(`https://community-api.idesign.market/api/post/get-community-notification?userDataId=${localStorage.getItem("userId")}`)
    if(res && !res.error){
      // console.log(res?.data?.count)
      setNewCount(res?.data?.count)
    }
  }, [])

  // console.log(newCount)

  return (
    <React.Fragment>
      <div className="lmsweb-header" style={{ height: "10vh"}}>
        <div className="d-flex" >
          <div
            className="lmsweb-header-logo"
            style={{paddingTop:"0.5rem"}}
            role="button"
            onClick={() => {
              navigateTo("/lead");
            }}
          >
            <img src={logo}/>
          </div>
          {/* <div className="header-search">
            <img src={search} />
            <input type="search" placeholder="Search" />
          </div> */}
        </div>

        <div style={{ display: "flex", width: "57%", alignItems: "flex-end", height: "100%" }}>
          <div className={path.pathname === "/lead" ? styles.headerLinksActive : styles.headerLinksInactive} onClick={() => navigateTo("/lead")}>
            {/* <img src={path.pathname === "/lead" ? manageleadsActive : manageleadsimg} style={{height: "16px", width: "16px"}} />  */}
             Manage Leads
          </div>
          <div className={path.pathname === "/buy-leads" ? styles.headerLinksActive : styles.headerLinksInactive} onClick={() => navigateTo("/buy-leads")}>
            {/* <img src={path.pathname === "/buy-leads" ? buyleadsActive : buyleadsimg} style={{ height: "16px", width: "16px" }} />  */}
            Buy Leads
          </div>
          {/* <div className={path.pathname === "/3dLanding" || path.pathname === "/3dFinal" || path.pathname === "/3dUploadCad" || path.pathname === "/3dSelectRoom" || path.pathname === "/3dMakePPT" || path.pathname === "/3dMakePPT/MakeOne" || path.pathname === "/3dMakePPT/UploadPdf" || path.pathname === "/3dCart" || path.pathname === "/3dCartHalf" ? styles.headerLinksActive : styles.headerLinksInactive} onClick={() => navigateTo("/3dLanding")}>
            <img src={path.pathname === "/3dLanding" || path.pathname === "/3dFinal" || path.pathname === "/3dUploadCad" || path.pathname === "/3dSelectRoom" || path.pathname === "/3dMakePPT" || path.pathname === "/3dMakePPT/MakeOne" || path.pathname === "/3dMakePPT/UploadPdf" || path.pathname === "/3dCart" || path.pathname === "/3dCartHalf" ? threedActive : threedimg} style={{ height: "16px", width: "16px" }} /> Book 3D Service
          </div> */}
          <div className={styles.headerLinksInactive} onClick={() => window.location.assign("https://www.idesign.market/community/")}>
            {/* <img src={communityimg} style={{ height: "16px", width: "16px" }} />  */}
            Community
            {newCount !== 0 && <div className={styles.commCount}>{newCount}</div>}
          </div>
        </div>

        <div className="lmsweb-header-controls">
          <div style={{ marginRight: "16px" }}>
            <img style={{ height: "20px",color:"#3B5998" }} src={notify} />
          </div>
          <div style={{ marginRight: "8px", marginLeft: "4px", display: "flex" }} role="button" onClick={goToCart}>
            <img style={{ height: "20px",color:"#3B5998" }} src={path.pathname === "/mycart" ? bucket : bucket} />
            {leadsInCart[0]?.data?.data?.leads?.length !== 0 && <div className="leadsInCartCounter">{leadsInCart[0]?.data?.data?.leads?.length}</div>}
          </div>
          <div style={{ marginLeft: "4px", display: "flex", alignItems: "center" }}>
            <Dropdown>
              <Dropdown.Toggle as="button" style={{ border: "none", backgroundColor: "#ffffff", marginTop: "6px" }}>
                <div className="d-flex align-items-center">
                  <div className="lmsweb-header-profile" role="button">
                    {profileData[0]?.data?.data?.imageUrl?.original ? (
                      <img style={{ width: "100%", height: "100%" }} src={profileData[0]?.data?.data?.imageUrl?.original} alt="image" />
                    ) : (
                      <img style={{ width: "100%", height: "100%" }} src={defaultProfile} alt="image" />
                    )}
                  </div>
                  <div className="ms-2">
                    <div>
                      {profileData && profileData[0]?.data?.data?.companyName} <BsChevronDown />
                    </div>
                    <div className={styles.planType}>{profileData && profileData[0]?.data?.data?.planId?._id === "6200c35b083dd9b8c3f69391" ? "Free Plan" : "Premium Plan"}</div>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className={styles.dropDownLinks} onClick={() => goToNav("myprofile")}>
                  <BsPersonCircle style={{ marginRight: "0.5rem" }} />
                  My Profile
                </Dropdown.Item>

                <div className="d-flex justify-content-center">
                  <Dropdown.Divider style={{ width: "90%" }} />
                </div>

                <Dropdown.Item className={styles.dropDownLinks} onClick={() => goToNav("my-plan-details")}>
                  <HiOutlineClipboardList style={{ marginRight: "0.5rem" }} />
                  My Plan
                </Dropdown.Item>
                <Dropdown.Item className={styles.dropDownLinks} onClick={() => goToNav("myreview")}>
                  <MdOutlineReviews style={{ marginRight: "0.5rem" }} />
                  Reviews
                </Dropdown.Item>
                {/* <Dropdown.Item className={styles.dropDownLinks} onClick={goToCart}>
                  <BsCartCheck style={{ marginRight: "0.5rem" }} />
                  My Orders
                </Dropdown.Item> */}
                <div className="d-flex justify-content-center">
                  <Dropdown.Divider style={{ width: "90%" }} />
                </div>

                <Dropdown.Item className={styles.dropDownLinks} onClick={() => goToNav("aboutidesign")}>
                  <AiOutlineInfoCircle style={{ marginRight: "0.5rem" }} />
                  About iDesign
                </Dropdown.Item>
                <Dropdown.Item className={styles.dropDownLinks} onClick={() => goToNav("howidesignworks")}>
                  <AiOutlineQuestionCircle style={{ marginRight: "0.5rem" }} />
                  How iDesign Works
                </Dropdown.Item>
                {/* <Dropdown.Item className={styles.dropDownLinks}>
                  <HiOutlineDocumentText style={{ marginRight: "0.5rem" }} />
                  Terms & Conditions
                </Dropdown.Item> */}

                <div className="d-flex justify-content-center">
                  <Dropdown.Divider style={{ width: "90%" }} />
                </div>

                <Dropdown.Item style={{ color: "#BE4C4C" }} onClick={goLogOut}>
                  <MdLogout style={{ marginRight: "0.5rem" }} />
                  Sign Out
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HeaderNav;
