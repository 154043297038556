import React, { useEffect, useState } from 'react'
import Header from '../../NewHome/Header'
import NewFooter from '../../NewHome/NewFooter'
import FAQ from '../../NewHome/FAQ'
import styles from './Common.module.css'
import JoinOnMobile from '../../NewHome/JoinOnMobile'
import CommonTols from './CommonTols'
import CommonTestimonial from './CommonTestimonial'
import CommonDemo from './CommonDemo'
import CommonIntro from './CommonIntro'
import CommonSteps from './CommonSteps'
import AboveFooter from '../../NewHome/AboveFooter'
import HeaderMob from '../../NewHome/HeaderMob'
import HamburgerPage from '../../NewHome/hamburgerMenus/HamburgerPage'
import NewTestimonialsMobile from '../../NewHome/NewTestimonialsMobile'
import Deliever from "./Images/Deliever.jpg"
import Quotation from "./Images/Quotation.jpg"
import Accounts from "./Images/Quotation.jpg"
import Vendor from "./Images/Vendor.jpg"
import Variation from "./Images/Variation.jpg"
import Leads from "./Images/Leads.jpg"


const NewWhoWeServe = () => {
    const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);

    const architectSteps = [
        {
            step: "1.",
            heading: "Improved documentation control",
            desc: "Enable versioning, facilitating real-time review and approval on the go. Accessing all drawings conveniently through the app ensures efficient project oversight and seamless collaboration.",
            image: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/a1.svg"
        },
        {
            step: "2.",
            heading: "Real-time updates and reporting",
            desc: "Enable seamless task assignment across teams while providing insights into their availability. Stay informed with instant updates, fostering efficient project management and decision-making.",
            image: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/a2.svg"
        },
        {
            step: "3.",
            heading: "Cost-tracking insights",
            desc: "Monitor resource expenses across projects or timeframes to understand project-specific billing and profit margins.",
            image: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Group%201000004452.svg"
        },
        {
            step: "4.",
            heading: "Selection Management Simplified",
            desc: "Save time and reduce errors. Access our Material library for creating client selections and effortlessly generate MOMs on the go, ready to share.",
            image: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/a4.svg"
        },
        {
            step: "5.",
            heading: "Enhanced Communication",
            desc: "Send files, images, MOM and Quotations. Chat feature allows you to boost onsite communication among clients and your team to enhance project coordination.",
            image: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Group%201000004452.svg"
        },
    ]
    const estateSteps = [
        {
            step: "1.",
            heading: "Control Team, Schedules, Budgets",
            desc: "Consistently meet deadlines through diligent progress tracking. Optimise worker efficiency, maintain organised schedules, and ensure client satisfaction by staying on time and within budget.",
            image: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/e1.png"
        },
        {
            step: "2.",
            heading: "Real-Time Monitoring",
            desc: "Monitor expenses live for full project financial insight and prevent losses from alterations.",
            image: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/e3.png"
        },
        {
            step: "3.",
            heading: "Financial Management Mastery",
            desc: "Manage POs/ WOs, track time, record all expenses, and monitor discrepancies to maintain a clear understanding of your finances and achieve target profits consistently.",
            image: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/e2.png"
        },
        {
            step: "4.",
            heading: "Lead Capture",
            desc: "Utilize our lead capture form to gather inbound leads directly into your CRM and streamline your sales pipeline for quicker deal closures.",
            image: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/e4.png"
        },
    ]
    const contractorSteps = [
        {
            step: "1.",
            heading: "Deliver on-time, everytime",
            desc: "Consistently meet deadlines with thorough project progress tracking. Enhance worker efficiency and maintain organised schedules for timely, budget-conscious results, ensuring client satisfaction.",
            image: `${Deliever}`
        },
        {
            step: "2.",
            heading: "Faster, Accurate Proposals",
            desc: "Streamlining job estimates is essential. Enhance efficiency and accuracy in delivering professional proposals with our advanced quotation feature.",
            // image: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/c2.png"
            image:`${Quotation}`
        },
        {
            step: "3.",
            heading: "Real-Time Monitoring",
            desc: "Monitor expenses live for full project financial insight and prevent losses from alterations.",
            // image: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/c3.png"
            image:`${Accounts}`
        },
        {
            step: "4.",
            heading: "Financial Management Mastery",
            desc: "Manage POs/ WOs, track time, record all expenses, and monitor discrepancies to maintain a clear understanding of your finances and achieve target profits consistently.",
            // image: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/c4.png"
            // image:"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/finManagemnt4.png"
            image:`${Vendor}`
        },
        {
            step: "5.",
            heading: "Change Order Management",
            desc: "Prevent financial losses due to alterations. Easily identify the need for change orders and swiftly send them to the customer with minimal effort.",
            // image: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/cc.png"
            image:`${Variation}`
        },
        {
            step: "6.",
            heading: "Lead Capture",
            desc: "Utilize our lead capture form to gather inbound leads directly into your CRM and streamline your sales pipeline for quicker deal closures.",
            image: `${Leads}`
        },
    ]
    const designSteps = [
        {
            step: "1.",
            heading: "Improved Documentation Control",
            desc: "Enable versioning, facilitating real-time review and approval on the go. Accessing all drawings conveniently through the app ensures efficient project oversight and seamless collaboration.",
            image: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/d2.png"
        },
        {
            step: "2.",
            heading: "Real-Time Updates and Reporting",
            desc: "Enable seamless task assignment across teams while providing insights into their availability. Stay informed with instant updates, fostering efficient project management and decision-making.",
            image: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/d1.png"
        },
        {
            step: "3.",
            heading: "Selection Management Simplified",
            desc: "Save time and reduce errors. Access our Material library for creating client selections and effortlessly generate MOMs on the go, ready to share.",
            image: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/d4.png"
        },
        {
            step: "4.",
            heading: "Sales Efficiency Boost",
            desc: "Streamline your sales and lead management procedures to increase job wins and profitability within shorter timeframes.",
            image: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/d3.png"
        },
        {
            step: "5.",
            heading: "Change Order Management",
            desc: "Prevent financial losses due to alterations. Easily identify the need for change orders and swiftly send them to the customer with minimal effort.",
            image: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/d6.png"
        },
        {
            step: "6.",
            heading: "Faster, Accurate Proposals",
            desc: "Streamlining job estimates is essential. Enhance efficiency and accuracy in delivering professional proposals with our advanced quotation feature.",
            image: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Frame%201410095544.svg"
        },
    ]
    const retailSteps = [
        {
            step: "1.",
            heading: "Control Team, Schedules, Budgets",
            desc: "Consistently meet deadlines through diligent progress tracking. Optimise worker efficiency, maintain organised schedules, and ensure client satisfaction by staying on time and within budget.",
            image: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/r1.png"
        },
        {
            step: "2.",
            heading: "Financial Management Mastery",
            desc: "Manage POs/ WOs, track time, record all expenses, and monitor discrepancies to maintain a clear understanding of your finances and achieve target profits consistently.",
            image: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/r2.png"
        },
        {
            step: "3.",
            heading: "Enhanced Communication",
            desc: "Chat feature allows you to Boost onsite communication among clients and your team to enhance project coordination.",
            image: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/r3.png"
        },
        {
            step: "4.",
            heading: "Documentation Control",
            desc: "Enable versioning, facilitating real-time review and approval on the go. Accessing all drawings conveniently through the app ensures efficient project oversight and seamless collaboration.",
            image: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/r4.png"
        },
    ]
    const [introImgMob, setIntroImgMob] = useState("")
    const [introImg, setIntroImg] = useState("")
    const [introHeading, setIntroHeading] = useState("")
    const [introSubHead, setIntroSubHead] = useState("")

    const [steps, setSteps] = useState([])
    const [stepsHeading, setStepsHeading] = useState("")
    const [openHamburger, setOpenHamburger] = useState(false)

    const [toolHeading, setToolHeading] = useState("")
    const [toolSubHead, setToolSubHead] = useState("")
    const [toolImg, setToolImg] = useState("")
    const updateMedia = () => {
        setIsDesktop(window.screen.width > 767);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    useEffect(() => {
        if (window?.location?.pathname?.includes("architect")) {
            setIntroImgMob("https://idesign-quotation.s3.ap-south-1.amazonaws.com/0/architectsMob.png")
            setIntroImg("https://idesign-quotation.s3.amazonaws.com/NO_COMPANYNAME/aintro.png")
            setIntroHeading("Empowering Architects with our innovative project management solutions")
            setIntroSubHead("Our platform offers a range of powerful tools that will help streamline your operations, increase efficiency, and drive profitability")

            setSteps(architectSteps)
            setStepsHeading("Why Architects Prefer iDesign for their Projects")

            setToolImg("https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/atool.png")
            setToolHeading("An Array of Essential Tools Interior Firms Prefer")
            setToolSubHead("iDesign consolidates all aspects of your business onto a single platform. It offers the simplest solution to streamline operations, reclaim lost time, and propel your company to new heights.")
        }
        if (window?.location?.pathname?.includes("contractor")) {
            setIntroImgMob("https://idesign-quotation.s3.ap-south-1.amazonaws.com/0/constructionMob.png")
            setIntroImg("https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/cintro.png")
            setIntroHeading("Transforming Construction Companies with Innovative Project Management Solutions")
            setIntroSubHead("Our platform offers a range of powerful tools that will help streamline your operations, increase efficiency, and drive profitability")

            setSteps(contractorSteps)
            setStepsHeading("Why Construction Companies Prefer iDesign for their Projects")

            setToolImg("https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/ctool.png")
            setToolHeading("An Array of Essential Tools Construction Companies Prefer")
            setToolSubHead("iDesign consolidates all aspects of your business onto a single platform. It offers the simplest solution to streamline operations, reclaim lost time, and propel your company to new heights.")
        }
        if (window?.location?.pathname?.includes("design")) {
            setIntroImgMob("https://idesign-quotation.s3.ap-south-1.amazonaws.com/0/designerMob.png")
            setIntroImg("https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/dintro.png")
            setIntroHeading("Empowering Interior Design Firms with Innovative Project Management Solutions")
            setIntroSubHead("Our platform offers a range of powerful tools that will help streamline your operations, increase efficiency, and drive profitability")

            setSteps(designSteps)
            setStepsHeading("Why Interior Firms Prefer iDesign for their Projects")

            setToolImg("https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/dtools.png")
            setToolHeading("An Array of Essential Tools Interior Firms Prefer")
            setToolSubHead("iDesign consolidates all aspects of your business onto a single platform. It offers the simplest solution to streamline operations, reclaim lost time, and propel your company to new heights.")
        }
        if (window?.location?.pathname?.includes("estate")) {
            setIntroImgMob("https://idesign-quotation.s3.ap-south-1.amazonaws.com/0/realMob.png")
            setIntroImg("https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/eIntro.png")
            setIntroHeading("Revolutionizing Real Estate Developers with Innovative Project Management Solutions")
            setIntroSubHead("Our platform offers a range of powerful tools that will help streamline your operations, increase efficiency, and drive profitability")

            setSteps(estateSteps)
            setStepsHeading("Why Real Estate Builders Prefer iDesign for their Projects")

            setToolImg("https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/etools.png")
            setToolHeading("An Array of Essential Tools Real Estate Developers Prefer")
            setToolSubHead("iDesign consolidates all aspects of your business onto a single platform. It offers the simplest solution to streamline operations, reclaim lost time, and propel your company to new heights.")
        }
        if (window?.location?.pathname?.includes("retail")) {
            setIntroImgMob("https://idesign-quotation.s3.ap-south-1.amazonaws.com/0/retailMob.png")
            setIntroImg("https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/rIntro.png")
            setIntroHeading("Transforming Retail Chains with Innovative Project Management Solutions")
            setIntroSubHead("Our platform offers a range of powerful tools that will help streamline your operations, increase efficiency, and drive profitability")

            setSteps(retailSteps)
            setStepsHeading("Why Retail Chains Prefer iDesign for their Projects")

            setToolImg("https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/rtool.png")
            setToolHeading("An Array of Essential Tools Retail Commpaines Prefer")
            setToolSubHead("iDesign consolidates all aspects of your business onto a single platform. It offers the simplest solution to streamline operations, reclaim lost time, and propel your company to new heights.")
        }

    }, [window?.location?.pathname])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            // behavior:"smooth"
        });
    }

    useEffect(() => {
        scrollToTop()
    })
    console.log('openHamburger', openHamburger)
    return (
        <div className={window?.location?.pathname?.includes("architect") && styles?.architect}>
            {openHamburger && <HamburgerPage openHamburger={openHamburger} setOpenHamburger={setOpenHamburger} />}
            {!openHamburger &&( isDesktop ? <Header /> : <HeaderMob openHamburger={openHamburger} setOpenHamburger={setOpenHamburger} />)}
           { !openHamburger && <CommonIntro
                heading={introHeading}
                desc={introSubHead}
                image={isDesktop ? introImg : introImgMob}
            />}
           {!openHamburger &&  <CommonSteps
                heading={stepsHeading}
                steps={steps}
            />}
           {!openHamburger &&  <CommonTols
                heading={toolHeading}
                subHeading={toolSubHead}
                image={toolImg}
            />}
         { !openHamburger &&   (isDesktop? <CommonTestimonial isDesktop={true} /> : <NewTestimonialsMobile />)}
            {!openHamburger && <div className={styles?.newfaq}>
                <FAQ />
            </div>}
            {!openHamburger && <JoinOnMobile />}
            {!openHamburger && <AboveFooter />}
            {/* <CommonDemo /> */}
            {!openHamburger && <NewFooter />}
        </div>
    )
}

export default NewWhoWeServe
