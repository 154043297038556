import React from 'react';
import styles from "../AboutMobCss/whereWeWork.module.css";

const WhereWeWorkMob = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.heading}>Where we work</div>
        <div style={{width:"100%",display:'flex',flexDirection:"column",marginTop:'2rem',justifyContent:'center',alignItems:'center'}}>
        <div style={{width:'90%',display:'flex',justifyContent:"center"}}>
            <img
              src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207493.svg"
              alt=""
              className={styles.rightImg}
            />
          </div>
          <div
            style={{
              // border: "2px solid red",
              display: "flex",
              flexDirection: "column",
              justifyContent:"center",
            //  paddingLeft:"4rem",
            alignItems:"flex-end",
              width:'90%',
              marginTop:'2rem',
            }}
          >
            <img
              src="https://idesign-office.s3.amazonaws.com/Group%202610980%20%281%29.svg"
              alt=""
              className={styles.leftImg}
            />
            <img
              src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group%202610981%20%281%29.svg"
              alt=""
              className={styles.leftImg}
            />
          </div>
          
        </div>
      </div>
    
    </>
  )
}

export default WhereWeWorkMob;