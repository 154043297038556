import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchAssignedLeads } from "../../Lms/Actions/lmsActions";
import MobHeader from "../../Lms/LmsMobileComponents/MobHeader";
import HeaderNav from "../../Lms/LmsWebComponents/HeaderNav/HeaderNav";
// import HeaderWeb from "../../Lms/LmsWebComponents/NewHeaderAndNavbar/HeaderWeb";
// import MobHeader from "../../Lms/LmsWebComponents/NewHeaderAndNavbar/MobHeader";
import LeadManagementPageMob from "./LeadManagementPageMob";
import LeadManagementPageWeb from "./LeadManagementPageWeb";

const LeadManagement = () => {
  // useEffect(() => {
  //   window.location.assign('https://www.idesign.market/leads/')
  // }, [])
  // const dispatch = useDispatch();
  // const leadBucket = useSelector((state) => state.noPersist.leadBucket);
  // const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
  // const updateMedia = () => {
  //   setIsDesktop(window.screen.width > 767);
  // };

  // useEffect(() => {
  //   window.addEventListener("resize", updateMedia);
  //   return () => window.removeEventListener("resize", updateMedia);
  // });

  // useEffect(() => {
  //   if (leadBucket) {
  //     if (leadBucket === "new") {
  //       dispatch(fetchAssignedLeads(1, 0));
  //     } else if (leadBucket === "active") {
  //       dispatch(fetchAssignedLeads(8, 0));
  //     } else if (leadBucket === "follow") {
  //       dispatch(fetchAssignedLeads(2, 0));
  //     } else if (leadBucket === "meeting") {
  //       dispatch(fetchAssignedLeads(3, 0));
  //     } else if (leadBucket === "estimate") {
  //       dispatch(fetchAssignedLeads(4, 0));
  //     } else if (leadBucket === "signed") {
  //       dispatch(fetchAssignedLeads(5, 0));
  //     } else if (leadBucket === "archive") {
  //       dispatch(fetchAssignedLeads(7, 0));
  //     } else if (leadBucket === "snooze") {
  //       dispatch(fetchAssignedLeads(6, 0));
  //     }
  //   }
  // }, [leadBucket]);



  return (
    <React.Fragment>
      {/* {isDesktop ? <HeaderNav /> : <MobHeader />}
      {isDesktop ? <LeadManagementPageWeb /> : <LeadManagementPageMob />} */}
    </React.Fragment>
  );
};

export default LeadManagement;
