import React from 'react'
import { useNavigate } from 'react-router-dom'
import SideBarWeb from '../Lms/LmsWebComponents/SideBarWeb'
import "./Vendor.css"
import VendorLandingMob from './VendorLandingMob'
import landingVen from './VendorImgs/landingVen.svg'
import SidebarWebNew from '../Lms/LmsWebComponents/SidebarNew/SidebarWebNew'
import HeaderNav from '../Lms/LmsWebComponents/HeaderNav/HeaderNav'

const VendorLanding = () => {
    const navigate = useNavigate()
    const handleRequest = () => {
        navigate("/vendor-ratelist")
    }

    return (
        <>
            <div className='d-none d-md-block'>
                <HeaderNav />
                <div className=" d-flex w-100">
                    <div className="sidBarHeight d-none d-md-block" style={{ height: "90vh", width: "22vw", borderRight: "1px solid #A7A7A7", paddingRight: "0" }}>
                        <SidebarWebNew />
                    </div>
                    <div className="d-flex w-100" style={{ background: "white" }}>
                        <div className="d-flex flex-column w-70" style={{ borderRight: "1px solid #A7A7A7", paddingTop: "2.5rem", paddingLeft: "2.5rem", paddingRight: "5rem" }}>
                            <h2 className="ven_head" style={{ fontSize: "32px" }}>Get more leads with iDesign vendor subscription plan</h2>
                            <span className="ven_sp" style={{ fontSize: "16px" }}>In this plan you will get more leads, more ways to generate your income</span>
                            {/* <h3 className="list_head">Get Started with just 3 easy steps</h3>
                            <ul className="list_start mt-2">
                                <li>Fill out the rate list</li>
                                <li>Get approved by iDesign, and you are in the vendor subscription</li>
                                <li>Do your payment</li>
                            </ul> */}
                            <button className='ven_btn' onClick={handleRequest} style={{ marginTop: "2.5rem" }}>Get Started</button>
                            <h2 className="ven-blah-blah" style={{ marginTop: "4rem" }}>How it Works</h2>
                            <div className="d-flex mt-4">
                                <div className="d-flex flex-column" style={{ width: "42%" }}>
                                    <p className="landing-ven-heder">1</p>
                                    <p className="lan-ven-start mb-0">Fill out the <strong> Rate List </strong></p>
                                    <span className="lan-ven-start2">You have to enter all your items rates in the given list, you can leave the price </span>
                                </div>
                                <div className="d-flex flex-column mx-5" style={{ width: "45%" }}>
                                    <p className="landing-ven-heder">2</p>
                                    <p className="lan-ven-start mb-0">Start you <strong> Free Trial  </strong></p>
                                    <span className="lan-ven-start2">It is a subsciption plan, which will automatically renew your subscription </span>
                                </div>
                            </div>

                        </div>
                        <div className="d-flex w-30">
                            <div className="d-flex flex-column m-4 p-3 w-100 div-bg22222" style={{ background: `url(${landingVen})`, backgroundRepeat: "no-repeat", backgroundSize: "contain" }}>
                                <p style={{
                                    fontWeight: "400",
                                    fontSize: "26px", color: "#176091", marginTop: "25px", marginLeft: "24px"
                                }}>Start your<br /> <strong> 1 month free </strong> <br /> trial access now</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <VendorLandingMob />
        </>
    )
}

export default VendorLanding