import React from "react";
import styles from "../AboutMobCss/leadershipMob.module.css";
import Carousel from "react-bootstrap/Carousel";

const LeaderShipMob = () => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.heading}>Leadership</div>
        <div className={styles.para}>Meet the iDesign.market team.</div>

        <Carousel
          nextIcon=""
          nextLabel=""
          prevIcon=""
          prevLabel=""
          style={{ width: "100%" }}
        >
          <Carousel.Item>
            <div
              style={{
                // border: "2px solid black",
                marginTop: "2rem",
                display: "flex",
                justifyContent: "space-between",
                // width: "100%",
                borderBottom:'none',
              }}
            >
              <div
                style={{
                  width: "fit-content",
                  display: "flex",
                  justifyContent: "center",
                  overflowX: "scroll",
                }}
              >
                <div style={{ width: "90%" }}>
                <div style={{display:'flex',justifyContent:'center'}}>
                  <img
                    src="https://idesign-office.s3.ap-south-1.amazonaws.com/ashish.svg"
                    alt=""
                    style={{ width: "80%", borderRadius: "8px" }}
                  />
                  </div>
                  <div style={{ marginTop: "1rem" }}>
                    <div style={{ display: "flex" }}>
                      <img
                        src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207489.svg"
                        alt=""
                      />
                      <div style={{ paddingLeft: "0.5rem" }}>
                        <div className={styles.name}>Ashish Dhingra</div>
                        <div className={styles.name}>CO-Founder and CEO</div>
                      </div>
                    </div>

                    <div style={{ textAlign: "justify", color: "#212121" }}>
                      Ashish comes with over 20 years of experiences across AEC
                      and financial industry. He is a graduate of School of
                      Planning & Architecture Delhi, and has been an
                      entrepreneur for over 10 years in the Interior and
                      Construction Space before iDesign.Market.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div
              style={{
                // border: "2px solid black",
                marginTop: "2rem",
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                borderBottom:"none"
              }}
            >
              <div
                style={{
                  width: "fit-content",
                  display: "flex",
                  justifyContent: "center",
                  overflowX: "scroll",
                }}
              >
                <div style={{ width: "90%" }}>
                  <div style={{display:'flex',justifyContent:'center'}}>
                  <img
                    src="https://idesign-office.s3.ap-south-1.amazonaws.com/sunil.svg"
                    alt=""
                    style={{ width: "80%", borderRadius: "8px" }}
                  />
                  </div>
                 
                  <div style={{ marginTop: "1rem" }}>
                    <div style={{ display: "flex" }}>
                      <img
                        src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207489.svg"
                        alt=""
                      />
                      <div style={{ paddingLeft: "0.5rem" }}>
                        <div className={styles.name}>Sunil Bhakuni</div>
                        <div className={styles.name}>CO-Founder and CEO</div>
                      </div>
                    </div>

                    <div style={{ textAlign: "justify", color: "#212121" }}>
                      Sunil has an experience of over 20 years in IT having
                      worked in senior roles such as Senior Solution Architect
                      in AWS, Founding Team Member Jabong and has been a part of
                      multiple startsups like Lenskart and Cardekho.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>

         
        </Carousel>
      </div>
    </>
  );
};

export default LeaderShipMob;
