import React from "react";
import HeaderSidebar from "../../../../AllProjectListPanel/HeaderSidebar/HeaderSidebar/HeaderSidebar";
import AllProfilePage from "./AllProfilePage";

const MainProfilePage = () => {
  return (
    <>
      <HeaderSidebar />
      <div className="container-box">
        <AllProfilePage />
      </div>
    </>
  );
};

export default MainProfilePage;
