import React, {useState, useEffect} from "react";
import Modal from 'react-bootstrap/Modal';
import styles from "./CongratulationsModal.module.css";

const CongratulationsModal = () => {
    const [show, setShow] = useState(true);    

    useEffect(() => {
        const updateStyles = () => {
          const modalContent = document.querySelector('.modal-content');
          const modalBody = document.querySelector('.modal-body');
          
          if (modalContent && modalBody) {
            const isSmallScreen = window.matchMedia("(max-width: 800px)").matches;
      
            if (isSmallScreen) {
              modalContent.style.top = "150px";
              modalContent.style.bottom = "150px";
              modalContent.style.borderRadius = "12px";
              modalContent.style.width = "70vw";
              modalBody.style.width = "70vw";
              modalContent.style.left = "50%";
              modalContent.style.transform = "translateX(-50%)";
            } else {
              modalContent.style.top = "258px";
              modalContent.style.bottom = "260px";
              modalContent.style.borderRadius = "12px";
              modalBody.style.width = "initial"; 
              modalContent.style.width = "unset";
              modalContent.style.left = "auto";
              modalContent.style.transform = "none";
              modalContent.style.marginTop = "-50px";
            }
          }
        };
      
        if (show) {
          updateStyles();
          window.addEventListener("resize", updateStyles);
        }
         return () => {
          window.removeEventListener("resize", updateStyles);
        };
      }, [show]);
      

    return (
        <div>
            <Modal show={show} size='lg' onHide={()=>setShow(!show)}>
                <Modal.Body>
                    <div>
                      <div className={styles.popperContainer}>
                    <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/image%2015.png" className={styles.poppers} />
                      <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/image%2015.png" className={styles.mirrorImagePoppers} />
                      </div>
                        <div className={styles.congratulationsImgContainer}>
                            <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/congrats.png" alt="" className={styles.congratulationsImg}  />
                        </div>
                        <div className={styles.CongratulationsContainer}>
                        <div className={styles.congratulations}>
                            Congratulations
                        </div>
                        <div className={styles.underCongratulations}>
                        Your payment is successfully done.
                        </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default CongratulationsModal; 