import React, { useEffect, useState } from 'react'
import side from './VendorImgs/side.svg'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import info from './VendorImgs/info.svg'
import { Modal, Offcanvas } from 'react-bootstrap'
import axios from 'axios'
import vendorFree from "./VendorImgs/vendorFree.svg";
import closeCanvasBar from "./VendorImgs/closeCanvasBar.svg";

const VendorRateListMobile = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);

    const authTok = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "";
    const navigate = useNavigate()
    const [showId, setShowId] = useState()
    const [ofArr, setOfArr] = useState([])

    const [venData, setVenData] = useState({
        tileFlooringFixing: "",
        wallTilesFixing: "",
        dadoPlasterForWallTiles: "",
        plainCementConcrete: "",
        brickworkHalf: "",
        brickworkFull: "",
        plastering: "",
        popFalseCeiling: "",
        popCove: "",
        popPunning: "",
        gypsumFalseCeiling: "",
        gypsumCove: "",
        woodenFalseCeiling: "",
        royalPaint: "",
        premiumEmulsion: "",
        wallPanellingWithLaminate: "",
        wallPanellingWithLaminateWithHardwoodFrame: "",
        wallPanellingVeneer: "",
        mirrorPanellingPlainRectangularWithBevelled: "",
        mirrorPanellingBeveledBorderAndColoredMirror: "",
        doorWithLaminateFinish: "",
        doorChaukathFrame: "",
        lookingMirrorBathrooms: "",
        shiftingPointsOneMetre: "",
        shiftingPointsOneToFiveMetre: "",
        newPowerPoints: "",
        falseCeilingElectricalWiring: "",
        lightInstallationOnWalls: "",
        fanInstallation: "",
        completeElectricalWiring: "",
        acCopperPiping: "",
        acDrainPipe: "",
        acInstallation: "",
    })
    const handleInput = (e) => {
        if (e.target.value?.length <= 4 && e.target?.value >= 0) {
            setVenData((prev) => {
                return {
                    ...prev,
                    [e.target.name]: e.target.value
                }
            })
        }
    }
    const dataArr = [
        {
            id: 1,
            name: "Tile Flooring Fixing",
            description: " Installation of Tiles with cement mortar/Chemical. (Tile to be provided by client at site). Tile size not to exceeed 2' x 4'. Incase of larer tiles there would be an additional fixing charge. Cost of Tile not included",
            unit: "Sq.ft",
            value: (venData.tileFlooringFixing).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "tileFlooringFixing"
        },
        {
            id: 2,
            name: "Wall Tiles Fixing",
            description: " Installation of Tiles with cement mortar/Chemical. (Tile to be provided by client at site). Tile size not to exceeed 2' x 4'. Incase of larer tiles there would be an additional fixing charge. Cost of Tile not included",
            unit: "Sq.ft",
            value: (venData.wallTilesFixing).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "wallTilesFixing"
        },
        {
            id: 3,
            name: "Dado Plaster for Wall Tiles",
            description: " Dado Plaster to be done on Wall for Installation of Tiles. (Tiles to be provided at site by client)",
            unit: "Sq.ft",
            value: (venData.dadoPlasterForWallTiles).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "dadoPlasterForWallTiles"
        },
        {
            id: 4,
            name: "PCC",
            description: "(Plain Cement Concrete) in toilets/ kitchen or any other floor as required upto 2 inch thick.",
            unit: "Sq.ft",
            value: (venData.plainCementConcrete).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "plainCementConcrete"

        },
        {
            id: 5,
            name: "Brickwork",
            description: "Making of half brick wall 115mm thick using first class bricks. 6mm thick steel reinforcement to be given after every 1 metre",
            unit: "Sq.ft",
            value: (venData.brickworkHalf).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "brickworkHalf"
        },
        {
            id: 6,
            name: "Brickwork",
            description: " Making of full brick wall 230 mm thick using first class bricks. 6mm thick steel reinforcement to be given after every 1 metre.",
            unit: "Sq.ft",
            value: (venData.brickworkFull).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "brickworkFull"
        },
        {
            id: 7,
            name: "Plastering",
            description: "Providing & Applying 10-12mm thick plaster in ratio 1:4 (1 Cement 4 Sand)",
            unit: "Sq.ft",
            value: (venData.plastering).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "plastering"
        },
        {
            id: 8,
            name: "Pop False Ceiling",
            description: "Providing and fixing False ceiling made out of POP (plaster of Paris) with GI framework",
            unit: "Sq.ft",
            value: (venData.popFalseCeiling).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "popFalseCeiling"
        },
        {
            id: 9,
            name: "POP Cove",
            description: "(Measurement taken 2 times as per internal + external cove) providing and fixing False ceiling Cove made out of POP (plaster of Paris) with GI framework",
            unit: "Sq.ft",
            value: (venData.popCove).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "popCove"
        },

        {
            id: 10,
            name: "Pop Punning on walls & ceiling",
            description: " ",
            unit: "Sq.ft",
            value: (venData.popPunning).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "popPunning"

        },
        {
            id: 11,
            name: "Gypsum False ceiling",
            description: "Providing & Fixing Gypsum False Ceiling made out of Gypsum boards on a GI Framework ",
            unit: "Sq.ft",
            value: (venData.gypsumFalseCeiling).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "gypsumFalseCeiling"
        },
        {
            id: 12,
            name: "Gypsum Cove",
            description: " Providing & Fixing Gypsum False Ceiling made out of Gypsum boards on a GI Framework",
            unit: "Rft",
            value: (venData.gypsumCove).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "gypsumCove"
        },
        {
            id: 13,
            name: "Wooden False Ceiling",
            description: "Made out of 12 mm plywood and 4 mm Veneer with a hardwood frame. Basic Cost of Veneer Rs 60 per sqft. Includes Melamine Polish. Frame to be treated with anti termite oil.  ",
            unit: "Sq.ft",
            value: (venData.woodenFalseCeiling).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "woodenFalseCeiling"
        },
        {
            id: 14,
            name: "Royal Paint (Asian Paints)",
            description: " Providing the application of 2 coats of putty and 3 coats of paint. Putty: Birla",
            unit: "Sq.ft",
            value: (venData.royalPaint).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "royalPaint"
        },
        {
            id: 15,
            name: "Premium Emulsion (Asian Paints)",
            description: " Providing the application of 2 coats of putty and coats of paint. Putty: Birla",
            unit: "Sq.ft",
            value: (venData.premiumEmulsion).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "premiumEmulsion"
        },
        {
            id: 16,
            name: "Wall panelling with laminate",
            description: "Made out of plywood with laminate finished. Laminate fixed on 16mm Plywood. Laminates of SF and HGL only of merino or century (without hardwood frame)",
            unit: "Sq.ft",
            value: (venData.wallPanellingWithLaminate).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "wallPanellingWithLaminate"
        },
        {
            id: 17,
            name: "Wall Panelling with laminate with hardwood frame",
            description: "  Wall Panelling to be done using 12mm plyboard with Laminate finish on a hardwood frame. Frame to be treated with anti termite oil. Cove to be given if required. Laminates of SF and HGL only of merino or century ",
            unit: "Sq.ft",
            value: (venData.wallPanellingWithLaminateWithHardwoodFrame).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "wallPanellingWithLaminateWithHardwoodFrame"
        },
        {
            id: 18,
            name: "Wall Panelling (Veneer)",
            description: " Wall Panelling to be done using 18mm plyboard with Veneer & Polish (Basic Cost of Veneer Rs 60 to Rs 75 per Sqft). Design to be straight lined.",
            unit: "Sq.ft",
            value: (venData.wallPanellingVeneer).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "wallPanellingVeneer"
        },
        {
            id: 19,
            name: "Mirror Panelling (Plain rectangular with bevelled as per design)",
            description: "Wall Panelling to be done using 18mm plyboard with 5mm  Plain Mirror. The mirror to be cut in rectangular shape with bevelling",
            unit: "Sq.ft",
            value: (venData.mirrorPanellingPlainRectangularWithBevelled).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "mirrorPanellingPlainRectangularWithBevelled"
        },
        {
            id: 20,
            name: "Mirror Panelling (Beveled/Border and colored mirror)",
            description: "Wall Panelling to be done using 18mm plyboard with 5mm  Beveled/border Plain/Colored Mirror. Glass: St Gobains/ Modi",
            unit: "Sq.ft",
            value: (venData.mirrorPanellingBeveledBorderAndColoredMirror).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "mirrorPanellingBeveledBorderAndColoredMirror"
        },
        {
            id: 21,
            name: "Door with Laminate finish",
            description: "(Cost of locks & handle extra as per selection) Door Size to be a maximum of 900mm x 2250mm. Brand: Bhutan Tuff Doors. Hinges: Hetich ",
            unit: "Nos",
            value: (venData.doorWithLaminateFinish).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "doorWithLaminateFinish"
        },
        {
            id: 22,
            name: "Door chaukath/frame",
            description: "of size 5 inch x 2.5 inch of Chaap/ Ivory coast with melamine polish. Chaukhat to be treated with anti termite oil",
            unit: "Nos",
            value: (venData.doorChaukathFrame).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "doorChaukathFrame"
        },
        {
            id: 23,
            name: "Looking Mirror",
            description: " in Bathrooms/ frameless on ply with bevelling as required. To be fixed on 12 mm waterproof ply . Mirror Glass: St Gobain/Modi",
            unit: "Nos",
            value: (venData.lookingMirrorBathrooms).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "lookingMirrorBathrooms"
        },
        {
            id: 24,
            name: "Wires: Polycab/Havells (upto 1 metre shifting)",
            description: " Shifting of Points with wiring chasing etc (One 6A/16A socket+Switch is 1 point)",
            unit: "Nos",
            value: (venData.shiftingPointsOneMetre).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "shiftingPointsOneMetre"
        },
        {
            id: 25,
            name: "Wires: Polycab/Havells (upto 1 metre to 5 metre shifting)",
            description: " Shifting of Points with wiring chasing etc (One 6A/16A socket+Switch is 1 point)",
            unit: "Nos",
            value: (venData.shiftingPointsOneToFiveMetre).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "shiftingPointsOneToFiveMetre"
        },
        {
            id: 26,
            name: "Wires: Polycab/Havells",
            description: "New Power Points (One 6A/16A Socket+Switch is 1 Point) (cost of switch plates is not included and to be extra or provided by client).",
            unit: "Nos",
            value: (venData.newPowerPoints).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "newPowerPoints"
        },
        {
            id: 27,
            name: "False Ceiling Electrical Wiring",
            description: " (Providing, laying 20 mm conduits & 1.5 Sq.mm Conductors wires of make Havells & , testing & commissioning including termination of wiring for Concealed ceiling light points, fan points exposed as required & installation of concealed light , cove light with complete testing). Wires: Polycab/Havells",
            unit: "Nos",
            value: (venData.falseCeilingElectricalWiring).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "falseCeilingElectricalWiring"
        },
        {
            id: 28,
            name: "Light Installation on Walls",
            description: "",
            unit: "Nos",
            value: (venData.lightInstallationOnWalls).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "lightInstallationOnWalls"
        },
        {
            id: 29,
            name: "Fan Installation ",
            description: "",
            unit: "Nos",
            value: (venData.fanInstallation).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "fanInstallation"
        },
        {
            id: 30,
            name: " Complete Electrical Wiring",
            description: "(Providing, laying 20 mm conduits & 1.5 Sq.mm and 2.5Sq.mm and 4 Sq.mm Copper Conductors wires of make Havells & , testing & commissioning including termination of wiring for Concealed ceiling light points, fan points exposed as required & installation of concealed light , cove light with complete testing & Db if required).Wires: Polycab/Havells ",
            unit: "Sq.ft",
            value: (venData.completeElectricalWiring).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "completeElectricalWiring"
        },
        {
            id: 31,
            name: "Ac Copper piping",
            description: "Includes cost of chasing of wall for piping and filling of Cement Plaster post the same. (thickness - 6 and 12mm) (upto 2 ton split ac)",
            unit: "Rft",
            value: (venData.acCopperPiping).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "acCopperPiping"
        },
        {
            id: 32,
            name: "Ac 20-25 mm drain pipe",
            description: "Includes cost of chasing of wall for piping and filling of Cement Plaster post the same",
            unit: "Rft",
            value: (venData.acDrainPipe).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "acDrainPipe"
        },
        {
            id: 33,
            name: "AC Installation ",
            description: "",
            unit: "Nos",
            value: (venData.acInstallation).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "acInstallation"
        },
    ]
    const handleShow2 = (id) => {
        setShowId(id)
        setShow2(true)
    }
    useEffect(() => {
        const b = dataArr.filter((currElem) => {
            return currElem.id == showId
        })
        setOfArr(b)
    }, [showId])

    // console.log(ofArr[0]?.name)
    function f(n) {
        return n != '' || n != undefined
    }

    const isNullish = Object.values(venData).every(value => {
        if (value === '' || value === undefined) {
            return true;
        }

        return false;
    });
    const [venId, setVenId] = useState([])

    useEffect(async () => {
        await axios.get("https://pro-api.idesign.market/user/listVendorPlans").then((venRes) => {
            console.warn(venRes)
            setVenId(venRes?.data?.data[0]?._id)
        }).catch(err => console.warn(err))
    }, [])
    const handleSubmit = async () => {
        const payload = {
            tileFlooringFixing: dataArr[0].value === "" ? 0 : parseInt(dataArr[0].value.replace(/,/g, '')),
            wallTilesFixing: dataArr[1].value === "" ? 0 : parseInt(dataArr[1].value.replace(/,/g, '')),
            dadoPlasterForWallTiles: dataArr[2].value === "" ? 0 : parseInt(dataArr[2].value.replace(/,/g, '')),
            plainCementConcrete: dataArr[3].value === "" ? 0 : parseInt(dataArr[3].value.replace(/,/g, '')),
            brickworkHalf: dataArr[4].value === "" ? 0 : parseInt(dataArr[4].value.replace(/,/g, '')),
            brickworkFull: dataArr[5].value === "" ? 0 : parseInt(dataArr[5].value.replace(/,/g, '')),
            plastering: dataArr[6].value === "" ? 0 : parseInt(dataArr[6].value.replace(/,/g, '')),
            popFalseCeiling: dataArr[7].value === "" ? 0 : parseInt(dataArr[7].value.replace(/,/g, '')),
            popCove: dataArr[8].value === "" ? 0 : parseInt(dataArr[8].value.replace(/,/g, '')),
            popPunning: dataArr[9].value === "" ? 0 : parseInt(dataArr[9].value.replace(/,/g, '')),
            gypsumFalseCeiling: dataArr[10].value === "" ? 0 : parseInt(dataArr[10].value.replace(/,/g, '')),
            gypsumCove: dataArr[11].value === "" ? 0 : parseInt(dataArr[11].value.replace(/,/g, '')),
            woodenFalseCeiling: dataArr[12].value === "" ? 0 : parseInt(dataArr[12].value.replace(/,/g, '')),
            royalPaint: dataArr[13].value === "" ? 0 : parseInt(dataArr[13].value.replace(/,/g, '')),
            premiumEmulsion: dataArr[14].value === "" ? 0 : parseInt(dataArr[14].value.replace(/,/g, '')),
            wallPanellingWithLaminate: dataArr[15].value === "" ? 0 : parseInt(dataArr[15].value.replace(/,/g, '')),
            wallPanellingWithLaminateWithHardwoodFrame: dataArr[16].value === "" ? 0 : parseInt(dataArr[16].value.replace(/,/g, '')),
            wallPanellingVeneer: dataArr[17].value === "" ? 0 : parseInt(dataArr[17].value.replace(/,/g, '')),
            mirrorPanellingPlainRectangularWithBevelled: dataArr[18].value === "" ? 0 : parseInt(dataArr[18].value.replace(/,/g, '')),
            mirrorPanellingBeveledBorderAndColoredMirror: dataArr[19].value === "" ? 0 : parseInt(dataArr[19].value.replace(/,/g, '')),
            doorWithLaminateFinish: dataArr[20].value === "" ? 0 : parseInt(dataArr[20].value.replace(/,/g, '')),
            doorChaukathFrame: dataArr[21].value === "" ? 0 : parseInt(dataArr[21].value.replace(/,/g, '')),
            lookingMirrorBathrooms: dataArr[22].value === "" ? 0 : parseInt(dataArr[22].value.replace(/,/g, '')),
            shiftingPointsOneMetre: dataArr[23].value === "" ? 0 : parseInt(dataArr[23].value.replace(/,/g, '')),
            shiftingPointsOneToFiveMetre: dataArr[24].value === "" ? 0 : parseInt(dataArr[24].value.replace(/,/g, '')),
            newPowerPoints: dataArr[25].value === "" ? 0 : parseInt(dataArr[25].value.replace(/,/g, '')),
            falseCeilingElectricalWiring: dataArr[26].value === "" ? 0 : parseInt(dataArr[26].value.replace(/,/g, '')),
            lightInstallationOnWalls: dataArr[27].value === "" ? 0 : parseInt(dataArr[27].value.replace(/,/g, '')),
            fanInstallation: dataArr[28].value === "" ? 0 : parseInt(dataArr[28].value.replace(/,/g, '')),
            completeElectricalWiring: dataArr[29].value === "" ? 0 : parseInt(dataArr[29].value.replace(/,/g, '')),
            acCopperPiping: dataArr[30].value === "" ? 0 : parseInt(dataArr[30].value.replace(/,/g, '')),
            acDrainPipe: dataArr[31].value === "" ? 0 : parseInt(dataArr[31].value.replace(/,/g, '')),
            acInstallation: dataArr[32].value === "" ? 0 : parseInt(dataArr[32].value.replace(/,/g, '')),
        }
        const res = await axios.post("https://pro-api.idesign.market/user/addContractorRateList", payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authTok
                }
            }
        ).then(async (res) => {
            console.warn(res)
            const res2 = await axios.post("https://pro-api.idesign.market/user/addVendorPlanToCart", { vendorPlanId: venId }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authTok
                }
            }).then((res2) => {
                console.warn(res2)
                navigate("/vendorcart")
            }).catch(err => console.warn(err))
        }).catch(err => console.warn(err))



    }

    const exceptThisSymbols = ["e", "E", "+", "-", "."];

    return (
        <>
            {/* <Offcanvas show={show} onHide={handleClose} placement="bottom" style={{ background: "white", height: "90vh" }}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="ven_head" style={{ fontSize: "20px" }}>Your rate list preview</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body >
                    <div className="d-flex flex-column w-100" >
                        <div className="d-flex flex-column p-4">
                            <div className="d-flex flex-column">
                                <div className="d-flex justify-content-between ">
                                    <p className="r-sno1">S No.</p>
                                    <p className="r-sno2_m" style={{ width: "40%" }}>Rate List</p>
                                    <p className="r-sno3 ">Unit</p>
                                    <p className="r-sno4 ">Price</p>
                                </div>
                                <div className="d-flex flex-column rate_main3">
                                    {dataArr.map((item, index) => (
                                        <div className="d-flex justify-content-between ">
                                            <p className='r_false3'> {item.id} </p>
                                            <p className="r_false3" style={{ width: "40%", marginLeft: "3rem" }}>{item.name}</p>
                                            <p className='r_false3'> {item.unit} </p>
                                            <p className="r_input6 mx-3">₹ {item.value}</p> 
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="d-flex">
                                <button className='rate_btn1' style={{ width: "50%" }} onClick={handleClose}>Edit</button>
                                <button className='rate_btn2 mx-2' style={{ width: "50%" }} onClick={handleSubmit}>Submit</button>
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas> */}

            <Offcanvas className="p-2" show={show2} onHide={handleClose2} placement="bottom" size="md" style={{ height: "fit-content", borderRadius: "2rem 2rem 0 0" }}>
                <div
                    className="w-100 d-flex justify-content-center mt-2"
                    onClick={handleClose2}
                >
                    <img src={closeCanvasBar} />
                </div>
                <Offcanvas.Body>
                    <div className="d-flex flex-column p-3">
                        <p className="r_false2 mb-1" style={{ fontSize: "16px", width: "90%", lineHeight: "20px" }}>{ofArr[0]?.name}</p>
                        <p className="r_desc ">{ofArr[0]?.description} </p>
                    </div></Offcanvas.Body>
            </Offcanvas>

            <div className='d-block d-md-none'>
                <Link className="d-flex align-items-center" to="/" style={{ textDecoration: "none", color: "black", height: "50px", background: "white", borderBottom: "1px solid #DFDFDF" }}>
                    <span className="d-flex align-items-center">
                        <div className="me-3 d-flex align-items-center">
                            <img style={{ width: "6px", height: "10px", marginLeft: "8px" }} src={side} />
                        </div>
                        <div className="page-Heading" style={{ fontWeight: "500" }}>Vendor Subscription</div>
                    </span>
                </Link>
                <div className="d-flex flex-column w-100" style={{ background: "white", height: "90vh" }}>
                    <div className="d-flex flex-column p-4">
                        <div className="d-flex">
                            <div className="d-flex flex-column">
                                <div className="d-flex justify-content-between">
                                    <h2 className="ven_head" style={{ fontSize: "20px" }}>Fill Out the rate list</h2>
                                    <div className="ven-free d-flex" style={{ height: "4.8vh", width: "28vw", marginTop: "-10px" }}>
                                        <img className='ven-blah m-2' style={{ height: "14px", width: "14px", alignSelf: "center" }} src={vendorFree} alt="" />
                                        <p className="ven-blah2 mb-0 mt-1" style={{ fontSize: "10px", lineHeight: "12px" }}>1 month free trial access</p>
                                    </div>
                                </div>
                                <span className="ven_sp" style={{ fontSize: "12px", lineHeight: "16px" }}>You have to enter all your items rates in the given list, you can leave the price empty if you don’t have a price for that item. </span>
                            </div>
                        </div>
                        <div className="d-flex flex-column mt-4 p-0">
                            <div className="d-flex justify-content-between ">
                                <p className="r-sno1">S No.</p>
                                <p className="r-sno2" style={{ width: "29%" }}>Rate List</p>
                                <p className="r-sno3 ">Unit</p>
                                <p className="r-sno4 ">Price</p>
                            </div>
                            <div className="d-flex flex-column rate_main2">
                                {dataArr.map((item, index) => (
                                    <div className="d-flex justify-content-between ">
                                        <p className='r_false3' style={{ width: "9%" }}> {item.id}. </p>
                                        <p className="r_false3" style={{ width: "39%" }}>{item.name}<img src={info} alt="" className="r-info" onClick={() => handleShow2(item.id)} /></p>

                                        <p className='r_false3'> {item.unit} </p>
                                        <input type="text" className="r_input4 mb-3" name={item.name2} value={item.value} onChange={handleInput} placeholder='₹ 00.00' onKeyDown={(e) => {
                                            exceptThisSymbols.includes(e.key) && e.preventDefault()
                                            // console.log(e.keyCode)
                                            if (e.keyCode == 8) {
                                                // console.log("first")
                                                if (item.value.toString().includes(',') == true) {
                                                    item.value = parseInt(item.value.toString().replace(',', ''))
                                                    document.querySelector('.r_input4').value = parseInt(item.value.toString().replace(',', ''))
                                                }
                                            }
                                        }
                                        } />
                                    </div>
                                ))}
                            </div>
                        </div>
                        {!isNullish ? <button className='rate_btn4' onClick={handleSubmit}>Submit</button> : <button className='rate_btn4' disabled>Submit</button>}
                    </div>
                </div>
            </div>
        </>
    )
}

export default VendorRateListMobile
