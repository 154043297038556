import React from "react";
import styles from "./buyLeadsCardMob.module.css";
import { MdShoppingCart } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addLeadsToCart, deleteLeadFromCart } from "../../../../../Lms/Actions";
import { getToken } from "../../../../../Components/Constants/constants";

const BuyLeadsCardMob = (props) => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const firstLetter = props.cardData && props.cardData.name.split(" ")[0] ? props.cardData.name.split(" ")[0].split("")[0].toUpperCase() : "";
  const secondLetter = props.cardData && props.cardData.name.split(" ")[1] ? props.cardData.name.split(" ")[1].split("")[0].toUpperCase() : "";
  const initials = firstLetter + secondLetter;
  const leadsInCart = useSelector((state) => state.addToCartReducer.leadsInCart);
  const cartLeadIdArr =
    leadsInCart[0] &&
    leadsInCart[0].data.data.leads.map((curElem) => {
      return curElem._id;
    });

  const sendLeadToCart = () => {
    if (props.cardData) {
      if (cartLeadIdArr && !cartLeadIdArr.includes(props.cardData._id)) {
        dispatch(addLeadsToCart(getToken(), props.cardData._id));
      } else {
        dispatch(deleteLeadFromCart(getToken(), props.cardData._id));
      }
    }
  };

  const addSingleLeadToCart = () => {
    if (props.cardData) {
      if (cartLeadIdArr && !cartLeadIdArr.includes(props.cardData._id)) {
        dispatch(addLeadsToCart(getToken(), props.cardData._id));
        navigateTo("/mycart");
      }
    }
  };
  return (
    <React.Fragment>
      <div className={styles.cardContainer}>
        <div className={styles.firstLine}>
          <div className={styles.initialsAndName}>
            <div className={styles.initialsCircle}>{initials}</div>
            <div className={styles.nameAndLocation}>
              <div className={styles.leadName}>{props.cardData && props.cardData.name}</div>
              <div className={styles.leadLocation}>
                {props.cardData && props.cardData.location ? `${props.cardData.location}, ` : ""}
                {props.cardData && props.cardData.city ? `${props.cardData.city}` : ""}
              </div>
            </div>
          </div>
          <div className={styles.timelinePill}>{props.cardData && props.cardData.renovationTimeline}</div>
        </div>

        <div className={styles.secondLine}>
          <div>
            <div className={styles.secondLineHeading}>Budget</div>
            <div className={styles.secondLineSubheading}>
              {props.cardData && props.cardData.budget === "₹ 1,00,000 - ₹ 3,00,000" ? "1 - 3 Lacs" : ""}
              {props.cardData && props.cardData.budget === "₹ 3,00,000 - ₹ 7,00,000" ? "3 - 7 Lacs" : ""}
              {props.cardData && props.cardData.budget === "Over ₹ 7,00,000" ? "Over 7 Lacs" : ""}
            </div>
          </div>
          <div>
            <div className={styles.secondLineHeading}>Work Type</div>
            <div className={styles.secondLineSubheading}>{props.cardData && props.cardData.requirements}</div>
          </div>
          <div>
            <div className={styles.secondLineHeading}>Project</div>
            <div className={styles.secondLineSubheading}>{props.cardData && props.cardData.propertyType}</div>
          </div>
        </div>

        <div className={styles.thirdLine}>
          <div className={styles.leadPrice}>₹ {props.cardData && props.cardData.leadPrice}</div>
          <div className={styles.buyButtons}>
            <button className={cartLeadIdArr && cartLeadIdArr.includes(props.cardData._id) ? styles.addToCartButtonActive : styles.addToCartButton} onClick={sendLeadToCart}>
              <MdShoppingCart />
            </button>
            <button className={styles.buyButton} onClick={addSingleLeadToCart}>
              Buy
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BuyLeadsCardMob;
