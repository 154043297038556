import React, { useEffect, useState, useRef} from "react";
import styles from "./Hero.module.css";
import { useNavigate } from "react-router-dom";

const Hero = () => {
  const navigate = useNavigate();
  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);

  const updateMedia = () => {
    setIsDesktop(window.screen.width > 767);
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const [scrollButtonVisible, setScrollButtonVisible] = useState(true);
  const [alertVisible, setAlertVisible] = useState(false);
  const buttonRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const button = buttonRef.current;
      if (button) {
        const buttonRect = button.getBoundingClientRect();
        const isVisible =
          buttonRect.top >= 0 &&
          buttonRect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight);
        setScrollButtonVisible(isVisible);
        setAlertVisible(!isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div
        style={{ position: "relative", display: "inline-block", width: "100%" }}
      >
        <img
          src={isDesktop ? "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/feat.png" :"https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/image%2065.png"}
          alt="Your Image"
          style={{
            display: "block",
            width: "100%",
            height: isDesktop ? "100%" : "25rem",
            zIndex: 2,
            height: isDesktop? "42.5rem":"28.5rem",
          }}
        />
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background:
              "linear-gradient(90deg, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.00) 100%)",
            zIndex: 1,
          }}
        ></div>
        <div
        // className={styles.heroTextContainer}
          style={{
            position: "absolute",
            top:isDesktop? "9.38rem":"4.38rem",
            left: isDesktop? "6.12rem":"2.12rem",
            right:isDesktop ?"0rem":"1.5rem",
            color: "white",
            zIndex: 4,
          }}
        ><div className={styles.firstLineContainer} style={{background:"rgba(114, 191, 254, 0.75)"
}}>
          <span className={styles.firstLine}>
            PROJECT MANAGEMENT SOFTWARE FOR CONSTRUCTION
          </span>
          </div>
          <div className={styles.secondLine}>
            Where Vision Meets Versatility: Unleashing Tools for Every Project,
            Every Team!
          </div>
          <div className={styles.thirdLine}>
            Elevate your business to new heights with our platform's powerful
            tools, engineered to streamline operations, enhance efficiency, and
            maximize profitability.
          </div>
          <div className={styles.btnContainer}>
            <div className={styles.scheduleDemoBtn}>
              <div style={{userSelect:"none"}}
                className={styles.scheduleDemoBtnText}
                onClick={() => navigate("/signup")}
                ref={buttonRef}
              >
                Get Started
              </div>
            </div>
          </div>
        </div>
        {alertVisible && (<>
        {/* <img
        style={{position:'fixed', right:'20px', bottom:'5rem', zIndex:'1'}}
          src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/topicon.png"
          alt=""
          onClick={() => scrollToTop()}
        /> */}
        <div className={styles.btnOuterContainer}>
          
          <div
            className={styles.scheduleDemoContainerSticky}
            onClick={() => navigate("/schedule")}
          >
            <div className={styles.scheduleDemoBtnText}>Schedule a demo</div>
          </div>
        </div></>)}
      </div>
    </>
  );
};

export default Hero;
