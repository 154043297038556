import React from 'react';
import styles from "./productMobCss/productSec2.module.css";
import icon1 from "../../SaasOnboarding/Products/ProductsImg/quotationImg/circle_notifications.svg";
import icon2 from "../../SaasOnboarding/Products/ProductsImg/quotationImg/grid_guides.svg";
import icon3 from "../../SaasOnboarding/Products/ProductsImg/quotationImg/inventory.svg";
// import quo from "./productMobImg/quotationImg/quotation.png";


const ProductSec2 = () => {
  return (
    <>
    <div className={styles.container} id="qo">
           <div className={styles.files_contain}>
       
       <div className={styles.file_content_div}>
         <div className={styles.file_head}>QUOTATION</div>
         <div className={styles.file_para}>
         Save time and improves the credibility of  the  interior designer by ensuring that the quotes are error-free and tailored  to the client's needs.
         </div>
         <div className={styles.img_div}>
         <img src="https://idesign-office.s3.amazonaws.com/Rectangle%207462%20%282%29.svg" alt="" className={styles.img} />
       </div>
         <div className={styles.icon_div}>
             <div className={styles.eachBox}>
               <div><img src={icon1} alt="" className={styles.mobIcon}/></div>
               <div className={styles.iconPara}>Real-time updates on the status of quotes</div>              
             </div>
             <div  className={styles.eachBox}>
               <div><img src={icon2} alt="" className={styles.mobIcon}/></div>
               <div className={styles.iconPara}>Quick Customized templates</div>
             </div>
             <div  className={styles.eachBox}>
               <div><img src={icon3} alt="" className={styles.mobIcon}/></div>
               <div className={styles.iconPara}>Accurate & Professional quotes </div>
             </div>
           </div>
       </div>
     
     </div>
     </div>
    
    
    </>
  )
}

export default ProductSec2