import React,{useState, useEffect} from 'react'
import Icon from "./Images/Icon.svg";
import right from "./Images/right.svg";
import web from "./Images/web.svg";
import app from "./Images/app.svg";
import pro from "./Images/pro.svg";
import caretRight from "./Images/CaretRight.svg";
import checkCircle from "./Images/CheckCircle.svg";
import styles from "./BoxOne.module.css";
import {mockDataBoxOne} from "./mockData";
import {additionalFeatures} from "./mockData";
import { useNavigate } from 'react-router-dom';
import styless from "./Dubai.module.css"
import {  FormControl, MenuItem, Select } from "@mui/material";
import CryptoJS from "crypto-js";

const mockData=[
    "Custom Business App",
    "Product Alignments with organization flow ",
    "Work privately on your white label Product",
    "ZOHO/META/Domain server Integrations",
    "Personalized Customizations ",
    "On-site Training",
    "Dedicated implementation and support teams",
    "High End Data Security with streamlined analytics"
]

const mockDataProfessional=[
    "Get a birds eye view of your company",
    "Efficiently capture and manage all projects",
    "On the go attendance location with Geo-location ",
    "Access for everyone, leave no one out",
    "Real-time progress reports",
    "Manage your leads in the in-built CRM",
    "Limit user access through access control",
    "Manage on the go via mobile application"
]

const BoxOne = ({scrollRef}) => {
  localStorage.setItem("fromCountry", "Sydney");
  const [clickedItem, setClickedItem] = useState(null);
    const [changePrice, setchangePrice] = useState("quarter");
    const [plan, setPlan] = useState("3 months");
  const [dubaiprices, setDubaiPrices] = useState({
    option1: 299,
    option2: 699,
    option3: 799,
    option4: 1399
  });
    const [selectedValue, setSelectedValue] = useState('option1');
    const handleDropdownChange = (event) => {
      setSelectedValue(event.target.value);
      switch(event.target.value) {
        case "option1":
            localStorage.setItem("price", changePrice === "quarter" ? 299 : 249);
            break;
        case "option2":
            localStorage.setItem("price", changePrice === "quarter" ? 699 : 399);
            break;
        case "option3":
            localStorage.setItem("price", changePrice === "quarter" ? 799 : 699);
            break;
        case "option4":
            localStorage.setItem("price", changePrice === "quarter" ? 1399 : 999);
            break;
        default:
            // Handle default case if needed
            break;
    }
    };

    const handleMenuItemClick = (value) => {
      setClickedItem(value);
    };  

useEffect(()=>{
    setDubaiPrices({
        option1: changePrice=="quarter"? 299 :249 ,
        option2: changePrice=="quarter" ? 699: 399,
        option3: changePrice=="quarter" ? 799: 699,
        option4: changePrice=="quarter" ? 1399: 999
    })
},[changePrice])

  const navigateTo= useNavigate();
    const [price, setPrice] = useState(changePrice=="quarter"? 299 :249);
    const [selectedBillingType, setSelectedBillingType] = useState("Quarterly");
    useEffect(() => {
      localStorage.setItem("paymentPlan",selectedBillingType )
      localStorage.setItem("price", price);
    }, [price]);

    function generateHashedLink(token, userId, paymentPlan, fullName, cId, price, password) {
      const encryptedData = {};
      const encryptionKey = "18B69E843399F1A2AFC5853129299";
    
      const encryptField = (field) => {
        const fieldValue = field?.toString() || ''; // Convert value to string, handle undefined
        return CryptoJS.AES.encrypt(fieldValue, encryptionKey).toString();
      };
    
      encryptedData.token = encryptField(token);
      encryptedData.userId = encryptField(userId);
      encryptedData.paymentPlan = encryptField(paymentPlan);
      encryptedData.cId = encryptField(cId);
      encryptedData.fullName = encryptField(fullName);
      encryptedData.price = encryptField(price);
      encryptedData.password = encryptField(password);
    
      const now = new Date();
      const timestamp = now.toISOString();
      const encryptedTimestamp = CryptoJS.AES.encrypt(timestamp, encryptionKey).toString();
      encryptedData.timestamp = encryptedTimestamp;
    
      const encryptKey = (key) => {
        return CryptoJS.AES.encrypt(key, encryptionKey).toString();
      };
      const url = `https://www.idesign.market/payment?${encryptKey("userId")}=${encryptedData.userId}&${encryptKey("cId")}=${encryptedData.cId}&${encryptKey("token")}=${encryptedData.token}&${encryptKey("fullName")}=${encryptedData.fullName}&${encryptKey("price")}=${encryptedData.price}&${encryptKey("paymentPlan")}=${encryptedData.paymentPlan}&${encryptKey("timestamp")}=${encryptedData.timestamp}&${encryptKey("password")}=${encryptedData.password}`;
      return url;
    }

    return (
      <div>
        <div className={styles.Mainbox_div}>
            <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", marginLeft:"auto", marginRight:"auto"}}>
          <div className={styles.box1} style={{ marginBottom:"4.62rem"}}>
             <div
          className="d-flex flex-column w-100"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className=" w-100 "
            style={{
              marginTop: "0.6rem",
              display: "flex",
            //   flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{cursor:"pointer", marginRight:"0.75rem"}}  onClick={() => {
                  setPlan("3 months");
                  setchangePrice("quarter");
                  localStorage.setItem("paymentPlan", "Quarterly" )
                  localStorage.setItem("price", 299);
                }}>Quarterly</div>
            <div
              className="d-flex p-1 align-items-center "
              style={{
                borderRadius: "3.125rem",
                boxShadow: "0px 4px 12px 0px rgba(33, 33, 33, 0.20)",
                width:" 4.625rem",
                height: "2.0625rem"
              }}
            >
              <div
                className={
                  plan === "3 months"
                    ? styless.toggleactive
                    : styless.toggleunactive
                }
                onClick={() => {
                  setPlan("3 months");
                  setchangePrice("quarter");
                  localStorage.setItem("paymentPlan", "Quarterly" )
                  localStorage.setItem("price", 299);
                }}
              >
              </div>

              <div
                className={
                  plan === "Yearly"
                    ? styless.toggleactive
                    : styless.toggleunactive
                }
                onClick={() => {
                  setPlan("Yearly");
                  setchangePrice("yearly");
                  localStorage.setItem("paymentPlan", "Yearly")
                  localStorage.setItem("price", 249);
                }}
              >
              </div>
            </div>
           <div style={{cursor:"pointer", marginLeft:"0.75rem", marginRight:"0.5rem"}}  onClick={() => {
                  setPlan("Yearly");
                  setchangePrice("yearly");
                  localStorage.setItem("paymentPlan", "Yearly")
                  localStorage.setItem("price", 249);
                }}>Yearly</div> 
            {changePrice === "yearly" ? (
                  <div className={styles.save25btn}>
                    <span className={styles.save25btnText} style={{width:"4.5rem"}}>Save 20%</span>
                  </div>
              ) : (
                <div className={styles.save25btn} style={{visibility:"hidden"}}>
                <span className={styles.save25btnText} style={{width:"4.5rem"}}>Save 20%</span>
              </div>
              )}
          </div>
        </div>
          </div>
            <div style={{display:"flex",gap:"3.88rem", justifyContent:"center", alignItems:"center"}}>
          <div className={styles.box2}>
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <div className={styles.buttons} style={{background: "#E5F4FF"}}>
                <span className={styles.buttonsText} style={{color: "#0081E8"}}>Small-mid Teams</span>
              </div>
            </div>
            <div style={{ marginTop: "1.12rem" }}>
              <div className={styles.inBoxHeading}>
              Professional
              </div>
            </div>
            <div style={{ marginTop: "1rem" }}>
              <div className={styles.inBoxSummary}>
              Free 1 TB Storage 
              </div>
            </div>
            <div style={{ marginBottom: "1.94rem", marginTop: "0.85rem", position: "relative" }}>
            <FormControl 
  sx={{ 
    width: "92%", 
    borderRadius: "0.38788rem",
border:" 0.776px solid #DEDEDE"
  }} 
>
  <Select
    labelId="demo-simple-select-helper-label"
    id="demo-simple-select-helper"
    value={selectedValue}
    onChange={handleDropdownChange}
    default="option1"
  >
    <MenuItem onClick={() => handleMenuItemClick("option1")}  style={{ color: clickedItem === "option1" ? "#017EE2" : "inherit" }} value="option1">Upto 5 users</MenuItem>
    <MenuItem onClick={() => handleMenuItemClick("option2")}  style={{ color: clickedItem === "option2" ? "#017EE2" : "inherit" }} value="option2">Upto 10 users</MenuItem>
    <MenuItem onClick={() => handleMenuItemClick("option3")}  style={{ color: clickedItem === "option3" ? "#017EE2" : "inherit" }} value="option3">Upto 20 users</MenuItem>
    <MenuItem onClick={() => handleMenuItemClick("option4")}  style={{ color: clickedItem === "option4" ? "#017EE2" : "inherit" }} value="option4">Unlimited users</MenuItem>
  </Select>
</FormControl>
    <div style={{ position: "absolute", top: "50%", right: "10px", transform: "translateY(-50%)" }}>
    </div>
</div>

            {mockDataProfessional.map((x,index)=>{
                   return <div key={index} style={{marginBottom:"0.75rem", display:"flex"}}>
                   <img src={right} alt="right" style={{ verticalAlign:"middle"}} />
                  <span style={{marginLeft:"0.75rem", fontSize:"0.9rem", fontWeight:"500"}}>{x}</span>
                  </div>
                })}
                 {selectedValue && (<div style={{ marginTop:"4.06rem"}}>
        <span style={{color: "#212123",
            fontFamily: "Inter",
            fontSize: "1.875rem",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
            letterSpacing: "-0.0625rem"}}>AUS{" "}
         {dubaiprices[selectedValue]} 
        </span>{" "}<span className={styles.afterAmount}>/month</span> </div>
      )}
            <div style={{marginTop:"2rem"}}>
            <div className={styles.blueButtonContainer}>
                <div className={styles.blueButton}>
                  <div className={styles.blueButtonText}
                   style={{cursor:"pointer", userSelect:"none"}}
                    onClick={()=>
                    {
                      if (localStorage.getItem("pricing") === "true") {
                      navigateTo("/signup");
                    } else {
                      // navigateTo("/payment");
                      window.location.assign(generateHashedLink(localStorage.getItem("token"),
                      localStorage.getItem("userId"),
                      localStorage.getItem("paymentPlan"),
                      localStorage.getItem("fullName"),
                      localStorage.getItem("cId"),
                      localStorage.getItem("price"),
                      localStorage.getItem("password")
                      )) 
                    }
                    }
                   }
                   >Get Started</div>
                  <img src={caretRight} className={styles.checkCircle} />
                </div>
              </div>
              <div
                className={styles.blueButtonContainer}
                style={{ marginTop: "0.75rem", marginBottom: "2rem" }}
              >
                <div className={styles.blueBorderButton}>
                  <div className={styles.blueBorderButtonText} 
                   style={{cursor:"pointer", userSelect:"none"}}
                  onClick={()=>navigateTo("/contact")}>
                  Let's Talk</div>
                </div>
              </div>
            </div>
            <div className={styles.showFeaturesBtn}
            style={{cursor:"pointer"}}
             onClick={()=>scrollRef.current.scrollIntoView({behavior:"smooth"})}>Show features</div>
          </div>
          <div className={styles.box2}>
            <div style={{ display: "flex" }}>
              <div className={styles.buttons} style={{background: "#E5F4FF"}}>
                <span className={styles.buttonsText} style={{color: "#0081E8"}}>Large firms</span>
              </div>
            </div>
            <div style={{ marginTop: "1.12rem" }}>
              <div className={styles.inBoxHeading}>Enterprise</div>
            </div>
            <div style={{ marginTop: "1rem" }}>
              <div className={styles.inBoxSummary}>
              Custom Integration for effortless project management in your company solution
              </div>
            </div>
            <div style={{marginTop:"1.12rem"}}>
            <div className={styles.additionalFeatures}> <img src={pro} alt="pro" className={styles.proImage} style={{marginLeft:"0rem", marginRight:"0rem"}} /> Additional features</div>
            <div >
                {mockData.map((x,index)=>{
                   return <div key={index} style={{marginBottom:"0.75rem", display:"flex"}}>
                   <img src={right} alt="right" style={{ verticalAlign:"middle"}} />
                  <span style={{marginLeft:"0.75rem", fontSize:"0.9rem", fontWeight:"500"}}>{x}</span>
                  </div>
                })}
            </div>
            <div style={{marginTop:"1.5rem"}}>
            <img src={right} alt="right" style={{ verticalAlign:"middle"}} />
            <span className={styles.yoursForeverText} style={{marginLeft:"0.75rem"}}>Yours to keep, forever.</span>
          </div>
          <img src={Icon} alt="" style={{marginTop:"-20px", marginLeft:"35px"}} />
          <div classname={styles.allBasicProText} style={{marginTop:"0.8rem", display:"flex", gap:"0.5rem"}}>
          <img src={checkCircle} alt="right" style={{ verticalAlign:"middle"}} />
          <span style={{fontWeight:"700"}}>All Professional features Included </span>
          </div>
          <div style={{marginTop:"2.9rem"}}>
          <div className={styles.blueButtonContainer} style={{visibility:"hidden"}}>
              <div className={styles.blueButton}>
                <div className={styles.blueButtonText}
                 style={{cursor:"pointer"}}
                >Get Started</div>
                <img alt="" src={caretRight} className={styles.checkCircle} />
              </div>
            </div>
          <div
                className={styles.blueButtonContainer}
                style={{ marginBottom: "2rem" }}
              >
                <div className={styles.blueBorderButton}>
                  <div className={styles.blueBorderButtonText}
                  onClick={()=>navigateTo("/contact")}
                  style={{cursor:"pointer", userSelect:"none"}}
                  >Let's Talk</div>
                </div>
              </div>
              </div>
              <div className={styles.showFeaturesBtn}
               style={{cursor:"pointer"}}
             onClick={()=>scrollRef.current.scrollIntoView({behavior:"smooth"})}
              >Show features</div>
          </div>
          </div>
          </div>
        </div>
        </div>
      </div>
    );
}

export default BoxOne