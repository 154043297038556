

export const featureArr=[
    {
        label:"File Manager",
        background:"#FEF7E6",
        textColor:"#F2AF06",
        link:"#file",
    },
    {
        label:"Quotation",
        background:"#F5E7FE",
        textColor:"#7109B6",
        link:"#quo",
    },
    {
        label:"Purchase Order",
        background:"#FDE7EA",
        textColor:"#EF233C",
        link:"#po",
    },
    {
        label:"Task Management",
        background:"#F3F5F0",
        textColor:"#718255",
        link:"#task",
    },
    {
        label:"DPR & MOM",
        background:"#F0EFF6",
        textColor:"#5E548F",
        link:"#report",
    },
    {
        label:"Accounts",
        background:"#EAF6FA",
        textColor:"#247BA0",
        link:"#aco"
    },
    {
        label:"Timelines",
        background:"#FFF2E5",
        textColor:"#FF8B13",
        link:"#time"
    },
    {
        label:"Lead Management",
        background:"#F0F0F5",
        textColor:"#2B2D42",
        link:"#lead"
    },
    {
        label:"Chat",
        background:"#FFE5ED",
        textColor:"#FF5E8F",
        link:"#chat"
    },
    {
        label:"Inventory",
        background:"#ECEAFF",
        textColor:"#6A5BFF",
        link:"#inventory"
    },
    {
        label:"Quality Check",
        background:"#F0FFEA",
        textColor:"#38D600",
        link:"#quality"
    },
    {
        label:"Timesheets",
        background:"#FBC9FF",
        textColor:"#B400C2",
        link:"#sheet"
    },
]

// arc
export const arcFeature=[
    {
        label:"File Manager",
        background:"#FEF7E6",
        textColor:"#F2AF06",
        link:"#file",
    },
    {
        label:"Quotation",
        background:"#F5E7FE",
        textColor:"#7109B6",
        link:"#quo",
    },
   
    {
        label:"Task Management",
        background:"#F3F5F0",
        textColor:"#718255",
        link:"#task",
    },
    {
        label:"DPR & MOM",
        background:"#F0EFF6",
        textColor:"#5E548F",
        link:"#report",
    },
    {
        label:"Quality Check",
        background:"#F0FFEA",
        textColor:"#38D600",
        link:"#quality"
    },
    {
        label:"Accounts",
        background:"#EAF6FA",
        textColor:"#247BA0",
        link:"#aco"
    },
     
    {
        label:"Timesheets",
        background:"#FBC9FF",
        textColor:"#B400C2",
        link:"#sheet"
    },
  
    {
        label:"Chat",
        background:"#FFE5ED",
        textColor:"#FF5E8F",
        link:"#chat"
    },
  

]

// contractor
export const conFeature=[
    {
        label:"File Manager",
        background:"#FEF7E6",
        textColor:"#F2AF06",
        link:"#file",
    },
    {
        label:"Quotation",
        background:"#F5E7FE",
        textColor:"#7109B6",
        link:"#quo",
    },
    {
        label:"Purchase Order",
        background:"#FDE7EA",
        textColor:"#EF233C",
        link:"#po",
    },
    {
        label:"Task Management",
        background:"#F3F5F0",
        textColor:"#718255",
        link:"#task",
    },
    {
        label:"DPR & MOM",
        background:"#F0EFF6",
        textColor:"#5E548F",
        link:"#report",
    },
    {
        label:"Accounts",
        background:"#EAF6FA",
        textColor:"#247BA0",
        link:"#aco"
    },
    {
        label:"Timelines",
        background:"#FFF2E5",
        textColor:"#FF8B13",
        link:"#time"
    },
    {
        label:"Lead Management",
        background:"#F0F0F5",
        textColor:"#2B2D42",
        link:"#lead"
    },
    {
        label:"Inventory",
        background:"#ECEAFF",
        textColor:"#6A5BFF",
        link:"#inventory"
    },
    {
        label:"Chat",
        background:"#FFE5ED",
        textColor:"#FF5E8F",
        link:"#chat"
    },
 
    {
        label:"Quality Check",
        background:"#F0FFEA",
        textColor:"#38D600",
        link:"#quality"
    },
  
]

export const desArr=[
    {
        label:"File Manager",
        background:"#FEF7E6",
        textColor:"#F2AF06",
        link:"#file",
    },
    {
        label:"Quotation",
        background:"#F5E7FE",
        textColor:"#7109B6",
        link:"#quo",
    },
    {
        label:"Purchase Order",
        background:"#FDE7EA",
        textColor:"#EF233C",
        link:"#po",
    },
    {
        label:"Task Management",
        background:"#F3F5F0",
        textColor:"#718255",
        link:"#task",
    },
    {
        label:"DPR & MOM",
        background:"#F0EFF6",
        textColor:"#5E548F",
        link:"#report",
    },
    {
        label:"Accounts",
        background:"#EAF6FA",
        textColor:"#247BA0",
        link:"#aco"
    },
    {
        label:"Timelines",
        background:"#FFF2E5",
        textColor:"#FF8B13",
        link:"#time"
    },
    {
        label:"Lead Management",
        background:"#F0F0F5",
        textColor:"#2B2D42",
        link:"#lead"
    },
 
    {
        label:"Inventory",
        background:"#ECEAFF",
        textColor:"#6A5BFF",
        link:"#inventory"
    },
    {
        label:"Chat",
        background:"#FFE5ED",
        textColor:"#FF5E8F",
        link:"#chat"
    },
    {
        label:"Quality Check",
        background:"#F0FFEA",
        textColor:"#38D600",
        link:"#quality"
    },
    {
        label:"Timesheets",
        background:"#FBC9FF",
        textColor:"#B400C2",
        link:"#sheet"
    },
]


export const EstateArr=[
    {
        label:"File Manager",
        background:"#FEF7E6",
        textColor:"#F2AF06",
        link:"#file",
    },
    {
        label:"Quotation",
        background:"#F5E7FE",
        textColor:"#7109B6",
        link:"#quo",
    },
    {
        label:"Purchase Order",
        background:"#FDE7EA",
        textColor:"#EF233C",
        link:"#po",
    },
    {
        label:"Task Management",
        background:"#F3F5F0",
        textColor:"#718255",
        link:"#task",
    },
    {
        label:"DPR & MOM",
        background:"#F0EFF6",
        textColor:"#5E548F",
        link:"#report",
    },
    {
        label:"Accounts",
        background:"#EAF6FA",
        textColor:"#247BA0",
        link:"#aco"
    },
    {
        label:"Timelines",
        background:"#FFF2E5",
        textColor:"#FF8B13",
        link:"#time"
    },
    {
        label:"Lead Management",
        background:"#F0F0F5",
        textColor:"#2B2D42",
        link:"#lead"
    },
   
    {
        label:"Inventory",
        background:"#ECEAFF",
        textColor:"#6A5BFF",
        link:"#inventory"
    },
    {
        label:"Chat",
        background:"#FFE5ED",
        textColor:"#FF5E8F",
        link:"#chat"
    },
    {
        label:"Quality Check",
        background:"#F0FFEA",
        textColor:"#38D600",
        link:"#quality"
    },
    {
        label:"Timesheets",
        background:"#FBC9FF",
        textColor:"#B400C2",
        link:"#sheet"
    },
]


export const retailArr=[
    {
        label:"File Manager",
        background:"#FEF7E6",
        textColor:"#F2AF06",
        link:"#file",
    },
    {
        label:"Quotation",
        background:"#F5E7FE",
        textColor:"#7109B6",
        link:"#quo",
    },
    {
        label:"Purchase Order",
        background:"#FDE7EA",
        textColor:"#EF233C",
        link:"#po",
    },
    {
        label:"Task Management",
        background:"#F3F5F0",
        textColor:"#718255",
        link:"#task",
    },
    {
        label:"DPR & MOM",
        background:"#F0EFF6",
        textColor:"#5E548F",
        link:"#report",
    },
    {
        label:"Accounts",
        background:"#EAF6FA",
        textColor:"#247BA0",
        link:"#aco"
    },
    {
        label:"Timelines",
        background:"#FFF2E5",
        textColor:"#FF8B13",
        link:"#time"
    },
   
    {
        label:"Chat",
        background:"#FFE5ED",
        textColor:"#FF5E8F",
        link:"#chat"
    },
   
    {
        label:"Quality Check",
        background:"#F0FFEA",
        textColor:"#38D600",
        link:"#quality"
    },
  
]