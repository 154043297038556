import React, { useEffect, useRef } from "react";
// import HeaderNav from '../HeaderNav'
import "./SaasOnboarding.css";
import closeEye from "./SaasAssets/closeEye.svg";
import eye from "./SaasAssets/eye.svg";
import bgmain from "./SaasAssets/bg-main.svg";
import { FcGoogle } from "react-icons/fc";
import { useState } from "react";
import SaasLogin from "./SaasLogin";
import info from "./SaasAssets/info.svg";
import union from "./SaasAssets/union.svg";
import { signInWithGoogle } from "../../../Config/firebase";
import { getDeviceId } from "../../../Helpers/utils";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { socialLogin } from "../../../Apis";
import { completeProfile, emailVerfy, handleRegister } from "../../../Redux/Actions/auth";
import Callout from "react-callout-component";
import { Overlay, Popover, Tooltip } from "react-bootstrap";
import LoginHeader from "./LoginHeader";
import SignUPMob from "../SaasOnboardingMobile/SignUpMob";
import { TextField } from "@mui/material";
import { constants } from "../../../constants";

const signUpValidationObject = {
    password: {
      required: true,
      minLength: 8,
      maxLength: 16,
      upperCasePattern: /[A-Z]/,
      specialCharacterPattern: /[!@#$%^&*()_+{}[\]:;<>,.?~|/-]/,
    },
  };
  const multipleValidationFunction = (field, value) => {
    let multipleVal = ["empty", "minlen", "maxlen", "noupper", "nospecial"];
    if (field.required && value.trim() === "") {
      let x = multipleVal;
      x = [...x, "empty"];
      multipleVal = x;
    } else {
      let x = multipleVal.filter((curElem) => {
        return curElem !== "empty";
      });
      multipleVal = x;
    }
  
    if (field.minLength && value.length < field.minLength) {
      let x = multipleVal;
      x = [...x, "minlen"];
      multipleVal = x;
    } else {
      let x = multipleVal.filter((curElem) => {
        return curElem !== "minlen";
      });
      multipleVal = x;
    }
  
    if (field.maxLength && value.length > field.maxLength) {
      let x = multipleVal;
      x = [...x, "maxlen"];
      multipleVal = x;
    } else {
      let x = multipleVal.filter((curElem) => {
        return curElem !== "maxlen";
      });
      multipleVal = x;
    }
  
    if (field.upperCasePattern && !field.upperCasePattern.test(value)) {
      let x = multipleVal;
      x = [...x, "noupper"];
      multipleVal = x;
    } else {
      let x = multipleVal.filter((curElem) => {
        return curElem !== "noupper";
      });
      multipleVal = x;
    }
  
    if (field.specialCharacterPattern && !field.specialCharacterPattern.test(value)) {
      let x = multipleVal;
      x = [...x, "nospecial"];
      multipleVal = x;
    } else {
      let x = multipleVal.filter((curElem) => {
        return curElem !== "nospecial";
      });
      multipleVal = x;
    }
    return multipleVal;
  };

  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,100}$/;

const SaasSignup = () => {
  const [errormsg, setErrorMsg] = useState("");
    const [passedChecks, setPassedChecks] = useState([]);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const navigateTo = useNavigate();
  const [passwordType, setPasswordType] = useState("password");
  const [tab, setTab] = useState(0);
  const [formData, setFormData] = useState({
    username: "",
    fname:"",
    lname:"",
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    lnameErr:"",
    nameErr: "",
    emailErr: "",
    passwordErr: "",
  });

  const [error, setError] = useState({
    lname:false,
    fname: false,
    email: false,
    password: false,
  });
  
  const [errorMsgs, setErrorMsgs] = useState({
    lnameErr:"",
    nameErr: "",
    emailErr: "",
    passwordErr: "",
  })

  const handleChange = (e) => {
    const name = e.target.name;
    const value= e.target.value;
    const alphaValue = value.replace(/[^a-zA-Z]/g, '');
    if(name=="fname"){
      if (value.length < 25  ) {
        setFormData((prev) => {
          return {
            ...prev,
            fname: alphaValue.charAt(0).toUpperCase() + alphaValue.slice(1) ,
          };
        });
        setError((prev) => {
          return {
            ...prev,
            fname: false,
          };
        });
      }
      if(value.length>=24){
        setErrorMsgs((prev) => {
          return { ...prev, nameErr: "Max character limit has been reached" };
        });
      }
      else if(value.length<=24){
        setErrorMsgs((prev) => {
          return { ...prev, nameErr: "" };
        });
      }
    }
    if(name=="lname"){
      if (value.length < 25 ) {
        setFormData((prev) => {
          return {
            ...prev,
            lname: alphaValue.charAt(0).toUpperCase() + alphaValue.slice(1) ,
          };
        });
        setError((prev) => {
          return {
            ...prev,
            lname: false,
          };
        });
      }
      if(value.length>=24){
        setErrorMsgs((prev) => {
          return { ...prev, lnameErr: "Max character limit has been reached" };
        });
      }else if(value.length<=24){
        setErrorMsgs((prev) => {
          return { ...prev, lnameErr: "" };
        });
      }
    }
    if (name == "email") {
      let x = emailRegex.test(value);
      if (!x) {
        setErrorMsg("You have entered invalid email");
      } else{
        setErrorMsg("");
      }
      setFormData((prev) => {
        return {
          ...prev,
          email: value,
        };
      });
      setError((prev) => {
        return {
          ...prev,
          email: false,
        };
      });
    }
    if(name=="password"){
      if (value.length < 16 ) {
        setFormData((prev) => {
          return {
            ...prev,
            password: value ,
          };
        });
      }
      if( value.length < 8 || !/[A-Z]/.test(value) || !/[!@#$%^&*(),.?":{}|<>]/.test(value) ){
        setErrorMsgs((prev) => {
          return { ...prev, passwordErr: "Please enter valid password" };
        });
      }else{
        setErrorMsgs((prev) => {
          return { ...prev, passwordErr: "" };
        });
      }
    }
    // if (name === "fname" || name === "lname") {
    //   e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
    // }
    // setFormData((prev) => {
    //   return { ...prev, [name]: e.target.value };
    // });
    // if (formData?.fname?.length > 0) {
    //   setErrors((prev) => {
    //     return { ...prev, nameErr: "" };
    //   });
    // }
    //   if (formData?.lname?.length > 0) {
    //     setErrors((prev) => {
    //       return { ...prev, lnameErr: "" };
    //     });
    //   }
    // if (formData?.email?.length > 0) {
    //   setErrors((prev) => {
    //     return { ...prev, emailErr: "" };
    //   });
    // }
    if (formData?.password?.length > 0) {
        let valArr = multipleValidationFunction(signUpValidationObject[name], e.target.value);
      setPassedChecks(valArr);
      setErrors((prev) => {
        return { ...prev, passwordErr: "" };
      });
    }
  };

  const handleTabs = () => {
    // setTab(1)
    // navigateTo("/login")
    window.location.assign(`${constants.PRO_URL}`);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  // console.log(tab)

  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(0);
  const [show3, setShow3] = useState(0);
  const [show4, setShow4] = useState(0);
  const target = useRef(null);
  const target2 = useRef(null);
  const target3 = useRef(null);
  const lnameTarget = useRef(null);
  const [refMain, setRefMain] = useState(null);

  const handleCreate = (e) => {
      const payload = {
        email: formData.email,
        password: formData.password,
        companyName: formData?.fname +' ' + formData.lname,
        fullName: formData?.fname +' ' + formData.lname,
        deviceId: `${getDeviceId()}`,
      };
      localStorage.setItem("initialName", formData?.fname +' ' + formData.lname);
      // localStorage.setItem("password",formData.password )
      dispatch(handleRegister(payload));
  };

  const googleLogin = () => {
    signInWithGoogle()
      .then((response) => {
        console.log(response)
        console.log(response.user.displayName);
        console.log("googleEmail", response.user.email);
        localStorage.setItem("initialName", response.user.displayName);
        socialLogin({
          email: response.user.email,
          googleId: response.user.uid,
          deviceId: `${getDeviceId()}`,
        }).then((res) => {
          console.log(res);
          window.localStorage.setItem("userId", res.data._id);
          localStorage.setItem("token", res.data.accessToken);
          // localStorage.setItem("initialName", res.data.firstName + res.data.lastName)
          window.localStorage.removeItem("sessionExpire");
          dispatch(completeProfile(res?.data));
          if (res.data.isProfileComplete) {
            // return navigateTo("/lead");
          }
          // navigateTo("/welcome");
          toast.success("Authentication successful");
           if(localStorage.getItem("pricing")=="true"){
            localStorage.setItem("isGoogleLogin", true)
            localStorage.setItem("googleEmail", response.user.email);
            navigateTo("/payment")
              localStorage.setItem("pricing", false)
            }
            else{
              localStorage.setItem("isGoogleLogin", true)
              localStorage.setItem("googleEmail", response.user.email);
              navigateTo("/pricing")
            }
        });
      })
      .catch((err) => {
        toast.error("Cannot authenticate! try again");
      });
  };

  const handleSignUp = () => {
    setTab(0);
  };
  const handleSignIn = () => {
    setTab(1);
  };

  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
  const updateMedia = () => {
    setIsDesktop(window.screen.width > 767);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

useEffect(()=>{
formData.email="";
formData.password=""
},[])

  return (
    <>
      {/* {isDesktop ? */}
      <div>
        <LoginHeader />
        <div
          className="w-100 main-saas"
          style={{
            backgroundImage: `url(${bgmain})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100%",
            backgroundPositionY: "center",
          }}
        >
          <div className="a1">
          <div className="saas-signup-header">Welcome</div>
            {/* <span className="saas-signup-span">Grow Your Business, Get Powerful Project Management Tools & Create Smart Client Presentations</span> */}
            <form className="flex-column d-flex" style={{ 
              // marginTop: "1rem",
               gap:"1rem",
               padding:"24px"
                // marginBottom:"1rem"
                 }}>
              <div
                className="d-flex align-items-center"
                style={{ position: "relative", marginBottom:"1rem" }}
              >
              <div style={{display:"flex", flexDirection:"column", gap:"20px", width:"100%"}}>
                <TextField
                error={error.fname}
                 ref={target2}
                label="First Name*"
                variant="outlined"
                className="saas-form"
                type="text"
                name="fname"
                value={formData.fname}
                onChange={handleChange}
                style={{ borderRadius: "12px", width: "100%" }}
                InputProps={{ style: { borderRadius: "12px" } }}
                fullWidth
              />
              {(errorMsgs.nameErr!=="")?<div style={{color:'red', fontSize:"10px", paddingLeft:"10px"}}>{errorMsgs.nameErr}</div>:""}
              </div>
                {/* {
                  errors.nameErr.length > 0 && (
                    <Overlay
                      target={target2.current}
                      show={show2}
                      placement="right"
                    >
                      <Tooltip id="overlay-example">
                        <img
                          src={info}
                          alt=""
                          style={{
                            height: "15px",
                            width: "15px",
                            marginRight: "5px",
                            marginBottom: "auto",
                          }}
                        />{" "}
                        {errors.nameErr}
                      </Tooltip>
                    </Overlay>
                  )

                } */}
              </div>
              <div
                className="d-flex align-items-center"
                style={{ position: "relative",  marginBottom:"1rem",  marginTop: (errorMsgs.nameErr.length > 0) ? "-20px" : "0px" }}
              >
              <div style={{display:"flex", flexDirection:"column", gap:"20px",  width:"100%"}}>
                <TextField
                error={error.lname}
                 ref={lnameTarget}
                label="Last Name*"
                variant="outlined"
                className="saas-form"
                type="text"
                name="lname"
                value={formData.lname}
                onChange={handleChange}
                style={{ borderRadius: "12px", width: "100%" }}
                InputProps={{ style: { borderRadius: "12px" } }}
                fullWidth
              />
              {(errorMsgs.lnameErr!=="")?<div style={{color:'red', fontSize:"10px", paddingLeft:"10px"}}>{errorMsgs.lnameErr}</div>:""}
              </div>
                {/* {
                  errors.lnameErr.length > 0 && (
                    <Overlay
                      target={lnameTarget.current}
                      show={show4}
                      placement="right"
                    >
                      <Tooltip id="overlay-example">
                        <img
                          src={info}
                          alt=""
                          style={{
                            height: "15px",
                            width: "15px",
                            marginRight: "5px",
                            marginBottom: "auto",
                          }}
                        />{" "}
                        {errors.lnameErr}
                      </Tooltip>
                    </Overlay>
                  )
                } */}
              </div>
              <div
                className="d-flex align-items-center"
                style={{ position: "relative", marginBottom:"1rem", marginTop: (errorMsgs.lnameErr.length > 0) ? "-20px" : "0px" }}
              >
              <div style={{display:"flex", flexDirection:"column", gap:"20px",  width:"100%"}}>
                <TextField
                error={error.email}
                ref={target}
                label="Email*"
                variant="outlined"
                className="saas-form"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                style={{ borderRadius: "12px", width: "100%" }}
                InputProps={{ style: { borderRadius: "12px" } }}
                fullWidth
              />
              {(errormsg.length>0)?<div style={{color:'red', fontSize:"10px", paddingLeft:"10px"}}>{errormsg}</div>:""}
              </div>
                {/* {errors.emailErr.length > 0 && (
                  // <div className=" d-flex align-items-center" style={{ position: "absolute", left: "23rem" }}>
                  //     <div style={{
                  //         height: "0",
                  //         borderLeft: "15px solid transparent",
                  //         borderBottom: "15px solid white",
                  //         // boxShadow: "0px 2px 8px rgb(0 0 0 / 10%)",
                  //         marginTop: "auto"
                  //     }}></div>
                  //     <div className="err-body d-flex align-items-center p-2" style={{ color: "#575757", fontWeight: "400", fontSize: "12px", borderRadius: "4px 4px 4px 0px", boxShadow: " 0px 2px 8px rgba(0, 0, 0, 0.1)", lineHeight: "14px", width: "13rem" }}> <img src={info} alt="" style={{ height: "15px", width: "15px", marginRight: "5px" }} />{errors.emailErr}</div>
                  // </div>
                  <Overlay
                    target={target.current}
                    show={show}
                    placement="right"
                  >
                    <Tooltip id="overlay-example">
                      <img
                        src={info}
                        alt=""
                        style={{
                          height: "15px",
                          width: "15px",
                          marginRight: "5px",
                          marginBottom: "auto",
                        }}
                      />{" "}
                      {errors.emailErr}
                    </Tooltip>
                  </Overlay>
                )} */}
              </div>
              <div
                className="d-flex align-items-center"
                style={{ position: "relative", marginBottom:"1rem", marginTop: (errormsg.length > 0) ? "-20px" : "0px" }}
              >
                <TextField
                error={error.password}
                 ref={target3}
                label="Password*"
                variant="outlined"
                className="saas-form"
                type={passwordType}
                name="password"
                value={formData.password}
                onChange={handleChange}
                style={{ borderRadius: "12px", width: "100%" }}
                InputProps={{ style: { borderRadius: "12px" } }}
              />
                {passwordType === "password" ? (
                  <img
                    src={eye}
                    alt=""
                    className="eye"
                    onClick={togglePassword}
                  />
                ) : (
                  <img
                    src={closeEye}
                    alt=""
                    className="eye"
                    onClick={togglePassword}
                  />
                )}
                {/* {errors.passwordErr.length > 0 && (
                  //  <div className=" d-flex align-items-center" style={{ position: "absolute", left: "23rem" }}>
                  //     <div style={{
                  //         height: "0",
                  //         borderLeft: "15px solid transparent",
                  //         borderBottom: "15px solid white",
                  //         // boxShadow: "0px 2px 8px rgb(0 0 0 / 10%)",
                  //         marginTop: "auto"
                  //     }}></div>
                  //     <div className="err-body d-flex align-items-center p-2" style={{ color: "#575757", fontWeight: "400", fontSize: "12px", borderRadius: "4px 4px 4px 0px", boxShadow: " 0px 2px 8px rgba(0, 0, 0, 0.1)", lineHeight: "14px", width: "13rem" }}> <img src={info} alt="" style={{ height: "15px", width: "15px", marginRight: "5px" }} />{errors.passwordErr}</div>
                  // </div>
                  <Overlay
                    target={target3.current}
                    show={show3}
                    placement="right"
                  >
                    <Tooltip id="overlay-example">
                      <img
                        src={info}
                        alt=""
                        style={{
                          height: "15px",
                          width: "15px",
                          marginRight: "5px",
                          marginBottom: "auto",
                        }}
                      />{" "}
                      {errors.passwordErr}
                    </Tooltip>
                  </Overlay>
                )} */}
              </div>
              {formData.password.length > 0 && (
                <div className="mb-3">
                  <div className="errorHead">Your password must contain</div>
                  <div className={passedChecks.includes("nospecial") ? "success" : "errorSubHead"}>
                    <li>A special character (e.g. @!$%^&*#).</li>
                  </div>
                  <div className={passedChecks.includes("noupper") ? "success" : "errorSubHead"}>
                    <li>At least one capital letter (P)</li>
                  </div> 
                  <div className={passedChecks.includes("minlen") || passedChecks.includes("maxlen") ? "success" : "errorSubHead" }>
                    <li>Minimum character should be 8 characters and maximum 15 characters</li>
                  </div>
                </div>
              )}
            </form>
            <div style={{padding:"0px 24px"}}>
            <button className="create222"
             onClick={()=>{
               Object.keys(formData).forEach((curElem) => {
                    if (formData[curElem]) {
                      setError((prev) => {
                        return {
                          ...prev,
                          [curElem]: false,
                        };
                      });
                    } else {
                      setError((prev) => {
                        return {
                          ...prev,
                          [curElem]: true,
                        };
                      });
                    }
                  });
                  if (formData.password !== "" && formData.email !== "" && formData.lname !== "" && formData.fname !== "" ) {
                    // handleCreate()
                    if (errormsg !== "") {
                        toast.error(errormsg);
                      }
                       else if (passedChecks.length > 0 || errorMsgs.passwordErr !== "") {
                        toast.error(errorMsgs.passwordErr);
                      }
                       else {
                        console.log("uhuhuhuh")
                        handleCreate();
                      }
                  }
            }
              } style={{userSelect:"none", marginBottom:"24px"}}>
              Create
            </button>
            </div>
          {/*  <div className="or-start">
              <div
                className="empty-line"
                style={{ visibility: "hidden" }}
              ></div>
              <span className="saas-or">OR</span>
              <div
                className="empty-line"
                style={{ visibility: "hidden" }}
              ></div>
            </div>
            <div style={{padding:"0px 24px 24px 24px"}}>
            <div className="create2-main" onClick={googleLogin} style={{cursor:"pointer", userSelect:"none",}}>
              <FcGoogle className="googgle" /><span>Sign up with Google</span> 
            </div>
            </div> */}
            <div style={{padding:"0px 24px 24px 24px", borderTop:"1px solid #e0e3e6" }}>
            <div className="saas-by mt-3">
              On signing up{" "}
              <Link
                to="/terms"
                style={{
                  fontWeight: "500",
                  textDecoration: "underline",
                  color: "#888888",
                }}
              >
                {" "}
                Terms of use{" "}
              </Link>{" "}
              and{" "}
              <Link
                to="/privacy-policy"
                style={{
                  fontWeight: "500",
                  textDecoration: "underline",
                  color: "#888888",
                }}
              >
                {" "}
                Privacy Policy{" "}
              </Link>
            </div>
            <div className="saas-by mt-3">
              Already have an account?{" "}
              <span
                style={{
                  fontWeight: "500",
                  color: "#3B5998",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={handleTabs}
              >
                Sign in{" "}
              </span>
            </div>
            </div>
          </div>
        </div>
        {/* <img src={zIndex: "1"} alt="" style={{width: "", height: "", position: "absolute", bottom: "0"}} /> */}
      </div>
      {/* : <SignUPMob />} */}
    </>
  );
};

export default SaasSignup;
