import React from 'react'

const SideBarNew = () => {
  return (
    <>
     <div style={{width:"18vw"}}>
        side
     </div>
    </>
  )
}

export default SideBarNew;