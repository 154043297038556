export const projectManagement=
    {
        mainHeading: "project management",
        subHeading:"Deliver on-time everytime!",
        para:"Master projects by keeping an eye on what's important—every file, task, and moment counts. Stick to a reasonable budget and stay on schedule to guarantee success. Pay close attention to detail to make sure everything adds up to victory. Keep things clear and in control by monitoring what matters most throughout the project. Stay ahead of the game, tackling problems head-on to keep things moving and reach your goals."
    }

export const projectManagementArray = [
    { route:"/fileManager", heading: "File Manager", description: "Upload, organize, and control your project files seamlessly. Collaborate with precision through reviews, comments, and feedback.", buttonText: "button" },
    { route:"/taskManager",heading: "Task Manager", description: "Boost project efficiency with thorough task management. Easily track progress, assign tasks, and encourage collaboration.", buttonText: "button" },
    { route:"/mom",heading: "Minute of Meeting (MOM)", description: "Effortlessly capture every meeting moment with MOM feature. From crafting agendas to jotting down notes, stay organized & informed. ", buttonText: "button" },
    { route:"/materialRequest",heading: "Material Request", description: "Streamline material management by enabling easy requests, reviews, and issuances for smoother project operations.", buttonText: "button" },
    { route:"/rfi",heading: "RFI", description: "Enhance project clarity with customizable RFIs. Easily manage inquiries, track responses, and mitigate impacts effectively.", buttonText: "button" },
    { route:"/timelineTimesheet",heading: "Timeline & Timesheet", description: "Effortlessly track progress and manage resources with our timesheet and timeline feature for timely project delivery", buttonText: "button" },
];
export const CostManagement=
    {
        mainHeading: "cost management",
        subHeading:"Unify Business Processes",
        para:"Streamline cost management throughout your project journey. Create detailed quotations with ease and manage invoices, payments, and purchase orders seamlessly. By centralising financial processes, achieve optimal efficiency and transparency. Gain clarity and control over project expenses at every stage. Simplify your project lifecycle in a single platform."
    }
;
export const costManagementArray=[
    {
        route:"/quotation",
        heading:"Quotation",
        description:"Precision quoting made easy: Customize, compare, and create seamlessly.",
        buttonText: "button" 
    },
    {
        route:"/account",
        heading:"Accounts",
        description:"Create, track, and summarise transactions with ease. Gain financial clarity for smarter decision-making.",
        buttonText: "button" 
    },
    {
        route:"/vendorPO",
        heading:"Vendor & PO",
        description:"Simplify catalogues, orders, and approvals seamlessly. Streamline purchasing processes for optimal cost control.",
        buttonText: "button" 
    }
]
export const leadManagement=
    {
        mainHeading:"Client Management",
        subHeading:"Empower Engagement, Enhance Collaboration",
        para:"Revolutionize client interactions with our comprehensive ERP solution. Seamlessly manage leads from inception to conversion with intuitive lead management tools. Foster seamless collaboration with built-in chat functionality, connecting teams and clients effortlessly. Enhance communication, boost productivity, and drive success with our integrated client management feature. Experience a new level of efficiency and satisfaction in client relationships."
    }

export const leadManagementArray=[
    {
        route:"/newLead",
        heading:"Leads",
        description:"Manage, track, and nurture leads from inception to conversion. Gain insights, take action, and accelerate growth with our integrated lead management tools",
        buttonText: "button" 
    },
    {
        route:"/chat",
        heading:"Chat",
        description:"Communicate, collaborate, and conquer with our chat feature. Seamlessly connect with team members & clients, sharing quotes, meeting minutes, & files instantly. ",
        buttonText: "button" 
    }
]
export const additional=
    {
        mainHeading:"Additional features",
        subHeading:"Elevate Efficiency, Maximize Potential",
        para:"From comprehensive item libraries to streamlined inventory management, we've got you covered. Seamlessly track attendance, manage teams, and oversee company operations with ease. Stay connected on the go with our native mobile app, integrated with popular platforms for seamless collaboration. Plus, enjoy robust support and flexible training options to ensure your success, online or offline."
    }

export const additionalArray=[
    {
        route:"/libraryTemplates",
        heading:"Library & Templates",
        description:"Access ready-made resources like quotations, purchase orders, lead forms, checklists, and MOM templates to simplify workflows.",
        buttonText: "button" 
    },
    {
        route:"/itemLibraryInventory",
        heading:"Item Library & Inventory",
        description:"Enhance organization with our material management solution, streamlining item creation, editing, categorization, and bill of materials.",
        buttonText: "button" 
    },
    {
        route:"/attendance",
        heading:"Attendance",
        description:"Efficiently track attendance, whether on or offsite, and manage team or individual attendance through detailed reports.",
        buttonText: "button" 
    },
    {
        route:"/companyManagement",
        heading:"Company Management ",
        description:"Manage multiple sites, branches, and work timings with ease. Streamline organization-wide processes.",
        buttonText: "button" 
    },
    {
        route:"/teamManagement",
        heading:"Team Management",
        description:"Effortlessly define roles, permissions, and hierarchies, visualize organization structure, and gain insights into team performance.",
        buttonText: "button" 
    },
    {
        route:"/mobile",
        heading:"Native Mobile App",
        description:"Define roles, permissions, hierarchies, visualize structure, & analyze team performance to gain valuable insights effortlessly.",
        buttonText: "button" 
    },
    {
        route:"/extras",
        heading:"Extras",
        description:"Seamlessly integrate with platforms like Zoho for effortless collaboration. Access robust support and comprehensive training.",
        buttonText: "button" 
    }
]