import React, { useEffect, useRef, useState } from "react";
import styles from "./headerSidebar.module.css";
// import logo from "../../../Assets/comp_logo.svg";
import {
  AiOutlineLeft,
  AiOutlinePlus,
  AiOutlineShoppingCart,
} from "react-icons/ai";
import { BsBell, BsChevronDown } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { sidebarLinks } from "./sidebarLinks";
// import { constants, BASE_URL_ESS } from "../../../../constants";
// import { getReq } from "../../../../api";
import { getReq } from "../../../Components/Services/api";

import { BASE_URL_ESS, constants } from "../../../constants";
// import {BASE_URL_ESS, constants} from "../../../../src/Cons"
// import { getToken } from "../../../piservices/authService";


const HeaderSidebar = ({ type }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState({});
  const [aclData, setAclData] = useState([]);

  const getProfileData = async () => {
    const res = await getReq(
      `${constants.B2B_BASE}/b2b/user/profile`,
      {},
      { Authorization: `Bearer ${localStorage.getItem("token")}` }
    );
    if (res && !res.error) {
      setProfileData({ ...res.data.data });
    }
    // else {
    //   console.log(res.error);
    //   localStorage.clear();
    //   window.location.assign(`${BASE_URL_ESS}`);
    // }
  };
  const getAclData = async () => {
    const res = await getReq(
      `${
        constants.B2B_BASE
      }.b2b/user/get-features-list?userId=${localStorage.getItem("userId")}`
    );
    if (res && !res.error) {
      // setProfileData({ ...res.data.data });
      setAclData(res?.data?.data);
      // console.log(res.data)
    }
    // else {
    //   console.log(res.error);
    //   localStorage.clear();
    //   window.location.assign(`${BASE_URL_ESS}`);
    // }
  };

  // console.log(aclData)

  const gotohome = () => {
    if (localStorage.getItem("token")) {
      window.location.assign(`${BASE_URL_ESS}admin/`);
    } else {
      window.location.assign(`${BASE_URL_ESS}/`);
    }
  };

  useEffect(() => {
    getProfileData();
    getAclData();
  }, []);

  const handleButtonClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleDropdownItemClick = () => {
    localStorage.clear();
    window.location.assign(`${BASE_URL_ESS}/`);
    setIsDropdownOpen(false);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
  const updateMedia = () => {
    setIsDesktop(window.screen.width > 767);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  return (
    <div
      className={`${styles.container} ${isDesktop ? "" : "d-none"}`}
      style={{ zIndex: isDropdownOpen ? "999" : "0" }}
    >
      <div className={styles.header}>
        <div className={styles.logobox} onClick={gotohome}>
          <a href={`${BASE_URL_ESS}admin/`} style={{ cursor: "pointer" }}>
            <img
              src="https://www.idesign.market/static/media/webViewLogo.5cbd374d615d9703f27f54b017238455.svg"
              alt=""
              height={28}
            />
          </a>
        </div>
        <div className={styles.menubox}>
          <div className={styles.eachHeaderLink}>
            <div
              onClick={() =>
                window.location.assign("https://www.idesign.market/leads/")
              }
            >
              Manage Leads
            </div>
          </div>
          <div className={styles.eachHeaderLink}>
            <div
              onClick={() =>
                window.location.assign("https://www.idesign.market/buy-leads")
              }
            >
              Buy Leads
            </div>
          </div>
          {/* <div className={styles.eachHeaderLink}>
            <div
              onClick={() =>
                window.location.assign("https://www.idesign.market/3dLanding")
              }
            >
              Book 3D Service
            </div>
          </div> */}
          <div className={styles.eachHeaderLink}>
            <div
              onClick={() =>
                window.location.assign("https://www.idesign.market/community/")
              }
            >
              Community
            </div>
          </div>
        </div>
        <div className={styles.profilebox}>
          <div className={styles.notification}>
            <BsBell />
          </div>
          <div className={styles.cart}>
            <AiOutlineShoppingCart />
          </div>
          <div className={styles.profileNameBox} onClick={handleButtonClick}>
            <div className={styles.profileName}>
              {profileData.companyName}{" "}
              <BsChevronDown style={{ marginLeft: "0.25rem" }} />
            </div>
            {isDropdownOpen && (
              <div className={styles.dropdown} ref={dropdownRef}>
                <div
                  className={styles.dropdownItem}
                  onClick={handleDropdownItemClick}
                >
                  Sign Out
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={
          type === "collapsed" ? styles.sidebarCollapsed : styles.sidebar
        }
      >
        <div style={{ height: "100%" }}>
          <div
            className={
              type === "collapsed"
                ? styles.collapsedCreateProjectButtonWrapper
                : styles.createProjectButtonWrapper
            }
          >
            <button className={styles.createProjectButton}>
              <AiOutlinePlus
                style={
                  type === "collapsed"
                    ? { marginRight: "0" }
                    : { marginRight: "0.25rem" }
                }
              />
              {type !== "collapsed" ? "Create a Project" : ""}
            </button>
          </div>
          <div className={styles.linksContainerWrapper}>
            <div className={styles.linksContainer}>
              {sidebarLinks.map((curElem, index) => {
                // console.log((aclData.includes(curElem?.accessName) || curElem?.accessName === "default"))
                return (
                  curElem.visible &&
                  (aclData.includes(curElem?.accessName) ||
                    curElem?.accessName === "default") && (
                    <div
                      key={index}
                      className={
                        curElem.active
                          ? styles.activeSidebarItem
                          : styles.sidebarItem
                      }
                    >
                      {curElem.link ? (
                        <Link
                          to={curElem.link}
                          className={
                            type === "collapsed"
                              ? styles.collapsedSidebarLink
                              : styles.sidebarLink
                          }
                        >
                          {curElem.icon}
                          {type !== "collapsed" && (
                            <span className={styles.sidebarLabel}>
                              {curElem.label}
                            </span>
                          )}
                        </Link>
                      ) : (
                        <a
                          href={curElem.href}
                          className={
                            type === "collapsed"
                              ? styles.collapsedSidebarLink
                              : styles.sidebarLink
                          }
                        >
                          {curElem.icon}
                          {type !== "collapsed" && (
                            <span className={styles.sidebarLabel}>
                              {curElem.label}
                            </span>
                          )}
                        </a>
                      )}
                    </div>
                  )
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderSidebar;
