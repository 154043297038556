import React from "react";
import styles from "./productMobCss/productSec5.module.css";
import icon1 from "../../SaasOnboarding/Products/ProductsImg/reports/bar_chart.svg";
import icon2 from "../../SaasOnboarding/Products/ProductsImg/reports/start.svg";
import icon3 from "../../SaasOnboarding/Products/ProductsImg/reports/legend_toggle.svg";
// import quo from "./productMobImg/reports/rep.png";

const ProductSec5 = () => {
  return (
    <>
      <div className={styles.container} >
        <div className={styles.files_contain}>
          <div className={styles.file_content_div}>
            <div className={styles.file_head}>Daily Project Report</div>
            <div className={styles.file_para}>
              Ensures that all team members are aware <br /> of the current status of
              the project and can make informed decisions accordingly.
            </div>
            <div className={styles.img_div}>
              <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207465%20%281%29.svg" alt="" className={styles.img} />
            </div>
            <div className={styles.icon_div}>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon1} alt="" className={styles.mobIcon} />
                </div>
                <div className={styles.iconPara}>
                  Improved project accountability
                </div>
              </div>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon3} alt="" className={styles.mobIcon} />
                </div>
                <div className={styles.iconPara}>Project status updates</div>
              </div>

              <div className={styles.eachBox}>
                <div>
                  <img src={icon2} alt="" className={styles.mobIcon} />
                </div>
                <div className={styles.iconPara}>
                  Real-time progress tracking
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSec5;
