import React from "react";
import BuyLeadsContentMob from "./Components/BuyLeadsComponentsMob/BuyLeadsContentMob/BuyLeadsContentMob";

const BuyLeadsPageMob = () => {
  return (
    <React.Fragment>
      <BuyLeadsContentMob />
    </React.Fragment>
  );
};

export default BuyLeadsPageMob;
