import React from 'react';
import styles from "../onboardingMobCss/completeMob.module.css";
// import main from "../footerImg/reports/reports.png";
// import icon from "../footerImg/reports/Vector.png";
// import icon1 from "../footerImg/task/Vector.png";
// import icon2 from "../footerImg/reports/Vector.png";
import check from '../footerImg/reports/check.svg';
import { useNavigate } from 'react-router-dom';
import { BASE_URL_ESS } from '../../../../../constants';

const CompleteMob3 = () => {
    const navigate=useNavigate();
  return (
    <>
  <div className={styles.container}>
        {/* second div */}
        <div className={styles.second_contain}>
            <div className={styles.PersonalContent_div}>
                <div style={{display:"flex",justifyContent:'space-between',alignItems:'center',width:'80%'}}>
                <div className={styles.personal_head}>Great Radhika, A few more steps & we are done</div>
                </div>
             <button className={styles.inputs1}><img src={check} alt="" className={styles.check}/> Complete your Profile</button>
             <button className={styles.inputs1}><img src={check} alt="" className={styles.check}/>Add Company Details</button>
             <button className={styles.inputs} onClick={()=>navigate("/profile")}>Create a Project Listing</button>
           {/* <a href="https://www.idesign.market/file-beta/" className={styles.inputs} style={{textDecoration:"none",textAlign:"center"}}>Upload new file to file manager</a> */}
            {/* <a href="https://www.idesign.market/quo-beta/enterprise-landing-page" className={styles.inputs} style={{textDecoration:"none",textAlign:"center"}}> Create a design quotation</a> */}
           <a href={BASE_URL_ESS} className={styles.skip} style={{textDecoration:'none'}}>Skip to dashboard  {">>"}</a>
            </div>
          
        </div>
     </div>
     
    
    </>
  )
}

export default CompleteMob3;