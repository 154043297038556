import React from "react";
import styles from "./myprofileCss/personDetails.module.css";
import axios from "axios";
import { useEffect, useState } from "react";

const PersonDetails = ({ editable }) => {
  const [data, setdata] = useState();
  const authTok = localStorage.getItem("token")
    ? `Bearer ${localStorage.getItem("token")}`
    : "";
  const getPersonalDetails = async () => {
    await axios
      .get("https://api.idesign.market/b2b/user/profile", {
        headers: {
          "Content-Type": "application/json",
          Authorization: authTok,
        },
      })
      .then(function (response) {
        console.log(response?.data?.data);
        setdata(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getPersonalDetails();
  }, []);

  const [input, setinput] = useState({
    fname: "",
    lname: "",
    email: "",
    address: "",
    dob: "",
    number: "",
  });
  const handleinput = (event) => {
    const { name, value } = event.target;
    setinput((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (data) {
      let x = input;
      x["fname"] = data.firstName ? data.firstName : "";
      x["lname"] = data.lastName ? data.lastName : "";
      x["email"] = data.email ? data.email : "";
      x["address"] = data.address ? data.address : "";
      x["number"] = data.phoneNumber ? data.phoneNumber : "";
      setinput({ ...x });
    }
  }, [data]);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.inputTopDiv}>
          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <span className={styles.names}>First Name</span>
            <input
              type="text"
              className={editable ? styles.topInputActive : styles.topInput}
              name="fname"
              value={input.fname}
              onChange={handleinput}
            />
          </div>

          <div
            style={{ display: "flex", flexDirection: "column", width: "50%" }}
          >
            <span className={styles.names}>Last Name</span>
            <input
              type="text"
              className={editable ? styles.topInputActive : styles.topInput}
              name="lname"
              value={input.lname}
              onChange={handleinput}
            />
          </div>
        </div>

        <div className={styles.restInput}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "1.5rem",
            }}
          >
            <span className={styles.names}>Email Address</span>
            <input
              type="text"
              className={editable ? styles.topInputActive : styles.topInput}
              name="email"
              value={input.email}
              onChange={handleinput}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "1.5rem",
            }}
          >
            <span className={styles.names}>Address</span>
            <input
              type="text"
              className={editable ? styles.topInputActive : styles.topInput}
              name="address"
              value={input.address}
              onChange={handleinput}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "1.5rem",
            }}
          >
            <span className={styles.names}>Date of Birth</span>
            <input
              type="date"
              className={editable ? styles.topInputActive : styles.topInput}
              name="dob"
              value={input.dob}
              onChange={handleinput}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "1.5rem",
            }}
          >
            <span className={styles.names}>Mobile Number</span>
            <input
              type="number"
              className={editable ? styles.topInputActive : styles.topInput}
              name="number"
              value={input.number}
              onChange={handleinput}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonDetails;
