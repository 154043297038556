export const mockDataBoxOne = [
  " Location based attendance Tracking",
  " Work privately, with data security",
  "Collaborate With teams across the globe in real-time",
];

export const additionalFeatures = [
  "Native app",
  "Product Alignments with organization flow ",
  "Work privately on your white label Product",
  "ZOHO/META/Domain server Integrations",
  "Personalized Customizations ",
  "On-site Training",
  "Dedicated implementation and support teams",
  "High End Data Security with streamlined analytics",
];

export const mockArray = [
  {
    heading: "Project management",
    subHeadings: [
      {
        subHead: "Timelines & Gantt Chart",
        toolTip: "Visualize project schedules for effective time management.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "Task Management & DPR",
        toolTip:
          " Streamline tasks and track daily progress reports efficiently.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "Subtasks & Dependencies",
        toolTip:
          "Manage project tasks and their interdependencies for smoother workflows.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "Create Checklist Tasks",
        toolTip: " Organize tasks systematically with customizable checklists.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "Raise Observations",
        toolTip:
          "Promptly report project issues and observations for swift resolution.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "Raise Material Request",
        toolTip: "Request materials seamlessly to keep projects on track.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "Inventory",
        toolTip:
          "Monitor and manage project resources effectively with inventory tracking.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "Minutes of Meeting",
        toolTip:
          " Document meeting discussions and decisions for future reference.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "Quality Check",
        toolTip:
          "Ensure project quality standards are met through systematic checks.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "File Management System",
        toolTip:
          "Organize and access project files efficiently for collaboration.",
        pro: "yes",
        ent: "yes",
      },
    ],
  },
  {
    heading: "cost management",
    subHeadings: [
      {
        subHead: "Quotation Tool",
        toolTip: " Generate project quotations accurately and efficiently.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "Purchase Order",
        toolTip:
          "Manage procurement processes smoothly with purchase order tracking.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "Accounts",
        toolTip:
          "Track project finances and transactions with comprehensive accounting.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "Bill of Materials",
        toolTip:
          "Create detailed lists of project materials for accurate costing.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "Item Master",
        toolTip:
          "Maintain a centralized database of project items for easy reference.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "Running Bills",
        toolTip:
          "Monitor ongoing project expenses and billing activities in real-time.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "Vendor Management",
        toolTip: " Efficiently handle vendor relationships and communications.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "Create Templates",
        toolTip:
          "Save time by creating standardized templates for cost-related documents.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "Budget Management",
        toolTip:
          "Track expenses and generate insightful reports",
        pro: "yes",
        ent: "yes",
      },
    ],
  },
  {
    heading: "client & lead management",
    subHeadings: [
      {
        subHead: "Client Login",
        toolTip:
          " Provide secure access for clients to monitor project progress.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "Client Approvals",
        toolTip:
          " Simplify approval processes for client feedback and decisions.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "Lead Management",
        toolTip: "Track and manage potential project leads effectively.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "Assign Leads",
        toolTip: "Allocate leads to team members for follow-up and conversion.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "GPS Based Attendance",
        toolTip: "Monitor attendance using GPS technology for accuracy.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "Group & One-One Chat",
        toolTip:
          "Foster communication through both group and private chat options.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "Automated Emails",
        toolTip:
          "Automate email notifications for timely client communications.",
        pro: "yes",
        ent: "yes",
      },
      // {
      //   subHead: "Listing on iDesign",
      //   toolTip: "Showcase projects on iDesign for increased visibility.",
      //   pro: "yes",
      //   ent: "yes",
      // },
      {
        subHead: "Team Reports",
        toolTip:
          "Generate comprehensive reports on team performance and project metrics.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "Role Based Access",
        toolTip:
          "Control access permissions based on roles for enhanced security.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "Notifications",
        toolTip:
          "Stay updated with timely notifications on project activities.",
        pro: "yes",
        ent: "yes",
      },
      {
        subHead: "IOS/Android App",
        toolTip:
          "Access project management features conveniently via mobile apps.",
        pro: "yes",
        ent: "yes",
      },
      // {
      //   subHead: "Book 3D View",
      //   toolTip:
      //     "Allow clients to book 3D views of project designs for visualization.",
      //   pro: "yes",
      //   ent: "yes",
      // },
      // {
      //   subHead: "Buy from Lead Bank",
      //   toolTip: "Enable lead conversion by offering direct purchase options.",
      //   pro: "yes",
      //   ent: "yes",
      // },
    ],
  },
  {
    heading: "additional benefits",
    subHeadings: [
      {
        subHead: "Custom E-mail Domain (SNS)",
        toolTip: "Personalize email communication with a custom domain name.",
        pro: "no",
        ent: "yes",
      },
      // {
      //   subHead: "Basic Implementation Team Support",
      //   toolTip:
      //     "Receive support from the implementation team for basic setup needs.",
      //   pro: "no",
      //   ent: "yes",
      // },
      {
        subHead: "Online training",
        toolTip: "Access training materials and sessions conveniently online.",
        pro: "no",
        ent: "yes",
      },
      {
        subHead: "On-site training",
        toolTip:
          "Benefit from in-person training sessions for hands-on learning experiences.",
        pro: "no",
        ent: "yes",
      },
      {
        subHead: "Advanced Implementation Team Support",
        toolTip:
          "Get expert assistance for complex implementation requirements.",
        pro: "no",
        ent: "yes",
      },
      {
        subHead: "Custom Deployment",
        toolTip:
          "Tailor the deployment process to suit specific project needs.",
        pro: "no",
        ent: "yes",
      },
      {
        subHead: "Integrations",
        toolTip:
          "Seamlessly integrate with other tools and systems for enhanced functionality and efficiency.",
        pro: "no",
        ent: "yes",
      },
    ],
  },
];
