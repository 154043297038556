import React, { useState, useEffect } from "react";
import styles from "../cssFiles/task.module.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import axios from 'axios'

const Tasks = () => {
  const [delayedTasks, setDelayedTasks] = useState([])
  const [approaching, setApproaching] = useState([])
  const getTasks = async () => {
    await axios.get(`https://pmt-api.essentiaenvironments.com/api/timelines2/get-task-clientDashboard?clientId=${localStorage.getItem('userId')}`).then((res) => {
      // setData(res.data)
      setDelayedTasks(res.data.delayedTasks)
      setApproaching(res.data.approachingDeadlineTasks)
    })
    .catch(err => {
      console.log(err)
    })
  }

  useEffect(() =>{
    getTasks()
  }, [])

  return (
    <>
      <div className={styles.container}>
        <div
          style={{
            width: "52%",
            paddingRight: "2rem",
            borderRight: "1px solid #E6E6E6",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div className={styles.fileTextt}>Files Summary</div>
            <div className={styles.filesmall}>Total Task: {delayedTasks.length + approaching.length}</div>
          </div>

          <div style={{ display: "flex", width: "100%", gap: "6rem" }}>
            {/* <div
              style={{
                // border: "2px solid red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <CircularProgressbar
                percentage={60}
                text={`${6}/10`}
                className={styles.cicularbar}
                // stroke={Red}
              />
              <div className={styles.circularText}>
                Pending <br /> Tasks
              </div>
            </div> */}

            <div
              style={{
                // border: "2px solid red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <CircularProgressbar
                percentage={60}
                text={`${delayedTasks.length}/${delayedTasks.length + approaching.length}`}
                className={styles.cicularbar}
                stroke={"red"}
              />
              <div className={styles.circularText}>
                Delayed <br /> Tasks
              </div>
            </div>
            <div
              style={{
                // border: "2px solid red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <CircularProgressbar
                percentage={60}
                text={`${approaching.length}/${delayedTasks.length + approaching.length}`}
                className={styles.cicularbar}
              />
              <div className={styles.circularText}>
                Approaching <br /> Deadline
              </div>
            </div>
          </div>
        </div>

        <div style={{ width: "48%", paddingLeft: "1rem" }}>
          <div className={styles.pending}>Pendencies</div>
          <div className={styles.fileDiv}>
            <div className={styles.fileText}>
              <img
                src="https://idesign-office.s3.ap-south-1.amazonaws.com/receipt_long.svg"
                alt=""
                style={{ height: "1.5rem" }}
              />
              <div style={{ marginLeft: "0.7rem" }}>
                <div>Material Request</div>
                <div className={styles.date}>Due on 1st Mar 23</div>
              </div>
            </div>
          </div>

          <div className={styles.fileDiv}>
            <div className={styles.fileText}>
              <img
                src="https://idesign-office.s3.ap-south-1.amazonaws.com/price_change.svg"
                alt=""
                style={{ height: "1.5rem" }}
              />
              <div style={{ marginLeft: "0.7rem" }}>
                <div>Payment Request</div>
                <div className={styles.date}>Due on 1st Mar 23</div>
              </div>
            </div>

            <div className={styles.fileText1}>
              <div>Amount</div>
              <div>₹3,00,000</div>
            </div>
          </div>

          <div className={styles.fileDiv}>
            <div className={styles.fileText}>
              <img
                src="https://idesign-office.s3.ap-south-1.amazonaws.com/price_change.svg"
                alt=""
                style={{ height: "1.5rem" }}
              />
              <div style={{ marginLeft: "0.7rem" }}>
                <div>Payment Request</div>
                <div className={styles.date}>Due on 1st Mar 23</div>
              </div>
            </div>

            <div className={styles.fileText1}>
              <div>Amount</div>
              <div>₹3,00,000</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tasks;
