import React from 'react';
import styles from "../onboardingMobCss/fewMob.module.css";
import check from '../footerImg/reports/check.svg';
import { BASE_URL_ESS } from '../../../../../constants';

const FewMob = () => {
  return (
    <>
    <div className={styles.container}>
        {/* second div */}
        <div className={styles.second_contain}>
            <div className={styles.PersonalContent_div}>
                <div style={{display:"flex",justifyContent:'space-between',alignItems:'center',width:'80%'}}>
                <div className={styles.personal_head}>Great Radhika, A few more steps & we are done</div>
                </div>
             <button className={styles.inputs1}><img src={check} alt="" className={styles.check}/> Complete your Profile</button>
             <button className={styles.inputs1}><img src={check} alt="" className={styles.check}/>Add Company Details</button>
             <button className={styles.inputs1}><img src={check} alt="" className={styles.check}/>Create a Project Listing</button>
             {/* <a href="https://www.idesign.market/file-beta/" className={styles.inputs} style={{textDecoration:"none",textAlign:"center"}}>Upload new file to file manager</a>
             <a href="https://www.idesign.market/quo-beta/enterprise-landing-page" className={styles.inputs} style={{textDecoration:"none",textAlign:"center"}}> Create a design quotation</a> */}
           <a href={BASE_URL_ESS} className={styles.skip} style={{textDecoration:"none"}}>Skip to dashboard{">>"}</a>
            </div>
          
        </div>
     </div>
    </>
  )
}

export default FewMob;