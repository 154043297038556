const initialState = {
  buyLeads: [],
  leadsInCart: [],
  assignedLeads: {},
  footBannerState: true,
};

const lmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_LEADS":
      return {
        ...state,
        buyLeads: action.payload,
      };

    case "SET_FOOT_BANNER":
      return {
        ...state,
        footBannerState: action.payload,
      };

    case "SAVE_ASSIGNED_LEADS":
      return {
        ...state,
        assignedLeads: { ...action.payload },
      };

    default:
      return state;
  }
};
export default lmsReducer;
