import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLmsBuyLeads } from "../../Lms/Actions/lmsActions";
import MobHeader from "../../Lms/LmsMobileComponents/MobHeader";
import HeaderNav from "../../Lms/LmsWebComponents/HeaderNav/HeaderNav";
// import HeaderWeb from "../../Lms/LmsWebComponents/NewHeaderAndNavbar/HeaderWeb";
// import MobHeader from "../../Lms/LmsWebComponents/NewHeaderAndNavbar/MobHeader";
import BuyLeadsPageMob from "./BuyLeadsPageMob";
import BuyLeadsPageWeb from "./BuyLeadsPageWeb";

const BuyLeads = () => {
  const dispatch = useDispatch();
  const buyLeadsFilterBudget = useSelector((state) => state.noPersist.buyLeadsFilterBudget);
  const buyLeadsFilterWork = useSelector((state) => state.noPersist.buyLeadsFilterWork);
  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
  const updateMedia = () => {
    setIsDesktop(window.screen.width > 767);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  useEffect(() => {
    dispatch(fetchLmsBuyLeads(100, buyLeadsFilterBudget, buyLeadsFilterWork));
  }, [buyLeadsFilterBudget, buyLeadsFilterWork]);
  return (
    <React.Fragment>
      {isDesktop ? <HeaderNav /> : <MobHeader />}
      {isDesktop ? <BuyLeadsPageWeb /> : <BuyLeadsPageMob />}
    </React.Fragment>
  );
};

export default BuyLeads;
