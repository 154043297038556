import React, { useState, useEffect } from "react";
import BasicMobDubai from "./BasicMobDubai";
import BasicMobSydney from "./BasicMobSydney";
import EnterpriseMobSydney from "./EnterpriseMobSydney";
import EnterpriseMobDubai from "./EnterpriseMobDubai";
import BasicMob from "./BasicMob";
import EnterpriseMob from "./EnterpriseMob";

const LocationComponent = ({change, forScroll}) => {
  const [location, setLocation] = useState({
    latitude: 20.5937, // India's latitude
    longitude: 78.9629, // India's longitude
  });
  
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }
  }, []);

  const determineLocationComponent = () => {
    if (!location) {
      return (
        <>
      <div style={{ width: "fit-content", display: "flex", gap: "1.52rem", flexDirection:"column", justifyContent:"center" }}>
        <BasicMobSydney change={change} forScroll={forScroll} />
        <EnterpriseMobSydney forScroll={forScroll} />
        </div>
        </>
      )
    }
    const { latitude, longitude } = location;
    if (
      latitude >= -33.87 &&
      latitude <= -33.55 &&
      longitude >= 151.18 &&
      longitude <= 151.35
    ) {
      return(
        <>
      <div style={{ width: "fit-content", display: "flex", gap: "1.52rem", flexDirection:"column", justifyContent:"center" }}>
         <BasicMobSydney change={change} forScroll={forScroll} />
        <EnterpriseMobSydney forScroll={forScroll} />
        </div>
        </>
      )
    } else if (
      latitude >= 25.06 &&
      latitude <= 25.38 &&
      longitude >= 55.08 &&
      longitude <= 55.25
    ) {
      return(
        <>
     <div style={{ width: "fit-content", display: "flex", gap: "1.52rem", flexDirection:"column", justifyContent:"center" }}>
        <BasicMobDubai change={change} forScroll={forScroll} />
       <EnterpriseMobDubai  forScroll={forScroll} />
       </div>
       </>
      )
    } else {
      return(
        <>
       <div style={{ width: "fit-content", display: "flex", gap: "1.52rem", flexDirection:"column", justifyContent:"center" }}>
        <BasicMob change={change} forScroll={forScroll} />
       <EnterpriseMob  forScroll={forScroll} />
       </div>
       </>
      )
    }
  };
  return <div>{determineLocationComponent()}</div>;
};

export default LocationComponent;
