import React, { useState, useEffect } from "react";
import LoginHeader from "../../Lms/LmsWebComponents/SaasOnboarding/LoginHeader";
import Footer from "../../Lms/LmsWebComponents/SaasOnboarding/PricingPage/Footer";
import HeaderNav from "../../Lms/LmsWebComponents/HeaderNav/HeaderNav";
import "./Schedule.css";
import myimage from "./RAD.png";
import { Box, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { Button } from "react-bootstrap";
import FooterMobile from "../../Lms/LmsWebComponents/SaasOnboardingMobile/FooterMobile";
import HeaderMob from "../../Lms/LmsWebComponents/SaasOnboarding/PricingPage/onboardingMob/HeaderMob";
// import HeaderMob from "../../Lms/LmsMobileComponents/HeaderMob"
import axios from "axios";
import MuiPhoneNumber from "material-ui-phone-number";


const Schedule = () => {
  const [demostate, setDemostate] = useState({
    name: "",
    company: "",
    email: "",
    // phone: "",
    companyType: "",
    companyTurnover: "",
    designation: "",
    number:"",
  });

  const [error, setError] = useState({
    name: false,
    email: false,
    company: false,
    number: false,
  });

  const [errormsg, setErrorMsg] = useState("");
  const [PhnError, setPhnError] = useState("");
  const [finalPhoneNumber, setFinalPhoneNumber] = useState("");
  const [dialCode, setDialCode] = useState("");
  // const allowedDomain = ["gmail.com", "yahoo.com", "outlook.com"];
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,100}$/;
  const scrollToTop=()=>{
    window.scrollTo({
      top:0,
      // behavior:"smooth"
    });
  }
   useEffect(()=>{
   scrollToTop()
  })

  function removeSpecialCharacters(inputString) {
    const specialCharsRegex = /[()\-_\W]/g;
        const cleanedString = inputString.replace(specialCharsRegex, '');
    return cleanedString;
}

  const handleChange = (e, countryCode) => {
    let { name, value } = e.target;
    if (name === "phone") {
      if (e.target.value.length <= 10) {
        setDemostate((prev) => {
          return { ...prev, ["phone"]: e.target.value };
        });
        if (value.length < 10) {
          setPhnError("10 digit");
        } else {
          setPhnError("");
        }
        setError((prev) => {
          return {
            ...prev,
            number: false,
          };
        });
      }
    } else if (name === "email") {
      let x = emailRegex.test(value);
      if (!x) {
        setErrorMsg("Invalid Email");
      } else {
        setErrorMsg("");
        // if (value.includes("@") && allowedDomain.includes(value.split("@")[1])) {
        //   setErrorMsg(`${value.split("@")[1]} not allowed`);
        // } else {
        //   setErrorMsg("");
        // }
      }
      setDemostate((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
      setError((prev) => {
        return {
          ...prev,
          email: false,
        };
      });
    }else if(name==="company"){
      
      setDemostate((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
      setError((prev) => {
        return {
          ...prev,
          company: false,
        };
      });
    }else if(name==="name"){
      setDemostate((prev) => {
        return {
          ...prev,
          [name]: value.charAt(0).toUpperCase() + value.slice(1),
        };
      });
      setError((prev) => {
        return {
          ...prev,
          name: false,
        };
      });
    }
    else if(name==="number"){
      // const dialCode = "+" + e?.target?.countryCode?.dialCode;
      setDialCode( e?.target?.countryCode?.dialCode)
      const trimmedValue = removeSpecialCharacters(value);
      // console.log("isha dialCode", dialCode);
      // console.log("isha trimmedValue", trimmedValue)
      // console.log("isha", removeSpecialCharacters(value).replace(e?.target?.countryCode?.dialCode, ''))
      setFinalPhoneNumber(removeSpecialCharacters(value).replace(e?.target?.countryCode?.dialCode, ''))
      // console.log("isha", typeof(e.target.countryCode.dialCode))
      setDemostate((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
      if(e?.target?.countryCode?.dialCode == "91"){
        if((removeSpecialCharacters(value).replace(e?.target?.countryCode?.dialCode, ''))?.length>9){
          setError((prev) => {
            return {
              ...prev,
              number: false,
            };
          });
        }else{
          setError((prev) => {
            return {
              ...prev,
              number: true,
            };
          });
        }
      }else{
        if((removeSpecialCharacters(value).replace(e?.target?.countryCode?.dialCode, ''))?.length>1){
          setError((prev) => {
            return {
              ...prev,
              number: false,
            };
          });
        }else{
          setError((prev) => {
            return {
              ...prev,
              number: true,
            };
          });
        }
      }
    }
     else {
      setDemostate((prev) => {
        return { ...prev, [name]: value };
      });
      // setError((prev) => {
      //   return {
      //     ...prev,
      //     [name]: false,
      //   };
      // });
    }
  };

  const handleQuerySubmit = async () => {
    // alert(demostate)
    // console.log("above", demostate)
    if (
      demostate.name?.length > 0 &&
      demostate.company?.length > 0 &&
      demostate.email?.length > 0 &&
      demostate.number?.length > 0
      //  &&
      // demostate.companyType?.length > 0 &&
      // demostate.companyTurnover?.length > 0 &&
      // demostate.designation?.length > 0
       &&
      errormsg==="" &&
      PhnError===""
    )
      {
        await axios
        .post("https://api.idesign.market/b2b/user/schedule-demo", 
        {
          name: demostate.name,
          companyName: demostate.company,
          email: demostate.email,
          phoneNumber: demostate.number,
          companyType: demostate.companyType,
          companyTurnover: demostate.companyTurnover,
          designation: demostate.designation,
        })
        .then(function (response) {
          setDemostate({
            name: "",
            company: "",
            email: "",
            phone: "",
            number: "",
            companyType: "",
            companyTurnover: "",
            designation: "",
          });

          alert("Registration Success");
        })
        .catch((err) => console.log("Error in Upload", err));
      }
      else{
        console.log(demostate)
        // alert("Else is working")
      }
  };

  const exceptThisSymbols = ["e", "E", "+", "-", "."];

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   alert("hi")
  //   // handleQuerySubmit();
  //   if (dialCode === "91" && finalPhoneNumber.length < 10) {
  //     // Set the number error to true
  //     alert("yes")
  //     setError((prev) => ({
  //       ...prev,
  //       number: true,
  //     }));
  //   } else {
  //     // Call handleQuerySubmit function
  //     handleQuerySubmit();
  //   }
  // };

  // useEffect(() => {
  //   setTimeout(() => {
  //     const muiClass = document.querySelector('.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input');
  //     if (muiClass) {
  //       muiClass.style.padding = "9.5px 0px";
  //     }
  //   }, 0);
  // }, []);

  return (
    <>
      {window.innerWidth <= 500 ? <HeaderMob /> : <LoginHeader />}
      <div className="myContainer">
        <div className="left">
          <div className="left_wrap">
            <div className="left-top">
              <p>Elevate your Interior &</p>
              <p>Construction Business</p>
            </div>
            <div className="left-bottom"></div>
          </div>
        </div>
        <div className="right">
          <div className="right-wrap">
            <div className="form" style={{ height: "fit-content" }}>
              <div className="heading">Request a demo</div>
              <div className="form-field">
                {/* <form onSubmit={handleSubmit}> */}
                  <div className="form-field-col1">
                    <TextField
                    error={error.name}
                      size="small"
                      id="outlined-basic"
                      label="Name*"
                      variant="outlined"
                      sx={{ width: "100%", background: "white", zIndex: "1" }}
                      value={demostate.name}
                      name="name"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-field-col2">
                    <div className="col2-row1">
                      {/* <TextField
                        type="number"
                        size="small"
                        id="outlined-basic"
                        label="Mobile Number*"
                        variant="outlined"
                        value={demostate.phone}
                        name="phone"
                        sx={{ width: "100%", background: "white" }}
                        onChange={handleChange}
                        onKeyDown={(e) => exceptThisSymbols.includes(e.key) && e.preventDefault()}
                      /> */}
                      <MuiPhoneNumber
                  error={error.number}
                  variant="outlined"
                  label="Mobile Number*"
                  value={demostate.number}
                  onChange={(newVal, countryCode) => handleChange({ target: { name: "number", value: newVal, countryCode }})}
                  defaultCountry={"in"}
                  InputProps={{ placeholder: "", style: {backgroundColor:"#FFF"} }}
                  dropdownClass={"dropdownClass"}
                  countryCodeEditable={false}
                />
                      <div style={{ color: "red", fontSize: "10px" }}>{PhnError}</div>
                    </div>
                    <div className="col2-row2">
                      <TextField
                      error={error.email}
                        size="small"
                        id="outlined-basic"
                        label="Email Address*"
                        variant="outlined"
                        value={demostate.email}
                        sx={{ width: "100%", background: "white" }}
                        name="email"
                        onChange={handleChange}
                      />
                      <div style={{ color: "red", fontSize: "10px" }}>{errormsg}</div>
                    </div>
                  </div>

                  <div className="form-field-col3">
                    <div className="col3-row1">
                      <TextField
                      error={error.company}
                        size="small"
                        id="outlined-basic"
                        label="Company Name*"
                        variant="outlined"
                        value={demostate.company}
                        name="company"
                        sx={{ width: "100%", background: "white" }}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col3-row2">
                      <FormControl size="small" sx={{ background: "white", width: "100%" }}>
                        <InputLabel>Company Type</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Company Type"
                          value={demostate.companyType}
                          name="companyType"
                          onChange={handleChange}
                          sx={{ background: "white", width: "100%" }}
                        >
                        <MenuItem value={"Interior Designer"}>Interior Designer</MenuItem>
                          <MenuItem value={"General Contractor"}>General Contractor</MenuItem>
                          <MenuItem value={"Developer / Builder"}>Developer / Builder</MenuItem>
                          <MenuItem value={"Sub-Contractor"}>Sub-Contractor</MenuItem>
                          <MenuItem value={"Project Management Consultancy"}>Project Management Consultancy</MenuItem>
                          <MenuItem value={"Others"}>Others</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className="form-field-col4">
                    <div className="col4-row1">
                      <FormControl size="small" sx={{ width: "100%", background: "white" }}>
                        <InputLabel id="demo-simple-select-label">Company Turnover</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Company Turnover"
                          value={demostate.companyTurnover}
                          name="companyTurnover"
                          onChange={handleChange}
                          sx={{ width: "100%" }}
                        >
                          <MenuItem value={"LessThan$100000"}> Less than $100,000</MenuItem>
                          <MenuItem value={"$100000-$500000"}> $100,000- $500,000</MenuItem>
                          <MenuItem value={"$500000-$1million"}>  $500,000- $1 million</MenuItem>
                          <MenuItem value={"$1million-$5million"}>$1 million- $5 million</MenuItem>
                          <MenuItem value={"$5million-$10million"}>$5 million- $10 million</MenuItem>
                          <MenuItem value={"MoreThan$10Million"}> More than $10 million</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col4-row2">
                      <FormControl fullWidth size="small">
                        <InputLabel id="demo-simple-select-label">Designation</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Designation"
                          value={demostate.designation}
                          name="designation"
                          onChange={handleChange}
                          sx={{ width: "100%", backgroundColor: "white" }}
                        >
                          <MenuItem value={"Founder/CXO"}>Founder/CXO</MenuItem>
                          <MenuItem value={"Project Manager"}>Project Manager</MenuItem>
                          <MenuItem value={"Planning Manager"}>Planning Manager</MenuItem>
                          <MenuItem value={"Site Supervisor"}>Site Supervisor</MenuItem>
                          <MenuItem value={"Site Engineer"}>Site Engineer</MenuItem>
                          <MenuItem value={"Student"}>Student</MenuItem>
                          <MenuItem value={"Others"}>Others</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="scheduleDemoBtn">
                    <div
                      // type="submit"
                     className="scheduleDemoBtnText"
                      // onClick={handleQuerySubmit}
                      onClick={() => {
                        Object.keys(demostate).forEach((curElem) => {
                          if (demostate[curElem]) {
                            setError((prev) => {
                              return {
                                ...prev,
                                [curElem]: false,
                              };
                            });
                          } else {
                            setError((prev) => {
                              return {
                                ...prev,
                                [curElem]: true,
                              };
                            });
                          }
                        });
                        if(demostate.name!=="" && demostate.email!="" && demostate.company!="" && demostate.number!=""){
                          if(dialCode == "91"){
                            if(finalPhoneNumber?.length!==10){
                              setError((prev) => {
                                return {
                                  ...prev,
                                  number: true,
                                };
                              });
                            }
                            if(finalPhoneNumber?.length>9){
                              handleQuerySubmit()
                            }
                          }
                          if(dialCode!=="91"){
                            if(finalPhoneNumber?.length!==1){
                              setError((prev) => {
                                return {
                                  ...prev,
                                  number: true,
                                };
                              });
                            }
                            if(finalPhoneNumber?.length>0){
                              handleQuerySubmit()
                            }
                          }
                          
                        }
                        
                      }}
                    >
                      Schedule a Demo
                    </div>
                  </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {window.innerWidth <= 500 ? <FooterMobile /> : <Footer />}
    </>
  );
};

export default Schedule;
