// import React, {useState, useEffect} from "react";
// import styles from "./Container.module.css";
// import { useNavigate } from "react-router-dom";
// import { Tooltip } from 'react-tooltip';
// import 'react-tooltip/dist/react-tooltip.css';

// const Container = ({ array, headingTxt, headingColor, backColor, forScroll, headingBackground}) => {
//     const [showButton, setShowButton] = useState(false);
//   const [showButton1, setShowButton1] = useState(false);
//     const navigate=useNavigate();
//     const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
//     const updateMedia = () => {
//         setIsDesktop(window.screen.width > 767);
//       };
    
//       useEffect(() => {
//         window.addEventListener("resize", updateMedia);
//         return () => window.removeEventListener("resize", updateMedia);
//       });

//     const [hoveredImage, setHoveredImage] = useState("");

//     const chunkedData = (arr, size) => {
//         return arr.reduce((acc, _, i) => (i % size === 0 ? [...acc, arr.slice(i, i + size)] : acc), []);
//     };

//     const rows = chunkedData(array, 3).map((row, rowIndex) => (
//         <div key={rowIndex} className={styles.row}>
//             {row.map((item, index) => (
//                 <div key={index} className={styles.item}>
//                     <div className={styles.heading} >{item.heading}</div>
//                     <div className={styles.description}>{item.description}</div>
//                     <div className={styles.readMoreBtn} 
//                     onClick={()=>{navigate(item.route)}}
//                    onMouseEnter={() => setHoveredImage("https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/ArrowUpRight.svg")}
//                         onMouseLeave={() => setHoveredImage("")}
//                    >Read More
//                     <img src={hoveredImage} alt=""  /> </div>
//                 </div>
//             ))}
//         </div>
//     ));
//     const scrollToTop = () => {
//         window.scrollTo({
//             top: 0,
//             behavior: "smooth"
//         });
//     }
//     useEffect(() => {
//         const handleScroll = () => {
//           if (window.scrollY > 200) {
//             setShowButton(true);
//           } else {
//             setShowButton(false);
//           }
    
//           if (window.scrollY > 300) {
//             setShowButton1(true);
//           } else {
//             setShowButton1(false);
//           }
//         };
    
//         window.addEventListener("scroll", handleScroll);
//           return () => {
//           window.removeEventListener("scroll", handleScroll);
//         };
//       }, []);

//     return (
//         <>
//          {isDesktop?
//     <div className={`${styles.icon} ${styles.stickyImage}`}  style={{ display: showButton ? "block" : "none", height:"5.5rem" }}>
//     <img  data-tooltip-id="my-tooltip" data-tooltip-content="Go to top"
//               src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/topicon.png" 
//               alt="" onClick={() => scrollToTop()} 
//               className={styles.topIconImg}
//               /></div>
//             :
//             <div className={`${styles.icon} ${styles.stickyImage}`}  style={{ display: showButton1 ? "block" : "none", height:"5.5rem" }}>
//   <img 
//             src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/topicon.png" 
//             alt="" onClick={() => scrollToTop()} 
//             className={styles.topIconImg}
//             /></div>
// } <Tooltip id="my-tooltip" />
//         <div style={{background:backColor, paddingTop:"2rem", paddingBottom:"2rem", marginTop:isDesktop ? "3.5rem" : "0rem"}}  id={forScroll}>
//         <div style={{paddingLeft:isDesktop ?"3.5rem":"2rem" , paddingRight: isDesktop?"3.5rem":"2rem"}}>
//             <div className={styles.btnContainer}><span className={styles.headingTxtMainHeading} style={{ background: headingColor, color: headingBackground }}>{headingTxt.mainHeading}</span></div>
//             <div className={styles.headingTxtSubHeading}>{headingTxt.subHeading}</div>
//             <div className={styles.headingTxtPara}>{headingTxt.para}</div>
//             </div>
//             <div className={styles.container}>
//                 {rows}
//             </div>
//             </div>
//         </>
//     )
// }

// export default Container;

import React, { useState, useEffect } from "react";
import styles from "./Container.module.css";
import { useNavigate } from "react-router-dom";
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const Container = ({ array, headingTxt, headingColor, backColor, forScroll, headingBackground }) => {
    const [showButton, setShowButton] = useState(false);
    const [showButton1, setShowButton1] = useState(false);
    const navigate = useNavigate();
    const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
        const [hoveredImage, setHoveredImage] = useState("");

    const updateMedia = () => {
        setIsDesktop(window.screen.width > 767);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    const chunkedData = (arr, size) => {
        return arr.reduce((acc, _, i) => (i % size === 0 ? [...acc, arr.slice(i, i + size)] : acc), []);
    };

    const rows = chunkedData(array, 3).map((row, rowIndex) => (
        <div key={rowIndex} className={styles.row}>
            {row.map((item, index) => (
                <div key={index} className={styles.item}>
                    <div className={styles.heading}>{item.heading}</div>
                    <div className={styles.description}>{item.description}</div>
                    <div
                        className={styles.readMoreBtn}
                        onClick={() => { navigate(item.route) }}
                        onMouseEnter={() => setHoveredImage(item.route)}
                        onMouseLeave={() => setHoveredImage("")}
                        style={{userSelect:"none"}}
                    >
                        Read More
                        {hoveredImage === item.route && <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/ArrowUpRight.svg" alt="" />}
                    </div>
                </div>
            ))}
        </div>
    ));

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 200) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }

            if (window.scrollY > 300) {
                setShowButton1(true);
            } else {
                setShowButton1(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            {isDesktop ?
                <div className={`${styles.icon} ${styles.stickyImage}`} style={{ display: showButton ? "block" : "none", height: "5.5rem" }}>
                    <img data-tooltip-id="my-tooltip" data-tooltip-content="Go to top"
                        src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/topicon.png"
                        alt="" onClick={() => scrollToTop()}
                        className={styles.topIconImg}
                    />
                </div>
                :
                <div className={`${styles.icon} ${styles.stickyImage}`} style={{ display: showButton1 ? "block" : "none", height: "5.5rem" }}>
                    <img
                        src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/topicon.png"
                        alt="" onClick={() => scrollToTop()}
                        className={styles.topIconImg}
                    />
                </div>
            } <Tooltip id="my-tooltip" />
            <div style={{ background: backColor, paddingTop: "2rem", paddingBottom: "2rem", marginTop: isDesktop ? "3.5rem" : "0rem" }} id={forScroll}>
                <div style={{ paddingLeft: isDesktop ? "3rem" : "2rem", paddingRight: isDesktop ? "3.5rem" : "2rem" }}>
                    <div className={styles.btnContainer}><span className={styles.headingTxtMainHeading} style={{ background: headingColor, color: headingBackground }}>{headingTxt.mainHeading}</span></div>
                    <div className={styles.headingTxtSubHeading}>{headingTxt.subHeading}</div>
                    <div className={styles.headingTxtPara}>{headingTxt.para}</div>
                </div>
                <div className={styles.container}>
                    {rows}
                </div>
            </div>
        </>
    )
}

export default Container;
