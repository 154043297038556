import React from "react";
import styles from "../onboardingMobCss/createMob.module.css";
import cloud from "../footerImg/profileList/cloud.svg";
import Carousel from "react-bootstrap/Carousel";
import { useNavigate } from "react-router-dom";
import { useState, useRef } from "react";

const CreateMob5 = () => {
  const [changecomp, setChangecomp] = useState("change");
  const [img, setImg] = useState([]);
  const uploadRef = useRef(null);

  const uploadImage = (event) => {
    for (let i = 0; i < event.target.files.length; i++) {
      let url = URL.createObjectURL(event.target.files[i]);
      setImg((prev) => {
        return [...prev, url];
      });
    }
  };
  const navigate = useNavigate();

  // remove image
  const removeImage = (index) => {
    const oldArr = [...img];
    oldArr.splice(index, 1);
    setImg(oldArr);
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.first_contain}>
          <Carousel
            style={{
              display: "flex",
              width: "85%",
            }}
            nextIcon=""
            nextLabel=""
            prevIcon=""
            prevLabel=""
            // interval={10000000}
          >
            <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}>
              <div className={styles.ImgDiv}>
                <img
                  src="https://idesign-office.s3.amazonaws.com/Rectangle%207461%20%284%29.svg"
                  alt="First slide"
                  className={styles.imgg}
                />
              </div>
              <div style={{ backgroundColor: "#E9EFFB" }}>
                <div className={styles.heading}>FILE MANAGER</div>
                <div className={styles.para}>
                  Easily organize and manage all of your important documents
                  with our file manager.
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}>
              <div className={styles.ImgDiv}>
                <img
                  src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207467%20%283%29.svg"
                  alt="Second slide"
                  className={styles.imgg}
                />
              </div>
              <div style={{ backgroundColor: "#E9EFFB" }}>
                <div className={styles.heading}>TASK MANAGEMENT</div>
                <div className={styles.para}>
                  Optimize resource usage and ensures that projects are
                  completed efficiently and on time.
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}>
              <div className={styles.ImgDiv}>
                <img
                  src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207465%20%282%29.svg"
                  alt="Third slide"
                  className={styles.imgg}
                />
              </div>

              <div style={{ backgroundColor: "#E9EFFB" }}>
                <div className={styles.heading}>DAILY PROJECT REPORT</div>
                <div className={styles.para}>
                  Ensures that all team members are aware of the current status
                  of the project.
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
        {/* second div */}
        <div className={styles.second_contain}>
          <div className={styles.PersonalContent_div}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div className={styles.personal_head}>List Project</div>
            </div>

            <input
              type="text"
              placeholder="Project Name"
              className={styles.inputs}
            />
            <div
              style={{
                // border: "2px solid red",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                paddingTop: "1.2rem",
              }}
            >
              <div className={styles.uploadImg_head}>Upload Project Images</div>
              {changecomp === "yes" ? (
                <img
                  src="https://idesign-office.s3.amazonaws.com/Frame%202611617.svg"
                  alt=""
                  style={{
                    marginTop: "1rem",
                    width: "7rem",
                    cursor: "pointer",
                  }}
                  onClick={() => uploadRef.current.click()}
                />
              ) : (
                ""
              )}
            </div>

            {img && img.length > 0 ? (
              <>
                <div
                  style={{
                    // border: "2px solid black",
                    overflowX: "scroll",
                    height: "10rem",
                    width: "100%",
                    display: "flex",
                    gap: "1rem",
                    marginTop:"1rem",
                  }}
                >
                  <div
                    style={{
                      width: "fit-content",
                      display: "flex",
                      gap: "1rem",
                    }}
                  >
                    {img &&
                      img.map((curelem, index) => {
                        return (
                          <>
                            <div
                              style={{
                                height: "8rem",
                                width: "8rem",
                                borderRadius: "12px",
                                border: "1px solid grey",
                                position: "relative",
                                // border:"2px solid red"
                                overflow: "hidden",
                              }}
                            >
                              <img
                                src="https://idesign-office.s3.amazonaws.com/Group%202610961.svg"
                                alt=""
                                style={{
                                  height: "20px",
                                  position: "absolute",
                                  right: "2%",
                                  top: "2%",
                                  cursor: "pointer",
                                }}
                                onClick={() => removeImage(index)}
                              />
                              <img
                                src={curelem}
                                alt=""
                                style={{
                                  borderRadius: "12px",
                                  height: "100%",
                                  overflowX: "hidden",
                                }}
                              />
                            </div>
                          </>
                        );
                      })}
                  </div>
                </div>
              </>
            ) : (
              <div
                className={styles.uploadImg_div}
                onClick={() => {
                  uploadRef.current.click();
                  setChangecomp("yes");
                }}
              >
                <img src={cloud} alt="" className={styles.cloudImg} />
              </div>
            )}

            <input
              type="file"
              className="d-none"
              ref={uploadRef}
              onChange={uploadImage}
              multiple
            />

            <button className={styles.btn} onClick={() => navigate("/few")}>
              NEXT
            </button>
            <a
              href="https://www.idesign.market/"
              className={styles.skip}
              style={{ textDecoration: "none" }}
            >
              Skip Project Listing
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateMob5;
