import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";
import { Modal } from "react-bootstrap";
import { TextField } from "@mui/material";
import { Dropdown } from "react-bootstrap";
import styles from "./ProductsCss/schedule.module.css";
import { Select } from "@mui/material";
import { FormControl } from "@mui/material";
import { InputLabel } from "@mui/material";
import { MenuItem } from "@mui/material";

const ScheduleADemo = (props) => {
  //  console.log(props)

  const closeModal = () => {
    props.closeFunc();
  };

  // props.showFunc(true);

  const handleClose = () => {
    setDemostate({
      name: "",
      company: "",
      email: "",
      phone: "",
      companyType: "",
      companyTurnover: "",
      designation: "",
    });
    props.closeFunc();
  };

  const [show2, setShow2] = useState(false);
  const [show, setshow] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [demostate, setDemostate] = useState({
    name: "",
    company: "",
    email: "",
    phone: "",
    companyType: "",
    companyTurnover: "",
    designation: "",
  });
  const [errormsg, setError] = useState("");
  const allowedDomain = ["gmail.com", "yahoo.com", "outlook.com"];
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,100}$/;

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "phone") {
      if (e.target.value.length <= 10) {
        setDemostate((prev) => {
          return { ...prev, ["phone"]: value };
        });
      }
    } else if (name === "email") {
      let x = emailRegex.test(value);
      if (!x) {
        setError("Invalid Email");
      } else {
        if (
          value.includes("@") &&
          allowedDomain.includes(value.split("@")[1])
        ) {
          setError(`${value.split("@")[1]} not allowed`);
        } else {
          setError("");
        }
      }
      setDemostate((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    } else {
      setDemostate((prev) => {
        return { ...prev, [name]: value };
      });
    }
  };
  console.log(errormsg);

  const handleQuerySubmit = async () => {
    if (
      demostate.name?.length > 0 &&
      demostate.company?.length > 0 &&
      demostate.email?.length > 0 &&
      demostate.phone?.length > 0 &&
      demostate.companyType?.length > 0 &&
      demostate.companyTurnover?.length > 0 &&
      demostate.designation?.length > 0
    )
      await axios
        .post("https://api.idesign.market/b2b/user/schedule-demo", {
          name: demostate.name,
          companyName: demostate.company,
          email: demostate.email,
          phoneNumber: demostate.phone,
          companyType: demostate.companyType,
          companyTurnover: demostate.companyTurnover,
          designation: demostate.designation,
        })
        .then(function (response) {
          props.closeFunc();
          setShow2(true);
          setDemostate({
            name: "",
            company: "",
            email: "",
            phone: "",
            companyType: "",
            companyTurnover: "",
            designation: "",
          });
        })
        .catch(function (error) {
          toast.error(`${errormsg}`);
        });
    else {
      toast.error("Please enter all the fields");
    }
  };

  const exceptThisSymbols = ["e", "E", "+", "-", "."];
  return (
    <React.Fragment>
      {" "}
      <Modal
        show={show2}
        onHide={handleClose2}
        centered
        dialogClassName="modal-80w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <div
              className="d-flex"
              style={{
                textAlign: "center",
                color: "#212121",
                fontWeight: "500",
              }}
            >
              Your Query has been submitted, you'll get a revert within 4 hrs!!
            </div>
            <button
              className="mt-3"
              style={{
                background: "#3b5998",
                outline: "none",
                border: "none",
                color: "white",
                fontSize: "14px",
                // borderRadius: "50px",
                // height: "36px",
                padding: "0.5rem 1rem",
                width: "30%",
              }}
              onClick={() => setShow2(false)}
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={props.show}
        onHide={closeModal}
        centered
        dialogClassName="modal-40w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        {/* <Modal.Header closeButton className="pb-0">
        </Modal.Header> */}
        <Modal.Body className="p-0">
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "40%",
                background: "#F0F4FF",
                display: "flex",
                flexDirection: "column",
                padding: "2rem",
                borderRadius: "4px",
                // border:'2px solid black',
              }}
            >
              <div className={styles.scheduleHead}>
                Introducing our innovative B2B Interior Design SaaS Platform
              </div>
              <div className={styles.requestHead}>Request a Demo today!</div>

              <div>
                <div className={styles.eachLine}>
                  <img
                    src="https://idesign-office.s3.amazonaws.com/check_circle.svg"
                    alt=""
                    style={{ width: "1rem", marginRight: "0.5rem" }}
                  />
                  Collaborate with other professionals
                </div>
                <div className={styles.eachLine}>
                  <img
                    src="https://idesign-office.s3.amazonaws.com/check_circle.svg"
                    alt=""
                    style={{ width: "1rem", marginRight: "0.5rem" }}
                  />
                  Improve communication with your clients
                </div>
                <div className={styles.eachLine}>
                  <img
                    src="https://idesign-office.s3.amazonaws.com/check_circle.svg"
                    alt=""
                    style={{ width: "1rem", marginRight: "0.5rem" }}
                  />
                  Streamline your interior design process
                </div>
                <div className={styles.eachLine}>
                  <img
                    src="https://idesign-office.s3.amazonaws.com/check_circle.svg"
                    alt=""
                    style={{ width: "1rem", marginRight: "0.5rem" }}
                  />
                  Stay ahead of the curve
                </div>
              </div>
            </div>

            {/* second div */}
            <div style={{ width: "60%", padding: "2rem" }}>
              <div className={styles.scheduleDemo}>Schedule a Demo</div>
              <div className="d-flex flex-column w-100">
                <div
                  className="d-flex flex-column"
                  style={{ marginBottom: "1.5rem" }}
                >
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Name*"
                    variant="outlined"
                    style={{ color: "rgb(164, 162, 162)" }}
                    value={demostate.name}
                    name="name"
                    onChange={handleChange}
                  />
                </div>

                <div
                  className="d-flex flex-column"
                  style={{ marginBottom: "1.5rem" }}
                >
                  <TextField
                    type="number"
                    size="small"
                    id="outlined-basic"
                    label="Mobile Number*"
                    variant="outlined"
                    value={demostate.phone}
                    name="phone"
                    onChange={handleChange}
                    onKeyDown={(e) =>
                      exceptThisSymbols.includes(e.key) && e.preventDefault()
                    }
                  />
                </div>

                <div
                  className="d-flex flex-column"
                  style={{ marginBottom: "1.5rem" }}
                >
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Email Address*"
                    variant="outlined"
                    value={demostate.email}
                    name="email"
                    onChange={handleChange}
                  />
                  <div>{errormsg}</div>
                </div>

                <div
                  className="d-flex flex-column"
                  style={{ marginBottom: "1.5rem" }}
                >
                  <TextField
                    size="small"
                    id="outlined-basic"
                    label="Company Name*"
                    variant="outlined"
                    value={demostate.company}
                    name="company"
                    onChange={handleChange}
                  />
                </div>

                <div
                  className="d-flex flex-column"
                  style={{ marginBottom: "1.5rem" }}
                >
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Company Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Company Type"
                      value={demostate.companyType}
                      name="companyType"
                      onChange={handleChange}
                      style={{ width: "100%" }}
                    >
                      <MenuItem value={"General Contractor"}>
                        General Contractor
                      </MenuItem>
                      <MenuItem value={"Developer / Builder"}>
                        Developer / Builder
                      </MenuItem>
                      <MenuItem value={"Sub-Contractor"}>
                        Sub-Contractor
                      </MenuItem>
                      <MenuItem value={"Project Management Consultancy"}>
                        Project Management Consultancy
                      </MenuItem>
                      <MenuItem value={"Others"}>Others</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div
                  className="d-flex flex-column"
                  style={{ marginBottom: "1.5rem" }}
                >
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Company Turnover
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Company Turnover"
                      value={demostate.companyTurnover}
                      name="companyTurnover"
                      onChange={handleChange}
                      style={{ width: "100%" }}
                    >
                      <MenuItem value={"oneToFiveCrore"}>1-5 crore</MenuItem>
                      <MenuItem value={"fiveTo10Crore"}>5-10 crore</MenuItem>
                      <MenuItem value={"tenToFiftyCrore"}>10-50 crore</MenuItem>
                      <MenuItem value={"FiftyToHundredCrore"}>
                        50-100 crore
                      </MenuItem>
                      <MenuItem value={"MorethanHundredCrore"}>
                        More than 100 crore
                      </MenuItem>
                      <MenuItem value={"LessthanOneCrore"}>
                        Less than 1 crore
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div
                  className="d-flex flex-column"
                  style={{ marginBottom: "1.5rem" }}
                >
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">
                      Designation
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Designation"
                      value={demostate.designation}
                      name="designation"
                      onChange={handleChange}
                      style={{ width: "100%" }}
                    >
                      <MenuItem value={"Founder/CXO"}>Founder/CXO</MenuItem>
                      <MenuItem value={"Project Manager"}>
                        Project Manager
                      </MenuItem>
                      <MenuItem value={"Planning Manager"}>
                        Planning Manager
                      </MenuItem>
                      <MenuItem value={"Site Supervisor"}>
                        Site Supervisor
                      </MenuItem>
                      <MenuItem value={"Site Engineer"}>Site Engineer</MenuItem>
                      <MenuItem value={"Student"}>Student</MenuItem>
                    </Select>
                  </FormControl>
                </div>

                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    className="mt-1"
                    style={{
                      background: "#1D3557",
                      outline: "none",
                      border: "none",
                      color: "white",
                      fontSize: "14px",
                      borderRadius: "9px",
                      padding: "0.5rem 1rem",
                    }}
                    onClick={handleQuerySubmit}
                  >
                    Schedule a Demo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ScheduleADemo;
