import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import cart from "./VendorImgs/venCartSuitcase.svg";
import vendorFree from "./VendorImgs/vendorFree.svg";

const VendorCartSide = () => {
  const authTok = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "";
  const navigate = useNavigate()


  const profileInfo = useSelector((state) => state.addToCartReducer.profileData);
  const comapnyName = profileInfo[0]?.data?.data?.companyName

  // console.log(comapnyName)
  const [data, setData] = useState([])
  useEffect(async () => {
    const res = await axios.get("https://pro-api.idesign.market/user/listVendorPlanInCart", {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authTok
      }
    }).then((res) => {
      console.log(res)
      setData(res?.data?.data)
    }).catch(err => console.warn(err))
  }, [])
  // console.log(data)

  const [venId, setVenId] = useState([])

  useEffect(async () => {
    await axios.get("https://pro-api.idesign.market/user/listVendorPlans").then((venRes) => {
      console.warn(venRes)
      setVenId(venRes?.data?.data[0]?._id)
    }).catch(err => console.warn(err))
  }, [])

  // console.log(venId)



  const handleRemove = async () => {
    // localStorage.setItem("vendorFlag", true)
    const res = await axios.put("https://pro-api.idesign.market/user/deleteVendorPlanIncart", { vendorPlanId: venId }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authTok
      }
    }).then((res2) => {
      console.warn(res2)
      // navigate("/lead")
    }).catch(err => console.warn(err))
  }

  // console.log(Math.floor(new Date().getTime()) + 2592000000)
  // const date = (new Date((Math.floor(new Date().getTime()) + 2592000000))).toString().substring(4, 15);
  // console.log(date.getTime())
  // console.log(date)

  return (
    <div>
      {data?.planPrice === 0 ? <div className="ven-free d-flex px-2 mt-3">
        <img className='ven-blah' src={vendorFree} alt="" />
        <p className="ven-blah2 m-2">Hi {comapnyName}, start your <strong> 1-month free </strong> trial now and after that <strong> ₹ 1,000 </strong> per month</p>
      </div> : ""}
      <div className="d-flex flex-column">
        <div className="d-flex flex-column w-100 vendor-cart-start">
          <div className="d-flex w-100 ">
            <div className="img-vendor p-3">
              <img className="img-vendor2" src={cart} alt="" />
            </div>
            <div className="d-flex mx-3 flex-column w-100">
              <div className="d-flex w-100">
                <h3 className='vendor-header-price '>Vendor Subscription</h3>
                <h3 className='vendor-header-price2'>₹ {data?.planPrice}</h3>
              </div>
              <ul className="ven-price-span mt-2">
                <li>Monthly after free trial will be ₹1,000 + GST </li>
                <li>You can cancel anytime before {(new Date((Math.floor(new Date().getTime()) + 2592000000))).toString().substring(4, 15)}</li>
              </ul>
            </div>
          </div>
          <button className="vendor-cart-remove" onClick={handleRemove}>Remove</button>
        </div>
      </div>
    </div>
  )
}

export default VendorCartSide