import React, { useState, useEffect } from "react";
import Container from "./Container";
import Header from "../NewHome/Header";
import {
  costManagementArray,
  leadManagement,
  leadManagementArray,
  CostManagement,
  additional,
  additionalArray,
  projectManagement,
  projectManagementArray,
} from "./MockData";
import HeroContainer from "./Hero";
import FAQ from "../NewHome/FAQ";
import JoinOnMobile from "../NewHome/JoinOnMobile";
import NewFooter from "../NewHome/NewFooter";
import AboveFooter from "../NewHome/AboveFooter";
import Testmonial from "./Testimonial";
import styles from "./Container.module.css";
import { testimonialsArray } from "./FeaturePages/mockData";
import HamburgerPage from "../NewHome/hamburgerMenus/HamburgerPage";
import HeaderMob from "../NewHome/HeaderMob";

const Feature = () => {
  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
  const [openHamburger, setOpenHamburger] = useState(false);

  const updateMedia = () => {
    setIsDesktop(window.screen.width > 767);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      {openHamburger && (
        <HamburgerPage
          openHamburger={openHamburger}
          setOpenHamburger={setOpenHamburger}
        />
      )}
      {!openHamburger &&
        (isDesktop ? (
          <Header />
        ) : (
          <HeaderMob
            openHamburger={openHamburger}
            setOpenHamburger={setOpenHamburger}
          />
        ))}
     {!openHamburger &&   <HeroContainer />}
      {/* {!openHamburger &&  (isDesktop ? (
        <div
          className={`${styles.topIconImageContainer} ${styles.topImageIconConImage}`}
          style={{ display: showButton ? "block" : "none" }}
        >
          <img
            src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/topicon.png"
            alt=""
            onClick={() => scrollToTop()}
          />
        </div>
      ) : (
        <div
          style={{
            padding: " 0 2.5rem 1.5rem 0",
            textAlign: "right",
            cursor: "pointer",
            marginBottom: "-3.5rem",
            display: "none",
          }}
        >
          <img
            src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/topicon.png"
            alt=""
            onClick={() => scrollToTop()}
          />
        </div>
      ))} */}
{!openHamburger &&      <Container
        forScroll="jumpto1"
        array={projectManagementArray}
        headingTxt={projectManagement}
        headingBackground ={"#007638"}
        backColor={"#FFF"}
        headingColor={"#F0FFF7"}
      />}
   {!openHamburger &&     <Container
        forScroll="jumpto2"
        array={costManagementArray}
        headingTxt={CostManagement}
        headingBackground={"#1478B8"}
        backColor={"#F9FAFB"}
        headingColor ={"#D1EAFA"}
      />}
  {!openHamburger &&      <Container
        forScroll="jumpto3"
        array={leadManagementArray}
        headingTxt={leadManagement}
        headingBackground={"#F78508"}
        backColor={"#FFF"}
        headingColor  ={"#FEF3E6"}
      />}
   {!openHamburger &&     <Container
        forScroll="jumpto4"
        array={additionalArray}
        headingTxt={additional}
        headingBackground ={"#6B21A8"}
        backColor={"#F9FAFB"}
        headingColor={"#ECDDF8"}
      />}
      {!openHamburger &&  <Testmonial testimonialData={testimonialsArray[1]} />}
      {!openHamburger && ( isDesktop ? <div style={{ marginTop: "-2rem" }}></div> : "")}
      {!openHamburger &&  <FAQ />}
      {!openHamburger &&  <JoinOnMobile />}
      {!openHamburger &&  <AboveFooter />}
  {!openHamburger &&      <NewFooter />}
    </>
  );
};

export default Feature;
