import React, { useRef, useState, useEffect } from "react";
import styles from "./PricingMain.module.css";
import Testimonials from "./Testimonials";
import QAndA from "./QAndA";
import info from "./Images/Info.svg";
import caretRight from "./Images/CaretRight.svg"
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import OtherCompanies from "./OtherCompanies";
import BoxOne from "./BoxOne";
import FooterPricing from "./FooterPricing";
import LoginHeader from "../LoginHeader";
import {mockArray} from "./mockData";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Dubai from "./Dubai";
import Sydney from "./Sydney";
import LocationComponent from "./LocationComponent";
import NewTestimonials from "../../../../NewHome/NewTestimonials";
import NewTestimonialsMobile from "../../../../NewHome/NewTestimonialsMobile";
import NewFooter from "../../../../NewHome/NewFooter";
import Header from "../../../../NewHome/Header";
import CryptoJS from "crypto-js";

const PricingMain = () => {
  const [isTalkBtnClicked,setIsTalkBtnClicked] = useState(false)
 const scrollRef=useRef();
const navigateTo=useNavigate();
const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
const updateMedia = () => {
  setIsDesktop(window.screen.width > 767);
};

useEffect(() => {
  window.addEventListener("resize", updateMedia);
  return () => window.removeEventListener("resize", updateMedia);
});

const scrollToTop=()=>{
  window.scrollTo({
    top:0,
  });
}

useEffect(()=>{
 scrollToTop()
})

let lastScrollTop = 0;

// window.addEventListener('scroll', function() {
//   var hiddenDiv = document.getElementById('hiddenDiv');
//   var position = hiddenDiv.getBoundingClientRect();
//   var distanceFromTop = position.top + window.scrollY;
//   var st = window.pageYOffset || document.documentElement.scrollTop;

//   if (st < lastScrollTop) {
//     // User is scrolling up, hide the hiddenDiv
//     hiddenDiv.style.display = 'none';
//   } else {
//     if (distanceFromTop <= 85) {
//       hiddenDiv.style.display = 'block';
//     }else if (position.top <= 85) {
//       hiddenDiv.style.display = 'block';
//     }  
//     else {
//       hiddenDiv.style.display = 'none'; 
//     }
//   }
//   lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
// });

function generateHashedLink(token, userId, paymentPlan, fullName, cId, price, password) {
  const encryptedData = {};
  const encryptionKey = "18B69E843399F1A2AFC5853129299";

  const encryptField = (field) => {
    const fieldValue = field?.toString() || ''; // Convert value to string, handle undefined
    return CryptoJS.AES.encrypt(fieldValue, encryptionKey).toString();
  };

  encryptedData.token = encryptField(token);
  encryptedData.userId = encryptField(userId);
  encryptedData.paymentPlan = encryptField(paymentPlan);
  encryptedData.cId = encryptField(cId);
  encryptedData.fullName = encryptField(fullName);
  encryptedData.price = encryptField(price);
  encryptedData.password = encryptField(password);

  const now = new Date();
  const timestamp = now.toISOString();
  const encryptedTimestamp = CryptoJS.AES.encrypt(timestamp, encryptionKey).toString();
  encryptedData.timestamp = encryptedTimestamp;

  const encryptKey = (key) => {
    return CryptoJS.AES.encrypt(key, encryptionKey).toString();
  };
  const url = `https://www.idesign.market/payment?${encryptKey("userId")}=${encryptedData.userId}&${encryptKey("cId")}=${encryptedData.cId}&${encryptKey("token")}=${encryptedData.token}&${encryptKey("fullName")}=${encryptedData.fullName}&${encryptKey("price")}=${encryptedData.price}&${encryptKey("paymentPlan")}=${encryptedData.paymentPlan}&${encryptKey("timestamp")}=${encryptedData.timestamp}&${encryptKey("password")}=${encryptedData.password}`;
  return url;
}

  return (
    <div>
    {/* <LoginHeader /> */}
    <Header />
      <div className={styles.blueContainer} style={{ paddingBottom: "2.5rem" }}>
        <div className={styles.headingText}>
          Seamless Solutions, Straightforward Pricing
        </div>
        {/* <BoxOne scrollRef={scrollRef} /> */}
        <LocationComponent scrollRef={scrollRef} />
      </div>
      <div className={styles.extraDiv}></div>


      {/* second div */}

      <div
      ref={scrollRef}
   
       className={styles.outSecondContainer}
    
  
      >
        <div className={styles.secondContainerFirst} style={{ position: "relative"}}>
          <div style={{ marginTop: "6.5rem", marginBottom:"2.5rem" }}>
            <div className={styles.compareOurPlansTxt}>Compare our plans</div>
          </div>
          <div id="hiddenDiv" className={styles.hiddenDiv} ></div>
          {mockArray.map((category) => (
            <div key={category.heading} className={styles.arrayHeadingContainer}>
              <div className={styles.overlayWhite}></div>
              <div
                className={`${styles.arrayHeading} ${styles.overlay}`}
                style={{padding:"0.44rem 1.25rem" }}> {category.heading} </div>
              {category.subHeadings.map((subHeading) => (
                <div className={styles.arraySubHeadingsContainer}>
                <div
                  key={subHeading.subHead}
                  className={styles.arraySubHeadings} 
                  style={{marginLeft:"1.25rem"}}
                >
                  {subHeading.subHead}
                 <img alt="" src={info} data-tooltip-id="my-tooltip"  data-tooltip-place="right"
                 data-tooltip-content={subHeading.toolTip}
                  style={{cursor:"pointer", marginLeft:"0.56rem"}} />
                </div>
                </div>
              ))}
            </div>
          ))}
           <Tooltip id="my-tooltip" />
        </div>
        <div className={styles.secondContainer}>
          <div
            className={styles.secondContainerBoxes}
            style={{ boxShadow: " 0px 4px 12px 0px rgba(0, 0, 0, 0.10)" }}
          >
            <div
              className={styles.secondContainerBoxHeading}
              // style={{ background: " #0081E8" }}
            >
              <span className={styles.secondContainerBoxHeadingText}>
                Professional
              </span>
            </div>
            <div className={styles.blueButtonContainer} style={{marginTop:"2rem"}}>
              <div className={styles.blueButton}
               style={{cursor:"pointer", userSelect:"none"}}
              onClick={()=>
                {
                  if (localStorage.getItem("pricing") === "true") {
                  navigateTo("/signup");
                } else {
                  // navigateTo(`/payment`);
                  window.location.assign(generateHashedLink(localStorage.getItem("token"),
                  localStorage.getItem("userId"),
                  localStorage.getItem("paymentPlan"),
                  localStorage.getItem("fullName"),
                  localStorage.getItem("cId"),
                  localStorage.getItem("price"),
                  localStorage.getItem("password")
                  )) 
                }
                }
               }>
                <div className={styles.blueButtonText}>Get Started</div>
                <img alt="" src={caretRight} className={styles.checkCircle} />
              </div>
            </div>
            <div>
              {mockArray.map((category) => (
                <div key={category.heading} className={`${styles.arrayHeadingContainer} ${styles.arrayHeadingContainerExtra}`}>
                  <div className={`${styles.arrayHeading} ${styles.arrayHeadingExtra}  ${styles.arrayHeadingExtraShow}`} >
                    {category.heading}
                  </div>
                  {category.subHeadings.map((subHeading) => (
                    <div
                      key={subHeading.subHead}
                      className={styles.arraySubHeadings}
                      style={{ textAlign: "center", marginTop: "27px" }}
                    >
                      {subHeading.pro == "yes" ? (
                        <img alt="" src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Check11.svg" className={styles.checkCircle} />
                      ) : (
                        <img alt="" src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/XCircle1.svg" className={styles.checkCircle} />
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          
          </div>
          <div
            className={styles.secondContainerBoxes}
            style={{ boxShadow: " 0px 4px 12px 0px rgba(0, 0, 0, 0.10)" }}
          >
            <div
              className={styles.secondContainerBoxHeading}
              // style={{ background: "#D261D2" }}
            >
              <span className={styles.secondContainerBoxHeadingText}>
                Enterprise
              </span>
            </div>
            <div
              className={styles.blueButtonContainer}
              style={{ marginTop: "2rem", userSelect:"none"}}
            >
              <div className={` ${isTalkBtnClicked ? styles.blueBorderButtonClicked : styles.blueBorderButton}`} 
               style={{cursor:"pointer"}}
               onClick={()=>{
                setIsTalkBtnClicked(true)
                navigateTo("/contact")
              }
              }
               >
                <div className={styles.blueBorderButtonText}
                >Let's Talk</div>
              </div>{" "}
            </div>
            <div>
              {mockArray.map((category) => (
                <div key={category.heading} className={`${styles.arrayHeadingContainer} ${styles.arrayHeadingContainerExtra}`}>
                <div className={`${styles.arrayHeading} ${styles.arrayHeadingExtra}  ${styles.arrayHeadingExtraShow}`} >
                    {category.heading}
                  </div>
                  {category.subHeadings.map((subHeading) => (
                    <div
                      key={subHeading.subHead}
                      className={styles.arraySubHeadings}
                      style={{ textAlign: "center", marginTop: "27px" }}
                    >
                      {subHeading.ent == "yes" ? (
                        <img alt="" src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Check11.svg"  className={styles.checkCircle} />
                      ) : (
                        <img alt="" src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/XCircle1.svg"  className={styles.checkCircle} />
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div style={{ height: "2.25rem", background: "#F4F8FB" }}></div>
      {/* <Testimonials /> */}
      <NewTestimonials />
      <div className={styles.blueContainer} style={{ paddingTop: "5.69rem" }}>
      <OtherCompanies />
        <QAndA />
        {/* <FooterPricing /> */}
        <NewFooter />
      </div>
    </div>
  );
};

export default PricingMain;
