import React from 'react';
import { useNavigate } from 'react-router-dom'
import { useState } from "react";
import LeadsHistory from './LeadsHistory';
import ThreeDHistory from './ThreeDHistory';
import MeasurementHistory from './MeasurementHistory';
import SideBarMobile from './SideBarMobile';
import LeadsHistoryMob from './LeadsHistoryMob';

export default function OrderLandingMobile() {
    const navigate = useNavigate()
    const [lead, setLead] = useState(true);
    const [threeD, setThreeD] = useState(false);
    const [measure, setMeasure] = useState(false);
    const [boq, setBoq] = useState(false);

    const handleButton = (buttontype) => {
        if (buttontype == "lead") {
            setLead(true)
            setThreeD(false)
            setMeasure(false)
        }
        if (buttontype == "threeD") {
            setThreeD(true)
            setLead(false)
            setMeasure(false)
        }
        if (buttontype == "measure") {
            setMeasure(true)
            setThreeD(false)
            setLead(false)
        }
        if (buttontype == "boq") {
            setThreeD(false)
            setLead(false)
            setBoq(true)
        }
    }


    return (

        <div>
            <SideBarMobile />
            <div style={{ width: '100%', borderRight: '1px solid #DFDFDF', backgroundColor: '#ffffff' }}>
                <div style={{ marginLeft: '20px' }}>
                    <p className="heading mt-0">My Orders</p>
                </div>

                <div className="sub-heads_mob" style={{ borderBottom: '1px solid #DFDFDF' }}>
                    <div className="mx-3" style={{ backgroundColor: '#ffffff', display: 'flex', flexDirectionL: 'row', width: '93%' }}>
                        <div role="button" style={{ marginRight: '20px' }} className={lead ? 'active' : "unactive"} onClick={() => handleButton("lead")}> Leads</div>
                        <div role="button" style={{ marginRight: '20px' }} className={threeD ? 'active' : "unactive"} onClick={() => handleButton("threeD")}>3Ds</div>
                        {/* <div role="button" className={measure ? 'active' : "unactive"} onClick={() => handleButton("measure")} >Measurements</div> */}
                        {/* <div role="button"   onClick={() => handleButton("boq")}>BOQ Assistance</div> */}
                    </div>

                </div>
                <div>
                    {lead ? <LeadsHistoryMob /> : threeD ? <ThreeDHistory /> : measure ? <MeasurementHistory /> : ''}
                </div>
            </div>
        </div>
    )
}