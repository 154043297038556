import React from "react";
import styles from "../../onBoardingCss/DashboardCssNew/financialsTab.module.css";
import { useState,useEffect } from "react";
import axios from "axios";


const Financials = ({projectId}) => {
  const [acc, setacc] = useState([]);

  const GetAccounts = async () => {
    await axios
      .get(
        `https://pmt.idesign.market/api/projects/get-financialData?projectId=${projectId}`
      )
      .then(function (response) {
        // console.log(response?.data);
        setacc(response?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    GetAccounts();
  }, [])
  
  return (
    <>
      <div className={styles.container}>
        <div>
          <img
            src="https://idesign-office.s3.amazonaws.com/Group%202610734%20%281%29.svg"
            alt=""
          />
        </div>
        <div style={{display:'flex',gap:"5rem"}}>
          <div style={{display:'flex',flexDirection:"column",gap:'1rem',marginTop:'0.1rem'}}>
            <div>
              <img
                src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207496%20%281%29.svg"
                alt=""
                style={{marginRight:'0.5rem'}}
              />
             <span className={styles.text}>Received: ₹ {acc?.totalCredit}</span> 
            </div>
            <div>
              <img
                src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207497.png"
                alt=""
                style={{marginRight:'0.5rem'}}
              />
              <span className={styles.text}>Expense: ₹ {acc?.totaldebit}</span> 
            </div>
            <div>
              <img
                src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207498.svg"
                alt=""
                style={{marginRight:'0.5rem'}}
              />
              <span className={styles.text}>Balance: ₹ 0</span>  
            </div>
          </div>

          <div style={{display:'flex',flexDirection:"column",gap:'1rem',marginTop:'0.6rem'}}>
            <div>
              <div className={styles.totalAmount}>Total BOQ Amount</div>
              <div style={{color:"#9AA4AC"}}>₹ {acc?.totalBoq}</div>
            </div>
            <div>
              <div className={styles.totalAmount}>Total Expense</div>
              <div style={{color:"#9AA4AC"}}>₹ {acc?.totaldebit}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Financials;
