export const breadCrumbSchemaOnDesignerProfile = (designerProfileUrl, designerProfileName, cityName) => {
    let data = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          item: {
            "@id": "https://www.idesign.market",
            name: "Homepage",
          },
        },
        {
          "@type": "ListItem",
          position: 2,
          item: {
            "@id": "https://www.idesign.market/find-professionals",
            name: "Find Professionals",
          },
        },
        {
          "@type": "ListItem",
          position: 3,
          item: {
            "@id": `https://www.idesign.market/find-professionals/interior-designers-in-${cityName}`,
            name: `Find Interior Designers in ${cityName}`,
          },
        },
        {
          "@type": "ListItem",
          position: 4,
          item: {
            "@id": `${designerProfileUrl}`,
            name: `${designerProfileName}`,
          },
        },
      ],
    };
    return JSON.stringify(data);
  };
  
  export const webpageSchema = (pageUrl, h1TagContent, name, description) => {
    let data = {
      "@context": "http://schema.org",
      "@type": "WebPage",
      url: pageUrl,
      headline: h1TagContent,
      breadcrumb: "",
      name: name,
      description: description,
    };
    return JSON.stringify(data);
  };
  
  export const breadCrumbSchemaOnDesignIdeas = () => {
    let data = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          item: {
            "@id": "https://www.idesign.market",
            name: "Homepage",
          },
        },
        {
          "@type": "ListItem",
          position: 2,
          item: {
            "@id": "https://www.idesign.market/design-ideas",
            name: "Design Ideas",
          },
        },
      ],
    };
    return JSON.stringify(data);
  };
  
  export const breadCrumbSchemaOnDesignIdeasInner = (designIdeasInnerUrl, roomName) => {
    let data = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          item: {
            "@id": "https://www.idesign.market",
            name: "Homepage",
          },
        },
        {
          "@type": "ListItem",
          position: 2,
          item: {
            "@id": "https://www.idesign.market/design-ideas",
            name: "Design Ideas",
          },
        },
        {
          "@type": "ListItem",
          position: 3,
          item: {
            "@id": `${designIdeasInnerUrl}`,
            name: `${roomName} Interior Design Ideas`,
          },
        },
      ],
    };
    return JSON.stringify(data);
  };
  
  export const breadCrumbSchemaOnLocationPages = (typeOfPage, cityName, typeOfPageText) => {
    let data = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      itemListElement: [
        {
          "@type": "ListItem",
          position: 1,
          item: {
            "@id": "https://www.idesign.market",
            name: "Homepage",
          },
        },
        {
          "@type": "ListItem",
          position: 2,
          item: {
            "@id": "https://www.idesign.market/find-professionals",
            name: "Find Professionals",
          },
        },
        {
          "@type": "ListItem",
          position: 3,
          item: {
            "@id": `https://www.idesign.market/find-professionals/${typeOfPage}-in-${cityName}`,
            name: `Find ${typeOfPageText} in ${cityName}`,
          },
        },
      ],
    };
    return JSON.stringify(data);
  };
  
  export const faqSchema = (pageUrl) => {
    if (pageUrl === "https://www.idesign.market/design-ideas/bedroom-interior-designs") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/design-ideas/bedroom-interior-designs",
        mainEntity: [
          {
            "@type": "Question",
            name: "How Should One Style Their Small Bedroom?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "Here are the ways which you can follow to decorate your small bedroom: Use sliding doors to save space. Choose a light color for your walls. Use built-in storage spaces. Keep your furniture simple.",
            },
          },
          {
            "@type": "Question",
            name: "What are the most frequent faults while designing your bedroom interior?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "Some of the most common bedroom interior design mistakes that people make that can be easily avoided: Too many patterns. Lack of storage space. Too small of a bed or too large of a bed. Lack of lighting",
            },
          },
          {
            "@type": "Question",
            name: "What are the Top must have Elements in your Bedroom Furniture?",
            acceptedAnswer: {
              "@type": "Answer",
              text: "Here are the 5 Must Have Furniture that should be their in everyone’s bedroom : The Bed, Dresser or Armoire, Bedside Table, Mirrors, Seating",
            },
          },
        ],
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/design-ideas/living-room-interior-designs") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/design-ideas/living-room-interior-designs",
        mainEntity: [{
          "@type": "Question",
          name: "What is a Modern Living Room Style?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Modern living room styles are becoming more popular today. A modern living room style is all about focusing on the idea that ‘less is more’. The space should be as minimal as possible with only a few elements that optimize its functionality while looking sleek and stylish. Neutral colors are used for the most part, with one accent wall for color if you want it."
          },
        }, {
          "@type": "Question",
          name: "What are some design concepts for small living rooms?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "For small living rooms, some design options you can choose from include: Try using mirrors to make the room look bigger.Buy furniture that's lighter in color.Use tall lamps in place of small table lamps."
          },
        }, {
          "@type": "Question",
          name: "Which Color Schemes Work Best for a Living Room?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The color scheme of your living room sets the tone of your space, so choose wisely. Here are some color combinations to inspire you:Sky blue and white is a classic colour combination.Grey and yellow is a good combination for the living room because of combo of warm and bright colouUse pink and turquoise together to create a feminine look"
          },
        },
        ],
      };
      return JSON.stringify(data);
    };
  
    if (pageUrl === "https://www.idesign.market/design-ideas/modular-kitchen-interior-designs") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/design-ideas/modular-kitchen-interior-designs",
        mainEntity: [{
          "@type": "Question",
          name: "How can one Set Up A Small Modular Kitchen?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The ways which you can use to set up your small kitchens are: Choose a color scheme that works in your home, with your kitchen appliances, and with your overall design.Use glass wall tiles to reflect light into the room.Light up your space with LED strips under cabinets, spotlights or lamps."
          },
        }, {
          "@type": "Question",
          name: "Which type of Kitchen Designs Are Best For Small Spaces?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "A square kitchen is highly recommended for small spaces. This can be more effective instead of using a rectangular one.When you have a small space, it's best to use a rectangular or square-shaped table. Use the L and U shaped layouts to your advantage"
          },
        }, {
          "@type": "Question",
          name: "What Is A Modular Kitchen?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "A modular kitchen is a kitchen that is made of a series of cabinets, drawers and appliances that are designed to be assembled in a specific way. A modular kitchen is typically less expensive than a custom cabinet system because it doesn't require as much time for design and installation. Modular kitchens provide more flexibility than traditional kitchens because they allow you to customize your storage needs to suit the size and shape of your space. You can also change out individual p,ieces as needed without having to redo the whole thing at once."
          },
        },
        ],
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/design-ideas/wall-interior-designs") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/design-ideas/wall-interior-designs",
        mainEntity: [{
          "@type": "Question",
          name: "What is the most popular interior wall color?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "It's easy to understand why white has been a perennial choice among homeowners. Whites can do a huge amount to brighten up a room, make a tiny space feel bigger."
          },
        }, {
          "@type": "Question",
          name: "Are wooden walls good?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Nobody should be surprised that wood is very sturdy. We apply it to furniture, flooring, walls, and other surfaces. The same holds true for wooden wall panels. It can survive for many years with good maintenance before ever needing to be replaced"
          },
        }, {
          "@type": "Question",
          name: "Can tiles be used in a living room?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Ceramic tiles are the ideal choice for your walls. Ceramic tiles can be used anywhere in the house—kitchen, bathroom, living room, or drawing room. They are easy to clean and durable."
          },
        },
        ],
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/design-ideas/false-ceiling-interior-designs") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/design-ideas/false-ceiling-interior-designs",
        mainEntity: [{
          "@type": "Question",
          name: "What type of false ceiling design is right for your space?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Due to the many advantages it has to offer, gypsum board false ceilings are a very popular alternative. The fact that this material is heat-resistant is one of its benefits."
          },
        }, {
          "@type": "Question",
          name: "What is the new ceiling trend?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "High glossy, shiny surface that shines is one of the most recent ceiling design trends. Glossy surfaces deceive the eye and provide the impression that the space is larger, especially when lighter colours and furniture are used. However, if using paint, the surface must be polished flat to remove any lumps."
          },
        }, {
          "@type": "Question",
          name: "What is a false ceiling?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "False ceilings are frequently secondary ceilings that are suspended below the primary ceiling using wires or struts. A variety of materials, including plaster of paris (POP), gypsum board, wood, etc., are used to make these ceilings"
          },
        },
        ],
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/design-ideas/furniture-interior-designs") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/design-ideas/furniture-interior-designs",
        mainEntity: [{
          "@type": "Question",
          name: "What are the five main components of furniture design?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Shape, material, colour, theme, positioning, and size are all included."
          },
        }, {
          "@type": "Question",
          name: "Why is furniture design important?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Interior designers use furniture to establish a pleasing sense of order in a room. They consider both two-dimensional and three-dimensional design in their arrangements, including shape, form, color, and texture. Design principles can also be applied to furniture."
          },
        },
        ],
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/design-ideas/furniture-interior-designs") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/design-ideas/furniture-interior-designs",
        mainEntity: [{
          "@type": "Question",
          name: "What are the five main components of furniture design?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Shape, material, colour, theme, positioning, and size are all included."
          },
        }, {
          "@type": "Question",
          name: "Why is furniture design important?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Interior designers use furniture to establish a pleasing sense of order in a room. They consider both two-dimensional and three-dimensional design in their arrangements, including shape, form, color, and texture. Design principles can also be applied to furniture."
          },
        },
        ],
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/design-ideas/kids-room-interior-designs") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/design-ideas/kids-room-interior-designs",
        mainEntity: [{
          "@type": "Question",
          name: "What are the must have Elements Kids Bedroom Design?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Although each child's bedroom may be unique, there are some basic essentials that are common to all bedrooms.Add plenty of storage spacA cozy bedding set.Playful details to personalize the room Desk Space"
          },
        }, {
          "@type": "Question",
          name: "Which Type of Bed You Should Pick for Your Children's Bedroom?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "As a parent, you want your child to have a comfortable place to sleep. The beds listed below can help you create the perfect spot for your child to rest. Beds with storage. Platform bedsCabin bedsBunk beds and loft bedsTrundle beds"
          },
        }, {
          "@type": "Question",
          name: "How Do I Select the Appropriate Furniture For The Children's Room?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "To choose the right furniture for the kids’ room, you need to first decide what type of theme you want. You can go with a sports theme or even a color scheme that represents your child’s favorite show. However, if you don’t have any specific preference, then just stick with neutral colors such as white or light wood tones.The next step is to choose furniture that is functional and multi-purpose. For example, you might want to invest in convertible modular furniture like chairs that can be used at different times as either a chair or table. This will save space in the room while providing maximum utility value since they double up as both chairs and tables depending on how they are being used at any given time!"
          },
        },
        ],
  
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/design-ideas/study-room-interior-designs") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/design-ideas/study-room-interior-designs",
        mainEntity: [{
          "@type": "Question",
          name: "Why is a study area necessary?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The ability to focus and concentrate better in a designated study space is one of the most significant benefits. A student who has a designated study area at home is considerably less likely to experience interruptions from family members."
          },
        }, {
          "@type": "Question",
          name: "What are some of the colors used in Scandinavian design?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "All you require is a decent desk and seats that are located close to the source of light, whether it be artificial or natural. Cabinets or shelves are also used in study room designs for storing books, stationary, and useful papers. You can also include white boards and chalkboards for fast notes if you have additional space."
          },
        },
        ],
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/design-ideas/home-office-interior-designs") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/design-ideas/home-office-interior-designs",
        mainEntity: [{
          "@type": "Question",
          name: "What are some ergonomic considerations when setting up a home office?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "You'll be able to work for longer periods of time without feeling tired if your home office is designed with your comfort in mind. When designing the inside of your workplace space, some ergonomics to keep in mind are: To prevent neck pain, make absolutely sure the keyboard is directly in front of the screen and that the screen is perfectly at eye level."
          },
        }, {
          "@type": "Question",
          name: "What are some must-haves for working from home?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "While working from home, there are a few necessities that might improve your workspace and increase your productivity. A chair that is both appropriate for your size and aesthetically pleasant is required."
          },
        }, {
          "@type": "Question",
          name: "How to Make Your Mini Office Feel Like Home?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Once you've established the layout of your home office, there are countless décor options you may use to personalise the space and inspire your creativity. These might include items like lights, desk plants, fragrant candles, and more that you could keep on your desk at work.Your home office should have enough room for your desk and chair, as well as allowing you to walk around comfortably. It's crucial to choose the appropriate desk and chair for your working space. To guarantee proper posture and prevent adding to your body's stress, both must be ergonomically constructed."
          },
        },
        ],
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/design-ideas/guest-room-interior-designs") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/design-ideas/guest-room-interior-designs",
        mainEntity: [{
          "@type": "Question",
          name: "What Should a Guest Room Include?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Making the guest room cosy and well-stocked with all the necessary is important because this is where your guests will relax and spend the night. A comfy bed, a bedside table, a wardrobe, accessible power outlets, and enough storage space for guests' luggage are all necessities in your guest bedroom. To make your visitors feel at ease, providing a television and some basic toiletries in the bathroom is a terrific idea. If your guest bedroom has a balcony, decorating it with blossoms and plants might be a great idea."
          },
        }, {
          "@type": "Question",
          name: "What Colour Is Most Suited to Guest Rooms?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "You can concentrate on utilising colours that are relaxing and relaxing to create the ideal guest bedroom so that your visitors can unwind fully. However, it's crucial to bear in mind your décor theme and choose colours that work well with it. For example, if your guest room design concept is modern, you should strive for a balance between bold colours and subtle tones.A foundation for the interior design of the guest room can be created using a neutral colour scheme like white, beige, or cream. Keep the bedding white or a soft green colour to match this style."
          },
        }, {
          "@type": "Question",
          name: "How Do You Design a Small Guest Room?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "With a few modest additions, a small guest bedroom may be transformed into a distinctive home. Choosing a neutral colour like white can make it appear more spacious and open, helping you to make the most of small areas. The presence of lots of natural light in the space is advantageous.Utilizing seating alternatives that can also serve as storage in a small area can increase the guest room's overall functionality. Simple guest room accessories like table lamps, artwork, flowers, wind chimes, and more can be added to brighten up the room without taking up too much space."
          },
        },
        ],
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/design-ideas/bathroom-interior-designs") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/design-ideas/bathroom-interior-designs",
        mainEntity: [{
          "@type": "Question",
          name: "What's the best way to get a bathroom set up without a lot of hassle?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Cleaning is made simple by fog proof glass because it doesn't have difficult-to-remove streaks.Integrated shelves for the best storageStorage drawers with mirrors are an example of functional décor elements that combine purpose and style."
          },
        }, {
          "@type": "Question",
          name: "How can you add storage options to your bathroom?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "a little shelf for daily needs in the shower area.Plastic or wicker containers for dirty laundry.Cabinet space below the sink.shelves put on walls"
          },
        }, {
          "@type": "Question",
          name: "What To Consider When Remodeling the Bathroom?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Hiring a contractor.Plan your bathroom remodel carefully.New lighting and ventilation.Painting and flooring.Budget"
          },
        },
        ],
  
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/design-ideas/dining-room-interior-designs") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/design-ideas/dining-room-interior-designs",
        mainEntity: [{
          "@type": "Question",
          name: "What Components Make Up a Great Dining Room Interior Design?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Any interior design style you choose for your dining room will work as long as certain elements are included.A cosy dining room table.Your crockery should be kept in a cabinet.Tablecloths to aid in setting the table.Suitable seating and lighting combinations.Other artistic elements"
          },
        }, {
          "@type": "Question",
          name: "How can one design their dining room interior on a budget?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "You may design the dining room you've always wanted on a budget by following some incredibly easy steps.Crockery cabinets can be replaced with floating shelves.Make use of, or repurpose existing features instead of buying new onesCreate a focal point by hanging a large painting or mirror.Add colourful wallpaper on the wall to spruce it upA little bit of planning can help you design your dream dining room on a budget."
          },
        }, {
          "@type": "Question",
          name: "How Should a Small Dining Room Be Designed?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Make your space look taller by starting from the floor up.Use the space you have for storage and display.Opt for light or neutral colors.Go for comfort, not formality.Consider your wall decor carefully"
          },
        },
        ],
  
  
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/design-ideas/pooja-room-interior-designs") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/design-ideas/pooja-room-interior-designs",
        mainEntity: [{
          "@type": "Question",
          name: "How to Choose a Pooja Room Style: Modern-Traditional or a Blend of both?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "When you are designing a Pooja room, it is important to choose the right style. There are many different styles that you can use for the Pooja room, and some of them have their own advantages. For example, if you want to showcase your Pooja room as an art piece then you might like to go with a modern design. If on the other hand, you want something more traditional then there are plenty of options available as well. In this article we will look at some of these styles and how they can be used when designing your Pooja Room."
          },
        }, {
          "@type": "Question",
          name: "Can you Renovate a Living Room to Pooja Room?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "In Hindu culture, the pooja room is the place where a family comes together to worship and pay homage to their deities. If you have a living room where the whole family spends time together, then choosing a corner or wall in your living room that you find relaxing and calming would be a good place for setting up your personal pooja room."
          },
        }, {
          "@type": "Question",
          name: "How Do You Create a Peaceful Spot of Your Home Into a Pooja Room?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "You don’t need a separate room in your house to practice pooja. If you have limited space, you can look into modern designs that take up little room but are still stylish."
          },
        },
        ],
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/design-ideas/balcony-interior-designs") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/design-ideas/balcony-interior-designs",
        mainEntity: [{
          "@type": "Question",
          name: "How do you style a small balcony?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Use your lighting well.Bring in as much greenery as possible.Get creative with storageChoose furniture that fits the available space.Install a balcony flooring that matches your style and is low- maintenance"
          },
        }, {
          "@type": "Question",
          name: "What do you use for the balcony floor?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The flooring on a balcony should be durable and water-resistant. Traditional or rubber tiles, resin layers, or composite boards are the best options. It is also possible to utilize a wooden floor, but it needs to be adequately proofed."
          },
        }, {
          "@type": "Question",
          name: "What does every balcony need?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Here are some of the things required in balcony.Balcony planters.Rocking chair.Outdoor rug.Convertible tables.shelving for plants"
          },
        },
        ],
  
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/design-ideas/scandinavian-interior-designs") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/design-ideas/scandinavian-interior-designs",
        mainEntity: [{
          "@type": "Question",
          name: "Is Scandinavian design modern?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "One of the most well-liked trends in modern design is Scandinavian style. There are still many golden-era designs in use nowadays."
          },
        }, {
          "@type": "Question",
          name: "What are some of the colors used in Scandinavian design?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The primary colours utilised in Scandinavian design are monochrome colours. Walls that are white or off-white are good since they create a bright, tidy background. Scandinavian furniture typically uses the colours white, grey, blue, black, and cream."
          },
        }, {
          "@type": "Question",
          name: "What characterizes a Scandinavian-style room?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Scandinavian interior design places a strong emphasis on airy, spacious areas with plenty of room to walk around. White space and simple visual features like lines convey a sense of capacity and calm."
          },
        },
        ],
  
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/design-ideas/contemporary-interior-designs") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/design-ideas/contemporary-interior-designs",
        mainEntity: [{
          "@type": "Question",
          name: "What are the main elements of contemporary design?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Excellent use of colour. Interiors designed in the contemporary style are decorated in soft neutral colours and black and white.Blend of materials and textures.Simple forms and clean lines."
          },
        }, {
          "@type": "Question",
          name: "Is contemporary design eternal and timeless?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Contemporary decorating is a timeless style defined by the classic approach of bringing rooms together through luxurious simplicity. This design movement is well worth the effort and investment because it never goes out of style and can easily be updated."
          },
        }, {
          "@type": "Question",
          name: "Can modern and contemporary designed be combined?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Mixing modern and contemporary furniture is a simple way to create a fresh look in your home. Consider using pieces that have similar lines or wood tones. Keep the traffic flow of the room in mind when placing furniture, and make sure you have enough seating for everyone."
          },
        },
        ],
      };
      return JSON.stringify(data);
    };
  };
  export const faqSchemaLocation = (pageUrl) => {
    if (pageUrl === "https://www.idesign.market/find-professionals/interior-designers-in-delhi") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/find-professionals/interior-designers-in-delhi",
        mainEntity: [{
          "@type": "Question",
          name: "How to find interior designers in Delhi near me?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "There are lots of ways which you can keep in mind while searching for the best interior designers in Delhi. First, you should have a plan and ideas about what all are your requirements, then search on internet or visit https://www.idesign.market/find-professionals/interior-designers-in-delhi to find, compare or hire an interior designer in Delhi."
          }
        }, {
          "@type": "Question",
          name: "What are the latest trends of Interior design in Delhi?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Some of the home interior design trends in Delhi are bedrooms in bold colors replacing the warm and earthy color palette with clean-lined furniture mixed with electric fusion and many more."
          }
        }, {
          "@type": "Question",
          name: "Who are the most famous interior designers in Delhi?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "There are many renowned interior designers in Delhi but to name a few are Essentia Interiors, Design Forum, Shruti-Sodhi Interior design, Beyond Vision, and MAD Design Studio."
          }
        }, {
          "@type": "Question",
          name: "What is the cost of interior design in Delhi?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "A rough estimate says that an interior designer in Delhi can charge between Rs 1500 to Rs 2500 per sqft for renovating a house."
          }
        }, {
          "@type": "Question",
          name: "How much interior designer charge in Delhi?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The costs of interior design in Delhi range from Rs 5000 a room to as high as Rs 25000 a room. Many designers charge on a per sqft basis, say Rs 90 per sqft. Here the range is between Rs 50 per Sqft to as high as Rs 250 per sqft."
          }
        }]
  
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/find-professionals/interior-designers-in-mumbai") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/find-professionals/interior-designers-in-mumbai",
        mainEntity: [{
          "@type": "Question",
          name: "How to find interior designers in Mumbai near me?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Finding the best interior designers in Mumbai will require a bit of searching and research. You just have to do thorough market research regarding the designer, can ask your family members or Visit https://www.idesign.market/find-professionals/interior-designers-in-mumbai to find the best interior designers in Mumbai."
          }
        }, {
          "@type": "Question",
          name: "What is the cost of interior design in Mumbai?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The cost of interior design in Mumbai goes from INR 1500 per sqft to INR 2500 per sqft. However, if you want a high-end interior finish, it might cost up to INR 3500 per sqft."
          }
        }, {
          "@type": "Question",
          name: "How much interior designer charge in Mumbai?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Interior Designers in Mumbai charge approx INR 3 lakh to 6 lakh depending upon the size, space, designs, and experience of interior designers."
          }
        }, {
          "@type": "Question",
          name: "What are the latest trends of Interior design in Mumbai?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Some of the top home interior design trends in Mumbai are bold colors and designs with 3D art and wall hanging in the living room."
          }
        }, {
          "@type": "Question",
          name: "Who are the most famous interior designers in Mumbai?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Some well-known interior designers in Mumbai are ZZ Architects, Talati & Partners, Neterwala & Aibara, Rajiv Saini and Associates, and eD – Research & Enquiry into Design."
          }
        }]
  
      };
      return JSON.stringify(data);
    };
  
    if (pageUrl === "https://www.idesign.market/find-professionals/interior-designers-in-gurgaon") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/find-professionals/interior-designers-in-gurgaon",
        mainEntity: [{
          "@type": "Question",
          name: "What is the cost of interior design in Gurgaon?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "A rough estimate says that an interior designer in Gurgaon can charge between Rs 1500 to Rs 2500 per sqft for renovating a house."
          }
        }, {
          "@type": "Question",
          name: "How much interior designer charge in Gurgaon?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The costs of interior design in Gurgaon range from Rs 8000 a room to as high as Rs 30000 a room. Many designers charge on a per sqft basis, say Rs 90 per sqft. Here the range is between Rs 70 per Sqft to as high as Rs 300 per sqft."
          }
        }, {
          "@type": "Question",
          name: "What are the latest trends of Interior design in Gurgaon?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The latest trends of Interior design in Gurgaon are using bold colors in the living rooms while green and terracotta are the new In, Incorporating crazy wallpapers with metal and colorful decors in the mandir."
          }
        }, {
          "@type": "Question",
          name: "Who are the most famous interior designers in Gurgaon?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "There are many top interior designers in Gurgaon but to name a few are 7WD Architecture & Interior Design Studio, Mads Creations, Livspace Experience Centre, Tribuz Interiors Pvt. Ltd., Riflessione Lussuosa and many more."
          }
        }, {
          "@type": "Question",
          name: "How to find interior designers in Gurgaon near me?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "To find home interior designers in Gurgaon you can search on the internet or can ask your close family members and friends regarding it or visit https://www.idesign.market/find-professionals/interior-designers-in-gurgaon to hire an interior designer in Gurgaon."
          }
        }]
  
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/find-professionals/interior-designers-in-faridabad") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/find-professionals/interior-designers-in-faridabad",
        mainEntity: [{
          "@type": "Question",
          name: "How to find interior designers in Faridabad near me?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Another choice is using social media platforms. Use Instagram's search function and enter interior design and the name of your city or neighborhood to discover more about your choices. Additionally, online marketplaces are useful options. Visit https://www.idesign.market/find-professionals/interior-designers-in-faridabad to hire interior designers in Faridabad."
          }
        }, {
          "@type": "Question",
          name: "What is the cost of interior design in Faridabad?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "A rough estimate says that an interior designer in Faridabad can charge between Rs 1000 to Rs 1500 per sqft for renovating a house."
          }
        }, {
          "@type": "Question",
          name: "How much interior designer charge in Faridabad?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The costs of interior design in Faridabad range from Rs 4000 a room to as high as Rs 22000 a room. Many designers charge on a per sqft basis, say Rs 50 per sqft. Here the range is between Rs 40 per Sqft to as high as Rs 220 per sqft."
          }
        }, {
          "@type": "Question",
          name: "What are the latest trends of Interior design in Faridabad?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Recent developments in interior design in Faridabad include space-saving concepts in modular kitchen designs and furniture with multiple uses like a fusion of ethnic and contemporary styles."
          }
        }, {
          "@type": "Question",
          name: "Who are the most famous interior designers in Faridabad?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The top interior designers in Faridabad are Meraki designers, Aspire Interiors, Maison Du Luxe, Space Shastra Architects, Eternity Designers, and many more."
          }
        }]
  
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/find-professionals/interior-designers-in-noida") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/find-professionals/interior-designers-in-noida",
        mainEntity: [{
          "@type": "Question",
          name: "How to find interior designers in Noida near me?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "To find the best interior designers in Noida you can take the help of friends, family, the internet, and many more. Then compare the rates of all the designers and hire them at https://www.idesign.market/find-professionals/interior-designers-in-noida."
          }
        }, {
          "@type": "Question",
          name: "Who are the most famous interior designers in Noida?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "You would want someone who can fully realize your idea while carrying out your concept when it comes to remodeling or redoing the interiors. The top interior designers in Noida are NuRah Designs, Kalki Interior & Decors, Keon Infra, Expert Interior & Decor, Woodskraft, and many more."
          }
        }, {
          "@type": "Question",
          name: "What are the latest trends of Interior design in Noida?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The usage of light wood furniture using Pantone colors and eco-friendly decor in the bedroom and indoor plants in the living room are a few of the prominent interior design ideas in Noida, among a long list of others."
          }
        }, {
          "@type": "Question",
          name: "How much interior designer charge in Noida?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The costs of interior design in Noida range from Rs 5000 a room to as high as Rs 25000 a room. Many designers charge on a per sqft basis, say Rs 50 per sqft. Here the range is between Rs 60 per Sqft to as high as Rs 250 per sqft."
          }
        }, {
          "@type": "Question",
          name: "What is the cost of interior design in Noida?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "A rough estimate says that an interior designer in Noida can charge between Rs 1500 to Rs 2000 per sqft for renovating a house."
          }
        }]
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/find-professionals/interior-designers-in-ghaziabad") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/find-professionals/interior-designers-in-ghaziabad",
        mainEntity: [{
          "@type": "Question",
          name: "How to find interior designers in Ghaziabad near me?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "To find interior designers in Ghaziabad take a note of few things first be specific and pen down the things you would like to incorporate in your house, you can take the help of social platforms or visit https://www.idesign.market/find-professionals/interior-designers-in-ghaziabad and then if you liked their services you can discuss on budgets and can hire them."
          }
        }, {
          "@type": "Question",
          name: "Who are the most famous interior designers in Ghaziabad?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Some of the top interior designers in Ghaziabad are Spacemount Private Limited, Studio Pramana, Gesture Designers and Architects, Srishti Studio, Advice Architect, and many more"
          }
        }, {
          "@type": "Question",
          name: "What are the latest trends of Interior design in Ghaziabad?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "To mention some of the latest interior design trends in Ghaziabad people here love multifunctional furniture, light and subtle colors with a Fusion of modern and ethnic styles in the Living room."
          }
        }, {
          "@type": "Question",
          name: "How much interior designer charge in Ghaziabad?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The costs of interior design in Ghaziabad range from Rs 4000 a room to as high as Rs 22000 a room. Many designers charge on a per sqft basis, say Rs 50 per sqft. Here the range is between Rs 30 per Sqft to as high as Rs 200 per sqft."
          }
        }, {
          "@type": "Question",
          name: "What is the cost of interior design in Ghaziabad?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "A rough estimate says that an interior designer in Ghaziabad can charge between Rs 1000 to Rs 1500 per sqft for renovating a house."
          }
        }]
  
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/find-professionals/interior-designers-in-bengaluru") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/find-professionals/interior-designers-in-bengaluru",
        mainEntity: [{
          "@type": "Question",
          name: "How to find interior designers in Bengaluru near me?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "To find the best interior designers in Bangalore you must contact the person from your family or friends who has already taken the services the interior design in Bangalore. They must guide you very well regarding this. Visit https://www.idesign.market/find-professionals/interior-designers-in-bengaluru to hire an interior designer in Bengaluru."
          }
        }, {
          "@type": "Question",
          name: "What is the cost of interior design in Bangalore?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "A rough estimate says that an interior designer in Bangalore can charge between Rs 1500 to Rs 2500 per sqft for renovating a house."
          }
        }, {
          "@type": "Question",
          name: "How much interior designer charge in Bangalore?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The costs of interior design in Bangalore range from Rs 6000 a room to as high as Rs 27000 a room. Many designers charge on a per sqft basis, say Rs 50 per sqft. Here the range is between Rs 80 per Sqft to as high as Rs 300 per sqft."
          }
        }, {
          "@type": "Question",
          name: "What are the latest trends of Interior design in Bangalore?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The latest trends in Bengaluru interior designs are Loves the concept of wood and like to incorporate them in their living room, also they love incorporating two-toned kitchen colors."
          }
        }, {
          "@type": "Question",
          name: "Who are the most famous interior designers in Bangalore?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "There are many renowned and best interior designers in Bangalore but to name a few of them are Voglia Interiors, Magnon Interiors, Creative Axis Interior, Kuvio Studio Interiors, Bluesky Concepts Interiors, and many more."
          }
        }]
  
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/find-professionals/interior-designers-in-hyderabad") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/find-professionals/interior-designers-in-hyderabad",
        mainEntity: [{
          "@type": "Question",
          name: "How to find interior designers in Hyderabad near me?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "There are multiple ways you can find interior designers in Hyderabad the best way is social media where you can upload or ask and tonnes of replies you can get and after that, you can contact them and discuss all the necessary things. Compare fees and experience of interior designers in Hyderabad at https://www.idesign.market/find-professionals/interior-designers-in-hyderabad."
          }
        }, {
          "@type": "Question",
          name: "What is the cost of interior design in Hyderabad?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "A rough estimate says that an interior designer in Hyderabad can charge between Rs 1500 to Rs 2000 per sqft for renovating a house."
          }
        }, {
          "@type": "Question",
          name: "How much interior designer charge in Hyderabad?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The costs of interior design in Hyderabad range from Rs 4000 a room to as high as Rs 20,000 a room. Many designers charge on a per sqft basis, say Rs 50 per sqft. Here the range is between Rs 40 per Sqft to as high as Rs 220 per sqft."
          }
        }, {
          "@type": "Question",
          name: "What are the latest trends of Interior design in Hyderabad?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "In 2022, interior design trends will be more spectacular than ever. Name few of the design trends are textures and patterns in the bedroom and a collection of artwork, and unique furniture with Indoor plants in the living room."
          }
        }, {
          "@type": "Question",
          name: "Who are the most famous interior designers in Hyderabad?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "There are tons of top interior designers in Hyderabad but a few of them are Cream Interiors, Tru Designs, Zing Designs, Raj Architects, V Square Interiors and there are many more such famous interior designers."
          }
        }]
  
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/find-professionals/interior-designers-in-pune") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/find-professionals/interior-designers-in-pune",
        mainEntity: [{
          "@type": "Question",
          name: "How to find interior designers in Pune near me?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "To find an interior designer in Pune easily, you can follow two ways. First is to look up on the internet and research well. And the second option is to visit our website for easy and quick find: https://www.idesign.market/find-professionals"
          }
        }, {
          "@type": "Question",
          name: "What is the cost of interior design in Pune?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "A rough estimate says that an interior designer in Pune can charge between Rs 1500 to Rs 2500 per sqft for renovating a house."
          }
        }, {
          "@type": "Question",
          name: "How much interior designer charge in Pune?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The costs of interior design in Pune range from Rs 5000 a room to as high as Rs 25,000 a room. Many designers charge on a per sqft basis, say Rs 50 per sqft. Here the range is between Rs 50 per Sqft to as high as Rs 300 per sqft."
          }
        }, {
          "@type": "Question",
          name: "What are the latest trends of Interior design in Pune?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "A few trends of the latest interior designs in Pune include the addition of greenery in the living room, space-saving foldable furniture for small rooms, nude color palettes instead of vibrant tones, contemporary home interiors, and many more."
          }
        }, {
          "@type": "Question",
          name: "Who are the most famous interior designers in Pune?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "There are many well-known interior designers in Pune. Some of the most famous interior designers in Pune are Sadguru Interiors, Kadiwa Studio, Minakshi Mohite, Matrix FMS India Pvt Ltd, RAD's, and many more."
          }
        }]
  
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/find-professionals/interior-designers-in-jaipur") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/find-professionals/interior-designers-in-jaipur",
        mainEntity: [{
          "@type": "Question",
          name: "How to find interior designers in Jaipur near me?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The best interior design company in Jaipur can be found by doing thorough research about the designers on the internet. Some interior designers charge more than others so it is important to keep a budget in mind. You can also visit our website for an easy comparison of budget: https://www.idesign.market/find-professionals"
          }
        }, {
          "@type": "Question",
          name: "What is the cost of interior design in Jaipur?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "A rough estimate says that an interior designer in Jaipur can charge between Rs 1000 to Rs 1500 per sqft for renovating a house."
          }
        }, {
          "@type": "Question",
          name: "How much interior designer charge in Jaipur?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The costs of interior design in Jaipur range from Rs 4000 a room to as high as Rs 22,000 a room. Many designers charge on a per sqft basis, say Rs 50 per sqft. Here the range is between Rs 40 per Sqft to as high as Rs 250 per sqft."
          }
        }, {
          "@type": "Question",
          name: "What are the latest trends of Interior design in Jaipur?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Interior design trends keep on evolving with time, and in the pink city of India, the trends are getting very creative and funky. Some latest trends of interior design in Jaipur are local handmade furniture, playing with textures and patterns for wall decor, using vibrant colors in the home furniture, adding small plants for decorations, etc."
          }
        }, {
          "@type": "Question",
          name: "Who are the most famous interior designers in Jaipur?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Here is the list of the most famous interior designers in Jaipur: Manmohana, Jaipur Interiors, Interior Decorators Jaipur, Asia Interiors & Exteriors, ASM Design Studio, Shape Interiors, Hanumant Developers, and many more."
          }
        }]
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/find-professionals/interior-designers-in-lucknow") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/find-professionals/interior-designers-in-lucknow",
        mainEntity: [{
          "@type": "Question",
          name: "How to find interior designers in Lucknow near me?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "To find suitable interior designers in Lucknow without the hassle of researching on the internet, visit our website for easy comparisons in terms of experience and budget: https://www.idesign.market/find-professionals"
          }
        }, {
          "@type": "Question",
          name: "What is the cost of interior design in Lucknow?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "A rough estimate says that an interior designer in Lucknow can charge between Rs 1500 to Rs 2500 per sqft for renovating a house."
          }
        }, {
          "@type": "Question",
          name: "How much interior designer charge in Lucknow?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The costs of interior design in Lucknow range from Rs 5000 a room to as high as Rs 25,000 a room. Many designers charge on a per sqft basis, say Rs 50 per sqft. Here the range is between Rs 50 per Sqft to as high as Rs 300 per sqft."
          }
        }, {
          "@type": "Question",
          name: "What are the latest trends of Interior design in Lucknow?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Lucknow is a beautiful city and people here love beautiful home interior design as well. The latest trends of interior design in Lucknow are Scandinavian interiors, fusions such as modern and traditional, boho chic interiors, and luxurious as one might guess."
          }
        }, {
          "@type": "Question",
          name: "Who are the most famous interior designers in Lucknow?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Out of a lot of interior designers in Lucknow, some of the most famous are Able Interiors, Alis Interior & Construction, Architect Rohit Verma, AMSAN Creative, Avishkaar Architects, DC Interiors, and Decorit."
          }
        }]
  
  
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/find-professionals/interior-designers-in-indore") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/find-professionals/interior-designers-in-indore",
        mainEntity: [{
          "@type": "Question",
          name: "How to find interior designers in Indore near me?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Interior designers in Indore can be found by doing thorough internet research. But a more convenient way is to visit our website for quick comparisons among a variety of designer professionals: https://www.idesign.market/find-professionals"
          }
        }, {
          "@type": "Question",
          name: "What is the cost of interior design in Indore?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "A rough estimate says that an interior designer in Indore can charge between Rs 1200 to Rs 2000 per sqft for renovating a house."
          }
        }, {
          "@type": "Question",
          name: "How much interior designer charge in Indore?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The costs of interior design in Indore range from Rs 4000 a room to as high as Rs 20,000 a room. Many designers charge on a per sqft basis, say Rs 50 per sqft. Here the range is between Rs 40 per Sqft to as high as Rs 200 per sqft."
          }
        }, {
          "@type": "Question",
          name: "What are the latest trends of Interior design in Indore?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Most of the homeowners in Indore have started to take interest in the latest trends in interior design in Indore. Some latest trends are eclectic home interiors, a functional home office, fancy & modern kitchen interiors, and playing with bold colors in wall designs, to name a few."
          }
        }, {
          "@type": "Question",
          name: "Who are the most famous interior designers in Indore?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Interior designers of Indore have a well-known reputation, and the few most famous interior designers in Indore are as follows: Adharshila Associates, Architectural Design Studio, Design Cube, Classic Decor, Architecture Basics, and more."
          }
        }]
  
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/find-professionals/interior-designers-in-ahmedabad") {
      let data = {
  
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/find-professionals/interior-designers-in-ahmedabad",
        mainEntity: [{
          "@type": "Question",
          name: "How to find interior designers in Ahmedabad near me?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Searching for an interior designer in Ahmedabad can be a time-consuming task but good internet research is always better than regretting later. But if you want to save time, have a look at our website for quick suggestions https://www.idesign.market/find-professionals"
          }
        }, {
          "@type": "Question",
          name: "What is the cost of interior design in Ahmedabad?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "A rough estimate says that an interior designer in Ahmedabad can charge between Rs 1000 to Rs 2000 per sqft for renovating a house."
          }
        }, {
          "@type": "Question",
          name: "How much interior designer charge in Ahmedabad?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The costs of interior design in Ahmedabad range from Rs 4000 a room to as high as Rs 20,000 a room. Many designers charge on a per sqft basis, say Rs 50 per sqft. Here the range is between Rs 40 per Sqft to as high as Rs 200 per sqft."
          }
        }, {
          "@type": "Question",
          name: "What are the latest trends of Interior design in Ahmedabad?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Ahmedabad is doing great with the interior design trends of 2022, as evidenced by the latest trends of interior design in Ahmedabad. A warm color palette for home interiors, eclectic designs, smart furniture, and boho aesthetics are a few trendy options."
          }
        }, {
          "@type": "Question",
          name: "Who are the most famous interior designers in Ahmedabad?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "You can find the list of the most famous interior designers in Ahmedabad here: Studio Ibis, Devananda Shah, SQFT Decor, 9 Degree Design Studio, and Zeelex Interiors, among many well-reputed designers."
          }
        }]
  
  
  
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/find-professionals/interior-designers-in-chennai") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/find-professionals/interior-designers-in-chennai",
        mainEntity: [{
          "@type": "Question",
          name: "How to find interior designers in Chennai near me?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Finding interior designers in Chennai should be an easy task. Internet sources are the first option to find a perfect match but it is always smart to have an efficient backup. Visit our website for verified designers https://www.idesign.market/find-professionals"
          }
        }, {
          "@type": "Question",
          name: "What is the cost of interior design in Chennai?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "A rough estimate says that an interior designer in Chennai can charge between Rs 1500 to Rs 2500 per sqft for renovating a house."
          }
        }, {
          "@type": "Question",
          name: "How much interior designer charge in Chennai?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The costs of interior design in Chennai range from Rs 5000 a room to as high as Rs 25,000 a room. Many designers charge on a per sqft basis, say Rs 50 per sqft. Here the range is between Rs 50 per Sqft to as high as Rs 250 per sqft."
          }
        }, {
          "@type": "Question",
          name: "What are the latest trends of Interior design in Chennai?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The latest trends in interior design in Chennai are quite interesting. Comfortable corners in living rooms, greenery around the house, vibrant and bright colors for furniture and walls, earthy elements, and modern home office top our list among many other interesting trends."
          }
        }, {
          "@type": "Question",
          name: "Who are the most famous interior designers in Chennai?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "If you're looking for the top interior designers in Chennai, we have created a quick list for you to find the most famous interior designer in Chennai. Bizzoppo Interiors, The Plank, Home and Beyond Interiors, Zenith Interior, Zeenius Interiors, are among the top designers in Chennai."
          }
        }]
  
      };
      return JSON.stringify(data);
    };
    if (pageUrl === "https://www.idesign.market/find-professionals/interior-designers-in-kolkata") {
      let data = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        url: "https://www.idesign.market/find-professionals/interior-designers-in-kolkata",
        mainEntity: [{
          "@type": "Question",
          name: "How to find interior designers in Kolkata near me?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "To find the best interior designer in Kolkata who is also affordable, visit our website https://www.idesign.market/find-professionals/interior-designers-in-kolkata. Here, you'll find a variety of verified designers along with their budgets and experiences."
          }
        }, {
          "@type": "Question",
          name: "What is the cost of interior design in Kolkata?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "A rough estimate says that an interior designer in Kolkata can charge between Rs 1500 to Rs 2500 per sqft for renovating a house."
          }
        }, {
          "@type": "Question",
          name: "How much interior designer charge in Kolkata?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "The costs of interior design in Kolkata range from Rs 5000 a room to as high as Rs 25,000 a room. Many designers charge on a per sqft basis, say Rs 50 per sqft. Here the range is between Rs 50 per Sqft to as high as Rs 250 per sqft."
          }
        }, {
          "@type": "Question",
          name: "What are the latest trends of Interior design in Kolkata?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Out of the many amazing trends of interior design, here are a few latest trends of interior design in Kolkata. Vintage look, natural theme, minimalist designs, neutral color palettes, functional home offices, fun floor patterns, and many more are trendy right now."
          }
        }, {
          "@type": "Question",
          name: "Who are the most famous interior designers in Kolkata?",
          acceptedAnswer: {
            "@type": "Answer",
            text: "Among many well-known designers Near Me Interiors, Spectrum Interiors, Estate Lookup Interiors, BlueMasons, DS INFRA, MARVEL, and Cee Bee Design Studio are the most famous interior designers in Kolkata."
          }
        }]
  
  
      };
      return JSON.stringify(data);
    };
  };
  
  
  