import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import HeaderMob from "../onboardingMob/HeaderMob";
import WelcomeMob from "../onboardingMob/WelcomeMob";
import Welcome from "../onBoarding/Welcome";
import CongratulationsModal from "../../Pricing/CongratulationsModal";

const WelcomeCombined = () => {
  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
  const [showCongratulationsModal, setShowCongratulationsModal] = useState(true);
  const updateMedia = () => {
    setIsDesktop(window.screen.width > 767);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowCongratulationsModal(false);
    }, 2000); 

    return () => clearTimeout(timer);
  }, []);
  
  useEffect(() => {
    function preventBack() { 
      window.history.forward();  
    } 
    
    setTimeout(preventBack(), 0); // Pass the function reference directly
    
    window.onunload = function() { return null; }; // Assign a function reference or function body
  }, []);

  return (
    <>
      {showCongratulationsModal && <CongratulationsModal />}
      {isDesktop ? (
        <Welcome />
      ) : (
        <>
          <HeaderMob />
          <WelcomeMob />
        </>
      )}
    </>
  );
};

export default WelcomeCombined;
