import React, { useState, useEffect, useRef } from "react";
import styles from "./HeroContainer.module.css";
import { useNavigate } from "react-router-dom";
import Home from "./Images/Home.jpg"

const HeroContainer = () => {
  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
  const updateMedia = () => {
    setIsDesktop(window.screen.width > 767);
  };
  const [scrollButtonVisible, setScrollButtonVisible] = useState(true);
  const [alertVisible, setAlertVisible] = useState(false);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const button = buttonRef.current;
      if (button) {
        const buttonRect = button.getBoundingClientRect();
        const isVisible =
          buttonRect.top >= 0 &&
          buttonRect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight);
        setScrollButtonVisible(isVisible);
        setAlertVisible(!isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  const navigate = useNavigate();

  return (
    <>
      <div className={styles.heroContainerMain}>
        <div className={styles.mainContainer}>
          {isDesktop ? (
            <img
              src={Home}
              alt="Your Image"
              className={styles.heroImg}
            />
          ) : (
            <img
              src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/image%2035.png"
              alt="Your Image"
              className={styles.heroImg}
            />
          )}
          <div className={styles.gradientContainer}></div>
          <div className={styles.flexInnerContainerOuter}>
            <div className={styles.innerContainer}>
              <div className={styles.firstLineContainer}>
                <span className={styles.firstLine}>
                SOFTWARE for INTERIOR, ARCHITECTURE & Construction firms
                </span>
              </div>
              <div className={styles.secondLine}>
              Manage the complexity of projects from one single platform
              </div>
              <div className={styles.thirdLine}>
                Digitize your business: access drawings remotely, manage teams,
                onboard clients, create quotes and track all project elements
                efficiently.
              </div>
              <div className={styles.btnContainer}>
                {isDesktop ? (
                  <div
                    className={styles.scheduleDemoBtn}
                    onClick={() => navigate("/schedule")}
                  >
                    <div className={styles.scheduleDemoBtnText}>
                    Schedule a demo
                    </div>
                  </div>
                ) : (
                  <div
                    ref={buttonRef} style={{userSelect:"none"}}
                    className={styles.scheduleDemoBtn}
                    onClick={() => navigate("/schedule")}
                  >
                    <div className={styles.scheduleDemoBtnText}>
                      Schedule a demo
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
       {alertVisible && (<div className={styles.btnOuterContainer}>
          <div
            className={styles.scheduleDemoBtn}
            onClick={() => navigate("/schedule")}
          >
            <div className={styles.scheduleDemoBtnText}>Schedule a demo</div>
          </div>
        </div>)}
      </div>
    </>
  );
};

export default HeroContainer;
