import React from "react";
import styles from "../../onBoardingCss/DashboardCssNew/manageleads.module.css";
import { useState } from "react";
import All from "./manageLeadsTabs/All";
import Interested from "./manageLeadsTabs/Interested";
import FollowUp from "./manageLeadsTabs/FollowUp";
import NotInterested from "./manageLeadsTabs/NotInterested";
import NoResponse from "./manageLeadsTabs/NoResponse";

const ManageLeads = () => {
  const [activeBtn, setactiveBtn] = useState("active1");
  return (
    <>
      <div className={styles.container}>
        <div style={{ display: "flex", gap: "1rem", paddingTop: "1.5rem" }}>
          <button
            className={
              activeBtn === "active1" ? styles.btnsActive : styles.btns
            }
            onClick={() => setactiveBtn("active1")}
          >
            All
          </button>
          <button
            className={
              activeBtn === "active2" ? styles.btnsActive : styles.btns
            }
            onClick={() => setactiveBtn("active2")}
          >
            Interested
          </button>
          <button
            className={
              activeBtn === "active3" ? styles.btnsActive : styles.btns
            }
            onClick={() => setactiveBtn("active3")}
          >
            Follow up
          </button>
          <button
            className={
              activeBtn === "active4" ? styles.btnsActive : styles.btns
            }
            onClick={() => setactiveBtn("active4")}
          >
            Not Interested
          </button>
          <button
            className={
              activeBtn === "active5" ? styles.btnsActive : styles.btns
            }
            onClick={() => setactiveBtn("active5")}
          >
            No Response
          </button>

         
        </div>
       
            {activeBtn === "active1" ? (
              <All />
            ) : activeBtn === "active2" ? (
              <Interested />
            ) : activeBtn === "active3" ? (
              <FollowUp />
            ) : activeBtn === "active4" ? (
              <NotInterested />
            ) : activeBtn === "active5" ? (
              <NoResponse />
            ) : null}
      
      </div>
    </>
  );
};

export default ManageLeads;
