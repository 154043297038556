import React from "react";
import styles from "./productMobCss/productSec3.module.css";
import icon1 from "../../SaasOnboarding/Products/ProductsImg/po/price_change.svg";
import icon2 from "../../SaasOnboarding/Products/ProductsImg/po/group.svg";
import icon3 from "../../SaasOnboarding/Products/ProductsImg/po/stream.svg";
// import quo from "./productMobImg/po/po.png";

const ProductSec3 = () => {
  return (
    <>
      <div className={styles.container} id="poo">
        <div className={styles.files_contain}>
          <div className={styles.file_content_div}>
            <div className={styles.file_head}>PURCHASE ORDER</div>
            <div className={styles.file_para}>
              Automate the purchasing process and <br /> ensuring that all
              necessary information is included in the purchase order.
            </div>
            <div className={styles.img_div}>
              <img
                src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207463%20%282%29.svg"
                alt=""
                className={styles.img}
              />
            </div>
            <div className={styles.icon_div}>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon1} alt="" className={styles.mobIcon} />
                </div>
                <div className={styles.iconPara}>
                  Integration with <br /> Accounting software
                </div>
              </div>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon2} alt="" className={styles.mobIcon} />
                </div>
                <div className={styles.iconPara}>
                  Manage Vendors <br /> Across Projects
                </div>
              </div>

              <div className={styles.eachBox}>
                <div>
                  <img src={icon3} alt="" className={styles.mobIcon} />
                </div>
                <div className={styles.iconPara}>
                  Streamlined <br /> procurement
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSec3;
