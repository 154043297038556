import React from "react";
import ProductSection2 from "./ProductSection2";
import ProductSection3 from "./ProductSection3";
import ProductSection4 from "./ProductSection4";
import ProductSection5 from "./ProductSection5";
import ProductSection6 from "./ProductSection6";
import ProductSection7 from "./ProductSection7";
import ProductSection8 from "./ProductSection8";
import ProductSection9 from "./ProductSection9";
import ProductSection10 from "./ProductSection10";
import LandingSection7 from "../NewLandingPage/LandingSection7";
import LandingSection8 from "../NewLandingPage/LandingSection8";
import ProductSectionFile from "./ProductSectionFile";
import Footer from "../PricingPage/Footer";
import ProductSection11 from "./ProductSection11";
import ProductSection12 from "./ProductSection12";
import ProductSection13 from "./ProductSection13";

const SameComponents = () => {
  return (
    <>
      <ProductSectionFile />
      <ProductSection2 />
      <ProductSection3 />
      <ProductSection4 />
      <ProductSection5 />
      <ProductSection6 />
      <ProductSection7 />
      <ProductSection8 />
      <ProductSection9 />
      <ProductSection10 />
      <ProductSection11 />
      <ProductSection12 />
      <ProductSection13 />
      <LandingSection7 />
      <LandingSection8 />
      <Footer />
    </>
  );
};

export default SameComponents;
