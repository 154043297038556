import React, { useState } from "react";
import styles from "./FAQ.module.css";
import { FaPlus, FaMinus } from "react-icons/fa";

const arrayData = [
  {
    question: "What is iDesign.Market?",
    answer:
    "iDesign.Market is a SaaS platform offering a centralised hub for project management, collaboration, and design visualisation. From ledger entries to vendor and PO management, iDesign offers a wide range of ERP tools tailored specifically to different user personas such as interior designers, builders and many more."
  },
  {
    question:
      "How is iDesign different from other project management solutions?",
    answer: `
    What sets iDesign apart includes:
      - No-code customization of workflows, approvals, permissions to meet your firm’s exact needs.
      - Cloud infrastructure readily scales from dozens to thousands of users globally.
      - Real-time centralised dashboards giving all stakeholders project visibility.
      - Process automation via AI and machine learning saves hours on manual tasks.

      In short, iDesign  offers versatility, scalability, and intelligent automation to gain efficiency and control across the project lifecycle. Our platform is tailored for your work streams and scales as you grow.
    `
  },
  {
    question: "Who does iDesign  serve?",
    answer: "We serve five segments of customers - Architects, Construction Companies, Interior firms, Real Estate Developers and Retail Companies"
  },
  {
    question: "How do I get started?",
    answer:
    "For details on pricing models, custom quotes based on user count, integrations, or tailoring to your industry vertical, our product experts are ready to discuss options fit to your needs. Contact us at "
  }
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <div className={styles.heading}>FAQs about iDesign.Market</div>
      <div className={styles.qAndACon}>
      {arrayData.map((item, index) => (
        <div key={index} style={{ marginBottom: "10px" }} className={`${styles.accordionItem} ${activeIndex ==index ? styles.clickedAccItem :""}`}>
          <div
            style={{ cursor: "pointer" }}
            className={styles.accordionContainer}
            onClick={() => toggleAnswer(index)}
          >
            <div className={`${styles.accordionQuestion} ${activeIndex==index ? styles.clicked : "" }`}>
              <span>{index + 1}.{" "}</span>
              <span>{item.question}</span>
            </div>
            <div className={styles.plusMinusToggle}>
              {activeIndex === index ? (
                <FaMinus size={12} color="#BFBFBF" />
              ) : (
                <FaPlus size={12} color="#BFBFBF" />
              )}
            </div>
          </div>
          {activeIndex === index && (
            <div className={styles.accordionAnsClass}>
              {index === 1 ? (
                <div className={styles.thirdAnswer}>
                  <div>What sets iDesign  apart includes:</div>
                  <ul className={styles.ulList}>
                    <li className={styles.ulListItem}>No-code customization of workflows, approvals, permissions to meet your firm’s exact needs.</li>
                    <li className={styles.ulListItem}>Cloud infrastructure readily scales from dozens to thousands of users globally.</li>
                    <li className={styles.ulListItem}>Real-time centralized dashboards giving all stakeholders project visibility.</li>
                    <li className={styles.ulListItem}>Process automation via AI and machine learning saves hours on manual tasks.</li>
                  </ul>
                  <div>In short, construction leaders choose iDesign  for the versatility, scalability, and intelligent automation to gain efficiency and control across the project lifecycle. Our platform is tailored for your workstreams and scales as you grow.
                </div>
                </div>
              ) : item.question === "How do I get started?" ? (
                <div>
                  {item.answer}<span style={{width:10}} ></span>
                  <a href="mailto:support@idesign.market?body=" >support@idesign.market</a>
                  .
                </div>
              ) : (
                item.answer
              )}
            </div>
          )}
        </div>
      ))}
      </div>
    </>
  );
};

export default FAQ;
