import React, { useEffect, useState } from "react";
import ProductPage from "../../SaasOnboardingMobile/ProductPageMob/ProductPage";
import LoginHeader from "../LoginHeader";
// import LandingSection9 from "../NewLandingPage/LandingSection9";
import Footer from "../PricingPage/Footer";
import ProductSection1 from "./ProductSection1";
import ProductSection2 from "./ProductSection2";
import ProductSection3 from "./ProductSection3";
import ProductSection4 from "./ProductSection4";
import ProductSection5 from "./ProductSection5";
import ProductSection6 from "./ProductSection6";
import ProductSection7 from "./ProductSection7";
import ProductSection8 from "./ProductSection8";
import ProductSection9 from "./ProductSection9";
import ProductSection10 from "./ProductSection10";
import LandingSection7 from "../NewLandingPage/LandingSection7";
import LandingSection8 from "../NewLandingPage/LandingSection8";
import { Helmet } from "react-helmet";
import { webpageSchema } from "../../../../Schemas";
import ProductSectionFile from "./ProductSectionFile";
import ProductSection11 from "./ProductSection11";
import ProductSection12 from "./ProductSection12";
import ProductSection13 from "./ProductSection13";
import { BsFillArrowUpCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { featureScroll } from "../../../Actions";

const ProductMain = () => {
  const dispatch = useDispatch();
  const { scrollToFeatures } = useSelector((state) => state.addToCartReducer);
  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
  const updateMedia = () => {
    setIsDesktop(window.screen.width > 767);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  // scroll to top
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  window.addEventListener("scroll", toggleVisible);

  // scroll to feature
  useEffect(() => {
    console.log(scrollToFeatures);
    if (scrollToFeatures) {
      const targetDiv = document.getElementById(scrollToFeatures);
      const offset = 360;
      const targetOffset = targetDiv.offsetTop - offset;

      window.scrollTo({
        top: targetOffset,
        behavior: "smooth",
      });

      dispatch(featureScroll(""));
      return () => {
        dispatch(featureScroll(""));
      };
    }
  }, [scrollToFeatures]);

  return (
    <>
      <Helmet>
        <script className="structured-data-list" type="application/ld+json">
          {webpageSchema(
            window.location.href,
            "Looking to Renovate your home?",
            "One Solution for Interior and Construction Companies",
            "iDesign.Market is a tech platform providing Cloud based software solutions to Interior and Construction companies to manage their projects & business from start to end."
          )}
        </script>
        <meta
          name="title"
          content="One Solution for Interior and Construction Companies"
        />
        <meta
          name="description"
          content="iDesign.Market is a tech platform providing Cloud based software solutions to Interior and Construction companies to manage their projects & business from start to end."
        />
        <link rel="canonical" href={`${window.location.href}`} />
        <title>
        One Solution for Interior and Construction Companies
        </title>
      </Helmet>
      {isDesktop ? (
        <div className="d-flex flex-column" style={{ overflowX: "hidden" }}>
          <LoginHeader />
          <div className="d-flex flex-column" style={{ zIndex: 0 }}>
            <ProductSection1 />
            <ProductSectionFile />
            <ProductSection2 />
            <ProductSection3 />
            <ProductSection4 />
            <ProductSection5 />
            <ProductSection6 />
            <ProductSection7 />
            <ProductSection8 />
            <div
              style={{
                position: "fixed",
                top: "90%",
                right: "5%",
                cursor: "pointer",
              }}
            >
              <BsFillArrowUpCircleFill
                onClick={scrollToTop}
                size={30}
                style={{ display: visible ? "inline" : "none" }}
              />
            </div>
            <ProductSection9 />
            <ProductSection10 />
            <ProductSection11 />
            <ProductSection12 />
            <ProductSection13 />
            <LandingSection7 />
            <LandingSection8 />

            <Footer />
          </div>
        </div>
      ) : (
        <ProductPage />
      )}
    </>
  );
};

export default ProductMain;
