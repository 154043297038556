import React from "react";
import HeaderNav from "../../../../HeaderNav/HeaderNav";
import SidebarWebNew from "../../../../SidebarNew/SidebarWebNew";
import AllDashBoard from "./AllDashBoard";
import HeaderSidebar from "../../../../../../AllProjectListPanel/HeaderSidebar/HeaderSidebar/HeaderSidebar";

const DashBoardAnj = () => {
  return (
    <>
      {/* <HeaderNav /> */}
      <HeaderSidebar />
      <div className="container-box">     
        <AllDashBoard />
      </div>
    </>
  );
};

export default DashBoardAnj;
