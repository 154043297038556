import React, { useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import dashContext from "../../picontext/DashBoard/dashContext";
import { getReq } from "../../piservices/apis";

const ChatItem = (props) => {
  const ref = useRef();
  const context = useContext(dashContext);
  const { chatId } = useParams();
  const navigateTo = useNavigate();
  const { activeChat, setActiveChatMsgs, searchKey } = context;
  // console.log("here");

  const init = async () => {
    if (props?.data?.conversationId && activeChat?.homeOwnerId === props?.data?.homeOwnerId) {
      const call2 = await getReq(`https://chat.idesign.market/message/${props.data.conversationId}`, {});
      if (call2 && !call2.error) {
        setActiveChatMsgs(call2?.data);
      } else {
        console.log(call2.error);
      }
    }
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId]);
  console.log(props.data);
  return (
    <div
      onClick={props.onClick}
      className={`${activeChat?.homeOwnerId === props?.data?.homeOwnerId ? "bg-grey1" : "bg-white"} ${
        searchKey && searchKey?.trim() !== "" && !props?.title?.toLowerCase().includes(searchKey) && !props?.subtitle?.toLowerCase().includes(searchKey) && "d-none"
      }`}
      style={{cursor: "pointer"}}
    >
      <div ref={ref} onClick={() => navigateTo("/secure-chat/" + props.data.homeOwnerId)} id={props.id} className="d-flex justify-content-between  px-2 ripple ">
        <div className="d-flex flex-row align-items-center">
          <div>
            <img
              src={props.avatar || "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava1-bg.webp"}
              alt={props.alt || "avatar"}
              className="d-flex align-self-center me-3"
              width={38}
            />
          </div>
          <div className="pt-1">
            <p className="fw-bold mb-0 cl-black  make-text-ellipsis">{props.title || "Not Found"}</p>
            <p className="small text-muted w-80 make-text-ellipsis mb-0">{props.subtitle || "Not Found"}</p>
          </div>
        </div>
        <div className="pt-1">{props.unread ? props.unread !== 0 ? <span className="badge bg-danger rounded-pill float-end"> {props.unread}</span> : "" : " "}</div>
      </div>
    </div>
  );
};

export default ChatItem;
