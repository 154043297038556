import React, { useState } from "react";
import dashContext from "./dashContext";

const DashState = (props) => {
    const [selectedCards, setSelectedCards] = useState();
    const [currSavedTab, setCurrSavedTab] = useState({ designers: true });
    const [showChat, setShowChat] = useState();
    const [allAvChatRes, setAllAvChatRes] = useState();
    const [activeChat, setActiveChat] = useState();
    const [activeChatMsgs, setActiveChatMsgs] = useState();
    const [socket, setSocket] = useState(null);
    const [msg, setMsg] = useState();
    const [searchKey, setSearchKey] = useState("");

    const setRoomActiveChat = (data) => {
         if (data?.conversationId) {
            socket.emit("joinChat", data.conversationId )
        }
        setActiveChat(data)
    }
    return (
        <dashContext.Provider
            value={{
                selectedCards,
                setSelectedCards,
                currSavedTab,
                setCurrSavedTab,
                showChat,
                setShowChat,
                allAvChatRes, setAllAvChatRes,
                activeChat,
                setActiveChat,
                activeChatMsgs,
                setActiveChatMsgs,
                socket,
                setSocket,
                setRoomActiveChat,
                msg,
                setMsg,
                searchKey,
                setSearchKey
            }}
        >
            {props.children}
        </dashContext.Provider>
    );
};

export default DashState;
