import React from "react";
import bijli from "../../NewLandingPage/imagesAnj/pricing/bijlii.svg";
import { BsTelephone } from "react-icons/bs";
// import styles from "./PricingNewMob.module.css";
import styles from "../../Pricing/BoxOne.module.css";
import Icon from "../../Pricing/Images/Icon.svg";
import right from "../../Pricing/Images/right.svg";
import web from "../../Pricing/Images/web.svg";
import app from "../../Pricing/Images/app.svg";
import pro from "../../Pricing/Images/pro.svg";
import caretRight from "../../Pricing/Images/CaretRight.svg";
import { additionalFeatures } from "../../Pricing/mockData";
import checkCircle from "../../Pricing/Images/CheckCircle.svg";
import { useNavigate } from 'react-router-dom';

const EnterpriseMob = ({forScroll}) => {
  const navigateTo= useNavigate()
  return (
    <>
      <div className={styles.box2}>
        <div style={{ display: "flex" }}>
          <div className={styles.buttons}>
            <span className={styles.buttonsText}>Large firms</span>
          </div>
        </div>
        <div style={{ marginTop: "0.56rem" }}>
          <div className={styles.inBoxHeading}>Enterprise</div>
        </div>
        <div style={{ marginTop: "0.69rem" }}>
          <div className={styles.inBoxSummary}>
            Bespoke system connected with existing platform and databases for
            seamless project management{" "}
          </div>
        </div>
        <div
            className={styles.blueButtonContainer}
            style={{ marginTop: "1.16rem", marginBottom: "0.84rem", marginLeft:"auto", marginRight:"auto" }}
          >
            <div className={styles.blueBorderButton} style={{marginLeft:"auto", marginRight:"auto"}}>
              <div className={styles.blueBorderButtonText}
               style={{cursor:"pointer"}}
               onClick={()=>navigateTo("/contact")}>Let's Talk!</div>
            </div>
          </div>
        <div style={{ marginTop: "0.75rem" }}>
          <div className={styles.additionalFeatures}>
          <img src={app} alt="app" style={{verticalAlign:"middle",  marginRight:"0.5rem", height:"1rem", width:"1rem"}}/>
          Additional features</div>
          <div>
            {additionalFeatures.map((x, index) => {
              return (
                <div key={index}>
                  <img
                    src={right}
                    alt="right"
                    style={{ verticalAlign: "middle",width:"1rem",height:"1rem" }}
                  />
                  <span
                    style={{
                      marginLeft: "0.75rem",
                      fontSize: "0.625rem",
                      fontWeight: "400",
                    }}
                  >
                    {x}
                  </span>
                </div>
              );
            })}
          </div>
          <div style={{ marginTop: "1.32rem" }}>
            <img src={right} alt="right" style={{ verticalAlign: "middle", width:"1rem",height:"1rem" }} />
            <span
              className={styles.yoursForeverText}
              style={{ marginLeft: "0.75rem" }}
            >
              Yours to keep, forever.
            </span>
          </div>
          <img
            src={Icon}
            alt=""
            style={{ marginTop: "-38px", marginLeft: "25px", width:"7rem" }}
          />
          <div
            classname={styles.allBasicProText}
            style={{ marginTop: "-0.8rem", display: "flex", gap: "0.5rem" }}
          >
            <img
              src={checkCircle}
              alt="right"
              style={{ verticalAlign: "middle", width:"0.9375rem", height:"0.9375rem" }}
            />
            <span style={{fontSize:"0.75rem", fontWeight:"700"}}>All basic Pro features</span>
          </div>
        
          <div
            className={styles.showFeaturesBtn}
            style={{ cursor: "pointer", marginTop:"0.84rem" }}
            //  onClick={()=>scrollRef.current.scrollIntoView({behavior:"smooth"})}
          >
           <a href={forScroll} style={{color:"inherit", textDecoration:"none"}}>Show features</a> 
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterpriseMob;