import React, {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import styles from "./AboveFooter.module.css";

const AboveFooter=()=>{
    const navigate = useNavigate();
    const scrollToTop=()=>{
      window.scrollTo({
        top:0,
        behavior:"smooth"
      });
    }

    const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
    const updateMedia = () => {
      setIsDesktop(window.screen.width > 767);
    };
  
    useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });
    return(
        <>
          <div className={styles.background}>
        <div className={styles.contain}>
          <div className={styles.heading}>
          Experience Seamless Interior Design Solutions 
          </div>
         {
         isDesktop ? <div className={styles.para}>
          Streamline your design process and take your business to the next level<br /> with our easy-to-use platform. 
          </div> :
          <div className={styles.para}>
          Streamline your design process and take your business<br /> to the next level with our easy-to-use platform. 
          </div>
          }

          {/* <div className={styles.btnContainer}>
          <div className={styles.btn2} onClick={()=> navigate("/schedule")}>
            <div> Schedule a Demo</div>
            </div>
            <a
              href="https://www.idesign.market/signup"
              target="blank"
              style={{ textDecoration: "none" }}
            >
              <div className={styles.btnn1}>Get Started</div>
            </a>
          </div> */}
            <div className={styles.btnContainer}>
            <div className={styles.btn2} onClick={() => {
              scrollToTop()
              navigate("/schedule")
            }}>
              <div> Schedule a Demo</div>
            </div>
            <div className={styles.btnn1} onClick={() => {
              scrollToTop()
              navigate("/signup")
            }}>
              <div>Get Started</div>
            </div>
          </div>
        </div>
      </div>
        </>
    )
};
export default AboveFooter;