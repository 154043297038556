import React from "react";
import { Table } from "react-bootstrap";
import styles from "../../onBoardingCss/DashboardCssNew/pendencies.module.css";
import axios from "axios";
import { useState,useEffect } from "react";

const Pendencies = ({ pendency }) => {
  console.log(pendency)
  const [sortDate, setsortDate] = useState("ascending");
  const [sortendDate, setsortendDate] = useState("asc");
  const [filterState, setfilterState] = useState([...pendency?.pendencies]);

  const sortStartDate = () => {
    let x = filterState;
    if (sortDate === "ascending") {
      x.sort((a, b) => {
        return (
          new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
        );
      });
      setfilterState([...x]);
    } else {
      x.sort((a, b) => {
        return (
          new Date(b.startDate).getTime() - new Date(a.startDate).getTime()
        );
      });
      setfilterState([...x]);
    }
  };

  useEffect(() => {
    sortStartDate();
  }, [sortDate]);

  // end date sort
  const sortEndDate = () => {
    let x = filterState;
    if (sortendDate === "asc") {
      x.sort((a, b) => {
        return new Date(a.endDate).getTime() - new Date(b.endDate).getTime();
      });
      setfilterState([...x]);
    }
    else{
      x.sort((a, b) => {
        return new Date(b.endDate).getTime() - new Date(a.endDate).getTime();
      });
      setfilterState([...x]);
    }
  };

  useEffect(() => {
    sortEndDate();
  }, [sortendDate])

 

  // id to name get for assigned by
  const getNameAssignedBY = async (id, index) => {
    await axios
      .get(
        `https://pro-api.idesign.market/api/listDesigners?apitoken=hWFfEkzkYE1X691J4qmcuZHAoet7Ds7ADhL&designerId=${id}&designerId=${id}`
      )
      .then(function(response) {
        let x=filterState;
        x[index]["AssignedByData"]=response?.data?.data?.data[0]; 
        setfilterState([...x]);
      });
  };
  useEffect(() => {
    filterState?.forEach((curelem, index) => {
      getNameAssignedBY(curelem.assignedBy, index);
    });
  }, []);
  
// console.log(filterState)
  return (
    <>
      <div style={{ marginTop: "1rem" }}>
        <Table className="border border-0 overflow-y-scroll" style={{borderRight:"none",borderLeft:"none"}}>
          <thead>
            <tr className="border border-0">
              <th
                style={{
                  border: "none",
                  paddingLeft: "2rem",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  color: "#272727",
                  fontSize: " 14px",
                  lineHeight: "19px",
                  cursor: "pointer",
                }}
              >
                Dependeny
              </th>
              <th
                style={{
                  border: "none",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  color: "#272727",
                  fontSize: " 14px",
                  lineHeight: "19px",
                  cursor: "pointer",
                }}
              >
                Parent Task
              </th>
              <th
                style={{
                  border: "none",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  color: "#272727",
                  fontSize: "14px",
                  lineHeight: "19px",
                  cursor: "pointer",
                }}
              >
                Assigned By
              </th>
              <th
                style={{
                  border: "none",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  color: "#272727",
                  fontSize: "14px",
                  lineHeight: "19px",
                  cursor: "pointer",
                }}
              >
                Start date
                <img
                  src="https://idesign-office.s3.amazonaws.com/Sort.svg"
                  alt=""
                  style={{ marginLeft: "0.5rem" }}
                  onClick={() => {
                    if (sortDate === "ascending") {
                      setsortDate("descending");
                    } else {
                      setsortDate("ascending");
                    }
                  }}
                />
              </th>
              <th
                style={{
                  border: "none",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  color: "#272727",
                  fontSize: "14px",
                  lineHeight: "19px",
                  cursor: "pointer",
                }}
              >
                End date
                <img
                  src="https://idesign-office.s3.amazonaws.com/Sort.svg"
                  alt=""
                  style={{ marginLeft: "0.5rem" }}
                  onClick={() => {
                    if (sortendDate === "asc") {
                      setsortendDate("des");
                    } else {
                      setsortendDate("asc");
                    }
                  }}
                />
              </th>
              <th
                style={{
                  border: "none",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: 500,
                  color: "#272727",
                  fontSize: "14px",
                  cursor: "pointer",
                  lineHeight: "19px",
                }}
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody style={{borderRight:"none",borderLeft:"none"}}>
            {filterState?.slice(0, 5)?.map((curelem) => {
              // console.log(curelem)
              return (
                <>
                  {curelem?.dependencies?.map((elem) => {
                    return (
                      <>
                        <tr style={{ borderBottom: "1px solid #E6E6E6" }}>
                          <td
                            className={styles.eachCol}
                            style={{ paddingLeft: "2rem", overflow: "hidden" }}
                          >
                            {elem.dependencyName}
                          </td>
                          <td className={styles.eachCol}>{curelem?.taskName}</td>
                          <td className={styles.eachCol}>{curelem?.AssignedByData?.fullName}</td>
                          <td className={styles.eachCol}>
                            {new Date(curelem?.startDate).toLocaleDateString(
                              "en-En",
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              }
                            )}
                          </td>
                          <td className={styles.eachCol}>
                            {new Date(curelem?.endDate).toLocaleDateString(
                              "en-En",
                              {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              }
                            )}
                          </td>
                          <td>
                            <button className={styles.percentBtn}>
                              {curelem?.progress}
                            </button>
                            
                         
                            <button className={styles.btn}>
                              {elem?.status === 0
                                ? "not started"
                                : elem?.status === 1
                                ? "Dependence"
                                : elem?.status === 2
                                ? "InProgress"
                                : null}
                            </button>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default Pendencies;
