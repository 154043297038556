import React from 'react';
import { useState, useEffect } from "react";
import HeaderMob from "../onboardingMob/HeaderMob";
import FewMoresteps from '../onBoarding/FewMoresteps';
import FewMob from '../onboardingMob/FewMob';

const FewCombined = () => {
    const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
    const updateMedia = () => {
      setIsDesktop(window.screen.width > 767);
    };
  
    useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });
  return (
   <>
   {isDesktop?<FewMoresteps/>:
   <>
    <HeaderMob/>
    <FewMob/>
   </>
   }
   </>
  )
}

export default FewCombined