import React from 'react';
import styles from "./myprofileCss/collab.module.css";
import { useState } from 'react';
import RemoveModal from './RemoveModal';

const Collab = () => {
  const [modalShow, setModalShow] = useState(false);
 
  return (
    <>
    <RemoveModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div className={styles.container}>
         <div className={styles.box1}>
          <div>
            <div className={styles.name}>Name: Ranchordas</div>
            <div className={styles.email}>Email: Ranchordas@gmail.com</div>
            <div className={styles.approve}><img src="https://idesign-office.s3.ap-south-1.amazonaws.com/mark_email_read.svg" alt="" /> Can approve drawings and requests</div>
          </div>
          <div className={styles.remove} onClick={() => setModalShow(true)}><img src="https://idesign-office.s3.amazonaws.com/person_remove.svg" alt="" /> Remove member</div>
         </div>


         <div className={styles.box1}>
          <div>
            <div className={styles.name}>Name: Ranchordas</div>
            <div className={styles.email}>Email: Ranchordas@gmail.com</div>
            <div className={styles.approve}><img src="https://idesign-office.s3.ap-south-1.amazonaws.com/mark_email_read.svg" alt="" /> Can approve drawings and requests</div>
          </div>
          <div className={styles.remove} onClick={() => setModalShow(true)}><img src="https://idesign-office.s3.amazonaws.com/person_remove.svg" alt="" /> Remove member</div>
         </div>
         <div className={styles.box1}>
          <div>
            <div className={styles.name}>Name: Ranchordas</div>
            <div className={styles.email}>Email: Ranchordas@gmail.com</div>
            <div className={styles.approve}><img src="https://idesign-office.s3.ap-south-1.amazonaws.com/mark_email_read.svg" alt="" /> Can approve drawings and requests</div>
          </div>
          <div className={styles.remove} onClick={() => setModalShow(true)}><img src="https://idesign-office.s3.amazonaws.com/person_remove.svg" alt="" /> Remove member</div>
         </div>
         
      </div>
    
    </>
  )
}

export default Collab;