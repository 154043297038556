import React, {useState, useEffect} from 'react'
import styles from './Common.module.css'
import { useNavigate } from 'react-router-dom'

const CommonIntro = ({ heading, desc, image }) => {
    const navigate = useNavigate()
    const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
    const updateMedia = () => {
        setIsDesktop(window.screen.width > 767);
    };

    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return (
        <div className={styles.intro} style={{ backgroundImage: `url('${image}')` }}>
            <h1>{heading}</h1>
            <p>{desc}</p>
          {isDesktop ?  <button onClick={() => navigate('/signup')}>Get Started</button>
          : <button onClick={() => navigate('/schedule')} style={{width:"80%", fontSize:"1rem"}}>Schedule a Demo</button>
          }
        </div>
    )
}

export default CommonIntro
