import React, {useState, useEffect}from "react";
import styles from "./JoinOnMobile.module.css";

const JoinOnMobile = () => {
  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
  const updateMedia = () => {
    setIsDesktop(window.screen.width > 767);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  return (
    <>
      <div className={styles.container}>
        <div className={styles.contain}>
          <div className={styles.img_div}>
            <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Frame%201410095520%201.svg"
             alt="" className={styles.mobImg} />
          </div>

          <div className={styles.content_div}>
            <div className={styles.mob_head}>Join us on Mobile</div>
            {isDesktop?
              <div className={styles.para}>
              Download our latest app to stay up to date with running projects and manage your team effectively
            </div>
            : 
            <div className={styles.para}>
              Download our latest app to stay up to date with all our projects
            </div>
            }
            <div className={styles.appDiv}>
            {isDesktop?
              <div className={styles.app_head}>
              Download iDesign App from
            </div>
            : 
            ""
            }
            <div className={styles.buttonContainer}>
              <a href="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/iDesignPro.apk" target="_blank">
                <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Google%20Play.png"
                 alt="" className={styles.apple} style={{marginLeft:isDesktop?16:0}} />
              </a>
              <div style={{height:10}} />
              <a href="https://apps.apple.com/in/app/idesign-pro/id6450389218" target="_blank">
                <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/apple.png"
                 alt="" className={`${styles.apple} ${styles.appleLogo}`} />
              </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinOnMobile;
