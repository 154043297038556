import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./NewFooter.module.css";

const NewFooter = () => {
  const [hoveredImage, setHoveredImage] = useState(null);
  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
  const updateMedia = () => {
    setIsDesktop(window.screen.width > 767);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <div className={styles.mainContainer}>
        <div className={styles.firstContainer}>
          <div className={styles.innerMainContainer}>
            <img
              src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/logo.png"
              className={styles.logoImg} style={{userSelect:"none"}}
            />

            <div style={{ marginTop: "1rem" }}>
              <div>
                <div className={styles.footerPara}>
                  Focusing on creativity and sustainability, iDesign Market
                  streamlines business management from client onboarding to task
                  tracking and beyond.
                </div>
              </div>
            </div>
            <div className={styles.socialIconContainer}>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/idesignmarket/"
                onMouseEnter={() =>
                  setHoveredImage(
                    "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Linkedin.png"
                  )
                }
                onMouseLeave={() => setHoveredImage(null)}
              >
                <img
                  src={
                    hoveredImage ===
                    "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Linkedin.png"
                      ? "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/LinkedinBlue.png"
                      : "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Linkedin.png"
                  }
                  alt="linkedin"
                  style={{ height: "2.1875rem", width: "2.1875rem", userSelect:"none" }}
                />
              </a>
              <a
                target="_blank"
                href="https://www.facebook.com/idesign.market/"
                onMouseEnter={() =>
                  setHoveredImage(
                    "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Facebook.svg"
                  )
                }
                onMouseLeave={() => setHoveredImage(null)}
              >
                <img
                  src={
                    hoveredImage ===
                    "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Facebook.svg"
                      ? "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/facebookBlue.svg"
                      : "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Facebook.svg"
                  }
                  alt="facebook"
                  style={{ height: "2.1875rem", width: "2.1875rem", userSelect:"none" }}
                />
              </a>
              <a
                target="_blank"
                href="https://api.whatsapp.com/send/?phone=%2B919289347893&text=Hi%2C+pls+resolve+my+below+query&app_absent=0"
                onMouseEnter={() =>
                  setHoveredImage(
                    "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Whatsapp.png"
                  )
                }
                onMouseLeave={() => setHoveredImage(null)}
              >
                <img
                  src={
                    hoveredImage ===
                    "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Whatsapp.png"
                      ? "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/WhatsappBlue.png"
                      : "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Whatsapp.png"
                  }
                  alt="whatsapp"
                  style={{ height: "2.1875rem", width: "2.1875rem", userSelect:"none" }}
                />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/iDesign.market/"
                onMouseEnter={() =>
                  setHoveredImage(
                    "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Instagram.png"
                  )
                }
                onMouseLeave={() => setHoveredImage(null)}
              >
                <img
                  src={
                    hoveredImage ===
                    "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Instagram.png"
                      ? "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/InstagramBlue.png"
                      : "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Instagram.png"
                  }
                  alt="insta"
                  style={{ height: "2.1875rem", width: "2.1875rem", userSelect:"none" }}
                />
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCZYsSoot4r9eZSPJk6F7-xw"
                onMouseEnter={() =>
                  setHoveredImage(
                    "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/youtube.png"
                  )
                }
                onMouseLeave={() => setHoveredImage(null)}
              >
                <img
                  src={
                    hoveredImage ===
                    "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/youtube.png"
                      ? "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/youtubeBlue.png"
                      : "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/youtube.png"
                  }
                  alt="youtube"
                  style={{ height: "2.1875rem", width: "2.1875rem", userSelect:"none" }}
                />
              </a>
            </div>
          </div>
          <div className={styles.footerRight} >
            <div className={styles.footerRightFirst}>
              <div className={styles.flexColFooter}>
                <div className={styles.flexColFooterHeading} style={{userSelect:"none"}}>Who we serve</div>
                <Link
                  onClick={() => scrollToTop()}
                  style={{ color: "#0A142F", textDecoration: "none", userSelect:"none" }}
                    to="/new/design"
                >
                  <div className={styles.flexColFooterItems}>
                    Interior Firms{" "}
                  </div>
                </Link>
                <Link
                  onClick={() => scrollToTop()}
                  style={{ color: "#0A142F", textDecoration: "none", userSelect:"none" }}
                  to="/new/architect"
                >
                  <div className={styles.flexColFooterItems}>Architects </div>
                </Link>
                <Link
                  onClick={() => scrollToTop()}
                  style={{ color: "#0A142F", textDecoration: "none", userSelect:"none" }}
                  to="/new/contractor"
                >
                  <div className={styles.flexColFooterItems}>
                    Construction Companies
                  </div>
                </Link>
                <Link
                  onClick={() => scrollToTop()}
                  style={{ color: "#0A142F", textDecoration: "none", userSelect:"none" }}
                  to="/new/estate"
                >
                  <div className={styles.flexColFooterItems}>
                  Real Estate Developers                  </div>
                </Link>
                <Link
                  onClick={() => scrollToTop()}
                  style={{ color: "#0A142F", textDecoration: "none", userSelect:"none" }}
                  to="/new/retail"
                >
                  <div className={styles.flexColFooterItems}>
                    Retail Chains
                  </div>
                </Link>
              </div>
              <div className={styles.flexColFooter}>
                <div className={styles.flexColFooterHeading} style={{userSelect:"none"}}>Product</div>
                  <div className={styles.flexColFooterItems}>
                  <a
                    href={`/features#jumpto1`}
                    style={{ textDecoration: "none", userSelect:"none" }}
                  >
                  <div style={{color: "#111",opacity:1}} className={styles.flexColFooterItems} >
                    Project Management
                    </div>
                    </a>
                  </div>
                  <div className={styles.flexColFooterItems}>
                    <a
                    href={`/features#jumpto2`}
                    style={{ textDecoration: "none",userSelect:"none" }}
                  >
                     <div style={{color: "#111",opacity:1}} className={styles.flexColFooterItems}>
                     Cost Management
                     </div>
                    </a>
                  </div>
                  <div className={styles.flexColFooterItems}>
                  <a
                    href={`/features#jumpto3`}
                    style={{ textDecoration: "none", userSelect:"none" }}
                  >
                     <div style={{color: "#111",opacity:1}} className={styles.flexColFooterItems}>
                     Client Management
                     </div>
                    </a>
                  </div>
                  <div className={styles.flexColFooterItems}>
                  <a
                    href={`/features#jumpto3`}
                    style={{ textDecoration: "none", userSelect:"none" }}
                  >
                     <div style={{color: "#111",opacity:1}} className={styles.flexColFooterItems}>
                    Additional Benefits
                    </div>
                    </a>
                  </div>
                <Link
                  onClick={() => scrollToTop()}
                  style={{ color: "#0A142F", textDecoration: "none", userSelect:"none" }}
                  to="/pricing"
                >
                  <div className={styles.flexColFooterItems} style={{userSelect:"none"}}>Pricing</div>
                </Link>
              </div>
            </div>
            <div className={styles.footerRightFirst}>
              <div className={styles.flexColFooter}>
                <div className={styles.flexColFooterHeading} style={{userSelect:"none"}}>Company</div>
                <Link
                  onClick={() => scrollToTop()}
                  style={{ color: "#0A142F", textDecoration: "none" }}
                  to="/about"
                >
                  <div className={styles.flexColFooterItems} style={{userSelect:"none"}}>About us</div>
                </Link>
                <Link
                  onClick={() => scrollToTop()}
                  style={{ color: "#0A142F", textDecoration: "none" }}
                  to="https://angel.co/company/idesign-market-1"
                >
                  <div className={styles.flexColFooterItems} style={{userSelect:"none"}}>Careers</div>
                </Link>
                <Link
                  onClick={() => scrollToTop()}
                  style={{ color: "#0A142F", textDecoration: "none" }}
                  to="/contact"
                >
                  <div className={styles.flexColFooterItems} style={{userSelect:"none"}}>Contact us</div>
                </Link>
                <Link
                  onClick={() => scrollToTop()}
                  style={{ color: "#0A142F", textDecoration: "none" }}
                  to="/privacy-policy"
                >
                  <div className={styles.flexColFooterItems} style={{userSelect:"none"}}>
                    Privacy Policy
                  </div>
                </Link>
                <Link
                  onClick={() => scrollToTop()}
                  style={{ color: "#0A142F", textDecoration: "none", userSelect:"none" }}
                  to="/cookie-policy"
                >
                  <div className={styles.flexColFooterItems} style={{userSelect:"none"}}>T&C</div>
                </Link>
              </div>
              <div className={styles.flexColFooter}>
                <div className={styles.flexColFooterHeading} style={{userSelect:"none"}}>Contact Us</div>
                <div className={styles.flexColFooterItems}>
                <a href="mailto:support@idesign.market?body=" style={{textDecoration:'none',color:'inherit'}} >support@idesign.market</a>
                </div>
                <div className={styles.flexColFooterItems}>+91-8287356403</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDesktop ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderTop: "1.5px solid #EDEDED",
            padding: "1.5rem 5rem",
          }}
        >
          <div className="fleft">
            <div style={{ display: "flex", gap: "0.75rem" }}>
              <div style={{fontSize:"0.75rem"}}>Australia</div>
              <div
                style={{
                  width: "0.375rem",
                  height: "0.375rem",
                  marginTop: "auto",
                  marginBottom: "auto",
                  borderRadius: "50%",
                  background: "rgba(10, 20, 47, 0.40)",
                }}
              ></div>
              <div  style={{fontSize:"0.75rem"}}>India</div>
              <div
                style={{
                  width: "0.375rem",
                  height: "0.375rem",
                  marginTop: "auto",
                  marginBottom: "auto",
                  borderRadius: "50%",
                  background: "rgba(10, 20, 47, 0.40)",
                }}
              ></div>
              <div  style={{fontSize:"0.75rem"}}>New Zealand</div>
              <div
                style={{
                  width: "0.375rem",
                  height: "0.375rem",
                  marginTop: "auto",
                  marginBottom: "auto",
                  borderRadius: "50%",
                  background: "rgba(10, 20, 47, 0.40)",
                }}
              ></div>
              <div  style={{fontSize:"0.75rem"}}>UAE</div>
            </div>
          </div>
          <div className="fright">
            <div style={{ display: "flex", gap: "0.75rem" }}>
              <div  style={{fontSize:"0.75rem"}}>{new Date().getFullYear().toString()} © iDesign</div>
              <div  style={{fontSize:"0.75rem"}}>|</div>
              <div  style={{fontSize:"0.75rem"}}>All Rights Reserved</div>
              <div  style={{fontSize:"0.75rem"}}>|</div>
              <div  style={{fontSize:"0.75rem"}}>TECHDESIGNWORKS PVT. LTD.</div>
            </div>
          </div>
        </div>
      ) : (
        <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: "#F5FAFF",
            flexDirection: "column",
            paddingTop:"1.58rem",
          }}
        >
          <div className="fleft">
            <div style={{ display: "flex", gap: "0.38rem" , marginBottom:"1.58rem"}}>
              <div className={styles.countryName}>Australia</div>
              <div
                style={{
                  width: "0.375rem",
                  height: "0.375rem",
                  marginTop: "auto",
                  marginBottom: "auto",
                  borderRadius: "50%",
                  background: "rgba(10, 20, 47, 0.40)",
                }}
              ></div>
              <div className={styles.countryName}>India</div>
              <div
                style={{
                  width: "0.375rem",
                  height: "0.375rem",
                  marginTop: "auto",
                  marginBottom: "auto",
                  borderRadius: "50%",
                  background: "rgba(10, 20, 47, 0.40)",
                }}
              ></div>
              <div className={styles.countryName}>New Zealand</div>
              <div
                style={{
                  width: "0.375rem",
                  height: "0.375rem",
                  marginTop: "auto",
                  marginBottom: "auto",
                  borderRadius: "50%",
                  background: "rgba(10, 20, 47, 0.40)",
                }}
              ></div>
              <div className={styles.countryName}>UAE</div>
            </div>
          </div>
          <div style={{width:"100%", height:"2px", backgroundColor:"#EDEDED"}}></div>
          <div className="fright" style={{ padding:"1.25rem 0rem", fontSize:"0.75rem" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex" }}>
                <div>{new Date().getFullYear().toString()} © iDesign</div>
                <div style={{marginLeft:"0.25rem"}}>|</div>
                <div style={{marginLeft:"0.25rem"}}>All Rights Reserved</div>
              </div>

              <div style={{textAlign:"center"}}>TECHDESIGNWORKS PVT. LTD.</div>
            </div>
          </div>
        </div>
        <div style={{height:"5.75rem", width:"100%", background:"white"}}>

          </div>
        </div>
      )}
    </div>
  );
};

export default NewFooter;
