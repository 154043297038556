import React from 'react';
import PrincingNew from './PrincingNew';
import PricingNewMob  from './PricingPageNewMob/PricingNewMob';
import { Helmet } from "react-helmet";
import { webpageSchema } from "../../../../Schemas";
import { useState,useEffect } from 'react';
import PricingMain from '../Pricing/PricingMain';

const PricingWebMob = () => {
    const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
    const updateMedia = () => {
      setIsDesktop(window.screen.width > 767);
    };
  
    useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });
  return (
    <>
     <Helmet>
        <script className="structured-data-list" type="application/ld+json">
          {webpageSchema(
            window.location.href,
            "Looking to Renovate your home?",
            "Interior Designers and Contractors Online Marketplace | iDesign Market",
            "iDesign Market is an online marketplace for interior design professionals in India. Find a right and reliable interior designer or contractor in your city."
          )}
        </script>
        <meta
          name="title"
          content="SaaS Platform for Interior, Architecture and Construction Co. | iDesign Market"
        />
        <meta
          name="description"
          content="iDesign Market is an online marketplace for interior design professionals in India. Find a right and reliable interior designer or contractor in your city."
        />
        <link rel="canonical" href={`${window.location.href}`} />
        <title>
          SaaS Platform for Interior, Architecture and Construction Co. | iDesign Market
        </title>
      </Helmet>

     {/* {isDesktop?<PrincingNew/>:<PricingNewMob/>} */}
    
    {isDesktop?<PricingMain />:<PricingNewMob/>}
    </>
  )
}

export default PricingWebMob;