import React from 'react';
import FooterMobile from '../FooterMobile';
import HamburgerMenu from '../HamburgerMeun';
import HeaderOnboard from '../HeaderOnboard';
import IdesignListing from '../LandingPage/IdesignListing';
import FileManager from './FileManager';
import ManaegLeads from './ManageLeads';
import MOM1 from './MOM1';
import Pmt1 from './Pmt1';
import ProductSec1 from './ProductSec1';
import ProductSec2 from './ProductSec2';
import Quotation1 from './Quotation1';
import TimeLine1 from './TimeLine1';
import ProductSec3 from './ProductSec3';
import ProductSec4 from './ProductSec4';
import ProductSec5 from './ProductSec5';
import ProductSec6 from './ProductSec6';
import ProductSec7 from './ProductSec7';
import ProductSec8 from './ProductSec8';
import ProductSec9 from './ProductSec9';
import Mobile from '../../SaasOnboardingMobile/LandingPage/Mobile';
import ProductSecFileMob from './ProductSecFileMob';
import ProductSec10 from './ProductSec10';
// import FooterMobile from '../../SaasOnboardingMobile/FooterMobile';


export default function ProductPage() {
    return (

        <div className='w-100'>
            <HamburgerMenu />
            <ProductSec1 />
            <div  style={{paddingLeft:"1rem",paddingRight:"1rem"}}>
            <ProductSecFileMob/>
            <ProductSec2 />
            <ProductSec3/>
            <ProductSec4/>
            <ProductSec5/>
            <ProductSec6/>
            <ProductSec7/>
            <ProductSec8/>
            <ProductSec9/>
           <ProductSec10/>
            </div>
            < Mobile/>
            <FooterMobile/>
              
        </div>

    )
}