
import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import styles from "../cssFiles/donut.module.css";

ChartJS.register(ArcElement, Tooltip, Legend);

const data = {
//   labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19, 3],
      backgroundColor: [
        '#71A5DE',
        '#26AD74',
        '#F5B82E',
      ],
      borderColor: [
        '#71A5DE',
        '#26AD74',
        '#F5B82E',

      ],
      borderWidth: 1,
    },
  ],
};

const Donut = () => {
  return (
    <>
     <Doughnut data={data} className={styles.donut}/>
    </>
  )
}

export default Donut