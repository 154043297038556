import { constants } from "../../Components/Constants/constants";
import { getReq, postReq } from "../../Components/Services/api";

export function setIsLoading(loadState) {
  return {
    type: "SET_IS_LOADING",
    payload: loadState,
  };
}

export function setFootBanner(bannerState) {
  return {
    type: "SET_FOOT_BANNER",
    payload: bannerState,
  };
}

export function selectLeadBucket(bucketName) {
  return {
    type: "SELECT_LEAD_BUCKET",
    payload: bucketName,
  };
}

export function saveLeads(leadsData) {
  return {
    type: "SAVE_LEADS",
    payload: leadsData,
  };
}
export function fetchLmsBuyLeads(limit, budgetFilter, workTypeFilter) {
  return async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const res = await getReq(
      `${constants.B2B_BASE}/b2b/api/newlistBuyLeads?apitoken=hWFfEkzkYE1X691J4qmcuZHAoet7Ds7ADhL&limit=${limit}${budgetFilter === "all" ? "" : `&budget=${budgetFilter}`}${
        workTypeFilter === "all" ? "" : `&workType=${workTypeFilter}`
      }`
    );
    if (res && !res.error) {
      dispatch(saveLeads(res));
      dispatch(setIsLoading(false));
    } else {
      console.log(res.error);
    }
  };
}
export function lmsBuyLeadsBudgetFilter(budgetFilter) {
  return {
    type: "BUY_LEADS_FILTER_BUDGET",
    payload: budgetFilter,
  };
}
export function lmsBuyLeadsWorkFilter(workFilter) {
  return {
    type: "BUY_LEADS_FILTER_WORK",
    payload: workFilter,
  };
}

export function saveAssignedLeads(assignedLeads) {
  return {
    type: "SAVE_ASSIGNED_LEADS",
    payload: assignedLeads,
  };
}
export function fetchAssignedLeads(leadStatus, pageNum) {
  return async (dispatch, getState) => {
    dispatch(setIsLoading(true));
    const res = await getReq(`${constants.B2B_BASE}b2b/user/leads?type=${leadStatus}&pageNo=${pageNum}`);
    if (res && !res.error) {
      dispatch(saveAssignedLeads(res.data.data));
      dispatch(setIsLoading(false));
    } else {
      console.log(res.error);
    }
  };
}
export function changeLeadBucket(leadId, bucketNum, currentBucket) {
  return async (dispatch, getState) => {
    const res = postReq(`${constants.B2B_BASE}/b2b/user/leadActions`, { leadId: leadId, leadStatus: bucketNum });
    if (res && !res.error) {
      dispatch(fetchAssignedLeads(currentBucket, 0));
    } else {
      console.log(res.error);
    }
  };
}
