import React from 'react';
import Modal from "react-bootstrap/Modal";
import styles from "./myprofileCss/removemodal.module.css";

const RemoveModal = (props) => {

  return (
   <>
    <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ paddingLeft: "1rem" }}>Remove Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div style={{paddingLeft:"1rem",color: '#4D4D4D',fontWeight:500}}>Are you you want to remove ranchordas from the collaborative?</div>
        </Modal.Body>
        <Modal.Footer>
          <div style={{display:'flex',justifyContent:"flex-start",width:"100%",gap:"1rem",paddingLeft:'1rem',paddingTop:'1rem'}}>
            <button className={styles.addBtn}>Remove</button>
            <button className={styles.cancelBtn}>Cancel</button>
          </div>
        </Modal.Footer>
      </Modal>
   </>
  )
}

export default RemoveModal