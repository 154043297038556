import React from "react";
import styles from "./ArticleSection.module.css";

const ArticleSection=()=>{
return(
    <>
    <div className={styles.mainContainer}>
    <div className={styles.flexContainer}>
        <div  className={styles.heading}>Explore our media mentions</div>
        <div className={`${styles.flexContainer} ${styles.articleFlexContainer}`} >
        <div className={`${styles.flexItemBox} ${styles.deccanContainer}`}>
        <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/business.png"
         alt="business" className={styles.deccan} />
        <div className={styles.articleName}>
        iDesign Market offers a user-friendly workflow management platform
        </div>
        <div className={styles.articleDate}>2 Jun, 2023</div>
        <a href="https://bwdisrupt.businessworld.in/article/SaaS-Startup-iDesign-Market-Secures-200K-In-Pre-Seed-Round/02-06-2023-478990/" target='blank'>
        <div className={styles.readFullArticle} style={{display:"flex"}}><div>Read Full Article</div>
         <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/ArrowUpRight.svg" alt="arrow" className={styles.arrow}  /></div>
        </a>
        </div>
        <div className={`${styles.flexItemBox} ${styles.constructionContainer}`}>
        <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/constuction.svg"
         alt="construction" className={styles.construction} />
        <div className={`${styles.articleName} ${styles.secondArticleName}`} >
        The new product by iDesign.Market is an all-inclusive solution for interior designers and contractors to manage their business. 
        </div>
        <div className={styles.articleDate}>10 Feb, 2022</div>
        <a href="https://www.constructionworld.in/latest-construction-news/real-estate-news/idesign.market-has-developed-software-for-interior-designers/33349" target='blank'>
        <div className={styles.readFullArticle}  style={{display:"flex"}}><div>Read Full Article</div>
        <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/ArrowUpRight.svg" alt="arrow" className={styles.arrow}  /></div>
        </a>
        </div>
        <div className={`${styles.flexItemBox} ${styles.zeeContainer}`}>
        <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/yourStory.svg" alt="yourStory" className={styles.zee} />
        <div className={styles.articleName}>
        iDesign Market offers a workflow management platform designed to facilitate team collaboration
        </div>
        <div className={styles.articleDate}>2 Jun, 2023</div>
        <a href=" https://yourstory.com/2023/06/startup-news-and-update-daily-roundup-june-2-2023" target='blank'>
        <div className={styles.readFullArticle}  style={{display:"flex"}}><div>Read Full Article</div>
        <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/ArrowUpRight.svg"
         alt="arrow" className={styles.arrow}  /></div>
        </a>
        </div>
        </div>
    </div>
    </div>
    </>
)
}

export default ArticleSection;