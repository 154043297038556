import React, { useContext, useEffect } from "react";
// import RenvationCal from '../RenvationCal'
import AllChatItems from "./AllChatItems";
import AllMessages from "./AllMessages/AllMessages";
import "./index.css";
import io from "socket.io-client";
import dashContext from "../picontext/DashBoard/dashContext";
import CollapsibleSidebar from "../../../Lms/LmsWebComponents/CollapsibleSidebar";
import AllRightSideBar from "./AllRightSideBar";
import SidebarWebNew from "../../../Lms/LmsWebComponents/SidebarNew/SidebarWebNew";
import HeaderNav from "../../../Lms/LmsWebComponents/HeaderNav/HeaderNav";
import MobHeader from "../../../Lms/LmsMobileComponents/MobHeader";

const SecureChat = () => {
  const context = useContext(dashContext);
  const { socket, setSocket } = context;

  const init = async () => {
    const newSocket = io(`https://chat.idesign.market`);
    setSocket(newSocket);
    return () => newSocket.close();
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSocket]);

  return (
    <div id="body">
      <div className="w-100 top-0 d-none d-md-block" style={{ zIndex: "9999999" }}>
        <HeaderNav />
      </div>
      <div className="w-100 top-0 d-block d-md-none" style={{ zIndex: "9999999" }}>
        <MobHeader />
      </div>
      <div className="das-main-box secureChat d-flex" style={{ height: "90vh" }}>
        <div className="d-none d-md-block">
          <SidebarWebNew />
        </div>
        <div style={{ width: "96.2vw", height: "90vh", overflow: "scroll" }}>
          <div className="d-flex flex-wrap">
            <div className="w-100 mv-w-100 border ">
              {socket ? (
                <div className="d-flex mainDiv">
                  <AllChatItems />
                  <AllMessages />
                  <AllRightSideBar />
                </div>
              ) : (
                <div>Not Connected</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecureChat;
