import React from "react";
import { Dropdown } from "react-bootstrap";
import styles from "./buyLeadsRightPanel.module.css";
import { BsChevronDown } from "react-icons/bs";
import kidPeekin from "../../../../../Assets/BuyLeads/kidPeekinHi.svg";
import dialogBoxPeekinHi from "../../../../../Assets/BuyLeads/dialogBoxPeekinHi.svg";
import coolKid from "../../../../../Assets/BuyLeads/coolKid.svg";
import lessThanMonthKid from "../../../../../Assets/BuyLeads/lessThanMonthKid.svg";
import sixWeeksKid from "../../../../../Assets/BuyLeads/sixWeeksKid.svg";
import BuyLeadsCardWeb from "../BuyLeadsCardWeb/BuyLeadsCardWeb";
import { useSelector } from "react-redux";
import LoadingComponent from "../../LoadingComponent/LoadingComponent";
import { useDispatch } from "react-redux";
import { lmsBuyLeadsBudgetFilter, lmsBuyLeadsWorkFilter } from "../../../../../Lms/Actions/lmsActions";

const BuyLeadsRightPanel = () => {
  const dispatch = useDispatch();
  const buyLeads = useSelector((state) => state.lmsReducer.buyLeads);
  console.log(buyLeads);
  const isLoading = useSelector((state) => state.noPersist.isLoading);
  const buyLeadsFilterBudget = useSelector((state) => state.noPersist.buyLeadsFilterBudget);
  const buyLeadsFilterWork = useSelector((state) => state.noPersist.buyLeadsFilterWork);
  return (
    <React.Fragment>
      <div className={styles.fullContainer}>
        <div className={styles.fullHeading}>
          <div className={styles.mainHead}>Buy Leads</div>
          <div className="d-flex">
            <Dropdown>
              <Dropdown.Toggle as="button" className={styles.fullHeadingDropdown}>
                {buyLeadsFilterBudget === "all"
                  ? "Budget"
                  : buyLeadsFilterBudget === "₹ 1,00,000 - ₹ 3,00,000"
                  ? "1 - 3 Lakhs"
                  : buyLeadsFilterBudget === "₹ 3,00,000 - ₹ 7,00,000"
                  ? "3 - 7 Lakhs"
                  : buyLeadsFilterBudget === "Over ₹ 7,00,000"
                  ? "Over 7 Lakh"
                  : "N/A"}{" "}
                <BsChevronDown />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => dispatch(lmsBuyLeadsBudgetFilter("all"))}>All</Dropdown.Item>
                <Dropdown.Item onClick={() => dispatch(lmsBuyLeadsBudgetFilter("₹ 1,00,000 - ₹ 3,00,000"))}>1 - 3 Lakhs</Dropdown.Item>
                <Dropdown.Item onClick={() => dispatch(lmsBuyLeadsBudgetFilter("₹ 3,00,000 - ₹ 7,00,000"))}>3 - 7 Lakhs</Dropdown.Item>
                <Dropdown.Item onClick={() => dispatch(lmsBuyLeadsBudgetFilter("Over ₹ 7,00,000"))}>Over 7 Lakh</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown>
              <Dropdown.Toggle as="button" className={styles.fullHeadingDropdown}>
                {buyLeadsFilterWork === "all"
                  ? "Work Type"
                  : buyLeadsFilterWork === "Full home interior"
                  ? "Full Home Interior"
                  : buyLeadsFilterWork === "Modular Renovation"
                  ? "Modular Renovation"
                  : buyLeadsFilterWork === "Only Design"
                  ? "Only Design"
                  : "N/A"}{" "}
                <BsChevronDown />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => dispatch(lmsBuyLeadsWorkFilter("all"))}>All</Dropdown.Item>
                <Dropdown.Item onClick={() => dispatch(lmsBuyLeadsWorkFilter("Full home interior"))}>Full Home Interior</Dropdown.Item>
                <Dropdown.Item onClick={() => dispatch(lmsBuyLeadsWorkFilter("Modular Renovation"))}>Modular Renovation</Dropdown.Item>
                <Dropdown.Item onClick={() => dispatch(lmsBuyLeadsWorkFilter("Only Design"))}>Only Design</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        {!isLoading ? (
          <div className="d-flex">
            <div className={styles.buyLeadsCardsContainer}>
              <div>
                <div className={`${styles.sectionHeading}`}>
                  <div className={styles.head1}>Just Landed!</div>
                  <div className={styles.infoDialogBubble}>
                    <img src={kidPeekin} alt="" />
                    <div style={{ backgroundImage: `url(${dialogBoxPeekinHi})` }} className={styles.dialogBox}>
                      These leads are brand-new, grab them with a call!
                    </div>
                  </div>
                </div>
                {buyLeads?.data?.data[0] && buyLeads?.data?.data[0]?.justLanded.length > 0 ? (
                  <div className={styles.allCardContainer}>
                    {buyLeads?.data &&
                      buyLeads?.data?.data[0]?.justLanded.map((curElem) => {
                        return <BuyLeadsCardWeb key={curElem._id} cardData={curElem} />;
                      })}
                  </div>
                ) : (
                  <div className={styles.nothingToShow}>Nothing To Show Here...</div>
                )}
              </div>

              <div>
                <div className={styles.sectionHeading}>
                  <div className={styles.head1}>Less than 2 Weeks Old</div>
                  <div className={styles.infoDialogBubble}>
                    <img src={coolKid} alt="" />
                    <div style={{ backgroundImage: `url(${dialogBoxPeekinHi})` }} className={styles.dialogBox}>
                      You wouldn't want to miss on a great lead, would you? So check out these leads right away!
                    </div>
                  </div>
                </div>
                {buyLeads?.data?.data[1] && buyLeads?.data?.data[1]?.twoWeeks.length > 0 ? (
                  <div className={styles.allCardContainer}>
                    {buyLeads?.data &&
                      buyLeads?.data?.data[1]?.twoWeeks.map((curElem) => {
                        return <BuyLeadsCardWeb key={curElem._id} cardData={curElem} />;
                      })}
                  </div>
                ) : (
                  <div className={styles.nothingToShow}>Nothing To Show Here...</div>
                )}
              </div>

              <div>
                <div className={styles.sectionHeading}>
                  <div className={styles.head1}>Less than a Month old</div>
                  <div className={styles.infoDialogBubble}>
                    <img src={lessThanMonthKid} alt="" />
                    <div style={{ backgroundImage: `url(${dialogBoxPeekinHi})` }} className={styles.dialogBox}>
                      Hey, take care not to keep these leads waiting for too long. Seize the deal today!
                    </div>
                  </div>
                </div>
                {buyLeads?.data?.data[2] && buyLeads?.data?.data[2]?.oneMonth.length > 0 ? (
                  <div className={styles.allCardContainer}>
                    {buyLeads?.data &&
                      buyLeads?.data?.data[2]?.oneMonth.map((curElem) => {
                        return <BuyLeadsCardWeb key={curElem._id} cardData={curElem} />;
                      })}
                  </div>
                ) : (
                  <div className={styles.nothingToShow}>Nothing To Show Here</div>
                )}
              </div>

              <div>
                <div className={styles.sectionHeading}>
                  <div className={styles.head1}> 4 to 6 Week Old</div>
                  <div className={styles.infoDialogBubble}>
                    <img src={sixWeeksKid} alt="" />
                    <div style={{ backgroundImage: `url(${dialogBoxPeekinHi})` }} className={styles.dialogBox}>
                      Good day! Hurry, you still have time to grab these fantastic leads!
                    </div>
                  </div>
                </div>
                {buyLeads?.data?.data[3] && buyLeads?.data?.data[3]?.sixWeeks.length > 0 ? (
                  <div className={styles.allCardContainer}>
                    {buyLeads?.data &&
                      buyLeads?.data?.data[3]?.sixWeeks.map((curElem) => {
                        return <BuyLeadsCardWeb key={curElem._id} cardData={curElem} />;
                      })}
                  </div>
                ) : (
                  <div className={styles.nothingToShow}>Nothing To Show Here...</div>
                )}
              </div>
            </div>
            <div></div>
          </div>
        ) : (
          <LoadingComponent />
        )}
      </div>
    </React.Fragment>
  );
};

export default BuyLeadsRightPanel;
