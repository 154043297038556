import React, { useEffect, useState } from 'react';
import img3d from '../3dComponents/3dImages/3dimg.svg'
import cadicon from '../3dComponents/3dImages/cadicon.svg'
import file from "./Images/cad.svg"
import { Modal, Offcanvas } from 'react-bootstrap'
import makeone from '../3dComponents/3dImages/MakeOne.svg'
import uploadAPdf from '../3dComponents/3dImages/uploadAPdf.svg'
import close from '../3dComponents/3dImages/close.svg'
import eye from '../3dComponents/3dImages/eye.svg'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { fetchProfileData } from '../Lms/Actions'
import '../3dComponents/3d.css';
import threed from "./Images/threeD.svg"
import gmeet from "./Images/google_meet.svg"
import pdficon from "./Images/filee.svg"
import write from "./Images/write.svg"
import { Link } from 'react-router-dom';
import CompleteThreeDOrdersMob from './CompleteThreeDOrdersMob';




export default function ThreeDHistoryMob() {
    const authToken = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "";
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [showId, setShowId] = useState();

    const handleClose = () => { setShow(false) }
    const handleClose2 = () => { setShow2(false) }
    const handleShow = () => { setShow(true) }


    const navigate = useNavigate()
    // console.log(localStorage.getItem("saveLater"))
    const autocadFile1 = useSelector((state) => state.threedReducer.autocadFile);
    const pdfFile1 = useSelector((state) => state.threedReducer.pdfFile);
    const [data, setData] = useState([])
    const [userData, setUserData] = useState([])

    const payM = useSelector((state) => state.threedReducer.payMethod)

    const handlleMakeOne = () => {
        localStorage.setItem("makeOne", true)
        navigate("/3dMakePPT/MakeOne")
        localStorage.removeItem("tick")
        localStorage.removeItem("tick2")
    }
    const handlleUploadOne = () => {
        localStorage.setItem("makepdf", true)
        navigate("/3dMakePPT/UploadPdf")
        localStorage.removeItem("tick")
        localStorage.removeItem("tick2")
    }
    const profileInfo = useSelector((state) => state.addToCartReducer.profileData);
    // console.log(profileInfo)
    const dispatch = useDispatch()

    useEffect(async () => {
        const res = await axios.get(`https://3d-api.idesign.market/api/room/inProcess-designs?designerId=${localStorage.getItem("userId")}`)
        setData(res.data)
    }, [])

    console.log(data)
    useEffect(() => {
        dispatch(fetchProfileData(authToken))
    }, [])

    const [ofArr, setOfArr] = useState([])
    const handleShow2 = (id) => {
        setShowId(id)
        setShow2(true)
        console.log(id)
    }
    // console.warn(ofArr)
    var tot = 0
    for (let i = 0; i < ofArr[0]?.room?.length; i++) {
        tot += ofArr[0]?.room[i]?.views
    }

    console.log(ofArr)
    useEffect(() => {
        const b = data.filter((currElem) => {
            return currElem._id == showId
        })
        setOfArr(b)
    }, [showId])
    console.log(showId)

    return (
        <>

            <div className='' style={{ overflow: 'scroll', height: "70vh" }}>
            <Offcanvas show={show2} onHide={handleClose2} placement='end' size="md">
                            <Offcanvas.Header closeButton>
                                <div>
                                    <Offcanvas.Title>{profileInfo[0]?.data?.data?.companyName}</Offcanvas.Title>
                                    <p style={{ fontSize: "14px", color: "#888888" }}>{profileInfo[0]?.data?.data?.city}</p>
                                </div>
                            </Offcanvas.Header>
                            <Offcanvas.Body style={{ marginTop: "-20px" }}>
                                <div className="cart_file_sub2 pt-2 px-3">
                                    {ofArr[0]?.cadFile?.fileName && <p className="file2"><img src={cadicon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />{ofArr[0]?.cadFile?.fileName.slice(0, 9)}{"..."}</p>}
                                    {ofArr[0]?.pdf?.fileName ? <p className="file2 mx-2" ><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />{ofArr[0]?.pdf?.fileName?.slice(0, 9)}{"..."}</p> : localStorage.getItem("saveLater") ? <><p className="file3" style={{ marginLeft: "30px" }}><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />File Not uploaded <button className="ord-off px-1 mx-2" onClick={handleShow} style={{ color: '#3B5998', border: '1px solid #3B5998', borderRadius: '50px', width: '72px', height: '22px', fontSize: '10px' }}>Upload File</button> </p> </> : <p className="file2 mx-2" ><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />xyz file</p>}
                                </div>
                                <div className='d-flex flex-wrap px-2'>
                                    {ofArr[0]?.room?.map((item) => (
                                        <button className='btn-off mx-2 mb-2'>{item.name} {item.views} <img src={eye} alt="" className="bbeye" /></button>
                                    ))}
                                    <p className='mx-2 mt-1 p-off'>Total {tot} views</p>
                                </div>
                                <div className='pt-2 px-3 d-flex'>
                                    <div className="off-line d-flex flex-column">
                                        <div className='d-flex flex-column'>
                                            {ofArr[0]?.adminStatus?.block?.file ?
                                                <>
                                                    <div className="d-flex ">
                                                        <div className="ord_circ2 m-0" ></div>
                                                        <h4 className="cart_head2 d-flex">Complete<span className='ord_span3'> (3D Block)</span></h4>
                                                    </div>
                                                    {ofArr[0]?.adminStatus?.block?.meet && <div className='d-flex mx-3'><img src={gmeet} alt="" /><p className='off-meet px-2 my-2'>Meet done</p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.block?.meet?.date)).toString().substring(4, 15)}</span></div>}
                                                    {ofArr[0]?.adminStatus?.block?.remark && <div className='d-flex mx-3'><img src={write} alt="" /><p className='off-meet px-2 my-2'>{ofArr[0]?.adminStatus?.block?.remark?.data} </p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.block?.remark?.date)).toString().substring(4, 15)}</span></div>}
                                                    {ofArr[0]?.adminStatus?.block?.file && <div className='d-flex mx-3'><img src={file} alt="" /> <p className='off-meet px-2 my-2'>File Uploaded sucessfully </p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.block?.file?.date)).toString().substring(4, 15)}</span></div>}
                                                </>
                                                : ofArr[0]?.adminStatus?.block && !ofArr[0]?.adminStatus?.block?.file ? <>
                                                    <div className="d-flex ">
                                                        <div className="ord_circ2 m-0" style={{ background: "#58A1FF" }}></div>
                                                        <h4 className="cart_head2 d-flex">Active<span className='ord_span3'> (3D Block)</span></h4>
                                                    </div>
                                                    {ofArr[0]?.adminStatus?.block?.meet && <div className='d-flex mx-3'><img src={gmeet} alt="" /><p className='off-meet px-2 my-2'>Meet done</p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.block?.meet?.date)).toString().substring(4, 15)}</span></div>}
                                                    {ofArr[0]?.adminStatus?.block?.remark && <div className='d-flex mx-3'><img src={write} alt="" /><p className='off-meet px-2 my-2'>{ofArr[0]?.adminStatus?.block?.remark?.data}</p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.block?.remark?.date)).toString().substring(4, 15)}</span></div>}
                                                </> : <div className="d-flex mb-5">
                                                    <div className="ord_circ2 m-0" style={{ background: "#FFAC0A" }}></div>
                                                    <h4 className="cart_head2 d-flex ">Pending<span className='ord_span3'> (3D Block)</span></h4>
                                                </div>}
                                            {ofArr[0]?.adminStatus?.lowRender?.file ? <> <div className="d-flex ">
                                                <div className="ord_circ2 m-0"></div>
                                                <h4 className="cart_head2 d-flex ">Complete<span className='ord_span3'> (Low render)</span></h4>
                                            </div>
                                                {ofArr[0]?.adminStatus?.lowRender?.meet && <div className='d-flex mx-3'><img src={gmeet} alt="" /><p className='off-meet px-2 my-2'>Meet done</p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.lowRender?.meet?.date)).toString().substring(4, 15)}</span></div>}
                                                {ofArr[0]?.adminStatus?.lowRender?.remark && <div className='d-flex mx-3'><img src={write} alt="" /><p className='off-meet px-2 my-2'>{ofArr[0]?.adminStatus?.lowRender?.remark?.data}</p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.lowRender?.meet?.date)).toString().substring(4, 15)}</span></div>}
                                                {ofArr[0]?.adminStatus?.lowRender?.file && <div className='d-flex mx-3'><img src={file} alt="" /> <p className='off-meet px-2 my-2'>File Uploaded sucessfully </p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.lowRender?.file?.date)).toString().substring(4, 15)}</span></div>}
                                            </> : ofArr[0]?.adminStatus?.lowRender && !ofArr[0]?.adminStatus?.lowRender?.file ? <> <div className="d-flex ">
                                                <div className="ord_circ2 m-0" style={{ background: "#58A1FF" }}></div>
                                                <h4 className="cart_head2 d-flex ">Active<span className='ord_span3'> (Low render)</span></h4>
                                            </div>
                                                {ofArr[0]?.adminStatus?.lowRender?.meet && <div className='d-flex mx-3'><img src={gmeet} alt="" /><p className='off-meet px-2 my-2'>Meet done</p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.lowRender?.meet?.date)).toString().substring(4, 15)}</span></div>}
                                                {ofArr[0]?.adminStatus?.lowRender?.remark && <div className='d-flex mx-3'><img src={write} alt="" /><p className='off-meet px-2 my-2'>{ofArr[0]?.adminStatus?.lowRender?.remark?.data}</p><span className='off-meet-s my-2'>· {(new Date(ofArr[0]?.adminStatus?.lowRender?.meet?.date)).toString().substring(4, 15)}</span></div>}
                                            </> : <div className="d-flex mb-5 mt-4">
                                                <div className="ord_circ2 m-0" style={{ background: "#FFAC0A" }}></div>
                                                <h4 className="cart_head2 d-flex ">Pending<span className='ord_span3'> (Low render)</span></h4>
                                            </div>}
                                            <div className="d-flex mb-5 mt-4">
                                                <div className="ord_circ2 m-0" style={{ background: "#FFAC0A" }}></div>
                                                <h4 className="cart_head2 d-flex ">Pending<span className='ord_span3'> (Final render)</span></h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="_line"></div>
                                </div>
                            </Offcanvas.Body>
                        </Offcanvas>
                <div>
                    <div className='w-100' style={{ height: 'fitContent' }}>
                        {data && data.map((ele, index) => {
                            return (
                                <div className="ordered_main d-flex p-2 w-100" style={{ borderBottom: "1px solid #DFDFDF" }}>
                                    <div style={{
                                        background: "#F0F0F0",
                                        borderRadius: "4px",
                                        margin: "7px",
                                        padding: '0px', width: '60px',
                                        height: '58px', display: 'flex', alignItems: 'center'

                                    }}>
                                        <img src={threed} style={{ width: '80px', height: "33px" }} alt="" className="ordImg" onClick={() => handleShow2(ele._id)} />
                                    </div>
                                    <div className="ord_upper d-flex flex-column w-100 justify-content-between" style={{ marginLeft: '10px', marginRight: '5px' }}>
                                        <div className="ord_head d-flex mt-2">
                                            <h4 className="cart_head d-flex flex-column">3D_0000{index + 1}  <span className='ord_span pt-1' style={{ fontSize: '12px' }}>Last Contacted {ele.createdAt.slice(0, 7)}</span></h4>
                                            <div className='d-flex' style={{ marginLeft: 'auto' }}>
                                                {ele.amountToBePaid === ele.paidAmount ? <div className="ord_span2 d-flex float-end" style={{
                                                    fontFamily: 'Inter',
                                                    fontWeight: '400',
                                                    fontSize: '14px', justifyContent: 'center',
                                                    color: '#26AA63', height: '25px', borderRadius: '50px', width: '50px',
                                                    marginLeft: '0px', backgroundColor: 'rgba(38, 170, 99, 0.15)'
                                                }}>Paid</div> :

                                                    <div className="ord_span2 d-flex float-end justify-content-center" style={{
                                                        fontFamily: 'Inter', height: '23px', borderRadius: '50px', width: '68px',
                                                        fontWeight: '400',
                                                        fontSize: '12px',
                                                        color: ' #EDA416',
                                                        marginLeft: '-12px', backgroundColor: 'rgba(237, 164, 22, 0.15)'
                                                    }}> 50% Paid</div>

                                                }
                                            </div>
                                        </div>
                                        <div className="cart_file_sub2 d-flex">
                                            <div className="file2" style={{ fontSize: '14px', marginRight: '10px', color: '#888888' }}><img src={file} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />{ele?.cadFile?.fileName.slice(0, 9)}{"..."}</div>
                                            {ele.pdf && ele.pdf.fileName ? <div className="file2" style={{ fontSize: '14px', color: '#888888' }}><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />{ele.pdf.fileName.slice(0, 9)}{"..."}</div> : localStorage.getItem("saveLater") ? <><div className="file3" style={{ marginLeft: "30px" }}><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />File Not uploaded</div> <button className="ord_upload" onClick={handleShow}>Upload</button> </> : <div className="file2 mx-2" ><img src={pdficon} style={{ height: "20px", width: "13px", marginRight: "8px" }} alt="" className="fileimg" />xyz file</div>}
                                        </div>
                                        <div className="ord_en d-flex mt-2">
                                            {ele?.adminStatus?.block && ele?.adminStatus?.lowRender ? <><div className="ord_circ" style={{ marginTop: '4px' }}></div>
                                                <div className="cart_head2 d-flex" style={{ paddingTop: '0px', fontSize: '14px' }}>Active<span style={{ fontSize: '14px' }} className='ord_span3'>(Low Render)</span></div></> : ele?.adminStatus?.block ? <><div className="ord_circ" style={{ marginTop: '0px' }}></div>
                                                    <div className="cart_head2 d-flex" style={{ paddingTop: '0px', fontSize: '14px' }}>Active<span style={{ fontSize: '14px' }} className='ord_span3'>(3D Block)</span></div></> : <><div className="ord_circ" style={{ background: "#FFAC0A", marginTop: '0px' }}></div>
                                                <div className="cart_head2 d-flex" style={{ paddingTop: '0px', fontSize: '14px' }}>Pending<span style={{ fontSize: '14px' }} className='ord_span3'>(3D Block)</span></div></>}
                                            {ele?.pdf && ele?.pdf?.fileName?.length === 0 && <Link style={{ textDecoration: 'underline' }} to="/3dMakePPT" className="ord_view p-0 " >Upload File</Link>}
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        <Modal show={show} onHide={handleClose} centered size="md">
                            <Modal.Body>
                                <img src={close} alt="" className='d-flex float-end' onClick={handleClose} style={{
                                    opacity: "0.5",
                                    height: "13px",
                                    width: "13px",
                                    cursor: "pointer"
                                }} />
                                <p className="mainRender_header mt-4" style={{ fontSize: "20px" }}>Upload/Add a reference images for each element of 3D</p>
                                <p className="mainRender_byline" style={{ fontSize: "16px", textAlign: "center" }}>(This will help in getting work done faster)</p>

                                <div className="mainRender2 flex-column align-items-center mb-4" >
                                    <div className="mainRen_comp1" style={{ width: "80%" }} onClick={handlleMakeOne}>
                                        <img src={makeone} className="upload_icons" alt="" />
                                        <div className="makeOne_lines">
                                            <p className='makOne_line1'>Make one right now</p>
                                            <p className="makOne_line2">Help in getting 3D done in 2 days</p>
                                        </div>
                                    </div>
                                    <p className="makeOne_or">OR</p>
                                    <div className="mainRen_comp2" style={{ width: "80%" }} onClick={handlleUploadOne}>
                                        <img src={uploadAPdf} className="upload_icons" alt="" />
                                        <div className="makeOne_lines">
                                            <p className="makOne_line1_2">Upload a PDF</p>
                                            <p className="makOne_line2_2">to be delivered in 3 days</p>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </div>
                </div>
                <CompleteThreeDOrdersMob />
            </div>

        </>
    )
} 