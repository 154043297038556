import React from 'react';
import { useState,useEffect } from 'react';
import CompleteSteps from "../onBoarding/CompleteSteps";
import CompleteMob3 from '../onboardingMob/CompleteMob3';
import HeaderMob from "../onboardingMob/HeaderMob";

const CompleteCombined = () => {
    const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);
    const updateMedia = () => {
      setIsDesktop(window.screen.width > 767);
    };
  
    useEffect(() => {
      window.addEventListener("resize", updateMedia);
      return () => window.removeEventListener("resize", updateMedia);
    });
  return (
    <>
    {isDesktop?<CompleteSteps/>:
    <>
     <HeaderMob/>
     <CompleteMob3/>
    </>
    
    }
    
    </>
  )
}

export default CompleteCombined