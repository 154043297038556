import React from 'react';
import styles from "./NewTestimonialsMobile.module.css";
import Mayank from "./Images/Mayank.jpg"

const peopleData = [
    {
      name: "Austin Knight",
      role: "Project Manager",
      companyName: "Cradle Design",
      imageSrc: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/test1.svg",
      headline:"Game-Changer: iDesign Connects All",
      desc: "iDesign Market has been a game-changer for my business. Their platform helps me stay connected with my team members and clients.",
    },
    {
      name: "Mayank Aggarwal",
      role: "Architect",
      companyName: "Rajiv Associates",
      imageSrc: `${Mayank}`,
      headline:"Effortless Management: iDesign Love",
    desc:"iDesign is a very user-friendly platform. It is very easy for me to track things, review orders, manage hierarchy and even maintain accounts. Love it!"
    },
    {
      name: "Tanya Khanduja",
      role: "Interior Designer",
      companyName: "Interia",
      imageSrc: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/test3.svg",
      headline:"On-Time, On-Budget: iDesign Success",
    desc:"It is fairly affordable, easy to use and has all the tools required to manage my interior designing business. I can deliver on time and on budget projects because now I have iDesign."
    },
  ];
  

const NewTestimonialsMobile = () => {
  return (
    <div className={styles.mainContainer}>
        <div className={styles.testHead}>Testimonials</div>
        <div className={styles.testSubHead}>What our clients have to say about us</div>
        <div className={styles.flexContainer}>
        {peopleData.map((person, index) => (
            <div className={styles.flexItem}> 
            <img src={person.imageSrc} alt={person.name} className={styles.testimonialsImage} />
            <div>
                    <div className={styles.testName}>{person.name}</div>
                    <div className={styles.testRole}>{person.role} • {person.companyName}</div>
                    <div className={styles.starContainer}>
              {[...Array(5)].map((_, index) => (
                <img
                  key={index}
                  src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Star.svg"
                  alt={`star${index + 1}`}
                  className={styles.star}
                />
              ))}
            </div>
                    <div className={styles.testDesc}>{person.desc}</div>
                </div>
                </div>
      ))}
      </div>
    </div>
  )
}

export default NewTestimonialsMobile