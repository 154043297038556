import React from "react";
import styles from "./ProductsCss/productSec13.module.css";

const ProductSection13 = () => {
  return (
    <>
      <div className={styles.container} id="sheet">
        <div className={styles.files_contain}>
          <div className={styles.img_div}>
            <img
              src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group%202610954%20%281%29.svg"
              alt=""
              className={styles.mainImg}
            />
          </div>
          <div className={styles.file_content_div}>
            <div className={styles.file_head}>Timesheets</div>
            <div className={styles.file_para}>
              Effectively manage your time, optimize resource allocation, and
              ensure accurate billing for the design services.
            </div>

            <div className={styles.iconDiv}>
              <div className={styles.eachBox}>
                <div>
                  <img
                    src="https://idesign-office.s3.ap-south-1.amazonaws.com/chronic.svg"
                    alt=""
                    className={styles.icon}
                  />
                </div>
                <div className={styles.icon_para}>Task and Time <br /> Tracking</div>
              </div>

              <div className={styles.eachBox}>
                <div>
                  <img
                    src="https://idesign-office.s3.ap-south-1.amazonaws.com/fact_check.svg"
                    alt=""
                    className={styles.icon}
                  />
                </div>
                <div className={styles.icon_para}>
                Timesheet Approval Workflow
                </div>
              </div>
              <div className={styles.eachBox}>
                <div>
                  <img
                    src="https://idesign-office.s3.ap-south-1.amazonaws.com/data_exploration.svg"
                    alt=""
                    className={styles.icon}
                  />
                </div>
                <div className={styles.icon_para}>
                Reporting and <br /> Analytics
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSection13;
