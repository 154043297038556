import React, {useState} from 'react';
import styles from "./QAndA.module.css";
import Plus from "./Images/Plus.svg";
import Minus from "./Images/Minus.svg";

const arrayData=[{
  question:"What is iDesign.Market?",
   answer:"iDesign.Market is a SaaS platform offering a centralised hub for project management, collaboration, and design visualisation. From ledger entries to vendor and PO management, iDesign offers a wide range of ERP tools tailored specifically to different user personas such as interior designers, builders and many more."
  },
{
    question:"Can I integrate outside apps or services with your platform?",
    answer:"Our enterprise plans allow for deep integrations with other apps via API and other methods. Certain third-party integrations may incur additional fees."
},
{
    question:"How is iDesign Pro different from solutions like Procore or Buildertrend or StudioDesigner?",
// answer:"What sets iDesign Pro apart includes:No-code customization of workflows, approvals, permissions to meet your firm’s exact needs.\nCloud infrastructure readily scales from dozens to thousands of users globally.\nReal-time centralized dashboards giving all stakeholders project visibility.\nProcess automation via AI and machine learning saves hours on manual tasks.\nIn short, construction leaders choose iDesign Pro for the versatility, scalability, and intelligent automation to gain efficiency and control across the project lifecycle. Our platform is tailored for your workstreams and scales as you grow."
answer:`
What sets iDesign Pro apart includes:
- No-code customization of workflows, approvals, permissions to meet your firm’s exact needs.
- Cloud infrastructure readily scales from dozens to thousands of users globally.
- Real-time centralized dashboards giving all stakeholders project visibility.
- Process automation via AI and machine learning saves hours on manual tasks.

In short, construction leaders choose iDesign Pro for the versatility, scalability, and intelligent automation to gain efficiency and control across the project lifecycle. Our platform is tailored for your workstreams and scales as you grow.
`
},
{
    question:"What payment methods do you accept?",
    answer:"We accept all major credit cards, PayPal, wire transfer and ACH payments. For enterprise plans, annual invoices can also be arranged."
},{
    question:"Is volume/multi-seat pricing available?",
    answer:"Absolutely. We offer discounted rates for teams and organizations with multiple users. Contact our sales team for customized multi-seat quotes."
},
{
    question:"How do I get started?",
    answer:"For details on pricing models, custom quotes based on user count, integrations, or tailoring to your industry vertical, our product experts are ready to discuss options fit to your needs. Contact us at support@idesign.market."
},
{
  question: "Who does iDesign Pro serve?",
  answer: "We serve five segments of customers - Architects, Construction Companies, Design and Build, Real Estate Developers and Retail Companies."
},
]

const QAndA = () => {
    const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const emailAddress = 'info@idesign.market';

  const handleClick = () => {
    window.location.href = `mailto:${emailAddress}`;
  };
  
  return (
    <>
    <div className={styles.accordion} >
    <div className={styles.accordionHeading}>
      Pricing Questions? We've got answers
    </div>
        {arrayData.map((item, index) => (
        <div className={`${styles.accordionItem}  ${openIndex ==index ? styles.clickedAccItem :""}`} key={index}>
          <div
            className={styles.accordionContainer}
            onClick={() => toggleAccordion(index)} 
          >
            <div className={`${styles.accordionQuestion} ${openIndex==index ? styles.clicked : "" }`}>
            <span>{index+1}.{" "}</span>
            <span>{item.question}</span>
            </div>
            <div className={styles.plusMinusToggle}>
              {openIndex === index ? <img src={Minus} alt="" 
              className={`${styles.plusMinusImage} `} /> : 
              <img src={Plus} alt="" className={`${styles.plusMinusImage} ${styles.plusImgHover}`} />}
            </div>
          </div>
          <div className={`${styles.accordionAnswer} ${openIndex === index ? styles.transitioned : ''}`} >
              {index==2?
               
               <div className={styles.thirdAnswer}>
                What sets iDesign Pro apart includes:
                <ul className={styles.ulList}>
                  <li className={styles.ulListItem}>No-code customization of workflows, approvals, permissions to meet your firm’s exact needs.</li>
                  <li className={styles.ulListItem}>Cloud infrastructure readily scales from dozens to thousands of users globally.</li>
                  <li className={styles.ulListItem}>Real-time centralized dashboards giving all stakeholders project visibility.</li>
                  <li className={styles.ulListItem}>Process automation via AI and machine learning saves hours on manual tasks.</li>
                </ul>
                In short, construction leaders choose iDesign Pro for the versatility, scalability, 
                and intelligent automation to gain efficiency and control across the project lifecycle.
                Our platform is tailored for your workstreams and scales as you grow.
                  </div>
                : item.answer
              }
              </div>
        </div>
      ))}
      <div className={styles.aboveFooter} >
      <span>Can't find the answer here? Reachout to us at </span><span className={styles.aboveFooterEmail} 
      // style={{cursor:"pointer"}}
      // onClick={handleClick}
      >info@idesign.market</span>
      </div>
    </div>
    </>
  )
}

export default QAndA;