import React from "react";
import rightPanelBoxImage from "./Images/rightPanelBoxImage.svg";

const AllRightSideBar = () => {
  return (
    <React.Fragment>
      <div className="d-none d-md-flex flex-column" style={{ width: "25%", backgroundColor: "#ffffff", zIndex: "1" }}>
        <div className="w-100 ms-3" style={{ fontWeight: "500" }}>
          Quick Links
        </div>
        <div className="p-3" style={{position: "relative"}}>
          <div style={{ background: "linear-gradient(115.6deg, #D2E0F5 4.71%, #F8EFE5 40.89%, #FFF1FA 66.2%)", borderRadius: "12px" }}>
            <div className="ps-3 pt-2">
              <div style={{ fontSize: "24px", fontWeight: "600", color: "#000000" }}>For a 45 mins call</div>
              <div style={{ color: "#3B5998" }}>+15 mins discussion time</div>
            </div>
            <div className="ps-3 pt-2">
              <div style={{ color: "#3b5998" }}>At Just</div>
              <div style={{ fontSize: "24px", fontWeight: "700", color: "#000000" }}>₹499/-</div>
            </div>
            <div className="ps-3 pt-4 pb-3">
              <button style={{ borderRadius: "1.2rem", fontSize: "12px", border: "none", backgroundColor: "#3B5998", color: "#ffffff", padding: "0.2rem 1rem" }}>Book a slot</button>
            </div>
          </div>
          <img style={{position: "absolute", right: "14px", bottom: "14px", height: "9rem"}} src={rightPanelBoxImage} alt="" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AllRightSideBar;
