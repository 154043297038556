import React from "react";
import SidebarWebNew from "../../Lms/LmsWebComponents/SidebarNew/SidebarWebNew";
import BuyLeadsRightPanel from "./Components/BuyLeadsComponentsWeb/BuyLeadsRightPanel/BuyLeadsRightPanel";

const BuyLeadsPageWeb = () => {
  return (
    <React.Fragment>
      <div className="d-flex">
        <SidebarWebNew />
        <BuyLeadsRightPanel />
      </div>
    </React.Fragment>
  );
};

export default BuyLeadsPageWeb;
