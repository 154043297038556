import React from "react";

const Awards = () => {
  return (
    <>
      <div>
        <div
          style={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "32px",
            lineHeight: "40px",
             textAlign:'center',
            color: "#000000",
          }}
        >
          Recognitions & Awards
        </div>
        <div style={{ display: "flex", justifyContent: "center",paddingTop:'2rem'}}>
          <div style={{display:"flex",justifyContent:"flex-end"}}>
            <a href="https://www.deccanherald.com/brandspot/pr-spot/amit-kumar-joins-as-advisor-to-tech-startup-idesignmarket-1111982.html" target="_blank">
            <img
              src="https://idesign-office.s3.ap-south-1.amazonaws.com/deccan.svg"
              alt=""
              style={{width:"80%",cursor:'pointer',marginLeft:"3rem"}}
            />
            </a>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "4rem" }}
          >
            <div>
              <a href="https://www.zeebiz.com/small-business/news-tech-startup-idesignmarket-launches-software-for-interior-designing-professionals-178661" target="_blank">
              <img
                src="https://idesign-office.s3.ap-south-1.amazonaws.com/zee.svg"
                alt=""
                style={{width:"75%",cursor:'pointer'}}
              />
              </a>
            </div>
            <div>
              <a href="https://www.constructionworld.in/latest-construction-news/real-estate-news/interiors/tech-startup-idesign.market-launches-software-for-interior-designers/32732" target="_blank">
              <img
                src="https://idesign-office.s3.ap-south-1.amazonaws.com/const.svg"
                alt=""
                style={{width:"52%",cursor:'pointer'}}   
              />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Awards;
