import React from "react";
import styles from "./ProductsCss/productSec10.module.css";
import icon1 from "./ProductsImg/meeting/assignment_add.svg";
import icon2 from "./ProductsImg/meeting/inventory.svg";
import icon3 from "./ProductsImg/meeting/quick_reference_all.svg";
// import img10 from "./ProductsImg/meeting/meett.svg";

const ProductSection10 = () => {
  return (
    <>
      <div className={styles.container} id="meet">
        <div className={styles.files_contain}>
          <div className={styles.file_content_div}>
            <div className={styles.file_head}>Minutes of Meeting</div>
            <div className={styles.file_para}>
              Document important meeting information, including discussions,
              decisions, and action items.
            </div>
            <div className={styles.iconDiv}>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon2} alt="" className={styles.icon} />
                </div>
                <div className={styles.icon_para}>
                  Keep track of your <br />
                  Notes
                </div>
              </div>

              <div className={styles.eachBox}>
                <div>
                  <img src={icon1} alt="" className={styles.icon} />
                </div>
                <div className={styles.icon_para}>
                  Document your <br />
                  meetings
                </div>
              </div>
              <div className={styles.eachBox}>
                <div>
                  <img src={icon3} alt="" className={styles.icon} />
                </div>
                <div className={styles.icon_para}>
                  Search <br /> functionality
                </div>
              </div>
            </div>
          </div>
          <div className={styles.img_div}>
            <img
              src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group%202610957%20%281%29.svg"
              alt=""
              className={styles.mainImg}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductSection10;
