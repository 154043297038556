// import React, { useState, useEffect } from "react";
// import BoxOne from "./BoxOne";
// import Dubai from "./Dubai";
// import Sydney from "./Sydney";

// const LocationComponent = ({scrollRef}) => {
//   const [location, setLocation] = useState(null);

//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition((position) => {
//         setLocation({
//           latitude: position.coords.latitude,
//           longitude: position.coords.longitude,
//         });
//       });
//     }
//   }, []);

//   const determineLocationComponent = () => {
//     if (!location) {
//       return <Sydney scrollRef={scrollRef} />;
//     }
//     const { latitude, longitude } = location;
//     if (
//       latitude >= -33.87 &&
//       latitude <= -33.55 &&
//       longitude >= 151.18 &&
//       longitude <= 151.35
//     ) {
//       return <Sydney scrollRef={scrollRef} />;
//     } else if (
//       latitude >= 25.06 &&
//       latitude <= 25.38 &&
//       longitude >= 55.08 &&
//       longitude <= 55.25
//     ) {
//       return <Dubai scrollRef={scrollRef} />;
//     } else {
//       return <BoxOne scrollRef={scrollRef} />;
//     }
//   };
//   return <div>{determineLocationComponent()}</div>;
// };

// export default LocationComponent;

import React, { useState, useEffect } from "react";
import BoxOne from "./BoxOne";
import Dubai from "./Dubai";
import Sydney from "./Sydney";

const LocationComponent = ({ scrollRef }) => {
  const [location, setLocation] = useState({
    latitude: 20.5937, // India's latitude
    longitude: 78.9629, // India's longitude
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      });
    }
  }, []);

  const determineLocationComponent = () => {
    const { latitude, longitude } = location;
    if (
      latitude >= -33.87 &&
      latitude <= -33.55 &&
      longitude >= 151.18 &&
      longitude <= 151.35
    ) {
      return <Sydney scrollRef={scrollRef} />;
    } else if (
      latitude >= 25.06 &&
      latitude <= 25.38 &&
      longitude >= 55.08 &&
      longitude <= 55.25
    ) {
      return <Dubai scrollRef={scrollRef} />;
    } else {
      return <BoxOne scrollRef={scrollRef} />;
    }
  };
  return <div>{determineLocationComponent()}</div>;
};

export default LocationComponent;
