import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import side from './VendorImgs/side.svg'
import landingVen from './VendorImgs/landingVen.svg'

const VendorLandingMob = () => {
    const navigate = useNavigate()
    const handleRequest = () => {
        navigate("/vendor-ratelist")
    }
  return (
      <div className='d-block d-md-none'>
          <Link className="d-flex align-items-center" to="/" style={{ textDecoration: "none", color: "black", height: "50px", background: "white",borderBottom: "1px solid #DFDFDF" }}>
              <span className="d-flex align-items-center">
                  <div className="me-3 d-flex align-items-center">
                      <img style={{ width: "6px", height: "10px", marginLeft: "8px" }} src={side} />
                  </div>
                  <div className="page-Heading" style={{fontWeight: "500"}}>Vendor Subscription</div>
              </span>
          </Link>
          <div className="d-flex flex-column w-100 mt-3" style={{ background: "white"}}>
              <div className="d-flex flex-column p-4">
                  <h2 className="ven_head" style={{fontSize: "22px"}}>Get more leads with iDesign vendor subscription plan</h2>
                  <span className="ven_sp mt-2" style={{ fontSize: "14px", lineHeight: "20px" }}>In this plan you will get more leads, more ways to generate your income</span>
              </div>
              <button className='ven_btn mx-3 mb-3' style={{width: "90%", borderRadius: "4px"}} onClick={handleRequest}>Get Started</button>
              <div className="d-flex flex-column m-4 p-3" style={{ background: `url(${landingVen})`, backgroundRepeat: "no-repeat",backgroundSize: "contain", height: "38vh"  }}>
                  <p style={{
                      fontWeight: "400",
                      fontSize: "24px", color: "#176091", marginTop: "25px", marginLeft: "24px"
                  }}>Start your<br /> <strong style={{fontSize: "24px" }}> 1 month free </strong> <br /> trial access now</p>
              </div>
              <div className="d-flex px-4 flex-column">
                  <h2 className="ven-blah-blah mt-1 mb-3" style={{ fontSize: "20px" }}>How it Works</h2>
                  <div className="d-flex flex-column mt-4 mb-3">
                      <p className="landing-ven-heder" style={{ fontSize: "14px", width: "35px", height: "35px" }}>1</p>
                      <p className="lan-ven-start mb-0" style={{ fontSize: "16px" }}>Fill out the <strong> Rate List </strong></p>
                      <span className="lan-ven-start2" style={{ fontSize: "12px" }}>You have to enter all your items rates in the given list, you can leave the price </span>
                  </div>
                  <div className="d-flex flex-column mt-5 mb-5">
                      <p className="landing-ven-heder" style={{ fontSize: "14px", width: "35px", height: "35px" }}>2</p>
                      <p className="lan-ven-start mb-0" style={{ fontSize: "16px" }}>Start you <strong> Free Trial  </strong></p>
                      <span className="lan-ven-start2 mb-3" style={{ fontSize: "12px" }}>It is a subsciption plan, which will automatically renew your subscription </span>
                  </div>
              </div>
      </div>
         
    </div>
  )
}

export default VendorLandingMob