import { Modal } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import SideBarWeb from '../Lms/LmsWebComponents/SideBarWeb'
import "./Vendor.css"
import { useNavigate } from 'react-router-dom'
import VendorRateListMobile from './VendorRateListMobile'
// import dataArr from './DataArr'
import axios from 'axios'
import vendorFree from "./VendorImgs/vendorFree.svg";
import SidebarWebNew from '../Lms/LmsWebComponents/SidebarNew/SidebarWebNew'
import HeaderNav from '../Lms/LmsWebComponents/HeaderNav/HeaderNav'

const VendorRateList = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const authTok = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "";
    const navigate = useNavigate()
    const [venData, setVenData] = useState({
        tileFlooringFixing: "",
        wallTilesFixing: "",
        dadoPlasterForWallTiles: "",
        plainCementConcrete: "",
        brickworkHalf: "",
        brickworkFull: "",
        plastering: "",
        popFalseCeiling: "",
        popCove: "",
        popPunning: "",
        gypsumFalseCeiling: "",
        gypsumCove: "",
        woodenFalseCeiling: "",
        royalPaint: "",
        premiumEmulsion: "",
        wallPanellingWithLaminate: "",
        wallPanellingWithLaminateWithHardwoodFrame: "",
        wallPanellingVeneer: "",
        mirrorPanellingPlainRectangularWithBevelled: "",
        mirrorPanellingBeveledBorderAndColoredMirror: "",
        doorWithLaminateFinish: "",
        doorChaukathFrame: "",
        lookingMirrorBathrooms: "",
        shiftingPointsOneMetre: "",
        shiftingPointsOneToFiveMetre: "",
        newPowerPoints: "",
        falseCeilingElectricalWiring: "",
        lightInstallationOnWalls: "",
        fanInstallation: "",
        completeElectricalWiring: "",
        acCopperPiping: "",
        acDrainPipe: "",
        acInstallation: "",
    })

    const handleInput = (e) => {
        if (e.target.value?.length <= 4 && e.target?.value >= 0) {
            setVenData((prev) => {
                return {
                    ...prev,
                    [e.target.name]: e.target.value
                }
            })
        }
    }

    const dataArr = [
        {
            id: 1,
            name: "Tile Flooring Fixing",
            description: " Installation of Tiles with cement mortar/Chemical. (Tile to be provided by client at site). Tile size not to exceeed 2' x 4'. Incase of larer tiles there would be an additional fixing charge. Cost of Tile not included",
            unit: "Sq.ft",
            value: (venData.tileFlooringFixing).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "tileFlooringFixing"
        },
        {
            id: 2,
            name: "Wall Tiles Fixing",
            description: " Installation of Tiles with cement mortar/Chemical. (Tile to be provided by client at site). Tile size not to exceeed 2' x 4'. Incase of larer tiles there would be an additional fixing charge. Cost of Tile not included",
            unit: "Sq.ft",
            value: (venData.wallTilesFixing).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "wallTilesFixing"
        },
        {
            id: 3,
            name: "Dado Plaster for Wall Tiles",
            description: " Dado Plaster to be done on Wall for Installation of Tiles. (Tiles to be provided at site by client)",
            unit: "Sq.ft",
            value: (venData.dadoPlasterForWallTiles).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "dadoPlasterForWallTiles"
        },
        {
            id: 4,
            name: "PCC",
            description: "(Plain Cement Concrete) in toilets/ kitchen or any other floor as required upto 2 inch thick.",
            unit: "Sq.ft",
            value: (venData.plainCementConcrete).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "plainCementConcrete"

        },
        {
            id: 5,
            name: "Brickwork",
            description: "Making of half brick wall 115mm thick using first class bricks. 6mm thick steel reinforcement to be given after every 1 metre",
            unit: "Sq.ft",
            value: (venData.brickworkHalf).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "brickworkHalf"
        },
        {
            id: 6,
            name: "Brickwork",
            description: " Making of full brick wall 230 mm thick using first class bricks. 6mm thick steel reinforcement to be given after every 1 metre.",
            unit: "Sq.ft",
            value: (venData.brickworkFull).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "brickworkFull"
        },
        {
            id: 7,
            name: "Plastering",
            description: "Providing & Applying 10-12mm thick plaster in ratio 1:4 (1 Cement 4 Sand)",
            unit: "Sq.ft",
            value: (venData.plastering).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "plastering"
        },
        {
            id: 8,
            name: "Pop False Ceiling",
            description: "Providing and fixing False ceiling made out of POP (plaster of Paris) with GI framework",
            unit: "Sq.ft",
            value: (venData.popFalseCeiling).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "popFalseCeiling"
        },
        {
            id: 9,
            name: "POP Cove",
            description: "(Measurement taken 2 times as per internal + external cove) providing and fixing False ceiling Cove made out of POP (plaster of Paris) with GI framework",
            unit: "Sq.ft",
            value: (venData.popCove).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "popCove"
        },

        {
            id: 10,
            name: "Pop Punning on walls & ceiling",
            description: " ",
            unit: "Sq.ft",
            value: (venData.popPunning).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "popPunning"

        },
        {
            id: 11,
            name: "Gypsum False ceiling",
            description: "Providing & Fixing Gypsum False Ceiling made out of Gypsum boards on a GI Framework ",
            unit: "Sq.ft",
            value: (venData.gypsumFalseCeiling).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "gypsumFalseCeiling"
        },
        {
            id: 12,
            name: "Gypsum Cove",
            description: " Providing & Fixing Gypsum False Ceiling made out of Gypsum boards on a GI Framework",
            unit: "Rft",
            value: (venData.gypsumCove).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "gypsumCove"
        },
        {
            id: 13,
            name: "Wooden False Ceiling",
            description: "Made out of 12 mm plywood and 4 mm Veneer with a hardwood frame. Basic Cost of Veneer Rs 60 per sqft. Includes Melamine Polish. Frame to be treated with anti termite oil.  ",
            unit: "Sq.ft",
            value: (venData.woodenFalseCeiling).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "woodenFalseCeiling"
        },
        {
            id: 14,
            name: "Royal Paint (Asian Paints)",
            description: " Providing the application of 2 coats of putty and 3 coats of paint. Putty: Birla",
            unit: "Sq.ft",
            value: (venData.royalPaint).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "royalPaint"
        },
        {
            id: 15,
            name: "Premium Emulsion (Asian Paints)",
            description: " Providing the application of 2 coats of putty and coats of paint. Putty: Birla",
            unit: "Sq.ft",
            value: (venData.premiumEmulsion).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "premiumEmulsion"
        },
        {
            id: 16,
            name: "Wall panelling with laminate",
            description: "Made out of plywood with laminate finished. Laminate fixed on 16mm Plywood. Laminates of SF and HGL only of merino or century (without hardwood frame)",
            unit: "Sq.ft",
            value: (venData.wallPanellingWithLaminate).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "wallPanellingWithLaminate"
        },
        {
            id: 17,
            name: "Wall Panelling with laminate with hardwood frame",
            description: "  Wall Panelling to be done using 12mm plyboard with Laminate finish on a hardwood frame. Frame to be treated with anti termite oil. Cove to be given if required. Laminates of SF and HGL only of merino or century ",
            unit: "Sq.ft",
            value: (venData.wallPanellingWithLaminateWithHardwoodFrame).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "wallPanellingWithLaminateWithHardwoodFrame"
        },
        {
            id: 18,
            name: "Wall Panelling (Veneer)",
            description: " Wall Panelling to be done using 18mm plyboard with Veneer & Polish (Basic Cost of Veneer Rs 60 to Rs 75 per Sqft). Design to be straight lined.",
            unit: "Sq.ft",
            value: (venData.wallPanellingVeneer).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "wallPanellingVeneer"
        },
        {
            id: 19,
            name: "Mirror Panelling (Plain rectangular with bevelled as per design)",
            description: "Wall Panelling to be done using 18mm plyboard with 5mm  Plain Mirror. The mirror to be cut in rectangular shape with bevelling",
            unit: "Sq.ft",
            value: (venData.mirrorPanellingPlainRectangularWithBevelled).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "mirrorPanellingPlainRectangularWithBevelled"
        },
        {
            id: 20,
            name: "Mirror Panelling (Beveled/Border and colored mirror)",
            description: "Wall Panelling to be done using 18mm plyboard with 5mm  Beveled/border Plain/Colored Mirror. Glass: St Gobains/ Modi",
            unit: "Sq.ft",
            value: (venData.mirrorPanellingBeveledBorderAndColoredMirror).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "mirrorPanellingBeveledBorderAndColoredMirror"
        },
        {
            id: 21,
            name: "Door with Laminate finish",
            description: "(Cost of locks & handle extra as per selection) Door Size to be a maximum of 900mm x 2250mm. Brand: Bhutan Tuff Doors. Hinges: Hetich ",
            unit: "Nos",
            value: (venData.doorWithLaminateFinish).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "doorWithLaminateFinish"
        },
        {
            id: 22,
            name: "Door chaukath/frame",
            description: "of size 5 inch x 2.5 inch of Chaap/ Ivory coast with melamine polish. Chaukhat to be treated with anti termite oil",
            unit: "Nos",
            value: (venData.doorChaukathFrame).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "doorChaukathFrame"
        },
        {
            id: 23,
            name: "Looking Mirror",
            description: " in Bathrooms/ frameless on ply with bevelling as required. To be fixed on 12 mm waterproof ply . Mirror Glass: St Gobain/Modi",
            unit: "Nos",
            value: (venData.lookingMirrorBathrooms).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "lookingMirrorBathrooms"
        },
        {
            id: 24,
            name: "Wires: Polycab/Havells (upto 1 metre shifting)",
            description: " Shifting of Points with wiring chasing etc (One 6A/16A socket+Switch is 1 point)",
            unit: "Nos",
            value: (venData.shiftingPointsOneMetre).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "shiftingPointsOneMetre"
        },
        {
            id: 25,
            name: "Wires: Polycab/Havells (upto 1 metre to 5 metre shifting)",
            description: " Shifting of Points with wiring chasing etc (One 6A/16A socket+Switch is 1 point)",
            unit: "Nos",
            value: (venData.shiftingPointsOneToFiveMetre).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "shiftingPointsOneToFiveMetre"
        },
        {
            id: 26,
            name: "Wires: Polycab/Havells",
            description: "New Power Points (One 6A/16A Socket+Switch is 1 Point) (cost of switch plates is not included and to be extra or provided by client).",
            unit: "Nos",
            value: (venData.newPowerPoints).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "newPowerPoints"
        },
        {
            id: 27,
            name: "False Ceiling Electrical Wiring",
            description: " (Providing, laying 20 mm conduits & 1.5 Sq.mm Conductors wires of make Havells & , testing & commissioning including termination of wiring for Concealed ceiling light points, fan points exposed as required & installation of concealed light , cove light with complete testing). Wires: Polycab/Havells",
            unit: "Nos",
            value: (venData.falseCeilingElectricalWiring).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "falseCeilingElectricalWiring"
        },
        {
            id: 28,
            name: "Light Installation on Walls",
            description: "",
            unit: "Nos",
            value: (venData.lightInstallationOnWalls).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "lightInstallationOnWalls"
        },
        {
            id: 29,
            name: "Fan Installation ",
            description: "",
            unit: "Nos",
            value: (venData.fanInstallation).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "fanInstallation"
        },
        {
            id: 30,
            name: " Complete Electrical Wiring",
            description: "(Providing, laying 20 mm conduits & 1.5 Sq.mm and 2.5Sq.mm and 4 Sq.mm Copper Conductors wires of make Havells & , testing & commissioning including termination of wiring for Concealed ceiling light points, fan points exposed as required & installation of concealed light , cove light with complete testing & Db if required).Wires: Polycab/Havells ",
            unit: "Sq.ft",
            value: (venData.completeElectricalWiring).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "completeElectricalWiring"
        },
        {
            id: 31,
            name: "Ac Copper piping",
            description: "Includes cost of chasing of wall for piping and filling of Cement Plaster post the same. (thickness - 6 and 12mm) (upto 2 ton split ac)",
            unit: "Rft",
            value: (venData.acCopperPiping).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "acCopperPiping"
        },
        {
            id: 32,
            name: "Ac 20-25 mm drain pipe",
            description: "Includes cost of chasing of wall for piping and filling of Cement Plaster post the same",
            unit: "Rft",
            value: (venData.acDrainPipe).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "acDrainPipe"
        },
        {
            id: 33,
            name: "AC Installation ",
            description: "",
            unit: "Nos",
            value: (venData.acInstallation).replace(/\d(?=(?:\d{3})+$)/g, '$&,'),
            name2: "acInstallation"
        },
    ]

    // console.log(dataArr)
    // console.log((2982932323).toLocaleString('en-US'))
    // console.log((venData.tileFlooringFixing).replace(/\d(?=(?:\d{3})+$)/g, '$&,'))

    const [venId, setVenId] = useState([])

    useEffect(async () => {
        await axios.get("https://pro-api.idesign.market/user/listVendorPlans").then((venRes) => {
            console.warn(venRes)
            setVenId(venRes?.data?.data[0]?._id)
        }).catch(err => console.warn(err))
    }, [])

    // console.log(parseInt(dataArr[0].value.replace(/,/g, '')))


    const handleSubmit = async () => {
        const payload = {
            tileFlooringFixing: dataArr[0].value === "" ? 0 : parseInt(dataArr[0].value.replace(/,/g, '')),
            wallTilesFixing: dataArr[1].value === "" ? 0 : parseInt(dataArr[1].value.replace(/,/g, '')),
            dadoPlasterForWallTiles: dataArr[2].value === "" ? 0 : parseInt(dataArr[2].value.replace(/,/g, '')),
            plainCementConcrete: dataArr[3].value === "" ? 0 : parseInt(dataArr[3].value.replace(/,/g, '')),
            brickworkHalf: dataArr[4].value === "" ? 0 : parseInt(dataArr[4].value.replace(/,/g, '')),
            brickworkFull: dataArr[5].value === "" ? 0 : parseInt(dataArr[5].value.replace(/,/g, '')),
            plastering: dataArr[6].value === "" ? 0 : parseInt(dataArr[6].value.replace(/,/g, '')),
            popFalseCeiling: dataArr[7].value === "" ? 0 : parseInt(dataArr[7].value.replace(/,/g, '')),
            popCove: dataArr[8].value === "" ? 0 : parseInt(dataArr[8].value.replace(/,/g, '')),
            popPunning: dataArr[9].value === "" ? 0 : parseInt(dataArr[9].value.replace(/,/g, '')),
            gypsumFalseCeiling: dataArr[10].value === "" ? 0 : parseInt(dataArr[10].value.replace(/,/g, '')),
            gypsumCove: dataArr[11].value === "" ? 0 : parseInt(dataArr[11].value.replace(/,/g, '')),
            woodenFalseCeiling: dataArr[12].value === "" ? 0 : parseInt(dataArr[12].value.replace(/,/g, '')),
            royalPaint: dataArr[13].value === "" ? 0 : parseInt(dataArr[13].value.replace(/,/g, '')),
            premiumEmulsion: dataArr[14].value === "" ? 0 : parseInt(dataArr[14].value.replace(/,/g, '')),
            wallPanellingWithLaminate: dataArr[15].value === "" ? 0 : parseInt(dataArr[15].value.replace(/,/g, '')),
            wallPanellingWithLaminateWithHardwoodFrame: dataArr[16].value === "" ? 0 : parseInt(dataArr[16].value.replace(/,/g, '')),
            wallPanellingVeneer: dataArr[17].value === "" ? 0 : parseInt(dataArr[17].value.replace(/,/g, '')),
            mirrorPanellingPlainRectangularWithBevelled: dataArr[18].value === "" ? 0 : parseInt(dataArr[18].value.replace(/,/g, '')),
            mirrorPanellingBeveledBorderAndColoredMirror: dataArr[19].value === "" ? 0 : parseInt(dataArr[19].value.replace(/,/g, '')),
            doorWithLaminateFinish: dataArr[20].value === "" ? 0 : parseInt(dataArr[20].value.replace(/,/g, '')),
            doorChaukathFrame: dataArr[21].value === "" ? 0 : parseInt(dataArr[21].value.replace(/,/g, '')),
            lookingMirrorBathrooms: dataArr[22].value === "" ? 0 : parseInt(dataArr[22].value.replace(/,/g, '')),
            shiftingPointsOneMetre: dataArr[23].value === "" ? 0 : parseInt(dataArr[23].value.replace(/,/g, '')),
            shiftingPointsOneToFiveMetre: dataArr[24].value === "" ? 0 : parseInt(dataArr[24].value.replace(/,/g, '')),
            newPowerPoints: dataArr[25].value === "" ? 0 : parseInt(dataArr[25].value.replace(/,/g, '')),
            falseCeilingElectricalWiring: dataArr[26].value === "" ? 0 : parseInt(dataArr[26].value.replace(/,/g, '')),
            lightInstallationOnWalls: dataArr[27].value === "" ? 0 : parseInt(dataArr[27].value.replace(/,/g, '')),
            fanInstallation: dataArr[28].value === "" ? 0 : parseInt(dataArr[28].value.replace(/,/g, '')),
            completeElectricalWiring: dataArr[29].value === "" ? 0 : parseInt(dataArr[29].value.replace(/,/g, '')),
            acCopperPiping: dataArr[30].value === "" ? 0 : parseInt(dataArr[30].value.replace(/,/g, '')),
            acDrainPipe: dataArr[31].value === "" ? 0 : parseInt(dataArr[31].value.replace(/,/g, '')),
            acInstallation: dataArr[32].value === "" ? 0 : parseInt(dataArr[32].value.replace(/,/g, '')),
        }
        const res = await axios.post("https://pro-api.idesign.market/user/addContractorRateList", payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authTok
                }
            }
        ).then(async (res) => {
            console.warn(res)
            const res2 = await axios.post("https://pro-api.idesign.market/user/addVendorPlanToCart", { vendorPlanId: venId }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authTok
                }
            }).then((res2) => {
                console.warn(res2)
                navigate("/vendorcart")
            }).catch(err => console.warn(err))
        }).catch(err => console.warn(err))


    }

    // console.log(parseInt(dataArr[0].value.replace(/,/g, '')))

    function f(n) {
        if (n != '' || n != undefined) {
            return true;
        }
        else {
            return false;
        }

    }
    const exceptThisSymbols = ["e", "E", "+", "-", "."];

    // console.log(Object.values(venData).find(f))

    const isNullish = Object.values(venData).every(value => {
        if (value === '' || value === undefined) {
            return true;
        }

        return false;
    });


    return (
        <>
            {/* <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Body>
                    <div className="d-flex flex-column p-3">
                        <h2 className="ven_head">Your rate list preview</h2>
                        <div className="d-flex flex-column my-4">
                            <div className="d-flex justify-content-between">
                                <p className="rate_l ">S.No</p>
                                <p className="rate_l_m1_pre" style={{ width: "13%" }}>Rate List</p>
                                <p className="rate_l_m2_pre" style={{ width: "46%" }}>Description</p>
                                <p className="rate_l_m3_pre">Unit</p>
                                <p className="rate_l_m4_pre">Price</p>
                            </div>
                            <div className="d-flex flex-column rate_main">
                                {dataArr.map((item, index) => (
                                    <div className="d-flex justify-content-between">
                                        <p className='r_false2'> {item.id}  </p>
                                        <p className="r_false2 mx-4" style={{ width: "13%" }}>{item.name}</p>
                                        <p className="r_desc" style={{ width: "48%" }}>{item.description} </p>
                                        <p className="r_desc" >{item.unit} </p>
                                        {item.value ? <p className="r_input2 mx-5">₹{item.value}</p> : <p className="r_input2 mx-5">₹0.00</p>}
                                       
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="d-flex" style={{ alignSelf: "end" }}>
                            <button className='rate_btn1 mx-3' onClick={handleClose}>Edit</button>
                            <button className='rate_btn2' onClick={handleSubmit}>Submit</button>
                        </div>
                    </div>
                    </Modal.Body>
            </Modal> */}

            <div className='d-none d-md-block'>
                <HeaderNav />
                <div className=" d-flex w-100">
                    <div className="sidBarHeight d-none d-md-block" style={{ height: "90vh", width: "22vw", borderRight: "1px solid #A7A7A7", paddingRight: "0" }}>
                        <SidebarWebNew />
                    </div>
                    <div className="d-flex w-100 flex-column " style={{ background: "white" }}>
                        <div className="d-flex flex-column" style={{ paddingTop: "2.5rem", paddingLeft: "2.5rem", paddingRight: "5rem" }}>
                            <div className="d-flex">
                                <div className="d-flex flex-column">
                                    <h2 className="ven_head">Fill out the rate list</h2>
                                    <span className="ven_sp">You have to enter all your items rates in the given list, you can leave the price empty if you don’t have a price for that item. </span>
                                </div>
                                <div className="ven-free d-flex px-2" style={{ height: "5vh", marginLeft: "auto", marginTop: "auto" }}>
                                    <img className='ven-blah' style={{
                                        height: "14px", width: "14px", marginTop: "12px",
                                        marginLeft: "6px"
                                    }} src={vendorFree} alt="" />
                                    <p className="ven-blah2 m-2" style={{ fontSize: "13px" }}>1 month free trial access</p>

                                </div>
                            </div>
                            <div className="d-flex flex-column my-4">
                                <div className="d-flex justify-content-between">
                                    <p className="rate_l">S.No</p>
                                    <p className="rate_l_m1" style={{ width: "10%" }}>Rate List</p>
                                    <p className="rate_l_m2 " style={{ width: "49%" }}>Description</p>
                                    <p className="rate_l_m3">Unit</p>
                                    <p className="rate_l_m4">Price</p>
                                </div>
                                <div className="d-flex flex-column rate_main">
                                    {dataArr.map((item, index) =>
                                    (<div className="d-flex justify-content-between">
                                        <p className='r_false2' style={{ width: "5%" }}> {item.id}. </p>
                                        <p className="r_false2 " style={{ width: "16%" }}>{item.name}</p>
                                        <p className="r_desc " style={{ width: "49%" }}>{item.description}</p>
                                        <p className="r_desc" style={{ color: "#000000", width: "11%", textAlign: "center" }}>{item.unit}</p>
                                        {/* <div className= {item.value.length >0 ? "dimag-ven" : "d-none"} style={{
                                            position: "absolute",
                                            right: "12rem",
                                            marginTop: "4.5px",
                                        }}>₹</div> */}
                                        <input type="text" name={item.name2} value={item.value} className="r_input mb-2" placeholder='₹ 00.00' onChange={handleInput} onKeyDown={(e) => {
                                            exceptThisSymbols.includes(e.key) && e.preventDefault()
                                            if (e.keyCode == 8) {
                                                if (item.value.toString().includes(',') == true) {
                                                    item.value = parseInt(item.value.toString().replace(',', ''))
                                                    document.querySelector('.r_input').value = parseInt(item.value.toString().replace(',', ''))
                                                }
                                            }
                                        }
                                        } style={{ marginRight: "2rem" }} />
                                    </div>))}
                                </div>
                            </div>
                        </div>
                        {!isNullish ? <button className='rate_btn' onClick={handleSubmit}>Submit</button> : <button className='rate_btn' disabled>Submit</button>}
                        {/* <button className='rate_btn' onClick={handleShow}>Preview</button> */}
                    </div>
                </div>
            </div>
            <VendorRateListMobile />
        </>
    )
}

export default VendorRateList;