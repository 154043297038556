import React from "react";
import MainMob from "./MainMob";
import WhatWeDoMob from "./WhatWeDoMob";
import HamburgerMeun from "../../../SaasOnboardingMobile/HamburgerMeun";
import StoriesMob from "./StoriesMob";
import LeaderShipMob from "./LeaderShipMob";
import WhereWeWorkMob from "./WhereWeWorkMob";
import FooterMobile from "../../../SaasOnboardingMobile/FooterMobile";
import AwardsMob from "./AwardsMob";
import BackedMob from "./BackedMob";

const AboutMob1 = () => {
  return (
    <>
      <HamburgerMeun />
      <div style={{ width: "100%", overflowX: "hidden"}}>
        <MainMob />
        <WhatWeDoMob />
        {/* <StoriesMob /> */}

        <LeaderShipMob />
        <AwardsMob/>
        <BackedMob/>
        <WhereWeWorkMob />
      </div>
      <FooterMobile />
    </>
  );
};

export default AboutMob1;
