import React from 'react'

const DashBoardLast = () => {
  return (
   <>
   <div style={{marginBottom:"4rem",marginTop:"1.5rem"}}>
      <div style={{display:'flex',gap:"1rem"}}>
        <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Frame%202611098.svg" alt="" style={{width:"68%"}}/>
        <img src="https://idesign-office.s3.amazonaws.com/Frame%202611639.svg" alt="" style={{width:"32%"}}/>
      </div>
   </div>
   
   </>
  )
}

export default DashBoardLast;