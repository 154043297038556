import React from 'react';
import {mockArray} from "../../Pricing/mockData"; 
import styles from "../../Pricing/PricingMain.module.css";

const SecondComponent = ({scrollRef,forScroll}) => {

  
  return (
    <>
    <div ref={scrollRef}   id={forScroll}
    style={{height:"7.71rem", padding:"1.38rem", display:"flex", justifyContent:"center", alignItems:"center", marginBottom:"20px"}}>
      <div style={{display:"flex"}}>
      <div className={styles.mainHeading} >
      Let us break it down for you
      </div>
      <div style={{display:"flex"}}>
        <div style={{color:"#0081E8", marginRight:"10px", marginLeft:"-22px"}} className={styles.rotatedText} >Professional</div>
        <div style={{color:"#D261D2", marginLeft:"-38px"}} className={styles.rotatedText}>Enterprise</div>
      </div>
      </div>
    </div>
    <div>
        {mockArray.map((category, index) => (
            <div key={category.heading} 
            style={{marginLeft:"auto", marginRight:"auto"}}
            className={`${styles.mainContainer} ${index % 2 === 0 ? styles.evenContainer : styles.oddContainer}`}
            >
              <div className={styles.arrayHeading}> {category.heading} </div>
              {category.subHeadings.map((subHeading) => (
                <div className={styles.arraySubHeadingsContainer}>
                <div
                  key={subHeading.subHead}
                  className={styles.arraySubHeadings}
                >
                <span style={{width:"9.4375rem"}}> {subHeading.subHead}</span>
                 <span style={{display:"flex", gap:"35px"}}>
                 {subHeading.pro == "yes" ? (
                        <img alt="" src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/blue.jpg" className={styles.checkCircle} />
                      ) : (
                        <img alt="" src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/XCircle.svg" className={styles.cross} />
                      )}
                  {subHeading.ent == "yes" ? (
                        <img alt="" style={{marginRight:"10px"}} src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/pink.jpg"  className={styles.checkCircle} />
                      ) : (
                        <img alt="" style={{marginRight:"10px"}} src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/XCircle.svg"  className={styles.cross} />
                      )}
                  </span>
                </div>
                </div>
              ))}
            </div>
          ))}
    </div>
    </>
  )
}

export default SecondComponent