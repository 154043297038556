import React from 'react';
import styles from "./productMobCss/productFileMob.module.css";
import icon1 from "../../SaasOnboarding/Products/ProductsImg/fileImg/lock.svg";
import icon2 from "../../SaasOnboarding/Products/ProductsImg/fileImg/bookmark_manager.svg";
import icon3 from "../../SaasOnboarding/Products/ProductsImg/fileImg/conversion_path.svg"

const ProductSecFileMob = () => {
  return (
    <>
     <div className={styles.files_contain} id="filee">
       
       <div className={styles.file_content_div}>
         <div className={styles.file_head}>FILE MANAGER</div>
         <div className={styles.file_para}>
           Easily organize and manage all of your important documents
           with our file manager feature.
         </div>
         <div className={styles.img_div}>
         <img src="https://idesign-office.s3.amazonaws.com/Rectangle%207461%20%283%29.svg" alt="" className={styles.img} />
       </div>
         <div className={styles.icon_div}>
             <div className={styles.eachBox}>
               <div><img src={icon1} alt="" className={styles.mobIcon}/></div>
               <div className={styles.iconPara}>Keep your Project Files  secure & safe</div>              
             </div>
             <div className={styles.eachBox}>
               <div><img src={icon2} alt="" className={styles.mobIcon}/></div>
               <div className={styles.iconPara}>Get Easy feedback on files from Client</div>
             </div>
             <div className={styles.eachBox}>
               <div><img src={icon3} alt="" className={styles.mobIcon}/></div>
               <div className={styles.iconPara}>Track and Control <br /> files versions</div>
             </div>
           </div>
       </div>
     
     </div>
    
    
    </>
  )
}

export default ProductSecFileMob;