import React from "react";
import AboutSec1 from "./AboutPage/AboutSec1";
import { useState, useEffect } from "react";
import AboutMob1 from "./AboutMobPage/AboutMob1";

const AboutWebMob = () => {
  const [isDesktop, setIsDesktop] = useState(window.screen.width > 767);

  const updateMedia = () => {
    setIsDesktop(window.screen.width > 767);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  return <>{isDesktop ? <AboutSec1 /> : <AboutMob1 />}</>;
};

export default AboutWebMob;
