import React, { useRef, useState, useEffect } from 'react';
import styles from "../../Pricing/BoxOne.module.css";
import Icon from "../../Pricing/Images/Icon.svg";
import right from "../../Pricing/Images/right.svg";
import web from "../../Pricing/Images/web.svg";
import app from "../../Pricing/Images/app.svg";
import pro from "../../Pricing/Images/pro.svg";
import caretRight from "../../Pricing/Images/CaretRight.svg";
import { mockDataBoxOne } from "../../Pricing/mockData";
import { useNavigate } from 'react-router-dom';
import {  FormControl, MenuItem, Select } from "@mui/material";
import CryptoJS from "crypto-js";

const mockDataProfessional = [
  "Get a birds eye view of your company",
  "Efficiently capture and manage all projects",
  "On the go attendance location with Geo-location ",
  "Access for everyone, leave no one out",
  "Real-time progress reports",
  "Manage your leads in the in-built CRM",
  "Limit user access through access control",
  "Manage on the go via mobile application"
]

const BasicMobSydney = ({ change, forScroll }) => {
  const [clickedItem, setClickedItem] = useState(null);
  const [dubaiprices, setDubaiPrices] = useState({
    option1: 299,
    option2: 699,
    option3: 799,
    option4: 1399
  });
  const [selectedValue, setSelectedValue] = useState('option1');
  
  const handleMenuItemClick = (value) => {
    setClickedItem(value);
  };  

  const handleDropdownChange = (event) => {
    setSelectedValue(event.target.value);
    switch (event.target.value) {
      case "option1":
        localStorage.setItem("price", change === "quarter" ? 299 : 249);
        break;
      case "option2":
        localStorage.setItem("price", change === "quarter" ? 699 : 399);
        break;
      case "option3":
        localStorage.setItem("price", change === "quarter" ? 799 : 699);
        break;
      case "option4":
        localStorage.setItem("price", change === "quarter" ? 1399 : 999);
        break;
      default:
        // Handle default case if needed
        break;
    }
  };
  const navigateTo = useNavigate();
  useEffect(() => {
    setDubaiPrices({
      option1: change == "quarter" ? 299 : 249,
      option2: change == "quarter" ? 699 : 399,
      option3: change == "quarter" ? 799 : 699,
      option4: change == "quarter" ? 1399 : 999
    })
  }, [change])

  localStorage.setItem("fromCountry", "Sydney");

  function generateHashedLink(token, userId, paymentPlan, fullName, cId, price, password) {
    const encryptedData = {};
    const encryptionKey = "18B69E843399F1A2AFC5853129299";
  
    const encryptField = (field) => {
      const fieldValue = field?.toString() || ''; // Convert value to string, handle undefined
      return CryptoJS.AES.encrypt(fieldValue, encryptionKey).toString();
    };
  
    encryptedData.token = encryptField(token);
    encryptedData.userId = encryptField(userId);
    encryptedData.paymentPlan = encryptField(paymentPlan);
    encryptedData.cId = encryptField(cId);
    encryptedData.fullName = encryptField(fullName);
    encryptedData.price = encryptField(price);
    encryptedData.password = encryptField(password);
  
    const now = new Date();
    const timestamp = now.toISOString();
    const encryptedTimestamp = CryptoJS.AES.encrypt(timestamp, encryptionKey).toString();
    encryptedData.timestamp = encryptedTimestamp;
  
    const encryptKey = (key) => {
      return CryptoJS.AES.encrypt(key, encryptionKey).toString();
    };
    const url = `https://www.idesign.market/payment?${encryptKey("userId")}=${encryptedData.userId}&${encryptKey("cId")}=${encryptedData.cId}&${encryptKey("token")}=${encryptedData.token}&${encryptKey("fullName")}=${encryptedData.fullName}&${encryptKey("price")}=${encryptedData.price}&${encryptKey("paymentPlan")}=${encryptedData.paymentPlan}&${encryptKey("timestamp")}=${encryptedData.timestamp}&${encryptKey("password")}=${encryptedData.password}`;
    return url;
  }

  return (
    <>
      <div className={styles.box2}>
        <div style={{ display: "flex", gap: "0.5rem" }}>
          <div className={styles.buttons} style={{ background: "#E5F4FF" }}>
            <span className={styles.buttonsText} style={{ color: "#0081E8" }}>Small-mid Teams</span>
          </div>
        </div>
        <div style={{ marginTop: "0.56rem" }}>
          <div className={styles.inBoxHeading}>
            Professional
          </div>
        </div>
        <div
        >
          <div className={styles.inBoxSummary}>
            Free 1 TB Storage
          </div>
        </div>
        <div style={{ marginBottom: "1.94rem", marginTop: "0.85rem", position: "relative" }}>
          <FormControl
            sx={{
              width: "100%",
              borderRadius: "0.38788rem",
              border: " 0.776px solid #DEDEDE",
            }}
          >
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={selectedValue}
              onChange={handleDropdownChange}
              default="option1"
            >
             <MenuItem onClick={() => handleMenuItemClick("option1")}  style={{ color: clickedItem === "option1" ? "#017EE2" : "inherit" }} value="option1">Upto 5 users</MenuItem>
    <MenuItem onClick={() => handleMenuItemClick("option2")}  style={{ color: clickedItem === "option2" ? "#017EE2" : "inherit" }} value="option2">Upto 10 users</MenuItem>
    <MenuItem onClick={() => handleMenuItemClick("option3")}  style={{ color: clickedItem === "option3" ? "#017EE2" : "inherit" }} value="option3">Upto 20 users</MenuItem>
    <MenuItem onClick={() => handleMenuItemClick("option4")}  style={{ color: clickedItem === "option4" ? "#017EE2" : "inherit" }} value="option4">Unlimited users</MenuItem>
  </Select>
          </FormControl>
          <div style={{ position: "absolute", top: "50%", right: "10px", transform: "translateY(-50%)" }}>
          </div>
        </div>
        {mockDataProfessional.map((x, index) => {
          return <div key={index} style={{ marginBottom: "0.75rem", display: "flex" }}>
            <img src={right} alt="right" style={{ verticalAlign: "middle", width: "16px", height: "16px" }} />
            <span style={{ marginLeft: "0.75rem", fontSize: "0.625rem", fontWeight: "500" }}>{x}</span>
          </div>
        })}
        {selectedValue && (<div style={{ marginTop: "2.06rem" }}>
          <span style={{
            color: "#212123",
            fontFamily: "Inter",
            fontSize: "1.25rem",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
            letterSpacing: "-0.0625rem"
          }}>AUS{" "}
            {dubaiprices[selectedValue]}
          </span>{" "}<span className={styles.afterAmount} style={{ fontSize: "0.75rem" }}>/month</span> </div>
        )}
        <div style={{ marginTop: "1rem", display: "flex", gap: "1rem", justifyContent: "space-between" }}>
          <div className={styles.blueButtonContainer}>
            <div className={styles.blueButton}>
              <div className={styles.blueButtonText}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (localStorage.getItem("pricing") === "true") {
                    navigateTo("/signup");
                  } else {
                    // navigateTo("/payment");
                    window.location.assign(generateHashedLink(localStorage.getItem("token"),
                    localStorage.getItem("userId"),
                    localStorage.getItem("paymentPlan"),
                    localStorage.getItem("fullName"),
                    localStorage.getItem("cId"),
                    localStorage.getItem("price"),
                    localStorage.getItem("password")
                    )) 
                  }
                }
                }
              >Get Started</div>
              <img src={caretRight} className={styles.checkCircle} />
            </div>
          </div>
          <div
            className={styles.blueButtonContainer}
            style={{ marginBottom: "0.75rem" }}
          >
            <div className={styles.blueBorderButton}>
              <div className={styles.blueBorderButtonText} style={{ cursor: "pointer" }}
                onClick={() => navigateTo("/contact")}>Let's Talk!</div>
            </div>
          </div>
        </div>
        <div className={styles.showFeaturesBtn}
          style={{ cursor: "pointer" }}
        //  onClick={()=>scrollRef.current.scrollIntoView({behavior:"smooth"})}
        >
          <a href={forScroll} style={{ color: "inherit", textDecoration: "none" }}>Show features</a>
        </div>
      </div>

    </>
  )
}

export default BasicMobSydney;