import React from 'react';
import styles from "./productMobCss/productSec4.module.css";
import icon1 from "../../SaasOnboarding/Products/ProductsImg/timeline/ballot.svg";
import icon2 from "../../SaasOnboarding/Products/ProductsImg/timeline/event_upcoming.svg";
import icon3 from "../../SaasOnboarding/Products/ProductsImg/timeline/notifications_active.svg";
// import quo from "./productMobImg/timeline/timeline.png";

const ProductSec4 = () => {
  return (
   <>
      <div className={styles.container} id="timee">
           <div className={styles.files_contain}>
       
       <div className={styles.file_content_div}>
         <div className={styles.file_head}>TIMELINES</div>
         <div className={styles.file_para}>
         Create project schedules and timelines <br /> & helps to keep projects on track  <br /> and ensures that deadlines are met.
         </div>
         <div className={styles.img_div}>
         <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207464%20%282%29.svg" alt="" className={styles.img} />
       </div>
         <div className={styles.icon_div}>
             <div className={styles.eachBox}>
               <div><img src={icon3} alt="" className={styles.mobIcon}/></div>
               <div className={styles.iconPara}>Get Real-time <br /> Updates</div>              
             </div>
             <div className={styles.eachBox}>
               <div><img src={icon1} alt="" className={styles.mobIcon}/></div>
               <div className={styles.iconPara}>Allocate resources <br /> Effectively </div>
             </div>
             <div className={styles.eachBox}>
               <div><img src={icon2} alt="" className={styles.mobIcon}/></div>
               <div className={styles.iconPara}>Schedule tasks & <br /> keep track of it.</div>
             </div>
           </div>
       </div>
     
     </div>
     </div>
   
   </>
  )
}

export default ProductSec4