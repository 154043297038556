import React from "react";
import styles from "./Common.module.css";
import Employe from "./Images/Employe.jpg"

const CommonTestimonial = ({ isDesktop }) => {
  const data = [
    {
      name: "Austin Knight",
      desigFirst: "Project Manager",
      desigLast:"Cradle Design",
      image:
        "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/test1.svg",
      desc: "iDesign Market has been a game-changer for my business. Their platform helps me stay connected with both my team members and clients. ",
    },
    {
      name: "Mayank Aggarwal",
      desigFirst: "Architect",
      desigLast:"Rajiv Associates",
      image:`${Employe}` ,
      desc: "iDesign is a very user-friendly platform. It is very easy for me to track things, review orders, manage hierarchy and even maintain accounts. Love it!",
    },
    {
      name: "Tanya Khanduja",
      desigFirst: "Interior Designer",
      desigLast:"Interia",
      image:
      "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/test3.svg",
      desc: "Its fairly affordable, easy to use and has all the tools required to manage my interior designing business. I can deliver on time and on budget projects because now I have iDesign.",
    },
  ];
  return (
    <di className={styles?.testimonials}>
      <p className={styles?.theading}>Testimonials</p>
      <p className={styles?.tsubheading}>
        What our clients have to say about us
      </p>
      <div className={styles?.tFlex}>
        {isDesktop
          ? 
          data?.map((ele, index) => (
              <div className={styles?.tCard}>
                <p>{ele?.desc}</p>
                <div className={styles?.tBottom}>
                  <img
                    src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Stars.png"
                    alt="ratings"
                  />
                  <img width="80px" height="80px"  src={ele?.image} alt="img" className={styles?.TCPeopleImg} />
                  <p className={styles?.TCName}>{ele?.name}</p>
                  <div className={styles?.TCDesig} style={{display:"flex",alignItems:"center",gap:"6px"}}><span>{ele?.desigFirst}</span><svg xmlns="http://www.w3.org/2000/svg" width="7" height="7" viewBox="0 0 7 7" fill="none">
  <circle cx="3.5" cy="3.5" r="3.5" fill="#999999"/>
</svg><span>{ele?.desigLast}</span></div>
                </div>
              </div>
            ))
          : data?.map((ele, index) => (
              <div className={styles?.tCard} style={{flexDirection:'row', alignItems:'flex-start', minHeight:'fit-content'}}>
               <div> <img src={ele?.image} alt="img" /></div>
               <div className={styles.textContainer}>
               <p className={styles?.TCName}>{ele?.name}</p>
                  <p className={styles?.TCDesig}>{ele?.desig}</p>
                  <img
                    src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Stars.png"
                    alt="ratings"
                  />
                   <p>{ele?.desc}</p>
               </div>
              </div>
            ))}
      </div>
    </di>
  );
};

export default CommonTestimonial;
