import React, { useEffect, useState } from 'react'
import profilepicblah from './VendorImgs/profilepicblah.svg'
import suitcase from './VendorImgs/suitcase.svg'
import project from './VendorImgs/project.svg'
import whatsApp from './VendorImgs/whatsApp.svg'
import phone from './VendorImgs/phone.svg'
import { Modal, Offcanvas } from 'react-bootstrap'
import redirectDesign from './VendorImgs/redirectDesign.svg'
import SidebarListing from './SidebarListing'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProfileData } from '../Lms/Actions'
import { getToken } from '../Components/SecureChat/piservices/authService';
import axios from 'axios'

const DesignerVenMob = () => {
    const profileInfo = useSelector((state) => state.addToCartReducer.profileData);
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [data, setData] = useState([])
    const authToken = localStorage.getItem("token") ? getToken() : "";
    const [showId, setShowId] = useState();
    const [ofArr, setOfArr] = useState([])
    const [new_, setNew] = useState([[0, true], []])

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (id) =>{ 

        setShowId(id)
        setShow(true)
    };


    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);

    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = (id) =>{ 
        setShowId(id)
        setShow3(true)
    };

    const [recoVenData, setRecoVenData] = useState({
        name: "",
        number: "",
        location: ""
    })

    const handleInput = (e) => {
        let name = e.target.name;
        if (name === "number") {
            if (e.target.value.length <= 10) {
                setRecoVenData((prev) => {
                    return {
                        ...prev,
                        [e.target.name]: e.target.value
                    }
                })
            }
        }
        else {
            setRecoVenData((prev) => {
                return {
                    ...prev,
                    [e.target.name]: e.target.value
                }
            })
        }
    }

    useEffect(() => {
        dispatch(fetchProfileData(authToken))
    }, [])

    const exceptThisSymbols = ["e", "E", "+", "-", "."];

    const handleSubmit = async(e) => {
        e.preventDefault()
        setShow(false)
        const payload = {
            name: recoVenData.name,
            phoneNumber: recoVenData.number,
            location: recoVenData.location
        }
        const res = await axios.post("https://pro-api.idesign.market/api/submitVendorDetails", payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authToken
            }
        }).then((res) => {
            console.log(res)
        }).catch(err => console.warn(err))
    }

    useEffect(async () => {
        const res = await axios.get("https://pro-api.idesign.market/api/listVendors", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authToken
            }
        }).then((res) => {
            // console.log(res)
            setData(res?.data?.data)
        }).catch(err => console.warn(err))
    }, [])

    const handleShow2 = (id) => {
        setShowId(id)
        setShow2(true)
    };

    // useEffect(() => {
    //     const b = data?.data?.filter((currElem) => {
    //         return currElem._id == showId
    //     })
    //     setOfArr(b)
    // }, [showId])

    // console.log(d)

    useEffect(async () => {
        const res = await axios.get(`https://pro-api.idesign.market/api/listVendors?designerId=${showId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authToken
            }
        }).then((res) => {
            // console.log(Object.entries(res?.data?.data?.data[0]?.contractorVendorFees))
            setOfArr(res?.data?.data?.data[0])
        }).catch(err => console.warn(err))
    }, [showId])

    return (
        <div className='d-block d-md-none' style={{ background: "white" }}>
            <Modal
                show={show2}
                onHide={handleClose2}
                centered>
                <Modal.Header className="p-0" closeButton style={{ margin: "16px 16px 0 16px" }}>
                    <Modal.Title style={{ borderBottom: "1px solid #DFDFDF", width: "100%", fontSize: "18px" }}> Vendor Details</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0" style={{ margin: "16px" }}>
                    <form style={{ marginBottom: "24px", display: "flex", flexDirection: "column", width: "91%" }} onSubmit={handleSubmit}>
                        <strong style={{fontWeight: "500" }}>Name</strong>
                        <input className='mb-3 input-blah' name="name" value={recoVenData.name} onChange={handleInput} type="text" placeholder='For eg. - Dikshant Negi' required onKeyDown={(e) =>
                            exceptThisSymbols.includes(e.key) && e.preventDefault()} />
                        <strong style={{ fontWeight: "500" }} >Contact No.</strong>
                        <input className='mb-3 input-blah' name="number" value={recoVenData.number} onChange={handleInput} type="number" placeholder='For eg. - 98xxxxxx69' required />
                        <strong style={{ fontWeight: "500" }}>Location</strong>
                        <input className='mb-3 input-blah' type="text" value={recoVenData.location} onChange={handleInput} name="location" placeholder='For eg. - near Palam' required />
                        <button className="gstModalWebButton">
                            Confirm
                        </button>
                    </form>
                </Modal.Body>
            </Modal>
            <Modal show={show3} onHide={handleClose3} centered size="md">
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className='d-flex flex-column p-4'>
                    Phone Number:
                    {ofArr && ofArr?.phoneNumber}
                </Modal.Body>
            </Modal>
            {ofArr && <Offcanvas show={show} onHide={handleClose} placement="bottom" style={{ background: "white", height: "100vh" }}>
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body >
                    <div className="d-flex flex-column w-100" >
                        <div className="d-flex w-100 mx-3" >
                            <p className="des-ven-p d-flex flex-column">{ofArr?.companyName}<span className="des-ven-s">{ofArr?.city}</span></p>
                            <a target="_blank" href={`https://www.idesign.market/find-professionals/contractors-in-${ofArr?.city?.toLowerCase()}/${ofArr?.companyName?.toLowerCase()}`}> <img src={redirectDesign} alt="" className="ven-gm mx-2" style={{ marginBottom: "auto", width: "25px", height: "25px" }} /></a>
                        </div>
                        <div className="d-flex mx-3 mb-2">
                            <img style={{ height: "35px", width: "35px" }} src={phone} alt="" onClick={() => handleShow3(ofArr?._id)} />
                            <a href={`https://wa.me/${ofArr?.phoneNumber}`} target="_blank"> <img className='mx-2' style={{ height: "35px", width: "35px" }} src={whatsApp} alt="" /> </a>
                        </div>
                        <p className="des-ven-p2 p-1" style={{ borderBottom: "1px solid #DFDFDF", width: "90%", textAlign: "left" }}>Rate List</p>
                        <div className="d-flex flex-column px-4">
                            <div className="d-flex flex-column">
                                <div className="d-flex justify-content-between ">
                                    <p className="r-sno1">S No.</p>
                                    <p className="r-sno2" style={{
                                        position: "relative",
                                        left: "-3.1rem"}}>Rate List</p>
                                    <p className="r-sno3 ">Unit</p>
                                    <p className="r-sno4 ">Price</p>
                                </div>
                                <div className="d-flex flex-column rate_main3">
                                    {ofArr?.contractorVendorFees && Object.entries(ofArr?.contractorVendorFees).map((item, index) => (<div className="d-flex justify-content-between ">
                                        <p className='r_false3' style={{ width: "9%" }}> {index+1} </p>
                                        <p className="r_false3" style={{ width: "39%" }}>{item[1]?.name}</p>
                                        <p className='r_false3'> {item[1]?.unit} </p>
                                        <p className="r_input6 mx-3" style={{ border: "none" }}>₹ {item[1]?.rate?.toLocaleString('en-US')}</p>
                                    </div>))}
                                </div>
                            </div>
                        </div>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>}
            <SidebarListing />
            <div className="d-flex flex-column p-2" >
                <h2 className="ven_head m-4" style={{ fontSize: "24px" }}>Recommended Page in {profileInfo[0]?.data?.data?.city}</h2>
                <div className="d-flex mx-3">
                    <button className="ven-vlah-vlah mx-2" style={{height: "40px", fontSize: "12px"}} onClick={() => navigate("/vendor-page")}>Get your listing here</button>
                    <button className="ven-vlah-vlah" style={{ height: "40px", fontSize: "12px" }} onClick={handleShow2}>Recommend a Vendor</button>
                </div>
                {data?.data?.length>0 ? 
                <div className="d-flex flex-column p-4 w-100">
                    {data?.data?.map((item, index) => (
                        <div className= "des-ven-start d-flex flex-column w-100 p-2 mb-3" id={index}>
                        <div className="d-flex">
                                <img src={item?.coverImage?.original ? item?.coverImage?.original : profilepicblah} style={{
                                    width:" 60px",
                                    height: "60px"
                                }} alt="" className='ven-img' />
                                <p className="des-ven-p d-flex flex-column p-2">{item?.companyName}<span className="des-ven-s">{item?.city}</span></p>
                        </div>
                        <div className="d-flex flex-column mx-2 align-self-center">
                            <div className="d-flex">
                                    <img style={{ height: "16px", width: "16px" }} src={suitcase} alt="" />
                                    <span className="des-ven-s mx-2" style={{ fontSize: "12px" }}><strong style={{ color: "black", fontWeight: "500" }}>{item?.workExperience}</strong> years of experience </span>
                            </div>
                            <div className="d-flex ">
                                    <img style={{ height: "16px", width: "16px" }} src={project} alt="" />
                                    <span className="des-ven-s mx-2" style={{fontSize: "12px"}}><strong style={{ color: "black", fontWeight: "500" }}>{item?.NumberOfProjectsDone}</strong> projects completed </span>
                            </div>
                        </div>
                        <div className="d-flex mt-3" >
                            <p className="mob-ven-blah" style={{position: "relative", top: "0.5rem" }} onClick={()=>handleShow(item._id)}>Check Rate List {">>>"}</p>
                            <div className="d-flex" style={{ marginLeft: "auto", marginTop: "auto" }}>
                                    <img className='mx-1' style={{ height: "35px", width: "35px" }} src={phone} alt="" onClick={() => handleShow3(item._id)} />
                                    <a href={`https://wa.me/${item?.phoneNumber}`} target="_blank"><img className='mx-1' style={{ height: "35px", width: "35px" }} src={whatsApp} alt="" /></a>
                            </div>
                        </div>
                    </div>))}
                </div> 
                :
                 <div className='no-content'> No vendors in your city</div>}
            </div>
        </div>
    )
}

export default DesignerVenMob