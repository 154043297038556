import React from 'react'

const NavbarNew = () => {
  return (
    <>
     <div style={{height:"10vh",border:'1px solid black',textAlign:'center'}}>
        navbar
     </div>
    </>
  )
}

export default NavbarNew