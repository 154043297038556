import React from "react";
import styles from "../../onBoardingCss/DashboardCssNew/updateTab.module.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { constants } from "../../../../../../constants";

const UpdatesTab = ({projectId}) => {
  const [notify, setnotify] = useState([]);
  const getUpdates = async () => {
    await axios
      .get(
        `${constants.notificationAPI}/api/notifications/get-notifications-By-ProjectID?projectId=6458a1e493e795b9877d3588`
      )
      .then(function (response){
        console.log(response?.data);
        setnotify(response?.data);
      })
      .catch(function (error){
        console.log(error);
      });
  };

  useEffect(() => {
    getUpdates();
  }, []);

  return (
    <>
      <div className={styles.container}>
        {notify.length>0? notify?.slice(0, 4)?.map((curelem) => {
          let date = new Date(curelem?.updatedAt);
          let hour = date.getHours();

          return (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  lignItems: "center",
                  padding: "0.5rem 0 0.5rem 0",
                  borderBottom: "1px solid #E6E6E6",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  {curelem?.notification.includes("uploaded") ? (
                    <img
                      src="https://idesign-office.s3.amazonaws.com/Group%202609555.svg"
                      alt=""
                    />
                  ) : curelem?.notification.includes("created") ? (
                    <img
                      src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group%202609557.svg"
                      alt=""
                    />
                  ) : curelem?.notification.includes("comment") ? (
                    <img
                      src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group%202609556.svg"
                      alt=""
                    />
                  ) : (
                    <img
                      src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group%202609556.svg"
                      alt=""
                    />
                  )}

                  <div>{curelem?.notification}</div>
                </div>
                <div style={{ padding: "0.5rem 0 0.5rem 0" }}>
                  <div style={{ fontSize: "14px" }}>{hour} hr ago</div>
                </div>
              </div>
            </>
          );
        }):<div style={{paddingTop:"1rem",paddingBottom:"1rem",paddingLeft:"0.5rem",fontWeight:"600",fontSize:"18px"}}>No Updates</div>}
       
      </div>
    </>
  );
};

export default UpdatesTab;
