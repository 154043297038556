import React from "react";
import Modal from "react-bootstrap/Modal";
import styles from "./myprofileCss/myvertical.module.css";

const MyVerticallyCenteredModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ paddingLeft: "1rem" }}>Add Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ paddingLeft: "1rem" }}>
            <div>
              <div className={styles.heading}>First Name</div>
              <input
                type="text"
                placeholder="Add Member"
                className={styles.inputs}
              />
            </div>
            <div>
              <div className={styles.heading}>Last Name</div>
              <input
                type="text"
                placeholder="Add Member"
                className={styles.inputs}
              />
            </div>
            <div>
              <div className={styles.heading}>Email Address</div>
              <input
                type="text"
                placeholder="Add Member"
                className={styles.inputs3}
              />
            </div>
            <div className={styles.bottomText}>
              <input type="checkbox" />  Allow approval of drawings and concepts
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div style={{display:'flex',justifyContent:"flex-start",width:"100%",gap:"1rem",paddingLeft:'1rem',paddingTop:'1rem'}}>
            <button className={styles.addBtn}>Add Member</button>
            <button className={styles.cancelBtn}>Cancel</button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MyVerticallyCenteredModal;
