import React, { useRef, useEffect } from "react";
import styles from "../onBoardingCss/companydetail.module.css";
import main from "../footerImg/task/task.png";
import icon from "../footerImg/task/Vector.png";
import icon1 from "../footerImg/task/Vector.png";
import icon2 from "../footerImg/reports/Vector.png";
import { AiOutlineArrowLeft } from "react-icons/ai";
import logo from "../footerImg/task/Frame 2611544.svg";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import idesign from "../../NewLandingPage/imagesAnj/logo.png";
import axios from "axios";
import { Dropdown } from "react-bootstrap";
import { TextField, MenuItem, FormControl, InputLabel, Select, useMediaQuery } from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import { Country } from "country-state-city";
import { toast } from "react-hot-toast";
import { constants } from "../../../../../constants";
import { MdMyLocation } from "react-icons/md";
import Map from "./Map";

const numberArray = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,100}$/;
const checkIfHasOnlyNumbers = (value) => {
  if (!isNaN(value) || value === "") {
    return true;
  } else {
    return false;
  }
};

const CompanyDetails = () => {
  const isMobile = useMediaQuery('(max-width:767px)');
  const [countries, setCountries] = useState([]);
  const [phoneCode, setPhoneCode] = useState("us");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("US");
  const [currency, setCurrency] = useState("");
  const [currencyTextField, setCurrencyTextField]=useState("")
  const [particularCountryData, setParticularCountryData] = useState({});

  const [errormsg, setErrorMsg] = useState("");
  const [dropDown, setdropDown] = useState([]);

  const navigate = useNavigate();
  const uploadRef = useRef(null);

  const [imageData, setImageData] = useState();
  const [imageUrl, setImageUrl] = useState();


  const [countryDataApi, setCountryDataApi] = useState(null);
  const [countryFlag, setCountryFlag] =useState();
  const [currencySymbol, setCurrencySymbol] = useState();
  const [currencyData, setCurrencyData] = useState({
    currencySymbol: "",
    currencyName: "",
    currencyFlag: ""
  });

  const fetchData = async (code) => {
    try {
      const response = await axios.get(
        `https://restcountries.com/v3.1/alpha/${code}`
      );
      setCountryDataApi(response.data);
      setCountryFlag(response.data[0].flags.png)
      setCurrencySymbol(Object.values(response.data[0].currencies)[0].symbol)
    } catch (error) {
      console.error("Error fetching country information:", error.message);
    }
  };

  const selectCompanyType = (ct) => {
    if (!dropDown.includes(ct)) {
      setdropDown((prev) => {
        return [...prev, ct];
      });
    }
  };

  const uploadImage = async (event) => {
    console.log(event.target.files);
    setImageData(event.target.files[0]);

    let imgs = new FormData();
    imgs.append("bucketName", "idesignchat");
    imgs.append("files", event.target.files[0]);
    await axios
      .put("https://s3-service.idesign.market/api/upload", imgs)
      .then(function (response) {
        setImageUrl(response.data.locations[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // const [input, setinput] = useState({
  //   compName: "",
  //   compType: "",
  //   gst: "",
  //   address: "",
  // });

  const [input, setinput] = useState({
    companyName: "",
    email: "",
    address: "",
    country: "",
    city:"",
    pincode: "",
    gstNo: "",
    number: "",
    currency: "",
  });

  const [error, setError] = useState({
    companyName: false,
    email: false,
    country: false,
    city: false,
    pincode: false,
    number: false,
    currency: false,
  });
  const [errorMsgs, setErrorMsgs] = useState({
    companyNameErr: "",
    cityNameErr: "",
    pinCodeErr:"",
  })
  const authTok = localStorage.getItem("token")
    ? `Bearer ${localStorage.getItem("token")}`
    : "";

  const postData = async () => {
    // const payload = new FormData();
    // payload.append("companyName", input.compName);
    // payload.append("companyType", dropDown);
    // payload.append("gstNumber", input.gst);
    // payload.append("companyLogo", imageUrl);
    // payload.append("address", input.address);

    // payload.append("companyName", input.companyName);
    // payload.append("email", input.email);
    // payload.append("address", input.address);
    // payload.append("city", input.city);
    // payload.append("pincode", input.pincode);
    // payload.append("gstNo", input.gstNo);
    // payload.append("contact", input.number);
    // payload.append("country", input.country);
    // payload.append("currency", input.currency);
    const data={
      "companyName":input.companyName,
      "email": input.email,
      "address": input.address,
      "city": input.city,
      "pincode": input.pincode,
      "gstNo": input.gstNo,
      "contact": input.number,
      "country": input.country,
      "companyLocation": [
        {
          latitude: localStorage.getItem("latitude"),
          longitude: localStorage.getItem("longitude"),
        }
      ]
    }
    await axios
      .post(`${constants.B2B_BASE}/b2b/api/enterprise/edit-company-settings?userId=${localStorage.getItem("userId")}`,
       data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: authTok,
        },
      })
      .then(function (res) {
        window.location.assign(`${constants.PRO_URL}team-onboard`)
        // window.location.assign(`${constants.PRO_URL}team-onboard?userId=${localStorage.getItem("userId")}&token=${localStorage.getItem("token")}`)
      })
      .catch(function (error) {
        console.log(error);
      });
  };   
  
  const handleInput = (event) => {
    const { name, value } = event.target;
    const alphaValue = value.replace(/[^a-zA-Z]/g, '');
    const numValue=value.replace(/[^0-9]/g, "");

    if (name === "pincode") {
      if(input.country =="India"){
        if (checkIfHasOnlyNumbers(value)) {
          if (value.length < 7) {
          setinput((prev) => {
            return {
              ...prev,
              pincode: numValue,
            };
          });
          setError((prev) => {
            return {
              ...prev,
              pincode: false,
            };
          });
        }
      }
      }
    else{
      if (checkIfHasOnlyNumbers(value)) {
        if (value.length < 10) {
        setinput((prev) => {
          return {
            ...prev,
            pincode: numValue,
          };
        });
        setError((prev) => {
          return {
            ...prev,
            companyName: false,
          };
        });
      }
    }
    }
    }

    if (name === "gstNo") {
      const inputValue = value.toUpperCase(); 
      const alphaNumericRegex = /^[a-zA-Z0-9]*$/
        if (inputValue.length < 17 && alphaNumericRegex.test(inputValue)) {
          setinput((prev) => {
            return {
              ...prev,
              gstNo: inputValue,
            };
          });
        }
    }

    if (name === "companyName") {
      if (value.length < 50) {
        setinput((prev) => {
          return {
            ...prev,
            companyName: alphaValue.charAt(0).toUpperCase() + alphaValue.slice(1),
          };
        });
        setError((prev) => {
          return {
            ...prev,
            companyName: false,
          };
        });
    }
    if(value.length>=50){
      setErrorMsgs((prev) => {
        return { ...prev, companyNameErr: "Max character limit has been reached" };
      });
    }
    else if(value.length<=50){
      setErrorMsgs((prev) => {
        return { ...prev, companyNameErr: "" };
      });
    }   
  }
    if (name === "address") {
        const words = value.trim().split(/\s+/);
        if (words.length <= 24) {
          setinput((prev) => {
                return {
                  ...prev,
                  address: value.charAt(0).toUpperCase() + value.slice(1),
                };
              });
    }
    }

    if (name === "city") {
      let check = numberArray.some((elem) => {
        return value.includes(elem);
      });
      if (!check) {
        if (value.length < 32) {
          setinput((prev) => {
            return {
              ...prev,
              city: value.charAt(0).toUpperCase() + value.slice(1),
            };
          });
          setError((prev) => {
            return {
              ...prev,
              city: false,
            };
          });
        }
      }
    }
    // if (name === "email") {
    //   setinput((prev) => {
    //     return {
    //       ...prev,
    //       email: value,
    //     };
    //   });
    // }
    if (name === "email") {
      let x = emailRegex.test(value);
      if (!x) {
        setErrorMsg("Invalid Email");
      } else{
        setErrorMsg("");
      }
      setinput((prev) => {
        return {
          ...prev,
          email: value,
        };
      });
      setError((prev) => {
        return {
          ...prev,
          email: false,
        };
      });
      }
      
      if (name === "number") {
        setinput((prev) => {
          return {
            ...prev,
            number: value,
          };
        });
        setError((prev) => {
          return {
            ...prev,
            number: false,
          };
        });
       }
  }

    useEffect(() => {
    const fetchCountries = async () => {
      const countryData = await Country.getAllCountries();
      setCountries(countryData);
      setSelectedCountry(localStorage.getItem("countryCode"));
      getParticularCountryData(localStorage.getItem("countryCode"));
      fetchData(localStorage.getItem("countryCode"));
      
    };
    fetchCountries();
    setinput((prev) => {
      return {
        ...prev,
        city: localStorage.getItem("cityName") == "undefined" ? "" :  localStorage.getItem("cityName") ,
        pincode: localStorage.getItem("pincodeValue") ? localStorage.getItem("pincodeValue") : "",
      };
    });
  }, []);

  const getParticularCountryData = async (countryCode) => {
    const data = await Country.getCountryByCode(countryCode);
     setinput((prev) => {
      return {
        ...prev,
        country: data?.name,
      };
    });
    setError((prev) => {
      return {
        ...prev,
        country: false,
      };
    });
    setCurrency(data?.currency)
    setPhoneCode(data?.isoCode.toLowerCase())
    setParticularCountryData(data);
    fetchData(countryCode);
  };
  const handleCountryChange = async (countryCode) => {
    setSelectedCountry(countryCode);
  };

  // company errror
  const [activeErr, setactiveErr] = useState("notActive");
  const companyError = () => {
    if (input.compName === "") {
      setactiveErr("Active");
    } else {
      setactiveErr("");
    }
    if (
      input.compName !== "" &&
      input.compType !== "" &&
      input.gst !== "" &&
      input.address !== ""
    ) {
      navigate("/complete");
    }
  };

  // company type error
  const [compTypeErr, setcompTypeErr] = useState("notActive");
  const companyTypeError = () => {
    if (dropDown.length === 0) {
      setcompTypeErr("Active");
    } else {
      setcompTypeErr("");
    }
    if (
      input.compName !== "" &&
      dropDown !== "" &&
      input.gst !== "" &&
      input.address !== ""
    ) {
      navigate("/complete");
    }
  };
  // company logo
  const [logoErr, setlogoErr] = useState("notActive");
  const companyLogoError = () => {
    if (imageUrl === undefined) {
      setlogoErr("Active");
    } else {
      setlogoErr("");
    }
    if (
      input.compName !== "" &&
      dropDown !== "" &&
      input.gst !== "" &&
      input.address !== "" &&
      imageUrl !== undefined
    ) {
      navigate("/complete");
    }
  };
  // gst error
  const [gstErr, setgstErr] = useState("notActive");
  const GStError = () => {
    if (input.gst === "") {
      setgstErr("Active");
    } else {
      setgstErr("");
    }
    if (
      input.compName !== "" &&
      input.compType !== "" &&
      input.gst !== "" &&
      input.address !== ""
    ) {
      navigate("/complete");
    }
  };

  // address error
  const [addressErr, setaddressErr] = useState("notActive");
  const AddError = () => {
    if (input.address === "") {
      setaddressErr("Active");
    } else {
      setaddressErr("");
    }
    if (
      input.compName !== "" &&
      input.compType !== "" &&
      input.gst !== "" &&
      input.address !== ""
    ) {
      navigate("/complete");
    }
  };

  // console.log(imageData)

  const [valueFromChild, setValueFromChild] = useState("");

  const [openMap, setopenMap] = useState(false);
  const handleValueFromChild = (value) => {
    setValueFromChild(value);
  };

  return (
    <>
      <Map open={openMap} close={() => setopenMap(false)}
      //  placeLocation={profileData?.CompanySettingDetails?.location}
      placeLocation={localStorage.getItem("city")}
       sendValueToParent={handleValueFromChild} />

      <div className={styles.container}>
        <div className={styles.first_contain}>
          {/* <img src={idesign} alt="" className={styles.idesignn}/> */}
          <div className={styles.logoImgContainer}><img src={idesign} alt="" className={styles.logoImg} /></div>
        <Carousel
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "85%",
              margin:"auto auto"
            }}
            nextIcon=""
            nextLabel=""
            prevIcon=""
            prevLabel=""
          >
            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
            <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                <div className={styles.insideCaraouselItem}>
                  <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:"0.75rem"}}><img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group.svg" alt="" className={styles.miniImg} />
                  <div className={styles.heading}>FILE MANAGER</div></div>
                  <div className={styles.para}>Seamlessly upload files and glide through a structured file hierarchy with ease, ensuring your documents are always organised and accessible.</div>
                </div>
              </div>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-quotation.s3.amazonaws.com/NO_COMPANYNAME/Edit%20location%20%26%20description.png" alt="First slide" className={styles.imgg} />
              </div>
              </div>
              <div className={styles.dotsContainer}>
                   <div className={styles.activeDots} ></div>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.notActiveDots}  ></div>
                </div>
            </Carousel.Item>

            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
            <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                <div className={styles.insideCaraouselItem}>
                <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:"0.75rem"}}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group1.svg" alt="" className={styles.miniImg} />
                  <div className={styles.heading}>TASK MANAGEMENT</div>
                  </div>
                  <div className={styles.para}>
                  Monitor team progress with Task Manager screen and effortlessly create, assign and update tasks. Also generate detailed reports for informed decision-making.
                  </div>
                </div>
              </div>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Table%20v2-1.png" alt="Second slide" className={styles.imgg} />
              </div>
              </div>
              <div className={styles.dotsContainer}>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.activeDots} ></div>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.notActiveDots}  ></div>
                </div>
            </Carousel.Item>
            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
            <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem" }}>
                <div className={styles.insideCaraouselItem}>
                <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:"0.75rem"}}>
                <img
                  src="https://idesign-office.s3.ap-south-1.amazonaws.com/CurrencyDollarSimple.svg"
                  alt="" className={styles.miniImg}
                />
                  <div className={styles.heading}>Quotation</div>
                  </div>
                  <div className={styles.para}>
                  Effortlessly create quotations from an item library. Compare and manage quotations efficiently. Streamline budget creation and purchase orders.
                  </div>
                </div>
              </div>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Create%20Quotation.png" alt="Third slide" className={styles.imgg} />
              </div>
              </div>
              <div className={styles.dotsContainer}>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.activeDots} ></div>
                  <div className={styles.notActiveDots}  ></div>
                </div>
            </Carousel.Item>

            <Carousel.Item className={styles.CarouselItem}>
            <div className={styles.flexForCarousel}>
            <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                <div className={styles.insideCaraouselItem}>
                <div style={{display:"flex", flexDirection:"row", alignItems:"center", gap:"0.75rem"}}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/LineSegments.svg" alt="" className={styles.miniImg} />
                  <div className={styles.heading}>Timeline</div>
                  </div>
                  <div className={styles.para}>
                  Easily create and allocate hours with Timesheets. Track project progress with multiple views. Create detailed timelines with predecessors, subtasks, and checklists.
                  </div>
                </div>
              </div>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/For%20onb%201.png" alt="Third slide" className={styles.imgg} />
              </div>
              </div>
              <div className={styles.dotsContainer}>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.notActiveDots}  ></div>
                  <div className={styles.activeDots} ></div>
                </div>
            </Carousel.Item>
          </Carousel>  

        </div>
       
        <div className={styles.second_contain}>
          <div className={styles.PersonalContent_div}>
            <div className={styles.companyDetailsDiv} >
              <div className={styles.personal_head}>Company Details</div>
              {/* <button className={styles.backBtn} onClick={() =>  navigate("/personal")}>
                <AiOutlineArrowLeft className={styles.backArrow} />
                Go back
              </button> */}
              {/* <div className={styles.skipShowHide}>
              <a href={"https://www.idesign.market/"} className={styles.skip} style={{ textDecoration: "none" }} onClick={()=>localStorage.removeItem("cameFrom")}>
              Skip
            </a></div> */}
            </div>

            {/* all inputs div */}
            <div style={{ display: "flex", flexDirection: "column", gap: "2.4rem" }}>
              <div>
              <div style={{display:"flex", flexDirection:"column", gap:"20px", width:"100%"}}>
                <TextField
                  id="outlined-basic"
                  error={error.companyName}
                  label="Company Name*"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="companyName"
                  value={input.companyName}
                  onChange={handleInput}
                  fullWidth
                  InputProps={{ style: {borderRadius: "12px"}}}
                  // inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                />
             {(errorMsgs.companyNameErr!=="")?<div style={{color:'red', fontSize:"10px"}}>{errorMsgs.companyNameErr}</div>:""}
                </div>
              </div>

              <div style={{display:"flex", flexDirection:"column", gap:"20px"}}>
                <TextField
                  id="outlined-basic"
                  error={error.email}
                  label="Company Email*"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="email"
                  value={input.email}
                  onChange={handleInput}
                  fullWidth
                  style={{ borderRadius: "12px" }}
                  InputProps={{ style: { borderRadius: "12px" }, inputMode: "text" }}
                  // inputProps={{  }}
                />
              {(errormsg.length>0)?<div style={{color:'red', fontSize:"10px"}}>{errormsg}</div>:""}
              </div>

              <div style={{position:"relative", marginTop: (errormsg.length > 0) ? "-30px" : "0px" }}>
                <TextField
                  id="outlined-basic"
                  // error={error.address}
                  label="Set Location"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="address"
                  // value={input.address}
                  value={valueFromChild}
                  onChange={handleInput}
                  style={{ borderRadius: "12px", width: "100%", borderRight:"none" }}
                  // InputProps={{ style: { borderRadius: "12px" } }}
                  InputProps={{ style: { borderRadius: "12px", paddingRight: "1rem"  }, inputMode: "text" }}
                  // inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                />
                  <div style={{ position: "absolute", right: "1.5rem", top: "0.9rem" }}>
                  <MdMyLocation 
                  onClick={() => setopenMap(true)}
                   style={{ color: "#0084EF", cursor: "pointer" }} />
                </div>
              </div>

              {/* <div style={{ display: "flex", gap: "1rem", position: "relative" }}> */}
              <div style={{ display: "flex", gap: "1rem" }}>      
      <FormControl sx={{borderRadius: "12px", width:"50%" }} error={error.country} >
                          <InputLabel id="demo-simple-select-helper-label" >Country*</InputLabel>
                          <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            value={selectedCountry} 
                            label="Country"
                             onChange={(e) => {
                                      handleCountryChange(e.target.value);
                                      getParticularCountryData(e.target.value);
                                    }}
                            sx={{borderRadius: "12px" }}
                          >
                              {/* <MenuItem value="">
                              <em>None</em>
                            </MenuItem> */}
                                        {countries.map((country) => (
                                  <MenuItem key={country.isoCode} value={country.isoCode}>
                                    {country.name}
                                  </MenuItem>
                                     ))}
                          </Select>                        
                         
                        </FormControl>
                        <div style={{position:"relative", width:"50%"}}>
                 <TextField
                  id="outlined-basic"
                  // error={error.gst}
                  label="Currency*"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="currency"
                  // value={input.currency}
                  // value={currency}
                  value={`${currency} (${currencySymbol})`}
                  onChange={handleInput}
                  readOnly
                  style={{ borderRadius: "12px", width: "100%",  }}
                  //   InputLabelProps={{ shrink: true }} // Removes mm/dd/yy placeholder
                  InputProps={{ placeholder: "", style: { borderRadius: "12px", position:"relative", paddingLeft: "50px" },  readOnly: true, }}
                  // inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                />
                  <div style={{ position: "absolute", left: "0.5rem", top: "50%", transform: "translateY(-50%)" }}>
                <img src={countryFlag} alt="Currency Image" style={{marginLeft:"10px", width: "1.8rem", height:"1.2rem", borderRadius:"0.1rem" }} />
              </div>
                 </div>
                </div>
              <div style={{ display: "flex", gap: "1rem", marginTop:"-20px" }}>

                <TextField
                  id="outlined-basic"
                  label="City*"
                  error={error.city}
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="city"
                  value={input.city}
                  onChange={handleInput}
                  // style={{ borderRadius: "12px", width: "50%" }}
                  sx={{ width: isMobile?"50%":"50%"}}
                  InputProps={{ style: { borderRadius: "12px" }, inputMode: "text" }}
                  // inputProps={{style:{ padding:isMobile ? "18px 20px":"18px 24px", fontSize:isMobile?"14px":"18px"}}}
                  autoComplete="off"
                />
                 
                <TextField
                  id="outlined-basic"
                  error={error.pincode}
                  label={input.country=="India" ? "Pin Code*" : "Zip Code*"}
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="pincode"
                  value={input.pincode}
                  onChange={handleInput}
                  style={{ borderRadius: "12px",  width: isMobile?"50%":"50%" }}
                  InputProps={{ style: { borderRadius: "12px" }, inputMode: "text" }}
                  sx={{ width: "50%"}}
                  autoComplete="off"
                />
              </div>
            
                <div>
                <TextField
                  id="outlined-basic"
                  // error={error.gstNo}
                  label="GST NO."
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="gstNo"
                  value={input.gstNo}
                  onChange={handleInput}
                  style={{ borderRadius: "12px", width: "100%" }}
                  //   InputLabelProps={{ shrink: true }} // Removes mm/dd/yy placeholder
                  InputProps={{ placeholder: "", style: { borderRadius: "12px" } }}
                />
              </div>
              <div>
                <MuiPhoneNumber
                error={error.number}
                  style={{ width: "100%" }}
                  variant="outlined"
                  label="Contact*"
                  value={input.number}
                  onChange={(newVal) => handleInput({ target: { name: "number", value: newVal } })}
                  defaultCountry={phoneCode}
                  InputProps={{ placeholder: "", style: { borderRadius: "12px" } }}
                />
              </div>
            </div>
            <div >
              <button
                className={styles.nextBtn}
                onClick={() => {

                 Object.keys(input).forEach((curElem) => {
                    if (input[curElem]) {
                      setError((prev) => {
                        return {
                          ...prev,
                          [curElem]: false,
                        };
                      });
                    } else {
                      setError((prev) => {
                        return {
                          ...prev,
                          [curElem]: true,
                        };
                      });
                    }
                  });

                  // postData();
                  if (input.companyName !== "" && input.email !== "" && input.city !== "" && input.country !== "" && input.pincode !== ""  && input.number !== "") {
                    // postData();
                    if (errormsg !== "") {
                        toast.error(errormsg);
                      }else{
                        localStorage.removeItem("pincodeValue");
                        localStorage.removeItem("cityName");
                        localStorage.removeItem("countryCode");
                        localStorage.removeItem("latitude");
                        localStorage.removeItem("longitude");
                        localStorage.removeItem("city");
                        postData();
                      }
                  }
                }}
              >
                Next
              </button>
              <div></div>
            </div>
            {/* <div className={styles.skipBtnInLast}>
            <a onClick={()=>localStorage.removeItem("cameFrom")} href="https://www.idesign.market/" className={styles.skip} style={{ textDecoration: "none" }}>
              Skip Complete Profile
            </a>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDetails;