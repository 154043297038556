import React from "react";
import DashBoardTop from "./DashBoardTop";
import DashBoardMiddle from "./DashBoardMiddle";
// import DashBoardLast from "./DashBoardLast";

const AllDashBoard = () => {
  return (
    <>
      <div
        style={{
          width: "80vw",
          paddingLeft: " 2rem",
          paddingRight: "2rem",
          backgroundColor:"#F7F7F7",
          overflowY:"scroll",
          // border:"2px solid red",
          height:"90vh",

        }}
      >
        <DashBoardTop />
        <DashBoardMiddle/>
        {/* <DashBoardLast/> */}
      </div>
    </>
  );
};

export default AllDashBoard;
