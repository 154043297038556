import React from "react";
import styles from "./myprofileCss/security.module.css";

const Security = () => {
  return (
    <>
      <div className={styles.container}>
        <div>
          <div className={styles.heading}>Current Password</div>
          <input type="password" placeholder="Lightyagami@deathnote.com" className={styles.inputs}/>
        </div>

        <div>
          <div className={styles.heading}>New Password</div>
          <input type="password" placeholder="Lightyagami@deathnote.com" className={styles.inputs}/>
        </div>

        <div>
          <div className={styles.heading}>Confirm Password</div>
          <input type="password" placeholder="Lightyagami@deathnote.com"className={styles.inputs} />
        </div>
      </div>
    </>
  );
};

export default Security;
