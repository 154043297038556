import React, { useEffect, useRef, useState } from "react";
import styles from "./Header.module.css";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const [hoveredItem, setHoveredItem] = useState(null);
  const [ddToshow, setDdToShow] = useState(null);
  const [selectedItem, setSelectedItem] = useState("");
  const [subSelectedItem, setSubSelectedItem] = useState("");
const [showTabs, setShowTabs] = useState(true)

useEffect(()=>{
if(window.location.href.includes("privacy") || window.location.href.includes("terms")){
  setShowTabs(false) 
}
},[window.location.href])

  const wwsmenu = [
    {
      icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/House.svg",
      title: "Interior Designers",
      desc: "Manage design to construction with intuitive features for the project journey",
      route: "/new/design",
      id:"wws1"
    },
    {
      icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/CompassTool.svg",
      title: "Architects",
      desc: "Streamline your design process to enhance efficiency and profitability",
      route: "/new/architect",
      id:"wws2"
    },
    {
      icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Hammer.svg",
      title: "Construction Companies",
      desc: "Efficiently manage projects and teams",
      route: "/new/contractor",
      id:"wws3"
    },
    {
      icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Buildings.svg",
      title: "Real Estate Developers",
      desc: "Efficiently manage projects and teams",
      route: "/new/estate",
      id:"wws4"
    },
    {
      icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/ShoppingCart.svg",
      title: "Retail companies",
      desc: "Get a complete overview of your stores with dynamic updates",
      route: "/new/retail",
      id:"wws5"
    },
  ];
  const resourcesMenu = [
    {
      icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/House.svg",
      title: "Blogs",
      desc: "Stay up to date with our latest news and features update.",
      route: "https://blogs.idesign.market/",
      id:"r1"
    },
    {
      icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/CompassTool.svg",
      title: "Case Studies",
      desc: "Explore Success Stories: Real-world Results with Our Software",
      route: "",
      id:"r2"
    },
  ];

  const featuresMenu = [
    {
      name: "Project Management",
      tools: [
        { name: "File Manager", route: "/fileManager", id:"f1" },
        { name: "Task Manager", route: "/taskManager", id:"f2" },
        { name: "Minutes of Meeting (MOM)", route: "/mom", id:"f3" },
        { name: "RFI", route: "/rfi", id:"f4" },
        { name: "Timeline & Timesheet", route: "/timelineTimesheet", id:"f5" },
        { name: "Material Request", route: "/materialRequest", id:"f6" },
      ],
      icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/Gear1.svg",
      loc: "1",
    },
    {
      name: "Cost Management",
      tools: [
        { name: "Quotation", route: "/quotation", id:"f7" },
        { name: "Accounts", route: "/account", id:"f8" },
        { name: "Vendor & PO", route: "/vendorPO", id:"f9" },
      ],
      icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/CurrencyDollar.svg",
      loc: "2",
    },
    {
      name: "Client Management",
      tools: [
        { name: "Leads", route: "/newLead", id:"f10" },
        { name: "Chat", route: "/chat", id:"f11" },
      ],
      icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/UsersThree1.svg",
      loc: "3",
    },
    {
      name: "Additional Features",
      tools: [
        { name: "Library & Templates", route: "/libraryTemplates", id:"f12" },
        { name: "Item Library & Inventory", route: "/itemLibraryInventory", id:"f13" },
        { name: "Attendance", route: "/attendance", id:"f14" },
        { name: "Company Management", route: "/companyManagement", id:"f15" },
        { name: "Team Management", route: "/teamManagement", id:"f16" },
        { name: "Native Mobile App", route: "/mobile", id:"f17" },
        { name: "Extras", route: "/extras", id:"f18" },
      ],
      icon: "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/CirclesThreePlus1.svg",
      loc: "4",
    },
  ];

  const headerRef = useRef(null);

  const handleClickOutside = (event) => {
    console.log(event,"mohit sharma")
    if (headerRef.current && !headerRef.current.contains(event.target)) {
      // Clicked outside the header, close the dropdown
      setDdToShow(0);
    }
  };


  console.log(ddToshow,"ddtshowvalue")
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []); // Run only on mount and unmount

  return (
    <div className={`${styles?.navDD} ${styles?.sticky}`} >
      <div className={styles.mainContainer}>
        <img
          src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/logoIdesign.png"
          alt="logo"
          className={styles.logo}
          style={{marginTop:"7px", cursor:"pointer", userSelect:"none"}}
          onClick={()=>navigate("/")}
        />
      { showTabs? <div className={styles.HeaderNav} ref={headerRef}>

      <div className={styles.HeaderNavItems} onClick={() => setDdToShow(0)}>
            <div style={{userSelect:"none"}}
             onClick={()=> {
              navigate("/");
              } }>Home</div>
          </div>
          <div
            className={styles.HeaderNavItems}
            onMouseEnter={() =>{
              (ddToshow != 1 ? 
                // setDdToShow(1) 
                setTimeout(() => {
                  setDdToShow(1);
              }, 0)
                :
                 setDdToShow(0)
                )
              setHoveredItem("features")}}
            onMouseLeave={() => {
              setHoveredItem(null)}}
            onClick={() => {
              navigate("/features")
            }}
          >
            <div style={{userSelect:"none"}}>Features</div>
            <img
              src={
                hoveredItem === "features"
                  ? "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/blueDown.svg"
                  : "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/down.svg"
              }
              alt="down"
              style={{
                transform: ddToshow === 1 ? "rotate(180deg)" : "none", userSelect:"none"
              }}
            />
          </div>
          <div
            className={styles.HeaderNavItems}
            // onMouseEnter={() => setHoveredItem("serve")}
            onMouseLeave={() => setHoveredItem(null)}
            onMouseEnter={() => (ddToshow != 2 ? setDdToShow(2) : setDdToShow(0))}
          >
            <div style={{userSelect:"none"}}>Who We Serve</div>
            <img 
              src={
                hoveredItem === "serve"
                  ? "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/blueDown.svg"
                  : "https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/down.svg"
              }
              alt="down"
              style={{
                transform: ddToshow === 2 ? "rotate(180deg)" : "none", userSelect:"none"
              }}
            />
          </div>
          <div className={styles.HeaderNavItems} onClick={() => setDdToShow(0)}>
            <div style={{userSelect:"none"}}
             onClick={()=> {
              localStorage.setItem("pricing", true)
              navigate("/pricing");
              } }>Pricing</div>
          </div>
         
           {/* <div className={styles.HeaderNavItems} onClick={() => setDdToShow(0)}>
            <div onClick={()=> {
              window.location.href = "https://blogs.idesign.market/"
              } }  style={{userSelect:"none"}}>Blogs</div>
          </div> */}
        </div>
        : 
        null}
        <div className={styles.btnContainer}>
          <div className={styles.signIn}>
            <div
              className={styles.signInText} style={{userSelect:"none"}}
              onClick={() =>
                window.location.assign("https://pro.idesign.market/")
              }
            >
              Sign In
            </div>
          </div>
          <div className={styles.signUp}>
            <div style={{userSelect:"none"}}
              className={styles.signUpText}
              onClick={() => navigate("/signup")}
            >
              Sign Up
            </div>
          </div>
          <div className={styles.scheduleDemoBtn}>
            <div style={{userSelect:"none"}}
              className={styles.scheduleDemoBtnText}
              onClick={() => navigate("/schedule")}
            >
              Schedule a Demo
            </div>
          </div>
        </div>
      </div>
      {(ddToshow == 1 || ddToshow == 2 || ddToshow == 3) && (
        <div className={styles?.dropdown}>
          {ddToshow == 3 ? (
            <div
              className={`${styles?.ddmenus}`}
              style={{
                padding: "2.25rem 4.12rem",
             
              }}

              onMouseLeave={handleClickOutside}

            >
              {resourcesMenu?.map((menu) => (
                <div
                  className={`${styles?.ddmenu} ${selectedItem === menu?.id ? styles.ddmenuActive : ''}`}
                  onClick={() => {
                    setSelectedItem(menu?.id)
                    window.location.href=(menu?.route)
                    setDdToShow(0);
                  }}
                >
                  <img src={menu?.icon} alt="menu" />
                  <p className={styles?.mflex}>
                    <p className={styles?.mhead}>{menu?.title}</p>
                    <p className={styles?.mdesc}>{menu?.desc}</p>
                  </p>
                </div>
              ))}
            </div>
          ) : null}

          {ddToshow == 1 ? (
            <div className={styles?.feautresMenuContainer}  onMouseLeave={handleClickOutside}>
              {featuresMenu?.map((tool, index) => (
  <div className={styles?.featuresMenuItems}>
    <a
      href={`/features#jumpto${tool?.loc}`}
      style={{ color: "inherit", textDecoration: "none" }}
      onClick={() => setDdToShow(0)}
    >
      <div className={styles.featuresHeading}>
        <img
          src={tool?.icon}
          alt=""
          style={{ marginRight: "0.62rem", height: "1.125rem", width: "1.125rem" }}
          className={styles.iconImg}
        />
        {tool?.name}
      </div>
    </a>
    {tool?.tools?.map((toolItem) => (
      <div
        className={`${styles.featuresSubHeading}
         ${subSelectedItem === toolItem.id ? styles.featuresSubHeadingActive : ''}
          ${index === 0 ? styles.firstListItem : ''}`}
        style={{ marginLeft: "1.5rem" }}
        onClick={() => {
          setSubSelectedItem(toolItem.id);
          navigate(toolItem.route);
        }}
      >
        {toolItem.name}
      </div>
    ))}
  </div>
))}

            </div>
          ) : null}

{ddToshow == 2 ? (
    <>
      <div
        className={styles?.dropdownContainer} // Add a wrapper around the dropdown
        onMouseEnter={() => setDdToShow(2)} // Ensure dropdown stays open when hovered
        onMouseLeave={handleClickOutside} // Close only when mouse leaves the entire container
      style={{display:"flex"}}
      >
        <div className={`${styles?.ddmenus}`}>
          {wwsmenu?.map((menu) => (
            <div
              className={`${styles?.ddmenu} ${selectedItem === menu?.id ? styles.ddmenuActive : ''}`}
              onClick={() => {
                setSelectedItem(menu?.id);
                navigate(menu?.route);
                setDdToShow(0);
              }}
            >
              <img src={menu?.icon} alt="menu" />
              <p className={styles?.mflex}>
                <p className={styles?.mhead}>{menu?.title}</p>
                <p className={styles?.mdesc}>{menu?.desc}</p>
              </p>
            </div>
          ))}
        </div>

        <div className={styles?.blog}>
          <div className={styles?.bni}>
            <img
              style={{ width: '13.75rem', height: '8.75rem' }}
              src={
                ddToshow == 1
                  ? 'https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/featuresblog.png'
                  : 'https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/wwsBlog.png'
              }
              alt=""
            />
          </div>
          <p className={styles?.bhead}>Featured Blog Post</p>
          <p>
            {ddToshow == 1 ? (
              <span>
                Architect’s Guide to SEO: Elevating Your Online Presence ...
              </span>
            ) : (
              <span>
                All you need to know about modern kitchen interior designs ...
              </span>
            )}
            <span
              className={styles.readMore}
              onClick={() => {
                ddToshow == 1
                  ? window.location.assign(
                      'https://magazine.idesign.market/idesign-recommended-articles/architects-guide-to-seo-elevating-your-online-presence/'
                    )
                  : window.location.assign(
                      'https://blogs.idesign.market/kitchen/modern-kitchen-interior-design-ideas/'
                    );
              }}
              style={{ display: 'inline-flex' }}
            >
              <span style={{ userSelect: 'none' }}>Read More </span>
              <img
                src="https://idesign-quotation.s3.ap-south-1.amazonaws.com/NO_COMPANYNAME/ArrowUpRight.svg"
                alt="arrow"
                className={styles.arrow}
              />
            </span>
          </p>
        </div>
      </div>
    </>
  ) : null}

 
        </div>
      )}
    </div>
  );
};

export default Header;
