import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// import idesign from './SaasAssets/idesign.png'

const NewOnboardingHeader = () => {
    var firstLetter2 = ''
    var secondLetter2 = ''
    // const firstLetter2 =
    //     localStorage.getItem("initialName") &&
    //     localStorage.getItem("initialName").split(" ")[0].split("")[0].toUpperCase();
    // const secondLetter2 =
    //     localStorage.getItem("initialName") &&
    //     localStorage.getItem("initialName").split(" ")[0] &&
    //     localStorage.getItem("initialName").split(" ")[1].split("")[0].toUpperCase();
    
    if (localStorage.getItem("initialName") && localStorage.getItem("initialName").includes(' ') == true) {
        firstLetter2 = localStorage.getItem("initialName").split(" ")[0][0].toUpperCase();
        secondLetter2 = localStorage.getItem("initialName").split(" ")[1][0].toUpperCase();
    }
    if (localStorage.getItem("initialName") && localStorage.getItem("initialName").includes(' ') == false) {
        firstLetter2 = localStorage.getItem("initialName").split(" ")[0][0].toUpperCase();
    }

    const initials2 = firstLetter2 + secondLetter2;
    const navigateTo = useNavigate()
  const profileData = useSelector((state) => state.addToCartReducer.profileData);
    // console.log(profileData)

    // useEffect(() => {
    //     if (localStorage.getItem("pathname") === null) {
    //         if (localStorage.getItem("token") != "null" && localStorage.getItem("token") != null) {
    //             navigateTo("/lead")
    //             // setIsLoggedIn(true)
    //         }
    //     } else {
    //         const path = localStorage.getItem("pathname")
    //         navigateTo(`${path}`)
    //     }
    // }, [])

    return (
        <div style={{ height: "10vh", borderBottom: "1px solid #A7A7A7", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div className="d-flex justify-content-center" style={{ width: "80%" }}>
                {/* <img src={idesign} alt="" style={{ width: "12rem" }} /> */}
                {/* <div className="d-flex justify-content-center align-items-center">
                    <div style={{ fontSize: "14px", fontWeight: "14px", color: "black", marginRight: "0.5rem" }}>Hi! {localStorage.getItem("initialName")}</div>
                    <div className="initials-again">
                        {initials2}
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default NewOnboardingHeader