import React from "react";
import header from "./Images/headertop.svg";
import facebook from "../SaasOnboarding/PricingPage/footerImg/Facebook.svg";
import insta from "../SaasOnboarding/PricingPage/footerImg/Instagram.svg";
import utube from "../SaasOnboarding/PricingPage/footerImg/Youtube.svg";
import whatspp from "../SaasOnboarding/PricingPage/footerImg/Whatsapp.svg";
import { submitQuery } from "../../../Apis";
import { toast } from "react-hot-toast";
import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "./imagesMobImg/logo.png";
import { constants, otherPagesURL } from "../../../constants";
 
export default function FooterMobile() {
  console.log(constants)
  const [email, setEmail] = useState("");

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const newsLetterSubmit = () => {
    if (email.length > 0) {
      submitQuery({ type: 1, data: email }).then((res) =>
        toast.success("Newsletter subscribed")
      );
    } else {
      toast.error("Please enter email");
    }
  };

  return (
    <div className="" style={{ backgroundColor: "#ffffff" }}>
      <div
        className="d-flex "
        style={{ paddingTop: "10px", paddingLeft: "2rem" }}
      >
        <img src={logo} style={{ width: "15rem", height: "2.5rem" }} />
      </div>

      <div className="d-flex mt-3 flex-column " style={{ paddingLeft: "2rem" }}>
        <div className="company mb-3" style={{ color: "#0A142F" }}>
          Company
        </div>
        <div className="abt mb-3">
          <Link
            style={{ color: "#0A142F", textDecoration: "none" }}
            to="/about"
          >
            {" "}
            About
          </Link>
        </div>
        <div className="abt mb-3">
          {" "}
          <Link
            style={{ color: "#0A142F", textDecoration: "none" }}
            to="/contact"
          >
            Contact
          </Link>
        </div>
        <div className="abt mb-3">
          {" "}
          <Link
            style={{ color: "#0A142F", textDecoration: "none" }}
            to={otherPagesURL.careers}
          >
            Careers
          </Link>
        </div>
        <div className="abt mb-3">
          <Link
            style={{ color: "#0A142F", textDecoration: "none" }}
            to="/cookie-policy"
          >
            Cookie policy
          </Link>
        </div>
        <div className="abt mb-3">
          <Link
            style={{ color: "#0A142F", textDecoration: "none" }}
            to="/privacy-policy"
          >
            Privacy Policy
          </Link>
        </div>
        <div className="abt mb-3">
          <Link
            style={{ color: "#0A142F", textDecoration: "none" }}
            to="/terms"
          >
            Terms and Conditions
          </Link>
        </div>
        <div className="abt mb-3">
          <Link
            style={{ color: "#0A142F", textDecoration: "none" }}
            to="/terms"
          >
            Sitemap
          </Link>
        </div>

        <div className="company mt-3 mb-3">
          <a
            style={{ color: "#0A142F", textDecoration: "none" }}
            href={otherPagesURL.design_professionals}
          >
            Design Professionals
          </a>
        </div>
        <div className="abt mb-3">
          <div
            style={{ color: "#0A142F", textDecoration: "none" }}
            onClick={() => {
              window.location.assign(`${constants.PRO_URL}`)
            }}
          >
            Join iDesign Pro
          </div>
        </div>
        {/* <div className="abt mb-3">
          <Link
            target="_blank "
            style={{ color: "#0A142F", textDecoration: "none" }}
            to="/pricing"
          >
            Pricing
          </Link>
        </div> */}
        <div className="abt mb-3">
          <a
            style={{ color: "#0A142F", textDecoration: "none" }}
            href={otherPagesURL.magazine}
          >
            Magazine
          </a>
        </div>

        <Link
            style={{ color: "#0A142F", textDecoration: "none" }}
            to="/contact"
          >
            Contact us
          </Link>
        <div className="abt mb-3">
          <Link style={{ color: "#0A142F", textDecoration: "none" }} to="/">
            mail :support@idesign.market
          </Link>
        </div>
        {/* <div className="abt mb-3">
          <Link
            target="_blank "
            style={{ color: "#0A142F", textDecoration: "none" }}
            to="/pricing"
          >
            Contact : +91-243678925
          </Link>
        </div> */}

        <div>
          <div className="company mt-3 mb-3">
            <a
              style={{ color: "#0A142F", textDecoration: "none" }}
              href={otherPagesURL.design_professionals}
            >
              Socials
            </a>
          </div>

          <div
            className="d-flex mt-2 mb-3"
            style={{ width: "50%", alignItems: "center" }}
          >
            <a
              href={otherPagesURL.facebook}
              style={{ textDecoration: "none" }}
            >
              {" "}
              <img src={facebook} />
            </a>
            <a
              href={otherPagesURL.whatsapp}
              style={{ textDecoration: "none" }}
            >
              {" "}
              <img
                src={whatspp}
                style={{ marginLeft: "1rem", color: "#000000" }}
              />
            </a>
            <a
              href={otherPagesURL.youtube}
              style={{ textDecoration: "none" }}
            >
              {" "}
              <img src={utube} style={{ marginLeft: "1rem" }} />
            </a>
            <a
              href={otherPagesURL.instagram}
              style={{ textDecoration: "none" }}
            >
              {" "}
              <img src={insta} style={{ marginLeft: "1rem" }} />
            </a>
          </div>
        </div>
      </div>
      <div
        className=" d-flex justify-content-center  footer_bottom"
        style={{
          background: "#ffffff",
          color: "#000000",
          borderTop: "1px solid #d6d4d4",
          marginRight: "1rem",
          marginLeft: "1rem",
          paddingTop: "1rem",
          paddingBottom: "1rem",
        }}
      >
        2024 © iDesign | All rights reserved | TECH DESIGNWORKS PVT. LTD
      </div>
    </div>
  );
}
