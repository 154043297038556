import React from "react";
import styles from "../cssFiles/bottom.module.css";
import { useState, useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import Files from "./Files";
import Tasks from "./Tasks";
import UpdatesNew from "./UpdatesNew";
import axios from 'axios'

const Bottom = () => {
  const [tabActive, settabActive] = useState("active");
  const [data, setData] = useState([])
  const getTimeline = async () => {
    await axios.get(` https://pmt-api.essentiaenvironments.com/api/timelines2/get-timeline-clientDashboard?clientId=${localStorage.getItem('userId')}`).then((res) => {
      setData(res.data)
    })
    .catch(err => {
      console.log(err)
    })
  }

  useEffect(() =>{
    getTimeline()
  }, [])

  return (
    <>
      <div className={styles.container}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            paddingTop: "1rem",
         
          }}
        >
          <div style={{ display: "flex", gap: "2rem" }}>
            <div>
              <div className={styles.admin_name}>Shubham's Villa</div>
              <div className={styles.place}>Hauz Khaz, Delhi</div>
            </div>
            <button className={styles.daysBtn}>40 Days Delayed</button>
            {/* {final < 0 ? (
              <button className={styles.daysBtnRed}>
                {final} Days Delayed
              </button>
            ) : (
              <button className={styles.daysBtn}>
                {final} Days to Handover
              </button>
            )} */}
          </div>
          <div className={styles.lastUpdated}>Last Updated :28 July 23</div>
        </div>
        <div className={styles.tableDiv}>
          <div className={styles.tableTabs}>
            <div
              className={
                tabActive === "active" ? styles.eachTabActive : styles.eachTab
              }
              onClick={() => settabActive("active")}
              style={{ paddingRight: "1rem" }}
            >
              Timeline
            </div>
            <div
              className={
                tabActive === "active2" ? styles.eachTabActive : styles.eachTab
              }
              onClick={() => settabActive("active2")}
              style={{ paddingRight: "1rem" }}
            >
              Files
            </div>
            <div
              className={
                tabActive === "active3" ? styles.eachTabActive : styles.eachTab
              }
              onClick={() => settabActive("active3")}
              style={{ paddingRight: "1rem" }}
            >
              Tasks
            </div>
            <div
              className={
                tabActive === "active4" ? styles.eachTabActive : styles.eachTab
              }
              onClick={() => settabActive("active4")}
              style={{ paddingRight: "1rem" }}
            >
              Updates
            </div>
          </div>
        </div>

      {tabActive==="active"? 
      
      <div className={styles.contentDiv}>
        {data.map((item, index) => (
        <div style={{marginBottom:"1.5rem"}}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className={styles.smallText}>
            <div>Flooring</div>
            <div className={styles.date}>12 Aug 23- 23 Aug 23</div>
          </div>

          <div className={styles.smallText}>
            <div>50%</div>
            <div style={{ fontWeight: "500", fontSize: "12px" }}>
              In-Progress
            </div>
          </div>
        </div>
        {/* <ProgressBar striped now="20" style={{ height: "0.8rem",marginTop:"0.6rem" }} /> */}
        <div className={styles.progress}>
          <div className={styles.progressChild}></div>
        </div>
      </div>
        ))}
          

          <div style={{display:"flex",gap:"4rem",marginTop:"3rem"}}>
            <div className={styles.latestText}>Latest Report: 12 Apr 23</div>
            <div className={styles.viewAll}>View Report</div>
          </div>
        </div>:tabActive==="active2"?<Files/>:tabActive==="active3"?<Tasks/>:tabActive==="active4"?<UpdatesNew/>:""}
      
      </div>
    </>
  );
};

export default Bottom;
