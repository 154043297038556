import React, { useEffect } from 'react'
import SideBarWeb from '../Lms/LmsWebComponents/SideBarWeb'
import designerPic1 from './VendorImgs/designerPic1.svg'
import suitcase from './VendorImgs/suitcase.svg'
import project from './VendorImgs/project.svg'
import whatsApp from './VendorImgs/whatsApp.svg'
import phone from './VendorImgs/phone.svg'
// import DesignerVendorSide from './DesignerVendorSide'
import DesignerVenMob from './DesignerVenMob'
import profilepic from './VendorImgs/profilepic.svg'
// import suitcase from './VendorImgs/suitcase.svg'
// import project from './VendorImgs/project.svg'
import redirectDesign from './VendorImgs/redirectDesign.svg'
import detailedView from './VendorImgs/detailedView.svg'
import profilepicblah from './VendorImgs/profilepicblah.svg'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProfileData } from '../Lms/Actions'
import { getToken } from '../Components/SecureChat/piservices/authService';
import axios from 'axios'
import SidebarWebNew from '../Lms/LmsWebComponents/SidebarNew/SidebarWebNew'
import HeaderNav from '../Lms/LmsWebComponents/HeaderNav/HeaderNav'

const DesignerVendorPage1 = () => {
    const authTok = localStorage.getItem("token") ? `Bearer ${localStorage.getItem("token")}` : "";
    const [data, setData] = useState([])
    const navigate = useNavigate()
    const authToken = localStorage.getItem("token") ? getToken() : "";
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [active, setActive] = useState(false)
    const [showId, setShowId] = useState();
    const [ofArr, setOfArr] = useState([])
    const [new_, setNew] = useState([[0, true], []])

    // new_[0] = [0, true]

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {
        // setShowId(id)
        setShow(true)
    };

    useEffect(() => {
        setShowId(data?.data && data?.data[0]?._id)
    }, [data?.data])

    // console.log(showId)

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const profileInfo = useSelector((state) => state.addToCartReducer.profileData);
    const [recoVenData, setRecoVenData] = useState({
        name: "",
        number: "",
        location: ""
    })

    const handleInput = (e) => {
        let name = e.target.name;
        if (name === "number") {
            if (e.target.value.length <= 10) {
                setRecoVenData((prev) => {
                    return {
                        ...prev,
                        [e.target.name]: e.target.value
                    }
                })
            }
        }
        else {
            setRecoVenData((prev) => {
                return {
                    ...prev,
                    [e.target.name]: e.target.value
                }
            })
        }

    }

    useEffect(() => {
        dispatch(fetchProfileData(authToken))
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault()
        setShow(false)

        const payload = {
            name: recoVenData.name,
            phoneNumber: recoVenData.number,
            location: recoVenData.location
        }
        const res = await axios.post("https://pro-api.idesign.market/api/submitVendorDetails", payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authTok
            }
        }).then((res) => {
            console.log(res)
        }).catch(err => console.warn(err))
    }

    const exceptThisSymbols = ["e", "E", "+", "-", "."];

    useEffect(async () => {
        const res = await axios.get("https://pro-api.idesign.market/api/listVendors", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authTok
            }
        }).then((res) => {
            // console.log(res)
            setData(res?.data?.data)
        }).catch(err => console.warn(err))
    }, [])

    const handleShow2 = (id) => {
        setShowId(id)
        setShow2(true)
    };

    // useEffect(() => {
    //     const b = data?.data?.filter((currElem) => {
    //         return currElem._id == showId
    //     })
    //     setOfArr(b)
    // }, [showId])

    // console.log(showId)

    // console.log(ofArr && ofArr[0])
    // console.log(data?.data)
    // for (let i = 0; i < ofArr[0]?.contractorVendorFees.length ; i++){

    // }

    // useEffect(() => {
    //     Object.entries(ofArr && ofArr[0]?.contractorVendorFees)
    // }, [ofArr && ofArr[0]?.contractorVendorFees])

    // console.log(Object.entries(ofArr && ofArr[0]?.contractorVendorFees))



    useEffect(async () => {
        const res = await axios.get(`https://pro-api.idesign.market/api/listVendors?designerId=${showId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authTok
            }
        }).then((res) => {
            // console.log(Object.entries(res?.data?.data?.data[0]?.contractorVendorFees))
            setOfArr(res?.data?.data?.data[0])
        }).catch(err => console.warn(err))
    }, [showId])

    // console.log(ofArr?.contractorVendorFees && Object.keys(ofArr?.contractorVendorFees).map((e,i) => {
    //     Object.keys(ofArr?.contractorVendorFees[e]).map((x,y) => {
    //         return ofArr?.contractorVendorFees[e][x]
    //     })
    // }))

    // useEffect(() => {
    //     
    // }, [ofArr?.contractorVendorFees])


    // console.log(ofArr?.contractorVendorFees)

    // console.log(ofArr && Object.keys(ofArr?.contractorVendorFees))
    // console.log(ofArr?.contractorVendorFees && Object.entries(ofArr?.contractorVendorFees))

    return (
        <>
            <div className="d-md-block d-none">
                <Modal
                    show={show}
                    onHide={handleClose}
                    centered
                >
                    <Modal.Header className="p-0" closeButton style={{ margin: "16px 16px 0 16px" }}>
                        <Modal.Title style={{ borderBottom: "1px solid #DFDFDF", width: "100%", fontSize: "18px" }}> Vendor Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="p-0" style={{ margin: "16px" }}>
                        <form style={{ marginBottom: "24px", display: "flex", flexDirection: "column" }} onSubmit={handleSubmit}>
                            <strong style={{ fontWeight: "500" }}>Name</strong>
                            <input className='mb-3 input-blah' name="name" value={recoVenData.name} onChange={handleInput} type="text" placeholder='For eg. - Dikshant Negi' required />
                            <strong style={{ fontWeight: "500" }}>Contact No.</strong>
                            <input className='mb-3 input-blah' name="number" value={recoVenData.number} onChange={handleInput} type="number" placeholder='For eg. - 98xxxxxx69' required onKeyDown={(e) =>
                                exceptThisSymbols.includes(e.key) && e.preventDefault()} />
                            <strong style={{ fontWeight: "500" }}>Location</strong>
                            <input className='mb-3 input-blah' type="text" value={recoVenData.location} onChange={handleInput} name="location" placeholder='For eg. - near Palam' required />
                            <button className="gstModalWebButton">Confirm</button>
                        </form>
                    </Modal.Body>
                </Modal>
                <Modal show={show2} onHide={handleClose2} centered size="md">
                    <Modal.Header closeButton>

                    </Modal.Header>
                    <Modal.Body className='d-flex flex-column p-4'>
                        Phone Number:
                        {ofArr && ofArr?.phoneNumber}
                    </Modal.Body>
                </Modal>
                <HeaderNav />
                <div className=" d-flex w-100">
                    <div className="sidBarHeight d-none d-md-block" style={{ height: "90vh", width: "22vw", borderRight: "1px solid #A7A7A7", paddingRight: "0" }}>
                        <SidebarWebNew />
                    </div>
                    <div className="d-flex w-100" style={{ background: "white" }}>
                        {!open ? <div className="d-flex w-100">
                            <div className="d-flex claasname flex-column p-4 w-70" style={{ height: "90vh", overflowY: "scroll" }}>
                                <div className="d-flex">
                                    <h2 className="ven_head mx-4">Recommended Page in {profileInfo[0]?.data?.data?.city}</h2>
                                    <div className="d-flex mx-3" style={{
                                        position: "absolute",
                                        right: "0"
                                    }}>
                                        {profileInfo[0]?.data?.data?.type === 2 ? <button className="ven-vlah-vlah mx-2" onClick={() => navigate("/vendor-page")}>Get your listing here</button> : ""}
                                        <button className="ven-vlah-vlah" onClick={handleShow}>Recommend a Vendor</button>
                                    </div>
                                </div>
                                {data?.data?.length > 0 ? <div className="d-flex flex-column p-4 w-100">
                                    {data?.data?.map((item, index) => {
                                        return (
                                            <div className={index == new_[index][0] && new_[index][1] == true ? "des-ven-start22 d-flex w-100 p-2 mb-3" : "des-ven-start d-flex w-100 p-2 mb-3"} id={index}>
                                                <img src={item?.coverImage?.original ? item?.coverImage?.original : profilepicblah} alt="" className='ven-img' />
                                                <p className="des-ven-p d-flex flex-column p-2 mt-2" style={{ alignSelf: "center" }} onClick={() => {
                                                    new_[index] = [index, true]

                                                    for (let i = 0; i < new_.length; i++) {
                                                        if (new_[i][0] != index) {
                                                            new_[i] = [index, false]
                                                        }

                                                        if (new_[i][0] = index) {
                                                            new_[i] = [index, true]
                                                        }
                                                    }
                                                    setShowId(item._id)
                                                }}>{item?.companyName}<span className="des-ven-s">{item?.city}</span></p>
                                                <div className="d-flex flex-column mx-2 px-2" style={{ alignSelf: "center" }}>
                                                    <div className="d-flex m-1">
                                                        <img src={suitcase} alt="" />
                                                        <span className="des-ven-s mx-2"><strong style={{ color: "black", fontWeight: "500" }}>{item?.workExperience}</strong>{" "} years of experience </span>
                                                    </div>
                                                    <div className="d-flex m-1">
                                                        <img src={project} alt="" />
                                                        <span className="des-ven-s mx-2"><strong style={{ color: "black", fontWeight: "500" }}>{item?.NumberOfProjectsDone}</strong>{" "} projects completed </span>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column" style={{ marginLeft: "auto", marginTop: "auto" }}>
                                                    <div className="d-flex">
                                                        <img src={phone} className='mx-1' style={{ height: "35px", width: "35px" }} alt="" onClick={() => handleShow2(item._id)} />
                                                        <a href={`https://wa.me/${item?.phoneNumber}`} target="_blank"><img className='mx-1' style={{ height: "35px", width: "35px" }} src={whatsApp} alt="" /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div> : <div className='no-content'> No vendors in your city</div>}
                            </div>
                            {ofArr && data?.data?.length > 0 ? <div className='ven-side'>
                                <div className="d-flex flex-column w-100 claasname" style={{ height: "77vh", overflowY: "scroll" }}>
                                    <div className="d-flex w-100 mx-2 mt-3">
                                        <img src={ofArr?.coverImage?.original ? ofArr?.coverImage?.original : profilepicblah} alt="" className="ven-pro" />
                                        <p className="des-ven-p d-flex flex-column p-2">{ofArr?.companyName}<span className="des-ven-s">{ofArr?.city}</span></p>
                                        <a target="_blank" href={`https://www.idesign.market/find-professionals/contractors-in-${ofArr?.city?.toLowerCase()}/${ofArr?.companyName?.toLowerCase()}`}> <img src={redirectDesign} alt="" className="ven-gm" style={{ marginBottom: "auto", width: "25px", height: "25px", marginTop: "5px", cursor: "pointer" }} /></a>
                                    </div>
                                    <div className="d-flex des-ven-blah justify-content-between align-self-center">
                                        <div className="d-flex" style={{ cursor: "pointer" }} role="button" onClick={() => {
                                            // console.log(showId)
                                            setOpen(true)
                                        }}>
                                            <img src={detailedView} alt="" style={{ width: "15px", height: "15px", marginTop: "9px", cursor: "pointer" }} />
                                            <p className="des-ven-p2 p-1" style={{ color: "#176091", cursor: "pointer" }}>Detailed View</p>
                                        </div>
                                        <p className="des-ven-p2 p-1">Rate List</p>
                                    </div>
                                    <div className="d-flex flex-column p-4">
                                        <div className="d-flex flex-column">
                                            <div className="d-flex justify-content-between" style={{ width: "96%" }}>
                                                <p className="r-sno">Item List</p>
                                                <p className="r-sno">Price</p>
                                            </div>
                                            <div className="d-flex flex-column claasname" style={{ height: "50vh", overflowY: "scroll" }} >
                                                {ofArr?.contractorVendorFees && Object.entries(ofArr?.contractorVendorFees).map((item, index) => (
                                                    <div className="d-flex justify-content-between ">
                                                        <p className="r_false3 mb-2">{item[1]?.name}</p>
                                                        <p className="r_false3 mb-2">₹ {item[1]?.rate?.toLocaleString('en-US')}</p>
                                                    </div>
                                                ))}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> : ""}
                        </div> :
                            <div className="d-flex w-100">
                                <div className="d-flex flex-column p-4 claasname" style={{ width: "31%", height: "50vh", overflowY: "scroll" }}>
                                    <div className="d-flex">
                                        <h2 className="ven_head" style={{
                                            position: "absolute"
                                        }}>Recommended Page in {profileInfo[0]?.data?.data?.city}</h2>
                                        <div className="d-flex mx-3" style={{
                                            position: "absolute",
                                            right: "0"
                                        }}>
                                            {profileInfo[0]?.data?.data?.type === 2 ? <button className="ven-vlah-vlah mx-2" onClick={() => navigate("/vendor-page")}>Get your listing here</button> : ""}
                                            <button className="ven-vlah-vlah" onClick={handleShow}>Recommend a Vendor</button>
                                        </div>
                                    </div>
                                    {data?.data?.length > 0 ? <div className="d-flex flex-column w-100" style={{ marginTop: "4rem" }}>
                                        {data?.data?.map((item, index) => (
                                            <div className={index == new_[index][0] && new_[index][1] == true ? "des-ven-start22 d-flex w-100 p-2 mb-3" : "des-ven-start d-flex w-100 p-2 mb-3"}>
                                                <img src={item?.coverImage?.original ? item?.coverImage?.original : profilepicblah} alt="" className='ven-img' />
                                                <p className="des-ven-p d-flex flex-column p-2" style={{ alignSelf: "center" }} onClick={() => {
                                                    new_[index] = [index, true]

                                                    for (let i = 0; i < new_.length; i++) {
                                                        if (new_[i][0] != index) {
                                                            new_[i] = [index, false]
                                                        }

                                                        if (new_[i][0] = index) {
                                                            new_[i] = [index, true]
                                                        }
                                                    }
                                                    setShowId(item._id)
                                                }}>{item?.companyName}<span className="des-ven-s mt-2">{item?.city}</span></p>
                                                <div className="d-flex flex-column" style={{ marginLeft: "auto", marginTop: "auto" }}>
                                                </div>
                                            </div>))}
                                    </div> : <div className='no-content'> No vendors in your city</div>}
                                </div>
                                {ofArr && <div className='ven-side2'>
                                    <div className="d-flex flex-column w-100 claasname" style={{ height: "77vh", overflowY: "scroll" }}>
                                        <div className="d-flex w-100 mx-2 mt-3" >
                                            <img src={ofArr?.coverImage?.original ? ofArr?.coverImage?.original : profilepicblah} alt="" className="ven-pro" />
                                            <p className="des-ven-p d-flex flex-column p-2">{ofArr?.companyName}<span className="des-ven-s">{ofArr?.city}</span></p>
                                            <a target="_blank" href={`https://www.idesign.market/find-professionals/contractors-in-${ofArr?.city?.toLowerCase()}/${ofArr?.companyName?.toLowerCase()}`}> <img src={redirectDesign} alt="" className="ven-gm" style={{ marginBottom: "auto", width: "25px", height: "25px", marginTop: "5px", cursor: "pointer" }} /></a>
                                            <div className="d-flex flex-column mt-2" style={{ marginLeft: "6rem" }}>
                                                <div className="d-flex m-1">
                                                    <img src={suitcase} alt="" />
                                                    <span className="des-ven-s mx-2"><strong style={{ color: "black", fontWeight: "500" }}>{ofArr?.workExperience}</strong> years of experience </span>
                                                </div>
                                                <div className="d-flex m-1">
                                                    <img src={project} alt="" />
                                                    <span className="des-ven-s mx-2"><strong style={{ color: "black", fontWeight: "500" }}>{ofArr?.NumberOfProjectsDone}</strong> projects completed </span>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column" style={{ marginLeft: "auto" }}>
                                                <div className="d-flex" style={{ marginRight: "2.5rem" }}>
                                                    <img className='mx-1' src={phone} style={{ height: "35px", width: "35px" }} alt="" onClick={() => handleShow2(ofArr?._id)} />
                                                    <a href={`https://wa.me/${ofArr?.phoneNumber}`} target="_blank"><img className='mx-1' src={whatsApp} style={{ height: "35px", width: "35px" }} alt="" /></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex des-ven-blah justify-content-between align-self-center">
                                            <div className="d-flex" role="button" style={{ cursor: "pointer" }} onClick={() => {
                                                // console.log(showId)
                                                setOpen(false)
                                            }}>
                                                <img src={detailedView} alt="" style={{ width: "15px", height: "15px", marginTop: "9px" }} />
                                                <p className="des-ven-p2 p-1" style={{ color: "#176091" }}>Basic Details</p>
                                            </div>
                                            <p className="des-ven-p2 p-1">Rate List</p>
                                        </div>
                                        <div className="d-flex flex-column p-4">
                                            <div className="d-flex flex-column">
                                                <div className="d-flex justify-content-between" style={{ width: "96%" }}>
                                                    <p className="r-sno">Item List</p>
                                                    <p className="r-sno" style={{
                                                        position: "relative",
                                                        left: "-3.3rem"
                                                    }}>Description</p>
                                                    <p className="r-sno" style={{
                                                        position: "relative",
                                                        left: "8.4rem"
                                                    }}>Unit</p>
                                                    <p className="r-sno" style={{
                                                        position: "relative",
                                                        left: "1.4rem"
                                                    }}>Price</p>
                                                </div>
                                                <div className="d-flex flex-column claasname" style={{ height: "50vh", overflowY: "scroll" }}>
                                                    {ofArr?.contractorVendorFees && Object.entries(ofArr?.contractorVendorFees).map((item, index) => (<div className="d-flex justify-content-between ">
                                                        <p className="r_false3 mb-2" style={{ width: "16%", color: "#444444" }}>{item[1]?.name}</p>
                                                        <p className="r_false3 mb-2" style={{ width: "50%", color: "#888888" }}>{item[1]?.description}</p>
                                                        <p className="r_false3 mb-2" style={{ color: "#444444" }}>{item[1]?.unit}</p>
                                                        <p className="r_false3 mb-2">₹ {item[1]?.rate?.toLocaleString('en-US')}</p>
                                                    </div>))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>}
                    </div>
                </div>
            </div>
            <DesignerVenMob />
        </>
    )
}

export default DesignerVendorPage1