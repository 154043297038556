import React, { useRef } from 'react'
import "./index.css"
import imgx from "./logo.webp"
const FullLoader = (props) => {
    const ref = useRef();
    
    console.log(props.msg)
    return (
        <div className={`container w-100 position-relative ${props.className} m-auto`} style={props.style} >
            <div className="text-align-center mb-4" >
                <img src={imgx} alt="" width={100} />
            </div>
            <div ref={ref} className="text-align-center" >
                {props.msg ? props.msg : <div className="fp-loading-bar m-auto">
                    <div className="fp-progress-bar" />
                </div>}
            </div>
        </div>

    )
}

export default FullLoader
